// eslint-disable-next-line no-unused-vars
import React from 'react';
import Framework from 'utils/framework';
import styled from 'react-emotion';
import shouldForwardProp from 'style/shouldForwardProp';

function getStyledComponent({ InnerComp, styledProps }) {
    let StyledComponent = styled(InnerComp, { shouldForwardProp })(...styledProps);
    StyledComponent = Framework.wrapComponent(StyledComponent, 'StyledComponent');

    return StyledComponent;
}

export default getStyledComponent;
