export default function getResource(label, vars = []) {
    const resources = {
        availabletimes: 'Heures de disponibilité',
        noTimesAvailable: 'Pas d’heures disponibles',
        for: 'pour',
        waitlist: 'Liste d’attente',
        availableArtists: 'Artistes disponibles',
        anyArtist: 'Tous les artistes disponibles',
        noArtistsAvailable: 'Aucun artiste disponible'
    };

    return resources[label];
}
