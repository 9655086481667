import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import Actions from 'Actions';
import UserActions from 'actions/UserActions';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import EmailLookupModalBindings from 'analytics/bindingMethods/components/globalModals/emailLookupModal/EmailLookupModalBindings';

const { wrapHOC } = FrameworkUtils;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/GlobalModals/EmailLookupModal/locales', 'EmailLookupModal');

const localization = createStructuredSelector({
    title: getTextFromResource(getText, 'title'),
    joinBiProgram: getTextFromResource(getText, 'joinBiProgram'),
    email: getTextFromResource(getText, 'email'),
    confirmButton: getTextFromResource(getText, 'confirmButton'),
    alreadyHaveAccount: getTextFromResource(getText, 'alreadyHaveAccount'),
    signIn: getTextFromResource(getText, 'signIn')
});

const fields = createStructuredSelector({
    localization
});

const functions = (dispatch, ownProps) => ({
    onDismiss: () => {
        dispatch(Actions.showEmailLookupModal({ isOpen: false }));
        typeof ownProps.originalArgumentsObj?.errback === 'function' && ownProps.originalArgumentsObj.errback();
    },
    showSignInModal: () => {
        dispatch(Actions.showEmailLookupModal({ isOpen: false }));
        dispatch(Actions.showSignInModal({ isOpen: true }));
    },
    checkUser: email => {
        const successCallback = data => {
            dispatch(Actions.showEmailLookupModal({ isOpen: false }));

            if (data.isStoreBiMember) {
                dispatch(Actions.showCheckYourEmailModal({ isOpen: true, email }));
            } else {
                const existingUserError = getText('existingUserError');
                dispatch(Actions.showSignInModal({ isOpen: true, email, messages: [existingUserError] }));
            }
        };

        const failureCallback = () => {
            dispatch(Actions.showEmailLookupModal({ isOpen: false }));
            dispatch(
                Actions.showRegisterModal({
                    ...ownProps.originalArgumentsObj,
                    isOpen: true,
                    userEmail: email,
                    isEmailDisabled: true,
                    skipEmailLookup: true
                })
            );
        };

        dispatch(UserActions.checkUser(email, successCallback, failureCallback));
    },
    pageLoadAnalytics: EmailLookupModalBindings.pageLoad
});

const withEmailLookupModalProps = wrapHOC(connect(fields, functions));

export {
    withEmailLookupModalProps, fields, functions
};
