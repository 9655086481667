/* eslint-disable complexity */
const ACTION_TYPES = {
    UPDATE_BASKET: 'UPDATE_BASKET',
    UPDATE_PICKUP_STORE_DETAILS: 'UPDATE_PICKUP_STORE_DETAILS',
    SHOW_BASKET_ERROR: 'SHOW_BASKET_ERROR',
    SHOW_BASKET_WARNING: 'SHOW_BASKET_WARNING',
    SHOW_STICKY_APPLE_PAY_BTN: 'SHOW_STICKY_APPLE_PAY_BTN',
    SHOW_PAYPAL_RESTRICTED_MESSAGE: 'SHOW_PAYPAL_RESTRICTED_MESSAGE',
    ADD_PENDING_SKU: 'ADD_PENDING_SKU',
    CLEAR_PENDING_SKU: 'CLEAR_PENDING_SKU',
    REMOVE_PENDING_SKU: 'REMOVE_PENDING_SKU',
    GET_BASKET_TYPE: 'GET_BASKET_TYPE',
    SET_BASKET_TYPE: 'SET_BASKET_TYPE',
    REMOVE_BASKET_SWITCH_MESSAGE: 'REMOVE_BASKET_SWITCH_MESSAGE',
    MARK_PROMO_WARNING_AS_SHOWN: 'MARK_PROMO_WARNING_AS_SHOWN',
    SET_FROM_BAZAAR: 'SET_FROM_BAZAAR'
};

const WARNING = 'warning';
const ERROR = 'error';
const PROMO_WARNING = 'basket.promoWarning';
const PROMO_INVALID = 'basketLevelMsg';

const initialState = {
    isInitialized: false,
    itemCount: 0,
    items: [],
    rewards: [],
    promos: [],
    appliedPromotions: [],
    samples: [],
    products: [],
    subtotal: '$0.00',
    rawSubTotal: '$0.00',
    pendingBasketSkus: [],
    currentBasketType: null,
    error: undefined,
    pickupBasket: {
        items: [],
        error: undefined
    }
};

/**
 * If removing property from basket state, don't remove property entirely.
 * If you do, state will not reflect change due to object.assign.
 * Instead set property value to null to simulate removal, this will update state.
 */
const reducer = function (state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_BASKET:
            return Object.assign(
                {},
                state,
                {
                    basketLevelMessages: undefined,
                    promoWarning: undefined,
                    firstBuyOrderDiscount: undefined,
                    error: action.clearError ? undefined : state.error
                },
                action.basket,
                {
                    pickupBasket: Object.assign(
                        {},
                        state.pickupBasket,
                        {
                            basketLevelMessages: undefined,
                            promoWarning: undefined,
                            firstBuyOrderDiscount: undefined,
                            error: action.clearError ? undefined : state.pickupBasket?.error
                        },
                        action.basket.pickupBasket
                    )
                }
            );
        case ACTION_TYPES.UPDATE_PICKUP_STORE_DETAILS: {
            return Object.assign({}, state, { pickupBasket: Object.assign({}, state.pickupBasket, { storeDetails: action.storeDetails }) });
        }
        case ACTION_TYPES.SHOW_BASKET_ERROR: {
            const nextItems = {
                ...(action.error && action.itemsAndErrors && { items: action.itemsAndErrors })
            };

            const nextState = action.isPickup
                ? {
                    ...state,
                    pickupBasket: {
                        ...state.pickupBasket,
                        error: action.error,
                        ...nextItems
                    }
                }
                : {
                    ...state,
                    error: action.error,
                    ...nextItems
                };

            return nextState;
        }
        case ACTION_TYPES.SHOW_BASKET_WARNING: {
            return Object.assign({}, state, { basketItemWarnings: action.basketItemWarnings });
        }
        case ACTION_TYPES.SHOW_STICKY_APPLE_PAY_BTN:
            return Object.assign({}, state, { showStickyApplePayBtn: action.showStickyApplePayBtn });
        case ACTION_TYPES.SHOW_PAYPAL_RESTRICTED_MESSAGE: {
            const nextState = action.isPickup
                ? Object.assign({}, state, {
                    pickupBasket: Object.assign({}, state.pickupBasket, { showPaypalRestrictedMessage: action.showPaypalRestrictedMessage })
                })
                : Object.assign({}, state, { showPaypalRestrictedMessage: action.showPaypalRestrictedMessage });

            return nextState;
        }
        case ACTION_TYPES.CLEAR_PENDING_SKU:
        case ACTION_TYPES.ADD_PENDING_SKU:
        case ACTION_TYPES.REMOVE_PENDING_SKU:
            return Object.assign({}, state, { pendingBasketSkus: action.pendingBasketSkus });
        case ACTION_TYPES.SET_BASKET_TYPE:
        case ACTION_TYPES.REMOVE_BASKET_SWITCH_MESSAGE: {
            const newState = Object.assign({}, state, {
                switchedItem: null,
                pickupBasket: Object.assign({}, state.pickupBasket, { switchedItem: null })
            });

            if (action.type === ACTION_TYPES.SET_BASKET_TYPE) {
                return Object.assign(newState, { currentBasketType: action.currentBasketType });
            }

            return newState;
        }
        case ACTION_TYPES.MARK_PROMO_WARNING_AS_SHOWN: {
            const basketLevelMessages = action.isPickup ? state.pickupBasket.basketLevelMessages : state.basketLevelMessages;

            const newBasketLevelMessages = basketLevelMessages.map(msg => {
                if ([WARNING, ERROR].includes(msg.type) && [PROMO_WARNING, PROMO_INVALID].includes(msg.messageContext)) {
                    msg.wasShown = true;
                }

                return msg;
            });

            const nextState = action.isPickup
                ? Object.assign({}, state, {
                    pickupBasket: Object.assign({}, state.pickupBasket, {
                        basketLevelMessages: newBasketLevelMessages
                    })
                })
                : Object.assign({}, state, { basketLevelMessages: newBasketLevelMessages });

            return nextState;
        }
        case ACTION_TYPES.SET_FROM_BAZAAR: {
            //Toggles boolean whether the action came from bazaar modal comp.
            return {
                ...state,
                fromBazaar: action.payload
            };
        }
        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
