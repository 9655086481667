/* eslint-disable object-curly-newline */
import { createContext, useReducer, useEffect } from 'react';
import AnalyticsConstants from 'analytics/constants';
import AnalyticsUtils from 'analytics/utils';
import deepEqual from 'deep-equal';
import linkTrackingError from 'analytics/bindings/pages/all/linkTrackingError';
import processEvent from 'analytics/processEvent';
import React from 'react';
import activityConstants from 'components/OnlineReservation/activityConstants';

const { WAIVER } = activityConstants;

const {
    Event: { EVENT_71 },
    LINK_TRACKING_EVENT
} = AnalyticsConstants;

const ModalContext = createContext({});

const ActionType = {
    RESET_STATE: 'RESET_STATE',
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    SET_READY_TO_USE_CREDIT_CARD: 'SET_READY_TO_USE_CREDIT_CARD',
    IS_PERSONAL_SERVICE: 'IS_PERSONAL_SERVICE',
    EDIT_CREDIT_CARD: 'EDIT_CREDIT_CARD',
    UPDATE_WAIVER_INFO: 'UPDATE_WAIVER_INFO',
    SET_READY_FOR_PAYMENT: 'SET_READY_FOR_PAYMENT'
};

const isReadyToSubmit = (userInfo, isEditingCreditCard, waiverInfo, paymentEnable, personalService) => {
    return (
        userInfo &&
        userInfo.phone &&
        (personalService ? true : paymentEnable) &&
        !isEditingCreditCard &&
        (waiverInfo.shouldShow
            ? waiverInfo.prefValue === WAIVER.ACKFORCLIENT || (waiverInfo.prefValue === WAIVER.ACKFORCLIENTUNDER18 && waiverInfo.hasParentAccepted)
            : true)
    );
};

const initialState = {
    readyToSubmit: false,
    selectedCreditCard: null,
    userInfo: null,
    isEditingCreditCard: false,
    waiverInfo: {},
    paymentEnable: false,
    personalService: false
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionType.RESET_STATE: {
            return initialState;
        }
        case ActionType.UPDATE_USER_INFO: {
            return {
                ...state,
                readyToSubmit: isReadyToSubmit(payload, state.isEditingCreditCard, state.waiverInfo, state.paymentEnable, state.personalService),
                userInfo: payload
            };
        }
        case ActionType.SET_READY_TO_USE_CREDIT_CARD: {
            return {
                ...state,
                selectedCreditCard: payload
            };
        }
        case ActionType.IS_PERSONAL_SERVICE: {
            return {
                ...state,
                personalService: payload
            };
        }
        case ActionType.UPDATE_WAIVER_INFO: {
            return {
                ...state,
                waiverInfo: payload,
                readyToSubmit: isReadyToSubmit(state.userInfo, state.isEditingCreditCard, payload, state.paymentEnable, state.personalService)
            };
        }
        case ActionType.SET_READY_FOR_PAYMENT: {
            return {
                ...state,
                paymentEnable: payload,
                readyToSubmit: isReadyToSubmit(state.userInfo, state.isEditingCreditCard, state.waiverInfo, payload)
            };
        }
        case ActionType.EDIT_CREDIT_CARD: {
            return {
                ...state,
                isEditingCreditCard: payload,
                readyToSubmit: isReadyToSubmit(state.userInfo, payload, state.waiverInfo, state.paymentEnable)
            };
        }
        default: {
            return state;
        }
    }
};

const ModalContextProvider = ({ contextComponent, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => () => dispatch({ type: ActionType.ResetState }), []);

    return (
        <ModalContext.Provider
            value={{
                state: {
                    ...state,
                    contextComponent
                },
                dispatch
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

const ModalContextConsumer = props => {
    const { children } = props;

    return (
        <ModalContext.Consumer>
            {({ state, dispatch }) =>
                typeof children === 'function'
                    ? children({
                        ...state,
                        editCreditCard: isEditingCreditCard => editCreditCard(isEditingCreditCard, dispatch),
                        setReadytoUseCreditcard: readyToUseCreditCard => setReadytoUseCreditcard(readyToUseCreditCard, dispatch),
                        updateWaiverInfo: waiverInfo => updateWaiverInfo(waiverInfo, dispatch),
                        triggerAnalyticsErrors: errors => triggerAnalyticsErrors(errors, props),
                        updateUserInfo: userInfo => updateUserInfo(userInfo, dispatch, state)
                    })
                    : null
            }
        </ModalContext.Consumer>
    );
};

const withModalContext = WrappedComponent => {
    const modalContextHOC = props => (
        <ModalContext.Consumer>
            {({ state, dispatch }) => (
                <WrappedComponent
                    {...props}
                    {...state}
                    editCreditCard={isEditingCreditCard => editCreditCard(isEditingCreditCard, dispatch)}
                    setReadytoUseCreditcard={readyToUseCreditCard => setReadytoUseCreditcard(readyToUseCreditCard, dispatch)}
                    setPersonalService={personalService => setPersonalService(personalService, dispatch)}
                    updateWaiverInfo={waiverInfo => updateWaiverInfo(waiverInfo, dispatch)}
                    triggerAnalyticsErrors={errors => triggerAnalyticsErrors(errors, props)}
                    updateUserInfo={userInfo => updateUserInfo(userInfo, dispatch, state)}
                    updateIsPaymentEnable={paymentEnable => updateIsPaymentEnable(paymentEnable, dispatch)}
                />
            )}
        </ModalContext.Consumer>
    );
    modalContextHOC.class = 'modalContextHOC';

    return modalContextHOC; // wrapFunctionalComponent(modalContextHOC, 'modalContextHOC');
};

const setReadytoUseCreditcard = (readyToUseCreditCard, dispatch) => {
    dispatch({
        type: ActionType.SET_READY_TO_USE_CREDIT_CARD,
        payload: readyToUseCreditCard
    });
};

const setPersonalService = (personalService, dispatch) => {
    dispatch({
        type: ActionType.IS_PERSONAL_SERVICE,
        payload: personalService
    });
};

const updateWaiverInfo = (waiverInfo, dispatch) => {
    dispatch({
        type: ActionType.UPDATE_WAIVER_INFO,
        payload: waiverInfo
    });
};

const updateIsPaymentEnable = (paymentEnable, dispatch) => {
    dispatch({
        type: ActionType.SET_READY_FOR_PAYMENT,
        payload: paymentEnable
    });
};

const editCreditCard = (isEditingCreditCard, dispatch) => {
    dispatch({
        type: ActionType.EDIT_CREDIT_CARD,
        payload: isEditingCreditCard
    });
};

const updateUserInfo = (updatedUserInfo, dispatch, state) => {
    const { userInfo } = state;
    const userInfoIsUpdated = !deepEqual(userInfo, updatedUserInfo);

    if (userInfoIsUpdated) {
        dispatch({
            type: ActionType.UPDATE_USER_INFO,
            payload: updatedUserInfo
        });
    }
};

const triggerAnalyticsErrors = (errors, { modalAnalytics }) => {
    let lastAsyncPageLoadData = AnalyticsUtils.getLastAsyncPageLoadData();

    if (modalAnalytics) {
        lastAsyncPageLoadData = {
            ...lastAsyncPageLoadData,
            ...modalAnalytics
        };
    }

    processEvent.process(LINK_TRACKING_EVENT, {
        data: {
            linkName: 'error',
            eventStrings: [EVENT_71],
            bindingMethods: linkTrackingError,
            errorMessages: errors,
            ...lastAsyncPageLoadData
        }
    });
};

export { ActionType, ModalContext, ModalContextConsumer, ModalContextProvider, withModalContext };
