import React from 'react';
import { Flex } from 'components/ui';
import FrameworkUtils from 'utils/framework';

const { wrapFunctionalComponent } = FrameworkUtils;

const ChatIcon = React.forwardRef(({
    isUnavailable, isFilled, children, showUpdatedChatIcon, showUpdatedUnavailableIcon, ...props
}, ref) => {
    return (
        <Flex
            ref={ref}
            position='relative'
            alignItems='center'
            css={
                isUnavailable || {
                    '.no-touch &:hover svg': {
                        fill: 'currentColor'
                    }
                }
            }
            {...props}
        >
            <span css={{ position: 'relative' }}>
                <svg
                    width={24}
                    height={24}
                    fill={isFilled ? 'currentColor' : 'none'}
                    css={{ display: 'block' }}
                >
                    {isUnavailable && showUpdatedUnavailableIcon ? (
                        <g>
                            <path
                                d='M10.9893 3C5.47237 3 1 7.47715 1 13C1 18.5228 5.47237 23 10.9893 23C13.1621 23 15.1729 22.3056 16.8122 21.1263L21 22.2496L19.7911 17.7331C20.5487 16.3241 20.9786 14.7123 20.9786 13'
                                stroke='black'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M10.9893 3.5C5.74902 3.5 1.5 7.75279 1.5 13C1.5 18.2472 5.74902 22.5 10.9893 22.5C13.0538 22.5 14.9631 21.8405 16.5202 20.7204C16.642 20.6328 16.7968 20.6045 16.9417 20.6434L20.2931 21.5423L19.3081 17.8624C19.2752 17.7393 19.2904 17.6084 19.3507 17.4963C20.0702 16.1583 20.4786 14.6276 20.4786 13C20.4786 12.7239 20.7025 12.5 20.9786 12.5C21.2548 12.5 21.4786 12.7239 21.4786 13C21.4786 14.7256 21.0624 16.3553 20.3246 17.7926L21.483 22.1203C21.5292 22.2929 21.4798 22.477 21.3535 22.6033C21.2271 22.7295 21.043 22.7788 20.8705 22.7325L16.9083 21.6697C15.2235 22.8244 13.1846 23.5 10.9893 23.5C5.19572 23.5 0.5 18.7985 0.5 13C0.5 7.20151 5.19572 2.5 10.9893 2.5C11.2655 2.5 11.4893 2.72386 11.4893 3C11.4893 3.27614 11.2655 3.5 10.9893 3.5Z'
                                fill='#666666'
                            />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M17.5 11C19.9853 11 22 8.98528 22 6.5C22 4.01472 19.9853 2 17.5 2C15.0147 2 13 4.01472 13 6.5C13 8.98528 15.0147 11 17.5 11ZM17.5 12C20.5376 12 23 9.53757 23 6.5C23 3.46243 20.5376 1 17.5 1C14.4624 1 12 3.46243 12 6.5C12 9.53757 14.4624 12 17.5 12Z'
                                fill='#666666'
                            />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M13.6917 9.88482L20.4609 2.69251L21.1284 3.32076L14.3592 10.5131L13.6917 9.88482Z'
                                fill='#666666'
                            />
                        </g>
                    ) : isUnavailable ? (
                        <g
                            fill='none'
                            fillRule='evenodd'
                        >
                            <path
                                d='M11.99 2C6.471 2 2 6.477 2 12s4.472 10 9.99 10a9.936 9.936 0 0 0 5.822-1.874L22 21.25l-1.209-4.517A9.965 9.965 0 0 0 21.98 12'
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                            />
                            <path
                                d='M11.99 2.5C6.748 2.5 2.5 6.753 2.5 12s4.249 9.5 9.49 9.5c2.064 0 3.973-.66 5.53-1.78a.5.5 0 0 1 .422-.077l3.351.9-.985-3.68a.5.5 0 0 1 .043-.367A9.464 9.464 0 0 0 21.479 12a.5.5 0 0 1 1 0c0 1.726-.417 3.355-1.154 4.793l1.158 4.327a.5.5 0 0 1-.613.613l-3.962-1.063a10.435 10.435 0 0 1-5.919 1.83C6.196 22.5 1.5 17.799 1.5 12c0-5.798 4.696-10.5 10.49-10.5a.5.5 0 1 1 0 1z'
                                fill='currentColor'
                            />
                            <path
                                d='M18.5 10a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zm0 1a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
                                fill='currentColor'
                            />
                            <path
                                fill='currentColor'
                                d='m14.692 8.885 6.769-7.193.667.629-6.769 7.192z'
                            />
                        </g>
                    ) : showUpdatedChatIcon ? (
                        <g>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.9893 2.5C6.74902 2.5 2.5 6.75279 2.5 12C2.5 17.2472 6.74902 21.5 11.9893 21.5C14.0538 21.5 15.9631 20.8405 17.5202 19.7204C17.642 19.6328 17.7968 19.6045 17.9417 19.6434L21.2931 20.5423L20.3081 16.8624C20.2752 16.7393 20.2904 16.6084 20.3507 16.4963C21.0702 15.1583 21.4786 13.6276 21.4786 12C21.4786 10.9826 21.3191 10.0036 21.024 9.08593C20.966 8.90541 20.9027 8.72725 20.8343 8.55162C20.7341 8.29429 20.8615 8.00447 21.1188 7.9043C21.3762 7.80412 21.666 7.93152 21.7662 8.18885C21.8418 8.38309 21.9118 8.58014 21.976 8.77981C22.3025 9.79518 22.4786 10.8775 22.4786 12C22.4786 13.7256 22.0624 15.3553 21.3246 16.7926L22.483 21.1203C22.5292 21.2929 22.4798 21.477 22.3535 21.6033C22.2271 21.7295 22.043 21.7788 21.8705 21.7325L17.9083 20.6697C16.2235 21.8244 14.1846 22.5 11.9893 22.5C6.19572 22.5 1.5 17.7985 1.5 12C1.5 6.20151 6.19572 1.5 11.9893 1.5C13.3339 1.5 14.6205 1.7535 15.8033 2.2158C16.0605 2.31633 16.1875 2.60633 16.0869 2.86352C15.9864 3.12071 15.6964 3.24771 15.4392 3.14718C14.3706 2.72949 13.2073 2.5 11.9893 2.5Z'
                                fill='black'
                            />
                            <path
                                d='M23.5 4C23.5 5.933 21.933 7.5 20 7.5C18.067 7.5 16.5 5.933 16.5 4C16.5 2.067 18.067 0.5 20 0.5C21.933 0.5 23.5 2.067 23.5 4Z'
                                fill='#008048'
                                stroke='white'
                            />
                        </g>
                    ) : (
                        <path
                            d='m21.87 21.733.837.224-.224-.837-1.158-4.327A10.466 10.466 0 0 0 22.479 12c0-5.798-4.696-10.5-10.49-10.5C6.196 1.5 1.5 6.202 1.5 12c0 5.799 4.696 10.5 10.49 10.5 2.195 0 4.233-.676 5.918-1.83l3.962 1.063z'
                            stroke='currentColor'
                        />
                    )}
                </svg>
                {children}
            </span>
        </Flex>
    );
});

export default wrapFunctionalComponent(ChatIcon, 'ChatIcon');
