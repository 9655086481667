import ufeApi from 'services/api/ufeApi';
const endpoint = '/api/activity/policies';
const activityIdPrefix = 'OLR-CD__';
/* eslint-disable max-len */
// API documentation: https://confluence.sephora.com/wiki/pages/viewpage.action?spaceKey=ILLUMINATE&title=Activity+Policies+API
/* eslint-enable max-len */

function getPoliciesPerActivity(token, params) {
    if (!params?.activityId) {
        return Promise.reject(new Error('Empty activityId, an activity id should be provided.'));
    }

    const url = `${endpoint}/${params.activityId.replace(activityIdPrefix, '')}`;

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getPoliciesPerActivity;
