import anaConsts from 'analytics/constants';
import anaUtils from 'analytics/utils';
import processEvent from 'analytics/processEvent';

const {
    LINK_TRACKING_EVENT, ASYNC_PAGE_LOAD, EVENT_NAMES, PAGE_NAMES, PAGE_DETAIL
} = anaConsts;

const { HAPPENING_AT_SEPHORA } = EVENT_NAMES;

const {
    RESERVATION_DETAILS, CANCEL_EVENT_CONFIRMATION, RSVP_BOOKING, BOOK_RESERVATION_PHONE_VALIDATION, START_BOOKING
} = HAPPENING_AT_SEPHORA;

const pageType = anaConsts.PAGE_TYPES.OLR;

// fires s.tl call
const triggerSOTAnalytics = eventData => {
    processEvent.process(LINK_TRACKING_EVENT, { data: eventData });
};

// fires s.t call
const triggerAPLAnalytics = eventData => {
    processEvent.process(ASYNC_PAGE_LOAD, { data: eventData });
};

const resetEventsData = () => {
    anaUtils.setNextPageData({
        navigationInfo: '',
        eventStrings: []
    });
};

const eventRSVPConfirmationPageLoadAnalytics = (activityName, storeId) => {
    const name = activityName.toLowerCase();
    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = 'book an event-rsvp';
    digitalData.page.attributes.world = 'n/a';
    digitalData.page.attributes.additionalPageInfo = `title=${name}`;
    digitalData.page.attributes.previousPageData = {
        ...digitalData.page?.attributes?.previousPageData,
        linkData: `happening:${RSVP_BOOKING}:event:${name}`,
        events: [anaConsts.Event.RSVP_EVENT_SUCCESS]
    };
    digitalData.page.attributes.experienceDetails = {
        ...digitalData.page.attributes?.experienceDetails,
        storeId
    };

    resetEventsData();
};

const triggerCancelEventAnalytics = (activityName, storeId, type) => {
    triggerSOTAnalytics({
        pageName: `${pageType}:${CANCEL_EVENT_CONFIRMATION}:n/a:*title=${activityName.toLowerCase()}`,
        storeId,
        actionInfo: `happening:canceled booking:${type === 'events' ? 'event' : 'service'}:${activityName.toLowerCase()}`
    });
};

const triggerRescheduleEventAnalytics = (activityName, storeId, type) => {
    const pageName = `${pageType}:reschedule ${type}-confirmation:n/a:*title=${activityName.toLowerCase()}`;

    const eventData = {
        pageName,
        storeId,
        actionInfo: `happening:reschedule booking:${type === 'events' ? 'event' : 'service'}:${activityName.toLowerCase()}`,
        previousPageName: digitalData.page.attributes.sephoraPageInfo.pageName,
        eventStrings: [anaConsts.Event.EVENT_219]
    };

    triggerAPLAnalytics(eventData);
};

const serviceBookingCancelledAnalytics = (activityName, storeId) => {
    const name = activityName.toLowerCase();
    const prop55 = `happening:canceled booking:service:${name}`;

    const eventData = {
        storeId,
        linkData: prop55,
        eventStrings: [anaConsts.Event.EVENT_219]
    };

    triggerAPLAnalytics(eventData);
};

const myReservationsPageLoadAnalytics = () => {
    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = PAGE_NAMES.MY_RESERVATIONS;
    digitalData.page.attributes.world = 'n/a';
};

const serviceReservationDetailsPageLoadAnalytics = (activityName, storeId, isCanceled = false) => {
    const status = isCanceled ? 'cancel service-confirmation' : 'book a service-confirmation';

    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = status;
    digitalData.page.attributes.world = 'n/a';
    digitalData.page.attributes.additionalPageInfo = `title=${activityName.toLowerCase()}`;

    if (digitalData.page.attributes?.experienceDetails) {
        digitalData.page.attributes.experienceDetails.storeId = storeId;
    } else {
        digitalData.page.attributes.experienceDetails = { storeId };
    }

    const { prop55 = '' } = anaUtils.getPreviousPageData();
    const [, previousOrigin = ''] = prop55.split(':');

    if (isCanceled && 'canceled booking' === previousOrigin) {
        digitalData.page.attributes.previousPageData = {
            ...digitalData.page?.attributes?.previousPageData,
            linkData: prop55,
            events: [anaConsts.Event.EVENT_219]
        };

        anaUtils.setNextPageData({
            eventStrings: []
        });
    }

    anaUtils.setNextPageData({
        navigationInfo: ''
    });
};

const eventReservationDetailsPageLoadAnalytics = (activityName, storeId, isCanceled = false) => {
    const status = isCanceled ? 'cancel event-confirmation' : 'book an event-rsvp';

    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = status;
    digitalData.page.attributes.world = 'n/a';
    digitalData.page.attributes.additionalPageInfo = `title=${activityName.toLowerCase()}`;

    if (digitalData.page.attributes?.experienceDetails) {
        digitalData.page.attributes.experienceDetails.storeId = storeId;
    } else {
        digitalData.page.attributes.experienceDetails = { storeId };
    }

    const { prop55 = '' } = anaUtils.getPreviousPageData();
    const [, previousOrigin = ''] = prop55.split(':');

    if (isCanceled && 'canceled booking' === previousOrigin) {
        digitalData.page.attributes.previousPageData = {
            ...digitalData.page?.attributes?.previousPageData,
            linkData: prop55,
            events: [anaConsts.Event.EVENT_219]
        };

        anaUtils.setNextPageData({
            eventStrings: []
        });
    }

    anaUtils.setNextPageData({
        navigationInfo: ''
    });
};

const serviceDetailsPageLoadAnalytics = (activityName, storeId) => {
    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = 'book a service-landing';
    digitalData.page.attributes.world = 'n/a';
    digitalData.page.attributes.additionalPageInfo = `title=${activityName.toLowerCase()}`;
    digitalData.page.attributes.previousPageData = {
        ...digitalData.page?.attributes?.previousPageData,
        linkData: `happening:start booking:service:${activityName.toLowerCase()}`,
        events: []
    };
    digitalData.page.attributes.experienceDetails = {
        ...digitalData.page.attributes?.experienceDetails,
        storeId: storeId
    };

    resetEventsData();
};

const eventDetailsPageLoadAnalytics = (activityName, storeId) => {
    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = 'book a event-landing';
    digitalData.page.attributes.world = 'n/a';

    digitalData.page.attributes.additionalPageInfo = `title=${activityName.toLowerCase()}`;
    digitalData.page.attributes.previousPageData = {
        ...digitalData.page?.attributes?.previousPageData,
        linkData: `happening:start booking:event:${activityName.toLowerCase()}`,
        events: [anaConsts.Event.EVENT_214]
    };
    digitalData.page.attributes.experienceDetails = {
        ...digitalData.page.attributes?.experienceDetails,
        storeId: storeId
    };

    resetEventsData();
};

const eventDetailsPhoneValidationPageLoadAnalytics = eventName => {
    const name = eventName.toLowerCase();
    const eventData = {
        pageType: anaConsts.PAGE_TYPES.OLR,
        pageName: `${pageType}:${BOOK_RESERVATION_PHONE_VALIDATION}:n/a:*title=${name}`,
        actionInfo: `happening:${START_BOOKING}:event:${name}`
    };

    triggerAPLAnalytics(eventData);
};

const serviceBookingPickStorePageLoadAnalytics = activityName => {
    const name = activityName.toLowerCase();
    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = RESERVATION_DETAILS;
    digitalData.page.attributes.world = 'step1';
    digitalData.page.attributes.additionalPageInfo = `title=${activityName}`;

    digitalData.page.attributes.previousPageData = {
        ...digitalData.page?.attributes?.previousPageData,
        linkData: `happening:continue booking:service:${name}`,
        events: [anaConsts.Event.EVENT_214]
    };

    resetEventsData();
};

const serviceBookingPickArtistDateTimePageLoadAnalytics = activityName => {
    const pageName = `${pageType}:${RESERVATION_DETAILS}:step2:*title=${activityName.toLowerCase()}`;

    triggerAPLAnalytics({ pageName });
};

const serviceBookingReviewAndPayPageLoadAnalytics = activityName => {
    const pageName = `${pageType}:${RESERVATION_DETAILS}:step3:*title=${activityName.toLowerCase()}`;

    triggerAPLAnalytics({ pageName });
};

const serviceBookingShowMapLinkAnalytics = activityName => {
    const prop55 = `happening:${PAGE_DETAIL.RESERVATION_DETAILS}:${HAPPENING_AT_SEPHORA.SHOW_MAP}:${activityName.toLowerCase()}`;

    triggerSOTAnalytics({ actionInfo: prop55 });
};

const serviceBookingShowCalendarPageLoadAnalytics = activityName => {
    const pageName = `${pageType}:${PAGE_NAMES.BOOK_SERVICE_DATE_LOCATION}:n/a:*title=${activityName.toLowerCase()}`;

    triggerAPLAnalytics({ pageName });
};

const serviceBookingConfirmationPageLoadAnalytics = (activityName, storeId) => {
    const name = activityName.toLowerCase();

    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = PAGE_NAMES.BOOK_SERVICE_CONFIRMATION;
    digitalData.page.attributes.world = 'n/a';
    digitalData.page.attributes.additionalPageInfo = `title=${name}`;
    digitalData.page.attributes.previousPageData = {
        ...digitalData.page?.attributes?.previousPageData,
        linkData: `happening:confirm booking:service:${name}`,
        events: [anaConsts.Event.BOOK_RESERVATION_SUCCESS]
    };
    digitalData.page.attributes.experienceDetails = {
        ...digitalData.page.attributes?.experienceDetails,
        storeId
    };

    resetEventsData();
};

const serviceBookingReschedulePageLoadAnalytics = (activityName, storeId) => {
    const name = activityName.toLowerCase();
    digitalData.page.category.pageType = pageType;
    digitalData.page.pageInfo.pageName = PAGE_NAMES.RESCHEDULE_SERVICE_CONFIRMATION;
    digitalData.page.attributes.world = 'n/a';
    digitalData.page.attributes.additionalPageInfo = `title=${name}`;
    digitalData.page.attributes.previousPageData = {
        ...digitalData.page?.attributes?.previousPageData,
        linkData: `happening:reschedule booking:service:${name}`,
        events: [anaConsts.Event.RESCHEDULE_SUCCESS]
    };
    digitalData.page.attributes.experienceDetails = {
        ...digitalData.page.attributes?.experienceDetails,
        storeId
    };

    resetEventsData();
};

const actionButtonsCancelBookingLinkAnalytics = activityName => {
    triggerSOTAnalytics({ actionInfo: `happening:${HAPPENING_AT_SEPHORA.CANCEL_BOOKING}:${activityName.toLowerCase()}` });
};

const actionButtonsAddToCalendarLinkAnalytics = activityName => {
    triggerSOTAnalytics({ actionInfo: `happening:${HAPPENING_AT_SEPHORA.ADD_TO_CALENDAR}:${activityName.toLowerCase()}` });
};

export default {
    triggerSOTAnalytics,
    triggerAPLAnalytics,
    eventRSVPConfirmationPageLoadAnalytics,
    triggerCancelEventAnalytics,
    triggerRescheduleEventAnalytics,
    serviceDetailsPageLoadAnalytics,
    serviceBookingCancelledAnalytics,
    myReservationsPageLoadAnalytics,
    serviceReservationDetailsPageLoadAnalytics,
    eventReservationDetailsPageLoadAnalytics,
    eventDetailsPhoneValidationPageLoadAnalytics,
    eventDetailsPageLoadAnalytics,
    serviceBookingPickStorePageLoadAnalytics,
    serviceBookingShowMapLinkAnalytics,
    serviceBookingPickArtistDateTimePageLoadAnalytics,
    serviceBookingShowCalendarPageLoadAnalytics,
    serviceBookingReviewAndPayPageLoadAnalytics,
    serviceBookingConfirmationPageLoadAnalytics,
    serviceBookingReschedulePageLoadAnalytics,
    actionButtonsCancelBookingLinkAnalytics,
    actionButtonsAddToCalendarLinkAnalytics
};
