import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import appliedPromotionsSelector from 'selectors/basket/appliedPromotions/appliedPromotionsSelector';
import { personalizationSelector } from 'viewModel/selectors/personalization/personalizationSelector';
import { coreUserDataSelector } from 'viewModel/selectors/user/coreUserDataSelector';
import FrameworkUtils from 'utils/framework';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import Empty from 'constants/empty';

const { wrapHOC } = FrameworkUtils;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/Content/PromotionList/locales', 'PromotionList');

const fields = createSelector(
    coreUserDataSelector,
    personalizationSelector,
    appliedPromotionsSelector,
    (_state, ownProps) => ownProps.items,
    (_state, ownProps) => ownProps.showSkeleton,
    createStructuredSelector({
        viewAll: getTextFromResource(getText, 'viewAll')
    }),
    (user, personalization, appliedPromotions, items, showSkeleton, localization) => {
        const { personalizedComponent, isPersonalizationInitializing } = personalization;

        const personalizedItems = personalizedComponent?.variationData?.items;
        const newItems = personalizedItems || items || Empty.Array;
        const isSkeleton = showSkeleton || (user.isInitialized && isPersonalizationInitializing);

        return {
            user,
            localization,
            appliedPromotions,
            isPersonalizationInitializing,
            items: newItems,
            showSkeleton: isSkeleton,
            areItemsPersonalized: (personalizedItems || Empty.Array)?.length !== 0
        };
    }
);

const withPromotionListProps = wrapHOC(connect(fields));

export {
    fields, withPromotionListProps
};
