/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import { buttons, space } from 'style/config';
import {
    Box, Flex, Image, Text, Divider, Button, Link, Icon
} from 'components/ui';
import MessageBox from 'components/MessageBox';
import DatePickerWidget from 'components/OnlineReservation/ExperienceDetailPage/DatePickerWidget/DatePickerWidget';
import BeautyAdvisorsAvailable from 'components/OnlineReservation/ExperienceDetailPage/BeautyAdvisorsAvailable/BeautyAdvisorsAvailable';
import ExperienceLocation from 'components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/ExperienceLocation';
import Popover from 'components/Popover/Popover';
import StoresAvailability from 'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoresAvailability';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import Modal from 'components/Modal/Modal';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import localeUtils from 'utils/LanguageLocale';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import resourceWrapper from 'utils/framework/resourceWrapper';
import ErrorMsg from 'components/ErrorMsg';
import servicePageBindings from 'analytics/bindingMethods/pages/happeningAtSephora/servicesBindings';
import store from 'store/Store';
import dateUtils from 'utils/Date';
import urlUtils from 'utils/Url';
import Actions from 'actions/Actions';
import ExperienceDetailsActions from 'actions/ExperienceDetailsActions';
import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';
import DateTZ from 'utils/DateTZ';
import anaUtils from 'analytics/utils';
import Authentication from 'utils/Authentication';
import activityConstants from 'components/OnlineReservation/activityConstants';

const { ANY_ARTIST } = activityConstants;

class BookWidget extends BaseClass {
    state = {
        experienceDetail: {},
        selectedDay: false,
        showChooseLocation: false,
        selectedArtistSlot: null,
        showMoreStores: false,
        errorMessage: false
    };

    componentDidMount() {
        this.rescheduleConfirmationNumber = this.getRescheduleConfirmationNumber();
        this.parseActivities(this.props).then(() => {
            if (!this.props.isModal && this.rescheduleConfirmationNumber) {
                this.launchBookModal({ isRescheduleLanding: true });
            }
        });
    }

    getRescheduleConfirmationNumber = () => {
        const rescheduleParam = urlUtils.getParamsByName('reschedule');

        return rescheduleParam && rescheduleParam[0] ? rescheduleParam[0] : null;
    };

    shouldDisplayPopover = () => {
        return this.state.showChooseLocation;
    };

    toggleShowChooseLocation = () => {
        this.setState({ showChooseLocation: !this.state.showChooseLocation });
    };

    updateCurrentLocation = location => {
        this.setState({ showChooseLocation: false }, () => {
            this.updateExperience({ location });
        });
    };

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        if (Sephora.isDesktop()) {
            document.addEventListener('mousedown', this.handleClick, false);
        }
    }

    componentWillUnmount() {
        if (Sephora.isDesktop()) {
            document.removeEventListener('mousedown', this.handleClick, false);
        }
    }

    handleClick = e => {
        if (this.state.showChooseLocation) {
            if (this.node.contains(e.target)) {
                return;
            }

            this.toggleShowChooseLocation();
        }
    };

    showMoreClick = () => {
        this.setState({
            showMoreStores: true
        });
    };

    handleTimeSlotClick = (selectedTimeSlot, activityClicked, storeClickedIndex, timeSlotIndex) => {
        const { selectedArtistSlot } = this.state;
        const isCurrentArtistAvailable = (selectedTimeSlot?.resources || []).find(function findArtist(artist) {
            return (
                (Boolean(artist.resourceId) && artist.resourceId === selectedArtistSlot?.resourceId) ||
                selectedArtistSlot?.resourceId === BeautyAdvisorsAvailable.getAnyArtistItem().resourceId
            );
        });

        this.setState({
            selectedTimeSlot,
            activityClicked: activityClicked.activity,
            timeSlotIndex,
            storeClickedIndex,
            selectedArtistSlot: isCurrentArtistAvailable ? selectedArtistSlot : BeautyAdvisorsAvailable.getAnyArtistItem(),
            showChooseNewArtistModal: Boolean(selectedArtistSlot) && !isCurrentArtistAvailable
        });

        if (!this.props.isModal) {
            store.dispatch(ExperienceDetailsActions.openInfoWindow(activityClicked.storeId));
        }
    };

    handleArtistSlotClick = selectedArtistSlot => {
        this.setState({
            selectedArtistSlot
        });
    };

    updateExperience = ({ location, selectedDay }) => {
        if (this.props.isModal) {
            store.dispatch(
                ExperienceDetailsActions.updateExperienceForModal({
                    location,
                    selectedDay
                })
            );
        } else {
            store.dispatch(
                ExperienceDetailsActions.updateExperience({
                    location,
                    selectedDay
                })
            );
        }
    };

    handleDayClick = selectedDay => {
        if (dateUtils.isSameDay(selectedDay, this.state.selectedDay)) {
            return;
        }

        this.setState({ selectedDay }, () => {
            this.updateExperience({ selectedDay });
        });
    };

    getSelectedDayActivities = (day, activities) => {
        const dayActivities = [];

        for (const activity of activities) {
            const timeSlots = activity.timeSlots.filter(timeSlot => {
                const timeZone = activity.store.storeHours && activity.store.storeHours.timeZone;
                const startDateTime = new DateTZ(timeSlot.startDateTime, timeZone);

                return dateUtils.isSameDay(startDateTime, day);
            });
            dayActivities.push({
                activity: activity,
                store: activity.store,
                timeSlots
            });
        }

        return dayActivities;
    };

    createAnalyticsData = () => {
        const expDetails = this.state.experienceDetail;
        const activityType = ACTIVITY.ANALYTICS_OLR_EXPERIENCE[expDetails.activityType];
        const pageDetail = `book a ${activityType}-date & location`;
        const pageName = anaConsts.PAGE_TYPES.OLR + `:${pageDetail}:n/a:*title=${expDetails.activityTitle}`;
        const pageType = digitalData.page.category.pageType;

        const data = {
            pageName,
            pageDetail,
            pageType
        };

        return data;
    };

    launchBookModal = (config = { isRescheduleLanding: false }) => {
        const expDetails = Object.assign({}, this.state.experienceDetail, {
            activityPolicies: this.props.activityPolicies,
            location: this.props.location,
            activities: this.props.activities,
            questions: this.props.questions,
            startDateParam: this.props.startDateParam,
            selectedDay: this.state.selectedDay,
            selectedTimeSlot: this.state.selectedTimeSlot,
            dayActivities: this.state.dayActivities,
            storeList: this.props.storeList,
            timeSlotIndex: this.state.timeSlotIndex,
            storeClickedIndex: this.state.storeClickedIndex,
            activityClicked: this.state.activityClicked,
            disabledDays: this.state.disabledDays
        });

        if (!config.isRescheduleLanding) {
            processEvent.process(anaConsts.ASYNC_PAGE_LOAD, { data: this.createAnalyticsData() });
        }

        store.dispatch(
            Actions.showBookWidgetModal({
                isOpen: true,
                experienceDetail: expDetails
            })
        );
    };

    handleBookNowClick = () => {
        this.setState({
            errorMessage: false
        });
        const {
            activityClicked,
            activityClicked: {
                priceInfo,
                store: { displayName, storeHours },
                activityId
            },
            selectedTimeSlot,
            selectedArtistSlot
        } = this.state;
        const { activityPolicies } = this.props;
        const activityDetails = {
            selectedActivity: activityClicked,
            hasSpecialRequests: this.getFirstQuestion() !== null,
            selectedTimeSlot: selectedTimeSlot,
            storeName: displayName,
            timeZone: storeHours && storeHours.timeZone,
            activityPolicies,
            selectedArtistSlot: this.isService() ? selectedArtistSlot : undefined
        };
        const isPersonalService = activityId === ACTIVITY.PERSONAL_SHOPPING_SERVICE_ACTIVITY_ID;
        // Personal Service is available with complementary in-store payment
        const isPaidService = this.isService() && priceInfo && priceInfo.listPrice;

        Authentication.requireLoggedInAuthentication()
            .then(() => {
                if (isPaidService || isPersonalService) {
                    this.launchPaidReservationModal(activityDetails);
                } else {
                    this.launchPhoneNumberModal(activityDetails);
                }
            })
            .catch(() => {
                // eslint-disable-next-line no-console
                console.log('Login dismissed.');
            });

        /*
        // We are going to revisit this on UTS-668
        if (userUtils.isAnonymous()) {
            store.dispatch(Actions.showAuthenticateModal({
                activityDetails,
                analyticsData: {
                    context: this.props.isModal ? anaConsts.PAGE_TYPES.OLR : null,
                    nextPageContext: this.props.isModal ? anaConsts.PAGE_TYPES.OLR : null
                },
                callback: () => this.launchPhoneNumberModal(activityDetails),
                contextComponent: this,
                getGuestDetails: guest => this.bookReservation(guest),
                isOpen: true,
                onSubmit: callback => {
                    const showPaidReservationModal = type === ACTIVITY.TYPE.SERVICES && priceInfo && priceInfo.listPrice;

                    if (showPaidReservationModal) {
                        store.dispatch(Actions.showAuthenticateModal({ isOpen: false }));
                        store.dispatch(Actions.showPaidReservationModal({
                            isOpen: true,
                            activityDetails,
                            reservation: this.bookReservation,
                            analyticsData: { context: this.props.isModal ? anaConsts.PAGE_TYPES.OLR : null }
                        }));
                    } else {
                        callback();
                    }
                }
            }));
        } else {
            priceInfo && priceInfo.listPrice ? this.launchPaidReservationModal(activityDetails) :
                this.launchPhoneNumberModal(activityDetails);
        }
        */
    };

    launchPhoneNumberModal = activityDetails => {
        const user = store.getState().user;
        store.dispatch(
            Actions.showPhoneNumberModal({
                isOpen: true,
                reservation: (phoneDetails, waiverInfo) => {
                    this.bookReservation(Object.assign({}, user, phoneDetails), null, waiverInfo);
                },
                activityDetails,
                analyticsData: { context: this.props.isModal ? anaConsts.PAGE_TYPES.OLR : null }
            })
        );
    };

    launchPaidReservationModal = activityDetails => {
        store.dispatch(
            Actions.showPaidReservationModal({
                activityDetails,
                analyticsData: { context: this.props.isModal ? anaConsts.PAGE_TYPES.OLR : null },
                contextComponent: this,
                isOpen: true,
                reservation: this.bookReservation,
                displayErrorMessage: this.displayErrorMessage
            })
        );
    };

    isService = () => {
        const { activityClicked = {} } = this.state;

        return activityClicked?.type === ACTIVITY.TYPE.SERVICES;
    };

    IsEligibleForBookByArtist = () => {
        const { experienceDetail, showMoreStores } = this.state;

        let displayBookByArtistSection = false;
        // check all stores when clicking on showMoreStores
        const activities = showMoreStores ? experienceDetail?.dayActivities : experienceDetail?.dayActivities?.slice(0, 1);

        activities?.forEach(storeInfo => {
            if (!displayBookByArtistSection) {
                storeInfo?.timeSlots?.forEach(slot => {
                    slot?.resources?.forEach(artist => {
                        if (!displayBookByArtistSection && artist?.employee?.isEligibleForBookByArtist === true) {
                            displayBookByArtistSection = true;

                            return;
                        }
                    });
                });
            }

            return;
        });

        return displayBookByArtistSection;
    };

    displayErrorMessage = () => {
        this.setState({
            errorMessage: true
        });
    };

    bookReservation = (user, payment, waiver) => {
        const { activityClicked = {}, selectedTimeSlot, selectedArtistSlot: selectedArtist } = this.state;
        const { type: activityType, name: activityTitle, storeId } = activityClicked;

        let reservationDetails = {
            // user details
            clientExternalId: user.login || user.userName || user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            smsEnabled: user.smsEnabled,

            // experience details
            activityType,
            storeId,
            bookingId: selectedTimeSlot.bookingId,
            startDateTime: selectedTimeSlot.startDateTime
        };

        const firstQuestion = this.getFirstQuestion();

        if (firstQuestion && user.specialRequests && user.specialRequests.trim().length > 0) {
            reservationDetails.specialRequests = user.specialRequests;
        }

        const reservationSuccess = () => {
            const bookSuccessEvent = this.rescheduleConfirmationNumber
                ? anaConsts.Event.RESCHEDULE_SUCCESS
                : anaConsts.Event.BOOK_RESERVATION_SUCCESS;
            const reservationEvents = [bookSuccessEvent];
            reservationDetails.questionAnswers && reservationEvents.push(anaConsts.Event.BOOK_ADD_SPECIAL_REQUEST);
            const mappedActivityType = ACTIVITY.ANALYTICS_OLR_EXPERIENCE[activityType];
            const prop55 = `happening:confirm booking:${mappedActivityType}:${activityTitle}`;
            const recentEvent = anaUtils.getLastAsyncPageLoadData({ pageType: anaConsts.PAGE_TYPES.OLR });
            anaUtils.setNextPageData({
                linkData: prop55,
                storeId: storeId,
                events: reservationEvents,
                ...recentEvent
            });
        };

        if (this.isService()) {
            if (waiver?.shouldShow) {
                const { prefId, prefName, prefValue } = waiver;
                reservationDetails = {
                    ...reservationDetails,
                    reservationPreferences: [
                        {
                            prefId,
                            prefName,
                            prefValue
                        }
                    ],
                    storeResourceIds: selectedArtist.resourceId !== ANY_ARTIST ? [selectedArtist.resourceId] : undefined
                };
            }

            reservationDetails = {
                ...reservationDetails,
                // Payment details
                paymentId: payment?.creditCardId
            };

            // 20.19: this service only works for actvityType = services
            // but will eventually replace bookReservation (SDN)
            return ExperienceDetailsUtils.createReservation(activityClicked.activityId, reservationDetails, this.rescheduleConfirmationNumber)
                .then(reservationSuccess)
                .catch(error => {
                    throw error;
                });
        } else {
            return ExperienceDetailsUtils.bookReservation(activityClicked.activityId, reservationDetails, this.rescheduleConfirmationNumber)
                .then(reservationSuccess)
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        }
    };

    getFirstQuestion = () => {
        return this.props.questions && this.props.questions.length > 0 && this.props.questions[0]['questionExternalId']
            ? this.props.questions[0]
            : null;
    };

    componentWillReceiveProps(newProps) {
        this.parseActivities(newProps);
    }

    parseActivities = currentProps => {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/ExperienceDetailPage/BookWidget/locales', 'BookWidget');
        const { activities, selectedDay, location } = currentProps;
        const currentActivity = ExperienceDetailsUtils.getFirstBookableActivity(activities);
        const timeZone = currentActivity && currentActivity.store && currentActivity.store.storeHours && currentActivity.store.storeHours.timeZone;
        const selectedDayValidated = this.isStoreDayValid(selectedDay, timeZone) && selectedDay;
        const nextAvailableDate = selectedDayValidated || this.getNextAvailableDate(activities, location);
        const initialSelectedDay = selectedDayValidated || nextAvailableDate;
        const dayActivities = this.getSelectedDayActivities(initialSelectedDay, activities);
        const disabledDays = ExperienceDetailsUtils.getDisabledDays(timeZone);
        // enable today store time - overriding even if it is in the past (for +1 day timezones)
        const availableDays = [new DateTZ(new Date(), timeZone)];
        let widgetTitle = '';
        let cta = '';
        let duration = '';
        let activityTitle = '';
        let priceInfo;
        let activityId;
        let activityType;
        let isVirtual = false;
        const rescheduleFlow = this.rescheduleConfirmationNumber !== null;

        if (currentActivity) {
            switch (currentActivity.type) {
                case ACTIVITY.TYPE.SERVICES:
                    widgetTitle = getText('bookReservation');
                    cta = rescheduleFlow ? getText('reschedule') : getText('continueToBooking');

                    break;

                case ACTIVITY.TYPE.CLASSES:
                    widgetTitle = getText('signUpForThisClass');
                    cta = rescheduleFlow ? getText('reschedule') : getText('continueToBooking');

                    break;

                default:
                    break;
            }

            duration = currentActivity.timeSlots.length > 0 && currentActivity.timeSlots[0].durationMin;
            activityTitle = currentActivity.name;
            activityId = currentActivity.activityId;
            activityType = currentActivity.type;
            priceInfo = currentActivity.priceInfo;
            isVirtual = currentActivity?.store?.isVirtual;
        }

        return new Promise(resolve => {
            this.setState(
                {
                    selectedDay: initialSelectedDay,
                    dayActivities,
                    experienceDetail: {
                        widgetTitle,
                        activityTitle,
                        priceInfo,
                        duration,
                        cta,
                        nextAvailableDate,
                        activityId,
                        activityType,
                        isVirtual,
                        dayActivities
                    },
                    disabledDays,
                    availableDays,
                    isDataReady: true
                },
                () => resolve()
            );
        });
    };

    setLocationRef = node => (this.node = node);

    handleDismissArtistModal = () => {
        this.setState({
            showChooseNewArtistModal: false
        });
    };

    handleOkClick = () => {
        this.setState({
            showChooseNewArtistModal: false
        });
    };

    getNextAvailableDate = (activities, location) => {
        if (!activities || activities.length === 0) {
            return null;
        }

        const availableDates = [];

        const getEarliestDateFromActivity = activity => {
            const timeZone = activity.store.storeHours && activity.store.storeHours.timeZone;

            for (const timeSlot of activity.timeSlots) {
                const date = new DateTZ(timeSlot.startDateTime, timeZone);
                date.setHours(0, 0, 0);
                availableDates.push(date);
            }

            return availableDates.sort((date1, date2) => date1.getTime() - date2.getTime())[0];
        };

        if (location && location.src !== 'DEFAULT') {
            // Try getting the earliest date from the first activity
            const earliestDateFromFirstActivity = getEarliestDateFromActivity(activities[0]);

            if (earliestDateFromFirstActivity) {
                return earliestDateFromFirstActivity;
            }
        }

        // If no valid date from the first activity, find the earliest date from all activities
        for (const activity of activities) {
            const date = getEarliestDateFromActivity(activity);

            if (date && !dateUtils.isDayInArray(date, availableDates)) {
                availableDates.push(date);
            }
        }

        return availableDates.sort((date1, date2) => date1.getTime() - date2.getTime())[0];
    };

    //we shouldn't allow a day to be selectable if it gets disabled when changing location and time zone
    isStoreDayValid = (day, timeZone) => {
        //if no day or day is an ISO Date String return false
        //selected day will be decided in getNextAvailableDate
        if (!day || dateUtils.isISODate(day)) {
            return false;
        }

        const todayStoreTime = new DateTZ(new Date(), timeZone);

        return dateUtils.isDayAfter(day, todayStoreTime) || dateUtils.isSameDay(day, todayStoreTime);
    };

    renderLocationTrigger = (showing, locationString, getTextFn) => {
        const { isModal } = this.props;

        return (
            <Link
                padding={2}
                margin={-2}
                onClick={this.toggleShowChooseLocation}
                display='flex'
                alignItems='center'
                arrowDirection={showing ? 'up' : 'down'}
            >
                <Text
                    numberOfLines={!isModal ? 1 : null}
                    maxWidth={!isModal && '10em'}
                    children={`${getTextFn('near')} ${locationString}`}
                />
            </Link>
        );
    };

    renderChooseNewArtistModal = getTextFn => {
        const { showChooseNewArtistModal } = this.state;
        const getTextOnSteroids = resourceWrapper(getTextFn);
        const useMarkdown = true;

        return showChooseNewArtistModal ? (
            <Modal
                width={1}
                isOpen={showChooseNewArtistModal}
                onDismiss={this.handleDismissArtistModal}
            >
                <Modal.Header>
                    <Modal.Title>{getTextFn('chooseNewArtistTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{getTextOnSteroids('chooseNewArtistText', useMarkdown)}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='primary'
                        block={true}
                        onClick={this.handleOkClick}
                    >
                        {getTextFn('ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
        ) : null;
    };

    renderMobileLocationWidget = (locationString, getTextFn) => {
        const {
            showChooseLocation,
            experienceDetail,
            selectedDay,
            dayActivities,
            selectedTimeSlot,
            activityClicked,
            selectedArtistSlot,
            storeClickedIndex,
            timeSlotIndex,
            disabledDays,
            availableDays
        } = this.state;

        const { isModal, storeList, storesWithActivities, getMoreStoreAvailabilities } = this.props;

        const isVirtual = experienceDetail.isVirtual;
        const getText = resourceWrapper(ExperienceDetailsUtils.getText);

        return (
            <React.Fragment>
                {isModal && (
                    <Modal
                        isOpen={showChooseLocation}
                        onDismiss={this.toggleShowChooseLocation}
                    >
                        <Modal.Header>
                            <Modal.Title>{getTextFn('chooseLocation')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ExperienceLocation
                                currentLocation={locationString}
                                updateCurrentLocation={this.updateCurrentLocation}
                            />
                        </Modal.Body>
                    </Modal>
                )}
                {this.renderChooseNewArtistModal(getTextFn)}
                {showChooseLocation && !isModal ? (
                    <React.Fragment>
                        <Box lineHeight='tight'>
                            <Link
                                color='blue'
                                display='block'
                                onClick={this.toggleShowChooseLocation}
                                children={getTextFn('cancel')}
                            />
                            <Text
                                is='p'
                                marginY={3}
                                fontWeight='bold'
                            >
                                {getTextFn('shareLocationAvailability')}
                            </Text>
                        </Box>
                        <ExperienceLocation
                            currentLocation={locationString}
                            updateCurrentLocation={this.updateCurrentLocation}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <DatePickerWidget
                            isModal={isModal}
                            selectedDay={selectedDay}
                            disabledDays={disabledDays}
                            availableDays={availableDays}
                            onDayClick={this.handleDayClick}
                            onSeeMoreDaysClick={this.launchBookModal}
                        />
                        {isModal && (
                            <MessageBox marginTop={3}>
                                {getText(
                                    'scheduleAppointment',
                                    false,
                                    <Link
                                        color='blue'
                                        underline={true}
                                        href={ACTIVITY.OLR_URLS.STORE_LOCATOR}
                                        children={getText('store')}
                                    />
                                )}
                            </MessageBox>
                        )}
                        <Divider
                            marginTop={4}
                            marginBottom={5}
                        />
                        <Flex
                            justifyContent='space-between'
                            alignItems='baseline'
                            lineHeight='tight'
                        >
                            <Text
                                fontWeight='bold'
                                data-at={Sephora.debug.dataAt('choose_a_time_section_name')}
                                children={`2. ${isVirtual ? getTextFn('chooseTime') : getTextFn('chooseLocationTime')}`}
                            />
                            {!isVirtual && this.renderLocationTrigger(false, locationString, getTextFn)}
                        </Flex>
                        <StoresAvailability
                            onRequestClose={isModal && this.launchBookModal}
                            isModal={isModal}
                            isVirtual={isVirtual}
                            activities={dayActivities}
                            selectedTimeSlot={selectedTimeSlot}
                            storeClickedIndex={storeClickedIndex}
                            timeSlotIndex={timeSlotIndex}
                            handleTimeSlotClick={this.handleTimeSlotClick}
                            launchBookModal={this.launchBookModal}
                            storeList={storeList}
                            storesWithActivities={storesWithActivities}
                            showMoreClick={this.showMoreClick}
                            getMoreStoreAvailabilities={getMoreStoreAvailabilities}
                        />

                        {this.isService() && this.IsEligibleForBookByArtist() && (
                            <>
                                <Divider
                                    marginTop={4}
                                    marginBottom={5}
                                />
                                <Flex
                                    justifyContent='space-between'
                                    alignItems='baseline'
                                    lineHeight='tight'
                                >
                                    <Text
                                        fontWeight='bold'
                                        data-at={Sephora.debug.dataAt('choose_a_beauty_advisor_section_name')}
                                        children={`3. ${getTextFn('chooseArtist')}`}
                                    />
                                </Flex>
                                {selectedTimeSlot ? (
                                    <>
                                        <Box
                                            marginY={4}
                                            paddingBottom={1}
                                            lineHeight='tight'
                                        >
                                            <BeautyAdvisorsAvailable
                                                storeClickedIndex={storeClickedIndex}
                                                selectedArtistSlot={selectedArtistSlot}
                                                activityClicked={activityClicked}
                                                storeIndex={storeClickedIndex}
                                                selectedTimeSlot={selectedTimeSlot}
                                                handleArtistSlotClick={this.handleArtistSlotClick}
                                            />
                                        </Box>
                                        <Text
                                            is='p'
                                            lineHeight='tight'
                                            fontSize='sm'
                                            marginY={4}
                                            data-at={Sephora.debug.dataAt('artist_availability_disclaimer')}
                                        >
                                            <Icon
                                                marginRight={2}
                                                name='infoOutline'
                                                size={16}
                                            />
                                            {getTextFn('artistDisclaimer')}
                                        </Text>
                                    </>
                                ) : (
                                    <Text
                                        key='artistSelect'
                                        is='p'
                                        marginBottom={2}
                                        data-at={Sephora.debug.dataAt('artist_location_time')}
                                        marginTop={2}
                                        children={getTextFn('chooseLocationTimeArtist')}
                                    ></Text>
                                )}
                            </>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    renderDesktopLocationWidget = (locationString, getTextFn) => {
        const {
            showChooseLocation,
            selectedDay,
            experienceDetail,
            dayActivities,
            selectedTimeSlot,
            storeClickedIndex,
            timeSlotIndex,
            disabledDays,
            availableDays,
            selectedArtistSlot,
            activityClicked
        } = this.state;

        const { isModal, storeList, storesWithActivities, getMoreStoreAvailabilities } = this.props;

        const isVirtual = experienceDetail.isVirtual;
        const getText = resourceWrapper(ExperienceDetailsUtils.getText);

        return (
            <React.Fragment>
                <DatePickerWidget
                    isModal={isModal}
                    selectedDay={selectedDay}
                    disabledDays={disabledDays}
                    availableDays={availableDays}
                    onDayClick={this.handleDayClick}
                    onSeeMoreDaysClick={this.launchBookModal}
                />
                {isModal && (
                    <MessageBox marginTop={3}>
                        {getText(
                            'scheduleAppointment',
                            false,
                            <Link
                                color='blue'
                                underline={true}
                                href={ACTIVITY.OLR_URLS.STORE_LOCATOR}
                                children={getText('store', false)}
                            />
                        )}
                    </MessageBox>
                )}
                {this.renderChooseNewArtistModal(getTextFn)}

                <Divider marginY={4} />
                <Flex
                    justifyContent='space-between'
                    alignItems='baseline'
                    lineHeight='tight'
                >
                    <Text
                        fontWeight='bold'
                        data-at={Sephora.debug.dataAt('choose_a_time_section_name')}
                        children={`2. ${isVirtual ? getTextFn('chooseTime') : getTextFn('chooseLocationTime')}`}
                    />
                    {!isVirtual && (
                        <div ref={this.setLocationRef}>
                            <Popover
                                width={390}
                                placement='bottom'
                                align='right'
                                padding={5}
                                content={
                                    <React.Fragment>
                                        <Text
                                            is='h3'
                                            fontSize='md'
                                            lineHeight='tight'
                                            fontWeight='bold'
                                            children={getTextFn('chooseLocation')}
                                        />
                                        <Divider marginY={4} />
                                        <ExperienceLocation
                                            currentLocation={locationString}
                                            updateCurrentLocation={this.updateCurrentLocation}
                                        />
                                    </React.Fragment>
                                }
                                onDismiss={this.toggleShowChooseLocation}
                                showImmediately={showChooseLocation}
                                shouldDisplayPopover={this.shouldDisplayPopover}
                            >
                                {this.renderLocationTrigger(showChooseLocation, locationString, getTextFn)}
                            </Popover>
                        </div>
                    )}
                </Flex>
                <StoresAvailability
                    onRequestClose={isModal && this.launchBookModal}
                    isModal={isModal}
                    isVirtual={isVirtual}
                    activities={dayActivities}
                    selectedTimeSlot={selectedTimeSlot}
                    storeClickedIndex={storeClickedIndex}
                    timeSlotIndex={timeSlotIndex}
                    handleTimeSlotClick={this.handleTimeSlotClick}
                    launchBookModal={this.launchBookModal}
                    storeList={storeList}
                    storesWithActivities={storesWithActivities}
                    showMoreClick={this.showMoreClick}
                    getMoreStoreAvailabilities={getMoreStoreAvailabilities}
                />

                {this.isService() && this.IsEligibleForBookByArtist() && (
                    <>
                        <Divider
                            marginTop={4}
                            marginBottom={5}
                        />
                        <Flex
                            justifyContent='space-between'
                            alignItems='baseline'
                            lineHeight='tight'
                        >
                            <Text
                                fontWeight='bold'
                                data-at={Sephora.debug.dataAt('choose_a_beauty_advisor_section_name')}
                                children={`3. ${getTextFn('chooseArtist')}`}
                            />
                        </Flex>
                        {selectedTimeSlot ? (
                            <>
                                <Box
                                    marginY={4}
                                    paddingBottom={1}
                                    lineHeight='tight'
                                >
                                    <BeautyAdvisorsAvailable
                                        storeClickedIndex={storeClickedIndex}
                                        selectedArtistSlot={selectedArtistSlot}
                                        activityClicked={activityClicked}
                                        storeIndex={storeClickedIndex}
                                        selectedTimeSlot={selectedTimeSlot}
                                        handleArtistSlotClick={this.handleArtistSlotClick}
                                    />
                                </Box>
                                <Text
                                    is='p'
                                    fontSize='sm'
                                    lineHeight='tight'
                                    marginY={4}
                                    data-at={Sephora.debug.dataAt('artist_availability_disclaimer')}
                                >
                                    <Icon
                                        marginRight={2}
                                        name='infoOutline'
                                        size={16}
                                    />
                                    {getTextFn('artistDisclaimer')}
                                </Text>
                            </>
                        ) : (
                            <Text
                                key='artistSelect'
                                is='p'
                                marginBottom={2}
                                data-at={Sephora.debug.dataAt('artist_location_time')}
                                marginTop={2}
                                children={getTextFn('chooseLocationTimeArtist')}
                            ></Text>
                        )}
                    </>
                )}
            </React.Fragment>
        );
    };

    renderNoStoresError = getTextFn => {
        const { noStoreLocation, resetErrors } = this.props;

        return (
            <Box
                textAlign='center'
                lineHeight='tight'
            >
                <Text
                    is='p'
                    fontWeight='bold'
                >
                    {getTextFn('notAbleToFindStore')} “{noStoreLocation}”
                </Text>
                <Text
                    is='p'
                    marginTop='.125em'
                    marginBottom='.5em'
                >
                    {getTextFn('tryDifferentLocation')}
                </Text>
                <Image
                    display='block'
                    src='/img/ufe/no-result.svg'
                    size={128}
                    marginX='auto'
                    marginBottom={4}
                />
                <Button
                    variant='secondary'
                    onClick={resetErrors}
                    children={getTextFn('changeLocation')}
                />
            </Box>
        );
    };

    renderError = getTextFn => {
        return (
            <Box
                textAlign='center'
                lineHeight='tight'
            >
                <Text
                    is='p'
                    fontWeight='bold'
                >
                    {getTextFn('noAppointmentsAvailable1')}
                    <br />
                    {getTextFn('noAppointmentsAvailable2')}
                </Text>
                <Text
                    is='p'
                    marginTop='.125em'
                    marginBottom='.5em'
                >
                    {getTextFn('tryDifferentExperience')}
                </Text>
                <Image
                    display='block'
                    src='/img/ufe/no-result.svg'
                    size={128}
                    marginX='auto'
                    marginBottom={4}
                />
                <Button
                    variant='secondary'
                    href={ACTIVITY.OLR_URLS.LANDING_PAGE}
                    children={getTextFn('seeWhatsHappening')}
                />
            </Box>
        );
    };

    /* eslint-disable-next-line complexity */
    render() {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/ExperienceDetailPage/BookWidget/locales', 'BookWidget');
        const {
            experienceDetail = {}, isDataReady, selectedTimeSlot, selectedArtistSlot, errorMessage
        } = this.state;
        const { isModal, noStoreLocation, noActivities, location } = this.props;
        const selectedDay = this.props.selectedDay || this.state.selectedDay;
        const locationString = location ? location.display : null;

        const isMobile = Sephora.isMobile();
        const isDesktop = Sephora.isDesktop();

        const cta = (
            <>
                {errorMessage && (
                    <ErrorMsg
                        marginBottom={4}
                        children={getText('genericErrorTimeSlots')}
                    />
                )}
                <Button
                    data-at={Sephora.debug.dataAt('continue_booking_btn')}
                    variant='primary'
                    block={true}
                    maxWidth={isDesktop && isModal && '20em'}
                    disabled={
                        !(
                            selectedDay &&
                            selectedTimeSlot &&
                            ((this.isService() && selectedArtistSlot) || !this.isService()) &&
                            !noActivities &&
                            !noStoreLocation
                        )
                    }
                    onClick={this.handleBookNowClick}
                    children={experienceDetail.cta}
                />
            </>
        );

        return (
            <Box
                borderRadius={2}
                padding={isDesktop && !isModal ? 4 : null}
                border={isDesktop && !isModal && 1}
                borderColor='lightGray'
                data-at={isModal && 'date_and_time_modal'}
            >
                {isModal || (
                    <Box marginBottom={4}>
                        <Text
                            is='h2'
                            fontSize='md'
                            fontWeight='bold'
                            lineHeight='tight'
                            children={experienceDetail.widgetTitle}
                        />
                        {isDesktop && <Divider marginTop={4} />}
                    </Box>
                )}
                {noStoreLocation ? (
                    this.renderNoStoresError(getText)
                ) : noActivities ? (
                    this.renderError(getText)
                ) : (
                    <div>
                        {isDataReady &&
                            (experienceDetail.nextAvailableDate
                                ? isMobile
                                    ? this.renderMobileLocationWidget(locationString, getText)
                                    : this.renderDesktopLocationWidget(locationString, getText)
                                : this.renderError(getText))}
                    </div>
                )}
                {isDesktop && <Divider marginY={4} />}
                {isModal && isMobile && <div css={{ height: space[3] * 2 + buttons.HEIGHT }} />}
                {isMobile ? <StickyFooter accountForBottomNav={!isModal}>{cta}</StickyFooter> : cta}
                {experienceDetail.priceInfo && !isModal && (
                    <Flex
                        justifyContent='center'
                        marginY={3}
                    >
                        <Link
                            href='/beauty/giftcards'
                            lineHeight='tight'
                            color='blue'
                            children={`🎁 ${getText('giftCardLink')}`}
                            onClick={servicePageBindings.giftAService}
                        ></Link>
                    </Flex>
                )}
            </Box>
        );
    }
}

export default wrapComponent(BookWidget, 'BookWidget', true);
