export default function getResource(label, vars = []) {
    const resources = {
        FIRST_NAME: 'Prénom requis. Veuillez saisir votre prénom.',
        FIRST_NAME_ADS_LONG: 'Maximum de 15 caractères.',
        FIRST_NAME_LONG: 'Maximum de 33 caractères.',
        LAST_NAME: 'Nom de famille requis. Veuillez saisir un nom de famille.',
        LAST_NAME_ADS_LONG: 'Maximum de 20 caractères.',
        LAST_NAME_LONG: 'Maximum de 33 caractères.',
        ADDRESS1_ADS_LONG: 'Maximum de 30 caractères.',
        ADDRESS1_LONG: 'Maximum de 35 caractères.',
        ADDRESS1: 'Adresse requise. Veuillez saisir la rue de votre adresse.',
        ZIP_CODE_US: 'Code ZIP requis. Veuillez saisir un code ZIP à 5 caractères.',
        ZIP_CODE_NON_US: 'Code zip/postal requis. Veuillez saisir un code postal ou ZIP valide.',
        INVALID_ZIP_CODE: 'Veuillez saisir un code zip valide.',
        ZIPCODE_ADS_LONG: 'Maximum de 5 caractères.',
        CITY: 'Ville requise. Veuillez saisir votre ville.',
        CITY_LONG: 'Maximum de 30 caractères.',
        CITY_ADS_LONG: 'Maximum de 20 caractères.',
        TIME: 'Veuillez entrer une heure pour votre aperçu.',
        STATE_A: 'Province requise',
        STATE_B: 'État/Région requis',
        PHONE_NUMBER: 'Numéro de téléphone requis. Veuillez saisir votre numéro de téléphone.',
        PHONE_NUMBER_INVALID: 'Veuillez saisir un numéro de téléphone valide.',
        MOBILE_NUMBER: 'Un seul numéro de téléphone requis. Veuillez saisir un numéro de téléphone.',
        MOBILE_NUMBER_INVALID: 'Saisir un numéro de téléphone valable.',
        ALTERNATIVE_NUMBER_INVALID: 'Saisir un autre numéro valable.',
        CREDIT_CARD_NUMBER: 'Numéro de carte de crédit requis.',
        CREDIT_CARD_PIN: 'NIP requis.',
        CREDIT_CARD_EXP_MONTH: 'Mois d’expiration requis.',
        CREDIT_CARD_EXP_YEAR: 'Année d’expiration requise',
        CREDIT_CARD_EXP_MONTH_INVALID: 'Mois non valide.',
        CREDIT_CARD_EXP_DATE: 'Carte de crédit expirée.',
        CREDIT_CARD_SECURITY_CODE: 'Veuillez saisir votre code de sécurité.',
        CREDIT_CARD_CVV_VALIDATION: 'Aucun paiement n’est requis. Veuillez vérifier votre numéro CVV/CVC à des fins de sécurité.',
        CREDIT_CARD_SECURITY_CODE_LENGTH: 'Veuillez saisir un numéro de sécurité valide.',
        PASSWORD: 'Veuillez entrer un mot de passe de 6 à 12 caractères (sans espace).',
        CAPTCHA: 'Vous n’avez pas saisi les caractères. Veuillez saisir le code correct.',
        JOIN_BI: 'Vous devez accepter les modalités du programme BI',
        JOIN_BI_BIRTHDAY: 'Veuillez sélectionner votre date de naissance.',
        JOIN_BI_MONTH: 'Veuillez saisir votre mois de naissance',
        JOIN_BI_DATE: 'Veuillez saisir votre date de naissance',
        AGE_LIMIT_18: 'Vous devez être âgé de 18 ans ou plus pour faire une demande de carte de crédit Sephora',
        EDIT_GIFT_CARD_IS_NOT_EMPTY: 'Vous êtes sur le point de quitter cette section et vous n’avez pas encore appliqué votre carte-cadeau. Veuillez sélectionner « APPLIQUER » avant de continuer.',
        EMAIL_EMPTY: 'Veuillez saisir votre adresse courriel.',
        EMAIL_INVALID: 'Veuillez saisir une adresse courriel au format nomutilisateur@domaine.com.',
        EMAIL_LONG: 'Maximum de 60 caractères.',
        EMAIL_ADS_LONG: 'Maximum de 50 caractères.',
        IN_STORE_USER: 'Il semble que vous vous êtes déjà inscrit(e) au programme Beauty Insider dans un magasin Sephora, car votre adresse courriel a été reconnue',
        checkoutPaymentIncomplete: 'Veuillez sélectionner un mode de paiement pour continuer.',
        checkoutPaymentGiftCardNotEnough: 'Le solde de votre carte-cadeau est inférieur au total de la commande. Veuillez ajouter un autre mode de paiement.',
        checkoutPaymentStoreCreditNotEnough: 'Le solde de votre crédit en magasin est inférieur au total de la commande. Veuillez ajouter un autre mode de paiement.',
        checkoutPaymentGiftCardNotApplied: 'Vous êtes sur le point de quitter cette section et vous n’avez pas encore appliqué votre carte-cadeau. Veuillez sélectionner « APPLIQUER » avant de continuer.',
        socialSecurityInvalid: 'Veuillez entrer les quatre derniers chiffres de votre numéro d’assurance sociale',
        SOCIAL_SECURITY_ZEROS: 'Veuillez entrer un numéro d’assurance sociale valide',
        annualIncome: 'Revenu annuel requis',
        ANNUAL_INCOME_ADS_LONG: 'Maximum de 7 caractères.',
        orderIdEmpty: 'Veuillez saisir un numéro de commande.',
        orderIdGeneric: 'Un problème est survenu. Veuillez essayer de nouveau.',
        orderIdNotFound: 'Ce numéro de commande n’existe pas. Veuillez essayer de nouveau.',
        orderIdInvalid: 'Saisissez un numéro de commande valable.',
        EMAIL_SUBJECT_REQUIRED: 'Objet requis.',
        gcNumber: 'Numéro de carte-cadeau requis.',
        gcPin: 'Numéro PIN de carte-cadeau requis.',
        APPLY_FORM_SECTION: 'Veuillez corriger la/les erreur(s) dans les champs en surbrillance ci-dessous.',
        error: 'Erreur',
        shippingRestriction: 'Restriction d’expédition',
        addressInUse: 'Supprimer l’adresse',
        cardInUse: 'Supprimer le mode de paiement',
        MARKETING_PHONE_NUMBER: 'Veuillez entrer votre numéro de téléphone pour vous inscrire aux alertes par texto de Sephora.',
        giftCardNumber: 'Le numéro de carte-cadeau est requis.',
        giftCardPIN: 'Le numéro NIP de carte-cadeau est requis.',
        taxExemptionNumberEmpty: 'Numéro d’exemption requis. Veuillez entrer votre numéro d’exemption.',
        taxExemptionNumberInvalid: 'Veuillez entrer un numéro d’exemption valide.',
        taxEffectiveDateEmpty: 'La date est requise. Veuillez entrer la date d’entrée en vigueur.',
        taxEffectiveDateInvalid: 'Veuillez entrer une date valide.',
        issueDateEmpty: 'La date est requise. Veuillez entrer la date d’émission.',
        expirationDateEmpty: 'La date est requise. Veuillez entrer la date d’expiration.',
        dateOutOfBounds: 'La date doit être comprise entre 1900 et 2100.',
        CREDIT_CARD_NUMBER_REQUIRED: 'Le numéro de carte de crédit est requis',
        CREDIT_CARD_NUMBER_INCORRECT: 'Le numéro de carte de crédit est incorrect',
        CREDIT_CARD_EXP_MONTH_REQUIRED: 'Le mois d’expiration est requis',
        CREDIT_CARD_EXP_MONTH_INCORRECT: 'Le mois d’expiration est incorrect',
        CREDIT_CARD_EXP_YEAR_REQUIRED: 'L’année expiration est requise',
        CREDIT_CARD_EXP_YEAR_INCORRECT: 'L’année d’expiration est incorrecte',
        CREDIT_CARD_CVV_REQUIRED: 'Le code de sécurité est requis',
        CREDIT_CARD_CVV_INCORRECT: 'Le code de sécurité est incorrect',
        CREDIT_CARD_FIRST_NAME_REQUIRED: 'Le prénom est requis',
        CREDIT_CARD_LAST_NAME_REQUIRED: 'Le nom de famille est requis',
        ADDRESS_PHONE_REQUIRED: 'Veuillez-saisir. un numéro de téléphone-cellulaire valable.',
        ADDRESS_STREET_REQUIRED: 'L’adresse municipale est requise',
        ADDRESS_ZIPCODE_REQUIRED: 'Le code postal est requis',
        ADDRESS_ZIPCODE_INCORRECT: 'Le code postal est incorrect',
        ADDRESS_CITY_REQUIRED: 'La ville est requis',
        ADDRESS_CITY_INCORRECT: 'La ville-est incorrecte'
    };
    return resources[label];
}
