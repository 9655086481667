const resources = {
    eyeColor: 'Couleur des yeux',
    blue: 'Bleus',
    brown: 'Bruns',
    green: 'Verts',
    gray: 'Gris',
    hazel: 'Noisette',
    hairColor: 'Couleur des cheveux',
    blonde: 'Blonds',
    brunette: 'Bruns',
    auburn: 'Auburn',
    black: 'Noirs',
    red: 'Roux',
    skinTone: 'Ton de peau',
    porcelain: 'Porcelaine',
    fair: 'Pâle',
    light: 'Légère',
    medium: 'Moyenne',
    tan: 'Bronzée',
    olive: 'Olive',
    deep: 'Très foncée',
    dark: 'Foncée',
    ebony: 'Ébène',
    skinType: 'Type de peau',
    normal: 'Normaux',
    combination: 'Mixte',
    dry: 'Secs',
    oily: 'Gras',
    skinConcerns: 'Préoccupations cutanées',
    acne: 'Acné',
    aging: 'Vieillissement',
    blackheads: 'Points noirs',
    calluses: 'Callosités',
    cellulite: 'Cellulite',
    cuticles: 'Cuticules',
    darkCircles: 'Cernes',
    dullness: 'Teint terne',
    redness: 'Rougeurs',
    sensitivity: 'Sensibilité',
    stretchMarks: 'Vergetures',
    sunDamage: 'Dommages causés par le soleil',
    unevenSkinTones: 'Teint inégal',
    hairType: 'Type de cheveux',
    chemicallyTreated: 'Traités chimiquement',
    coarse: 'Épais',
    curly: 'Frisés',
    fine: 'Fins',
    straight: 'Raides',
    wavy: 'Bouclés',
    hairConcerns: 'Préoccupations liées aux cheveux',
    antiAging: 'Anti-âge',
    colorProtection: 'Protection de la couleur',
    curlEnhancing: 'Rehausseur de boucles',
    damaged: 'Abîmés',
    dandruff: 'Pellicules',
    frizz: 'Frisottis',
    heatProtection: 'Protection contre la chaleur',
    hold: 'Tenue',
    oiliness: 'Tendance grasse',
    shine: 'Brillance',
    straighteningSmoothing: 'Raidir/Lisser',
    thinning: 'Cheveux clairsemés',
    volumizing: 'Volumisant',
    ageRange: 'Tranche d’âge',
    content: 'Contenu',
    photos: 'Photos',
    video: 'Vidéo',
    verifiedPurchases: 'Achats vérifiés',
    rating: 'Cote'
};

export default function getResource(label) {
    return resources[label];
}
