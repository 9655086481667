export default function getResource(label, vars = []) {
    const resources = {
        orderDetails: 'Order Details',
        orderHistory: 'View complete order history',
        orderNumber: 'Order #',
        pickUpOrderNumber: 'Pickup Order Number',
        pickUpOrder: 'Pickup Order',
        cancelOrderButton: 'Cancel Your Order',
        showBarcode: 'Show Pickup Barcode',
        scan: 'Scan for Pickup',
        show: 'Show pickup barcode to a Beauty Advisor.',
        continueShopping: 'Continue Shopping',
        originalReservationTitle: 'Original Reservation',
        originalReservationText: 'Please refer to your store receipt or ',
        purchaseHistory: 'purchase history',
        originalReservationText2: ' to see your final purchase of this pickup order.',
        needToReturn: 'Need to Return?',
        returnsText: 'Returns can be made at any store with a valid receipt.',
        seeReturnsPolicy: 'See Returns Policy',
        seefaq: 'See FAQs',
        buyItAgainLinkText: 'Buy It Again page',
        buyItAgainText1: 'Items added in store will appear as a separate transaction in your ',
        buyItAgainText2: ' and will have a separate store receipt.',
        changedYourMind: 'Changed your mind?',
        cancelYourOrder: 'Cancel your order',
        statusLabel: 'Status',
        deliverTo: 'Deliver To',
        deliveryInstructions: 'Delivery Instructions',
        billingInfo: 'Billing Info',
        sameDayDelivery: 'Same-Day Delivery',
        sameDayUnlimited: 'Same-Day Unlimited',
        manageSubscription: 'Manage Subscription',
        standardOrder: 'Standard Order',
        selfCancel: 'You canceled your order',
        faqs: 'See FAQs',
        paypalAccount: 'PayPal Account',
        deliveryWindow: 'Delivery Window',
        paidWith: 'Paid with',
        autoReplenishOrder: 'Standard, Auto-Replenish',
        inStorePickupNotAvailable: 'In-store pickup is not available for curbside concierge orders, but our Beauty Advisors are available if you want to shop for other items in store',
        curbsideConciergeNotAvailable: 'Curbside concierge is not available for in-store pickup orders. Ask a Beauty Advisor about curbside concierge and try it with your next order!',
        call188: ' Call 1-877-SEPHORA or ',
        chatWith: 'chat with an online representative',
        toCancel: ' to cancel your order.',
        needToReturnSomething: 'Need To Return Something?',
        deliveryIssue: 'Experiencing a Delivery Issue?',
        reportIssue: 'Report issue',
        applePay: 'Apple Pay',
        returnSddText: 'You can start a return as soon as your Same-Day Delivery items are on their way.',
        returnsNoLongerAvailable: 'Returns are no longer available for this order. See ',
        returnsNoLongerAvailable2: ' to learn more.',
        returnFAQs: 'Return FAQs'
    };

    return resources[label];
}
