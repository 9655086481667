export default function getResource(label, vars = []) {
    const resources = {
        sephoraStore: `Sephora ${vars[0]}`,
        pickupInfo: 'Renseignements sur le ramassage',
        callStore: 'Appeler le magasin',
        happeningAt: 'En cours chez Sephora',
        happeningAtRedesign: 'Services et événements chez Sephora',
        seeWhatsGoingOn: 'Services gratuits, lancements de marques, ateliers et plus - consulter les événements du jour dans votre magasin.',
        seeWhatsGoingOnRedesign: 'Découvrir les services de beauté et les événements gratuits dans votre magasin dès aujourd’hui.',
        seeWhatsHappening: 'Voir les événements dans ce magasin',
        seeWhatsHappeningRedesign: 'Découvrir les services et les événements',
        getDirections: 'Obtenir un itinéraire',
        away: 'absent',
        howToFindUs: 'Comment nous trouver?',
        tempClosed: 'Temporairement fermé',
        tempUnavailable: 'Non disponible pour le moment',
        KohlsCopy: 'Les promotions et les récompenses Sephora peuvent ne pas s’appliquer dans les magasins Kohl’s.'
    };

    return resources[label];
}
