import ufeApi from 'services/api/ufeApi';
import RCPSCookies from 'utils/RCPSCookies';
import userUtils from 'utils/User';

// https://confluence.sephora.com/wiki/display/ILLUMINATE/Switch+Preferred+Store+API

function switchPreferredStore(preferredStoreId, customerSelected) {
    let url = '/api/users/profile/preferredStore';
    const isUserAnonymous = userUtils.isAnonymous();
    const isRCPSProfileBiGroupAPIEnabled = RCPSCookies.isRCPSProfileBiGroupAPIEnabled();

    if (isRCPSProfileBiGroupAPIEnabled && !isUserAnonymous) {
        url = '/gway/v2/users/profiles/preferredStore';
    }

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            body: JSON.stringify({
                preferredStoreId: preferredStoreId,
                customerSelected: customerSelected
            })
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default switchPreferredStore;
