export default function getResource(label, vars = []) {
    const resources = {
        enterPhoneNumber: 'Please enter your phone number',
        verifyNumberPrompt: 'Do we have the right phone number?',
        phone: 'Phone',
        sendMeTexts: 'Yes, send me text message reminders about my appointment.',
        specialRequests: 'Special requests (optional)',
        specialRequestsPlaceholder: 'Help our Beauty Advisors prepare for your visit, e.g. I’m interested in trying out a new smoky eye for an upcoming event.',
        completeBooking: 'Complete Booking'
    };

    return resources[label];
}
