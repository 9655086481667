import ufeApi from 'services/api/ufeApi';
import cookieUtils from 'utils/Cookies';
const reservationApiBase = '/v1/reservations';
const RS_SES_ID = 'RS_SES_ID';
const AKAMWEB = 'akamweb';

// API documentation: https://confluence.sephora.com/wiki/pages/viewpage.action?spaceKey=ILLUMINATE&title=Create+reservation

function bookReservation(token, params) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const url = sdnDomain + reservationApiBase;
    const sessionId = cookieUtils.read(RS_SES_ID);
    const akamwebCookie = cookieUtils.read(AKAMWEB);
    var options = {
        url,
        method: 'post',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...(sessionId && { 'session-id': sessionId }),
            ...(akamwebCookie && { akamweb: akamwebCookie })
        },
        body: JSON.stringify(params),
        mode: 'cors',
        credentials: 'omit'
    };

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default bookReservation;
