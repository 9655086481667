import ufeApi from 'services/api/ufeApi';
import urlUtils from 'utils/Url';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Email+Notification+Request+API

function _requestEmailNotification(email, skuId, type, subscribe) {
    let qsParams;

    if (!subscribe) {
        qsParams = { action: 'cancel' };
    } else {
        qsParams = { type };
    }

    const url = '/api/util/skus/notify?' + urlUtils.makeQueryString(qsParams);

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            body: JSON.stringify({
                email,
                skuId
            })
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

function requestEmailNotificationForOutOfStockSku(email, skuId) {
    return _requestEmailNotification(email, skuId, 'outOfStock', true);
}

function requestEmailNotificationForComingSoonSku(email, skuId) {
    return _requestEmailNotification(email, skuId, 'comingSoon', true);
}

function cancelEmailNotificationRequest(email, skuId) {
    return _requestEmailNotification(email, skuId, null, false);
}

export default {
    requestEmailNotificationForOutOfStockSku,
    requestEmailNotificationForComingSoonSku,
    cancelEmailNotificationRequest
};
