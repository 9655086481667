const GET_PRODUCT_RECS = 'GET_PRODUCT_RECS';
const SELECT_PRODUCT_REC = 'SELECT_PRODUCT_REC';
const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
const RESET_ITEM_SUBSTITUTION_MODAL = 'RESET_ITEM_SUBSTITUTION_MODAL';
const UPDATE_CURRENT_SKU = 'UPDATE_CURRENT_SKU';
const SHOW_PRODUCT_RECS_LOADING = 'SHOW_PRODUCT_RECS_LOADING';
const SHOW_ERROR = 'SHOW_ERROR';
const SHOW_ADD_ITEM_ERROR = 'SHOW_ADD_ITEM_ERROR';
const SHOW_REMOVE_ITEM_ERROR = 'SHOW_REMOVE_ITEM_ERROR';

export {
    GET_PRODUCT_RECS,
    SELECT_PRODUCT_REC,
    SET_PRODUCT_DETAILS,
    RESET_ITEM_SUBSTITUTION_MODAL,
    UPDATE_CURRENT_SKU,
    SHOW_PRODUCT_RECS_LOADING,
    SHOW_ERROR,
    SHOW_ADD_ITEM_ERROR,
    SHOW_REMOVE_ITEM_ERROR
};
