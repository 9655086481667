import ufeApi from 'services/api/ufeApi';

const getReservationsDetails = (token, {
    country = 'US', reservationCountry = 'US', language = 'en', zipCode, confirmationNumber
}) => {
    const locale = `${language.toLowerCase()}-${country}`;
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;

    const url = `${sdnDomain}/v1/happening/reservations/${confirmationNumber}?channel=rwd&locale=${locale}&country=${reservationCountry}&zipCode=${zipCode}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
        }
    });
};

export default getReservationsDetails;
