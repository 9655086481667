const FOCUS = {
    PREV_WEEK: -7,
    PREV_DAY: -1,
    NEXT_DAY: 1,
    NEXT_WEEK: 7
};

const WEEKDAYS = 7;

export {
    FOCUS, WEEKDAYS
};
