export default function getResource(label, vars = []) {
    const resources = {
        status: 'Status',
        seeTrackingDetails: 'See tracking details',
        shippingMethod: 'Shipping Method',
        estimatedDelivery: 'Estimated Delivery',
        tracking: 'Tracking #',
        deliverTo: 'Deliver To',
        billingInfo: 'Billing Info',
        paypalAccount: 'PayPal Account',
        applePay: 'Apple Pay',
        shipment: 'SHIPMENT',
        needToReturnSomething: 'Need To Return Something?',
        changedYourMind: 'Changed your mind?',
        cancelYourOrder: 'Cancel your order',
        paidWith: 'Paid with ',
        shipTo: 'Ship To',
        shipToFeDexLocation: 'Shipping to FedEx Pickup Location',
        shipToPickupLocation: 'Shipping to a pickup Location',
        deliveryBy: 'Delivery By',
        autoReplenish: 'Auto-Replenish',
        manageSubscriptions: 'Manage Subscriptions',
        deliveryIssue: 'Experiencing a Delivery Issue?',
        reportIssue: 'Report issue',
        bannerButton: 'Sign Up for Text Alerts',
        bannerTitle: 'Be the first to know what’s new.',
        bannerParagraph: 'Never miss out on exclusives releases, events, and offers.',
        bannerRates: '*Msg & data rates may apply.',
        returnText: 'You can start a return as soon as your Get It Shipped items are on their way.'
    };

    return resources[label];
}
