/* eslint-disable class-methods-use-this */
import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import store from 'store/Store';
import Actions from 'Actions';
import userActions from 'actions/UserActions';
import BasketActions from 'actions/BasketActions';
import {
    Button, Link, Grid, Icon
} from 'components/ui';
import Modal from 'components/Modal/Modal';
import localeUtils from 'utils/LanguageLocale';
import InputZip from 'components/Inputs/InputZip/InputZip';
import userLocationUtils from 'utils/UserLocation';
import basketUtils from 'utils/Basket';
import getStateAndCityForZipCode from 'services/api/utility/getStateAndCityForZipCode';
import { SAME_DAY, SHIP_TO_HOME } from 'constants/UpperFunnel';
import GeoLocationDisclaimerModal from 'components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/GeoLocationDisclaimerModal/GeoLocationDisclaimerModal';
import productPageBindings from 'analytics/bindingMethods/pages/productPage/productPageSOTBindings';
import RwdBasketActions from 'actions/RwdBasketActions';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import Storage from 'utils/localStorage/Storage';

class ShippingDeliveryLocationModal extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            showGeoLocationDisclaimerModal: false
        };

        this.zipCodeInput = React.createRef();
    }

    // This will be shared with all instances of the modal so that we can show
    // an error generated by a previously closed ShippingDeliveryLocationModal
    static postponedActionErrorData = null;

    requestClose = () => {
        const { cancelCallback } = this.props;
        store.dispatch(Actions.showShippingDeliveryLocationModal({ isOpen: false })).then(() => {
            cancelCallback();
        });
    };

    handleConfirm = (locationData, shouldCloseModal, shouldTriggerCallback = true) => {
        const { callback } = this.props;
        shouldCloseModal &&
            store.dispatch(Actions.showShippingDeliveryLocationModal({ isOpen: false })).then(() => {
                shouldTriggerCallback && callback(locationData);
            });
        !shouldCloseModal && callback(locationData);
    };

    handleChangeZipCode = (locationData, shouldCloseModal) => {
        const { options, callback } = this.props;

        if (options?.taskQueue) {
            const zipCode = locationData.postalCode || locationData.zipCode;
            const taskId = options.isSdd ? SAME_DAY : SHIP_TO_HOME;
            const changeZipCodePromise = () =>
                new Promise(resolve => {
                    store.dispatch(userActions.update({ preferredZipCode: zipCode }, false));
                    this.changeZipCode(locationData, shouldCloseModal, false);
                    resolve();
                });
            store.dispatch(userActions.draftZipCode(zipCode));
            store.dispatch(Actions.showShippingDeliveryLocationModal({ isOpen: false }));
            options.taskQueue.add(changeZipCodePromise, taskId);
            callback({ zipCode });
        } else {
            this.changeZipCode(locationData, shouldCloseModal);
        }
    };

    handleChangeZipCodeSOT = locationData => {
        const { sku } = this.props;

        if (sku) {
            const { skuId, isOutOfStock } = sku;

            if (isOutOfStock) {
                productPageBindings.sameDayDeliveryZipCodeChangeOutOfStock({
                    skuId,
                    zipcode: locationData?.postalCode
                });
            } else {
                productPageBindings.sameDayDeliveryZipCodeChangeInStock({
                    skuId,
                    zipcode: locationData?.postalCode
                });
            }
        }
    };

    changeZipCode = (locationData, shouldCloseModal, shouldTriggerCallback) => {
        return userLocationUtils
            .updatePreferredZipCode({
                ...locationData,
                skipZipCodeUpdate: this.props?.options?.skipZipCodeUpdate || false
            })
            .then(({ sameDayAvailable, zipCode }) => {
                // Add to redux and ss for redundancy. Reloads cause redux value to be undefined.
                store.dispatch(RwdBasketActions.setSameDayDeliveryAvailable(sameDayAvailable));
                Storage.session.setItem(LOCAL_STORAGE.SAME_DAY_DELIVERY_AVAILABLE, sameDayAvailable);

                if (sameDayAvailable) {
                    store.dispatch({
                        type: 'CLEAR_RWD_CHECKOUT_ERRORS'
                    });
                }

                if (!this.props?.options?.skipZipCodeUpdate) {
                    store.dispatch(BasketActions.refreshBasket(true));
                }

                this.handleChangeZipCodeSOT(locationData);

                this.handleConfirm(
                    {
                        sameDayAvailable,
                        zipCode
                    },
                    shouldCloseModal,
                    shouldTriggerCallback
                );
            })
            .catch(error => {
                // If it's unmounted, it obviously means that the modal is hidden, so if
                // there are errors to handle, we must first show the modal and then save
                // the error data too show them when the modal is mounted
                if (this.isUnmounted) {
                    ShippingDeliveryLocationModal.postponedActionErrorData = error;
                    // By this point, the SDDLocationModal is closed, since the following modal
                    // showing the alert message would have shown and closed, so we need to
                    // show the modal again BEFORE attempting to update its state.
                    store.dispatch(Actions.showShippingDeliveryLocationModal({ isOpen: true }));

                    return;
                }

                this.updateError(error);
            });
    };

    updateError = error => {
        // We get this error when the user is in US locale but their location is in CA,
        // or viceversa
        if (error?.key === 'zipcode.invalid') {
            const zipErrorMsg = error?.errorMessages[0];

            if (zipErrorMsg) {
                this.setState({
                    zipErrorMsg,
                    invalid: true
                });
            }
        }
    };

    componentDidMount() {
        if (ShippingDeliveryLocationModal.postponedActionErrorData) {
            // If there's postponedActionErrorData (an error that was generated AFTER closing the modal)
            // show it
            this.updateError(ShippingDeliveryLocationModal.postponedActionErrorData);
            ShippingDeliveryLocationModal.postponedActionErrorData = null;
        } else {
            this.setState({ invalid: false });
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    updateZipCode = locationData => {
        if (basketUtils.hasSameDayItems() && !this.props?.options?.skipZipCodeUpdate) {
            const getText = localeUtils.getLocaleResourceFile(
                'components/GlobalModals/ShippingDeliveryLocationModal/locales',
                'ShippingDeliveryLocationModal'
            );
            const infoMessage = `${getText('changeLocationMessage')} <strong>${
                locationData?.postalCode || getText('currentLocationText')
            }</strong> ${getText('changeLocationMessage2')}.`;
            store.dispatch(Actions.showShippingDeliveryLocationModal({ isOpen: false }));
            store.dispatch(
                Actions.showInfoModal({
                    isOpen: true,
                    title: getText('changeLocation'),
                    message: infoMessage,
                    buttonText: getText('ok'),
                    showCancelButton: true,
                    callback: () => this.handleChangeZipCode(locationData, false),
                    cancelCallback: this.cancelInfoModalCallback,
                    isHtml: true
                })
            );
        } else {
            this.handleChangeZipCode(locationData, true);
        }
    };

    cancelInfoModalCallback = () => {
        store.dispatch(
            Actions.showShippingDeliveryLocationModal({
                ...this.props,
                isOpen: true
            })
        );
    };

    useMyLocation = () => {
        const getText = localeUtils.getLocaleResourceFile(
            'components/GlobalModals/ShippingDeliveryLocationModal/locales',
            'ShippingDeliveryLocationModal'
        );

        userLocationUtils
            .useBrowserGeoLocation()
            .then(locationObj => this.updateZipCode(locationObj))
            .catch(() => {
                store.dispatch(
                    Actions.showInfoModal({
                        isOpen: true,
                        title: getText('locationSharingDisabled'),
                        message: getText('locationUpdateSettings'),
                        buttonText: getText('ok')
                    })
                );
            });
    };

    validateZipCode = () => {
        const errorMessage = this.zipCodeInput.current.validateError();

        if (!errorMessage) {
            const zipCode = this.zipCodeInput.current.getValue();
            const currentCountry = localeUtils.getCurrentCountry();
            getStateAndCityForZipCode(currentCountry, zipCode)
                .then(() => {
                    const locationData = { postalCode: zipCode };
                    this.updateZipCode(locationData);
                })
                .catch(() => {
                    this.setState({ invalid: true });
                });
        }
    };

    closeGeoLocationDisclaimerModal = () => {
        this.setState({ showGeoLocationDisclaimerModal: false });
    };

    handleGeoLocationDisclaimer = e => {
        e.preventDefault();
        this.setState({ showGeoLocationDisclaimerModal: true });
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile(
            'components/GlobalModals/ShippingDeliveryLocationModal/locales',
            'ShippingDeliveryLocationModal'
        );
        const currentLocationLink = (
            <Link
                display='block'
                width='100%'
                color='blue'
                onClick={this.handleGeoLocationDisclaimer}
            >
                <Icon
                    name='currentLocation'
                    size='1em'
                    marginRight='.5em'
                />
                {getText('useLocation')}
            </Link>
        );

        const errorMessage = this.state.zipErrorMsg || getText('invalidZipError');

        return (
            <>
                <Modal
                    isOpen={this.props.isOpen}
                    onDismiss={this.requestClose}
                    width={0}
                >
                    <Modal.Header>
                        <Modal.Title>{getText('title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputZip
                            label={getText('zipCodePlaceholder')}
                            ref={this.zipCodeInput}
                            hidePlaceHolder={true}
                            required={true}
                            invalid={this.state.invalid}
                            error={this.state.invalid && errorMessage}
                            emptyZipError={getText('emptyZipError')}
                            invalidZipError={getText('invalidZipError')}
                            zipErrorMsg={this.state.zipErrorMsg}
                        />
                        {currentLocationLink}
                    </Modal.Body>
                    <Modal.Footer hasBorder={true}>
                        <Grid columns={2}>
                            <Button
                                block={true}
                                onClick={this.requestClose}
                                variant='secondary'
                                data-at={Sephora.debug.dataAt('modal_cancel_button')}
                                children={getText('cancel')}
                            />
                            <Button
                                block={true}
                                variant='primary'
                                data-at={Sephora.debug.dataAt('modal_confirm_button')}
                                onClick={this.validateZipCode}
                                children={this.props.primaryButtonText || getText('confirm')}
                            />
                        </Grid>
                    </Modal.Footer>
                </Modal>
                <GeoLocationDisclaimerModal
                    showGeoLocationDisclaimerModal={this.state.showGeoLocationDisclaimerModal}
                    onCloseModal={this.closeGeoLocationDisclaimerModal}
                    onAcceptGeoLocationDisclaimer={this.useMyLocation}
                />
            </>
        );
    }
}

ShippingDeliveryLocationModal.defaultProps = {
    callback: () => {},
    cancelCallback: () => {}
};

export default wrapComponent(ShippingDeliveryLocationModal, 'ShippingDeliveryLocationModal', true);
