export default function getResource(label, vars = []) {
    const resources = {
        youNowHaveText: 'Vous avez actuellement ',
        youAreExceeding: 'Vous dépassez de',
        beautyInsiderPointsText: ' points Beauty Insider',
        earnText: 'et obtenez ',
        todayText: ' aujourd’hui',
        redeemYourRewardsText: 'Utiliser vos récompenses',
        redeemYourRewardsLowerCaseText: 'utiliser vos récompenses',
        joinBeautyInsiderText: 'S’inscrire à Beauty Insider',
        joinText: 'S’inscrire',
        beautyInsiderText: 'Beauty Insider',
        signInText: 'Ouvrir une session',
        toSeeYourBeautyInsiderPointsText: 'pour consulter vos points Beauty Insider',
        pointsRemainingText: ' points restants',
        viewAllRewardsText: 'Voir toutes les récompenses',
        pointsText: 'points'
    };

    return resources[label];
}
