const ACTION_TYPES = {
    UPDATE_LEGACY_BASKET_CMS_DATA: 'UPDATE_LEGACY_BASKET_CMS_DATA'
};

const reducer = function (state = {}, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_LEGACY_BASKET_CMS_DATA: {
            return action.payload;
        }
        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
