/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import { Box, Flex, Text } from 'components/ui';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import IconCheckCircle from 'components/LegacyIcon/IconCheckCircle';
import IconWait from 'components/LegacyIcon/IconWait';
import ExperienceImage from 'components/OnlineReservation/ExperienceImage/ExperienceImage';
import localeUtils from 'utils/LanguageLocale';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import ExperienceUtils from 'utils/ExperienceDetails';
import urlUtils from 'utils/Url';
import reservationsBindings from 'analytics/bindingMethods/pages/reservations/ReservationsBindings';

class ActivityCard extends BaseClass {
    getLocationText = activity => {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/StoreHub/ActivityCard/locales', 'ActivityCard');

        if (activity.type === ACTIVITY.TYPE.EVENTS) {
            return getText('multiLocation');
        } else {
            return getText('otherLocations', [activity.otherStores]);
        }
    };
    /**
     *
     * @param {boolean} isConfirmation
     * @param {Object} activity
     * @param {string=} startDateQueryParam
     * @param {string=} encodedParams
     * @returns EDP url string
     */
    getEdpUrl = function getEdpUrl(isConfirmation, activity, startDate) {
        const encodedParams =
            !isConfirmation && activity.storeDetails
                ? '&' +
                  urlUtils.makeQueryString({
                      name: activity.name,
                      city: activity.storeDetails.city,
                      state: activity.storeDetails.state,
                      zipCode: activity.type !== ACTIVITY.TYPE.SERVICES ? activity.storeDetails.zipCode : undefined
                  })
                : '';

        const startDateQueryParam = startDate ? `&startDate=${startDate}` : '';

        return isConfirmation
            ? `/happening/confirmation?id=${activity.confirmationNumber}` + `&activityId=${activity.activityId}&bookingId=${activity.bookingId}`
            : `/happening/${activity.type}/${activity.activityId}?` + `storeId=${activity.storeId}` + startDateQueryParam + encodedParams;
    };

    getReservationStatusText = reservation => {
        const status = ExperienceUtils.getInitialReservationStatus(reservation);

        return ACTIVITY.RESERVATION_STATUS_CTA[status] ? ACTIVITY.RESERVATION_STATUS_CTA[status]['listText'] : '';
    };

    getDateText = activity => {
        if (activity.type === ACTIVITY.TYPE.EVENTS && activity.nextAvailable) {
            const timeZone = activity?.storeDetails?.timeZone;
            const isVirtual = activity?.storeDetails?.isVirtual;
            const durationArg = activity.durationMin ? activity.durationMin : null;

            return ExperienceUtils.getFullDateStoreTimeFormat(activity.nextAvailable, timeZone, durationArg, isVirtual);
        }

        return null;
    };

    getConfirmationDateText = reservation => {
        // do not show neither dates nor duration for announcements
        if (reservation.type === ACTIVITY.TYPE.ANNOUNCEMENTS || !reservation.startDate) {
            return `Sephora ${reservation.storeName}`;
        }

        const showEndDate = reservation.type === ACTIVITY.TYPE.EVENTS;
        const timeZone = reservation?.storeDetails?.timeZone;
        const isVirtual = reservation?.isVirtual;
        const durationArg = showEndDate && reservation.duration ? reservation.duration : null;
        const displayTimeZone = true;
        const dateText = ExperienceUtils.getFullDateStoreTimeFormat(reservation.startDate, timeZone, durationArg, isVirtual, displayTimeZone);

        return reservation.isVirtual ? `${dateText}` : `${dateText} at Sephora ${reservation.storeName || ''}`;
    };

    triggerOnClickTracking = activity => () => {
        const { activityName, storeId, activityId, type: activityType } = activity;
        reservationsBindings.reservationClick({
            reservationName: activityName.toLowerCase(),
            storeId,
            activityId,
            activityType
        });
    };

    renderActivityPrice = activity => {
        return activity.type === ACTIVITY.TYPE.SERVICES && activity.priceInfo && activity.priceInfo.listPrice ? (
            <Text
                key='activityCardPrice'
                is='p'
                fontSize='base'
                fontWeight='bold'
                color='black'
                data-at={Sephora.debug.dataAt('activity_price_label')}
                children={activity.priceInfo.listPrice}
            />
        ) : null;
    };

    renderActivityType = (showTypeFlag, duration = false) => {
        return (
            <Box
                key='activityCardType'
                color='gray'
                fontSize='xs'
                paddingTop={3}
                marginTop='auto'
            >
                <span
                    data-at={Sephora.debug.dataAt('activity_type')}
                    css={{ textTransform: 'capitalize' }}
                    children={showTypeFlag}
                />
                {duration && (
                    <React.Fragment>
                        {showTypeFlag && (
                            <Text
                                marginX={1}
                                children='•'
                            />
                        )}
                        <span data-at={Sephora.debug.dataAt('activity_duration')}>{duration} min</span>
                    </React.Fragment>
                )}
            </Box>
        );
    };

    renderStores = activity => {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/StoreHub/ActivityCard/locales', 'ActivityCard');

        const isVirtual = activity.type === ACTIVITY.TYPE.SERVICES && activity.isVirtual;

        return isVirtual ? (
            <div
                data-at={Sephora.debug.dataAt('virtual_activity_label')}
                children={getText('virtual')}
            />
        ) : (
            <React.Fragment>
                <div children={`Sephora ${activity.storeName}`} />
                {activity.otherStores > 0 && (
                    <Box
                        key='activityCardOtherLocations'
                        marginTop={activity.type === ACTIVITY.TYPE.EVENTS && 3}
                    >
                        {this.getLocationText(activity)}
                    </Box>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { activity, startDateTime = null } = this.props;

        const cardPadding = Sephora.isMobile() ? 3 : 4;
        const isServicesClasses = activity.type !== ACTIVITY.TYPE.EVENTS && activity.type !== ACTIVITY.TYPE.ANNOUNCEMENTS;
        let artistName = activity?.bookedByClientBaInfo?.displayName;
        const isArtistOptIn = activity?.isBaChosenByClient;

        if (!isArtistOptIn) {
            artistName = 'Any available artist';
        }

        const isService = activity.type === ACTIVITY.TYPE.SERVICES;
        const isVirtual = activity.type === ACTIVITY.TYPE.SERVICES && activity.isVirtual;
        const showTypeFlag = isVirtual ? null : ACTIVITY.FLAGS_OLR_EXPERIENCE[activity.type];
        const isConfirmation = !!activity.confirmationNumber;

        // For EVENTS and ANNOUNCEMENTS startDate param should reflect the beginning of the search period
        // For SERVICES and CLASSES startDate param should reflect the closest abvailability
        const startDate = isServicesClasses ? activity.nextAvailable : startDateTime;

        return (
            <Flex
                flexDirection='column'
                width='100%'
                borderRadius={2}
                border={1}
                borderColor='lightGray'
                lineHeight='tight'
                overflow='hidden'
                href={this.getEdpUrl(isConfirmation, activity, startDate)}
                onClick={this.triggerOnClickTracking(activity)}
                css={
                    Sephora.isTouch || {
                        ':hover .ActivityCard-name': {
                            textDecoration: 'underline'
                        }
                    }
                }
            >
                <Box
                    position='relative'
                    marginBottom={3}
                >
                    <Box paddingBottom='50%' />
                    <ExperienceImage
                        alt={activity.name || activity.activityName}
                        activity={activity}
                    />
                </Box>
                <Flex
                    flexDirection='column'
                    flex={1}
                    paddingBottom={cardPadding}
                    paddingX={cardPadding}
                >
                    <Text
                        is='h3'
                        fontWeight='bold'
                        marginBottom={3}
                        data-at={Sephora.debug.dataAt('activity_title')}
                        className='ActivityCard-name'
                        children={activity.name || activity.activityName}
                    />
                    {isConfirmation && (
                        <React.Fragment>
                            <Flex
                                key='resConfirmationSection'
                                alignItems='center'
                            >
                                {activity.bookingStatus === ACTIVITY.BOOKING_STATUS.WAITLISTED && (
                                    <IconWait
                                        color='yellow'
                                        fontSize={16}
                                    />
                                )}
                                {activity.bookingStatus === ACTIVITY.BOOKING_STATUS.WAITLISTED || (
                                    <IconCheckCircle
                                        color='blue'
                                        fontSize={16}
                                    />
                                )}
                                <Text
                                    marginLeft='.5em'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    color='gray'
                                    css={{ textTransform: 'uppercase' }}
                                    data-at={Sephora.debug.dataAt('activity_status')}
                                    children={this.getReservationStatusText(activity)}
                                />
                            </Flex>
                            <Text
                                is='p'
                                marginTop={3}
                                data-at={Sephora.debug.dataAt('activity_info')}
                                children={this.getConfirmationDateText(activity)}
                            />
                            {isService && (
                                <Text
                                    is='p'
                                    marginTop={1}
                                    data-at={Sephora.debug.dataAt('artistName_info')}
                                    children={artistName}
                                />
                            )}
                            {isVirtual && (
                                <Text
                                    is='p'
                                    data-at={Sephora.debug.dataAt('activity_info_virtual')}
                                    children={ExperienceDetailsUtils.getText('virtual')}
                                />
                            )}
                            {this.renderActivityType(showTypeFlag, isServicesClasses && activity.duration)}
                            {this.renderActivityPrice(activity)}
                        </React.Fragment>
                    )}
                    {isConfirmation || (
                        <React.Fragment>
                            <div
                                data-at={Sephora.debug.dataAt('activity_date_time')}
                                children={this.getDateText(activity)}
                            />
                            {this.renderStores(activity)}
                            {this.renderActivityType(showTypeFlag, isServicesClasses && activity.durationMin)}
                            {this.renderActivityPrice(activity)}
                        </React.Fragment>
                    )}
                </Flex>
            </Flex>
        );
    }
}

export default wrapComponent(ActivityCard, 'ActivityCard');
