import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';

const {
    EVENT_NAMES: { LOVES },
    SOT_LINK_TRACKING_EVENT
} = anaConsts;

class ViewAllLovesBindings {
    static triggerSOTAnalytics = ({ eventName, ...data }) => {
        const eventData = {
            data: {
                linkName: eventName,
                actionInfo: eventName,
                specificEventName: eventName,
                ...data
            }
        };

        processEvent.process(SOT_LINK_TRACKING_EVENT, eventData);
    };

    static shareClick = () => {
        const { SHARE } = LOVES;
        ViewAllLovesBindings.triggerSOTAnalytics({
            eventName: SHARE
        });
    };
}

export default ViewAllLovesBindings;
