export default function getResource(label, vars = []) {
    const resources = {
        allText: ' Tous',
        unLoveText: 'Je n’aime plus',
        lovedText: 'Coup de coeur',
        addAllText: `Ajouter${vars[0]} aux favoris`
    };

    return resources[label];
}
