import bccUtils from 'utils/BCC';

const itemWidths = {
    DESC: '45%',
    PRICE: '13%',
    QTY: '7.5%',
    AMOUNT: '9.5%',
    ACTION: '25%',
    IMAGE: bccUtils.IMAGE_SIZES[97]
};

export { itemWidths };
