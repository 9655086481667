export default function getResource(label, vars = []) {
    const resources = {
        shareExperience: 'Share this experience',
        copyLinkShare: 'Copy the following link to share',
        minutesAbbr: 'min',
        upcomingReservation: 'Your Upcoming Reservation',
        at: 'at',
        registerWithSephora: 'Register with Sephora',
        createAccountText: 'Create an account to easily access this reservation later',
        createAccountBtn: 'Create an Account',
        close: 'Close',
        whatToExpect: 'What to Expect',
        description: 'Description',
        bookingMessage: 'You will receive an email with your reservation details.',
        creditCardRequired: 'Your credit card is required to hold this reservation. Please review the cancellation and no-show fee policies below.',
        virtualMessage: 'You will receive a confirmation email with your reservation details, virtual meeting link, and access instructions.'
    };

    return resources[label];
}
