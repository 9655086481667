const active = rules => {
    return { [':active']: rules };
};
const hover = rules => {
    return { [':hover']: rules };
};
const focus = rules => {
    return { [':focus']: rules };
};

function hexToRGB(h, numOnly) {
    let r = 0,
        g = 0,
        b = 0;

    // 3 digits
    if (h.length === 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];

        // 6 digits
    } else if (h.length === 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }

    const val = `${+(+r) + ',' + +g + ',' + +b}`;

    return numOnly ? val : `rgb(${val})`;
}

export {
    active, hover, focus, hexToRGB
};
