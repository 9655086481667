export default function getResource(label, vars = []) {
    const resources = {
        enterPhoneNumber: 'Veuillez saisir votre numéro de téléphone',
        verifyNumberPrompt: 'Avons-nous le bon numéro de téléphone?',
        phone: 'Téléphone',
        sendMeTexts: 'Oui, envoyez-moi des rappels par message texte au sujet de mon rendez-vous.',
        specialRequests: 'Demandes spéciales (facultatives)',
        specialRequestsPlaceholder: 'Aidez nos conseillers beauté à se préparer pour votre visite, par exemple " j’aimerais essayer un nouveau look regard charbonneux pour un événement à venir".',
        completeBooking: 'Compléter la réservation'
    };

    return resources[label];
}
