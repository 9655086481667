const OPEN_SPA_PAGE = 'Open_SPA_Page';
const OPEN_SPA_PAGE_API_LOADED = `${OPEN_SPA_PAGE} API loaded`;
const OPEN_SPA_PAGE_DOM_UPDATED = `${OPEN_SPA_PAGE} DOM updated`;
const OPEN_SPA_PAGE_IMAGES_PRELOADED = `${OPEN_SPA_PAGE} Images preloaded`;
const OPEN_SPA_PAGE_IMAGES_PRELOADING = `${OPEN_SPA_PAGE} Image preload triggered`;
const OPEN_SPA_PAGE_START = `${OPEN_SPA_PAGE} Start`;
const OPEN_SPA_SEARCH_PAGE_API_START = `${OPEN_SPA_PAGE} Search API start`;
const OPEN_SPA_SEARCH_PAGE_API_LOADED = `${OPEN_SPA_PAGE} Search API loaded`;
const PAGE = 'Page';
const PAGE_LOADING = `${PAGE} Loading Started`;
const PAGE_RENDERED = `${PAGE} Rendered`;

export {
    OPEN_SPA_PAGE,
    OPEN_SPA_PAGE_API_LOADED,
    OPEN_SPA_PAGE_DOM_UPDATED,
    OPEN_SPA_PAGE_IMAGES_PRELOADED,
    OPEN_SPA_PAGE_IMAGES_PRELOADING,
    OPEN_SPA_PAGE_START,
    OPEN_SPA_SEARCH_PAGE_API_START,
    OPEN_SPA_SEARCH_PAGE_API_LOADED,
    PAGE,
    PAGE_LOADING,
    PAGE_RENDERED
};
