import ufeApi from 'services/api/ufeApi';
import jsUtils from 'utils/javascript';
import urlUtils from 'utils/Url';
const reservationsApiBase = '/v1/olr/reservations/';

function getReservationByConfNumber(token, params) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    let url;

    if (params.confirmationNumber) {
        url = `${sdnDomain}${reservationsApiBase}${params.confirmationNumber}`;
        delete params.confirmationNumber;
    }

    url += urlUtils.buildQuery(jsUtils.buildMap(params));

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            },
            mode: 'cors',
            credentials: 'omit'
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getReservationByConfNumber;
