import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import { Flex, Box } from 'components/ui';
import localeUtils from 'utils/LanguageLocale';

const getText = localeUtils.getLocaleResourceFile('components/ProductPage/Swatches/locales', 'Swatches');

function SwatchTypeSelector({ options = [], activeType, onSelect }) {
    return options.length > 0 ? (
        <Flex
            backgroundColor='lightGray'
            borderRadius='full'
            fontSize='sm'
        >
            {options.map((option, index) => {
                const isActive = activeType === option;

                return (
                    <Box
                        key={index}
                        data-at={Sephora.debug.dataAt(`${option.toLowerCase()}_btn`)}
                        disabled={isActive}
                        position='relative'
                        height='2em'
                        paddingX={'1em'}
                        borderRadius='full'
                        border={1}
                        zIndex={isActive && 0}
                        borderColor={isActive ? 'midGray' : 'transparent'}
                        backgroundColor={isActive && 'white'}
                        css={[
                            { outline: 0 },
                            isActive || {
                                '.no-touch &:hover': {
                                    textDecoration: 'underline'
                                }
                            }
                        ]}
                        onClick={e => onSelect(e, option)}
                        children={getText(option)}
                    />
                );
            })}
        </Flex>
    ) : null;
}

export default wrapFunctionalComponent(SwatchTypeSelector, 'SwatchTypeSelector');
