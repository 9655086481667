const { createSelector } = require('reselect');

const Empty = require('constants/empty').default;
const cookieUtils = require('utils/Cookies').default;
const { setPersonalizationCache } = require('utils/localStorage/P13n').default;
const { getPersonalizedComponent, checkDataToDisplay } = require('utils/Personalization').default;

const { p13nSelector } = require('selectors/p13n/p13nSelector');
const { coreUserDataSelector } = require('viewModel/selectors/user/coreUserDataSelector');

const personalizationSelector = createSelector(
    p13nSelector,
    coreUserDataSelector,
    (_state, ownProps) => ownProps.personalization,
    (p13n, user, personalization) => {
        let personalizedComponent = Empty.Array;

        if (!Sephora.isNodeRender && Sephora.Util.InflatorComps.services.loadEvents.HydrationFinished) {
            const prvCookie = cookieUtils.read(cookieUtils.KEYS.P13N_PRV);

            if (prvCookie && p13n.data?.length > 0) {
                personalizedComponent = p13n.data.find(item => item.context === personalization?.context) || Empty.Array;
            } else if (p13n.headData?.length) {
                setPersonalizationCache(p13n.headData);
                const headItemData = p13n.headData.find(item => (item.p13n?.context || item.context) === personalization?.context);
                personalizedComponent = checkDataToDisplay(headItemData, personalization);
            } else if (user.isAnonymous) {
                personalizedComponent = Empty.Array;
            } else {
                personalizedComponent = getPersonalizedComponent(personalization, user, p13n);
            }
        }

        const isPersonalizationInitializing = personalization?.isEnabled && !p13n.isInitialized;

        return {
            personalizedComponent,
            isPersonalizationInitializing
        };
    }
);

export { personalizationSelector };
