import viewModes from 'components/GlobalModals/PaidReservationModal/PaymentSection/viewModes';
import { PAYMENT_ERROR_CODES } from 'components/Checkout/constants';
import Actions from 'Actions';
import BaseClass from 'components/BaseClass';
import CreditCardUtils from 'utils/CreditCard';
import ErrorsUtils from 'utils/Errors';
import OnlineReservation from 'services/api/sdn';
import ProfileApi from 'services/api/profile';
import React from 'react';
import Store from 'Store';
import UserUtils from 'utils/User';

const { CREATE, SELECT } = viewModes;
const { loadChaseTokenizer } = CreditCardUtils;

const createNewCreditCard = (creditCard, address, isUpdating) => {
    const {
        creditCardId, cardNumber, expirationMonth, expirationYear, firstName, lastName, securityCode, cardType
    } = creditCard;
    const {
        address1, address2, city, country, phone, postalCode, state
    } = address;
    const newCreditCard = {
        address: {
            address1,
            address2,
            city,
            state,
            postalCode,
            country,
            phone
        },
        creditCardId: isUpdating ? creditCardId : undefined,
        cardNumber: cardNumber,
        cardType: !isUpdating ? CreditCardUtils.getCardType(cardNumber) : cardType,
        expirationMonth: parseInt(expirationMonth),
        expirationYear: expirationYear.length > 2 ? parseInt(expirationYear) : 2000 + parseInt(expirationYear),
        firstName,
        lastName,
        securityCode
    };

    return newCreditCard;
};

const withPaymentSectionContentViewModel = WrappedComponent => {
    class withPaymentSectionContentHOC extends BaseClass {
        state = {
            creditCards: [],
            creditCardToEdit: null,
            selectedCreditCard: null,
            isPaymentReady: false,
            shippingAddresses: []
        };
        isUnmounted = false;

        static postponedAction = null;

        componentDidMount() {
            if (Sephora.configurationSettings.isChasePaymentEnabled) {
                loadChaseTokenizer();
            }

            if (UserUtils.isSignedIn()) {
                if (withPaymentSectionContentHOC.postponedAction) {
                    this.performPostponedAction();
                } else {
                    this.loadUserData();
                }
            } else {
                withPaymentSectionContentHOC.postponedAction = null;
                Store.dispatch(Actions.showPaidReservationModal({ isOpen: false }));
            }
        }

        componentWillUnmount() {
            this.isUnmounted = true;
        }

        render() {
            const {
                creditCards, isPaymentReady, shippingAddresses, creditCardToEdit, selectedCreditCard
            } = this.state;
            const isAnonymous = UserUtils.isAnonymous();

            return (
                <WrappedComponent
                    {...this.props}
                    creditCards={creditCards}
                    creditCardToEdit={creditCardToEdit}
                    deleteCreditCard={this.deleteCreditCard}
                    getDefaultAddress={this.getDefaultAddress}
                    isAnonymous={isAnonymous}
                    processCreditCard={this.processCreditCard}
                    isPaymentReady={isPaymentReady}
                    refreshData={this.loadUserData}
                    saveSelectedCreditCard={this.saveSelectedCreditCard}
                    selectedCreditCard={selectedCreditCard}
                    setCreditCardToEdit={this.setCreditCardToEdit}
                    setSelectedCreditCard={this.setSelectedCreditCard}
                    shippingAddresses={shippingAddresses}
                />
            );
        }

        setCreditCardToEdit = creditCardToEdit => this.setState({ creditCardToEdit });

        getDefaultAddress = () => {
            const { shippingAddresses } = this.state;
            const defaultAddress = shippingAddresses.find(({ isDefault }) => isDefault);

            return defaultAddress;
        };

        loadUserData = () => {
            const cards = this.loadUserCreditCards();
            const addresses = this.loadUserAddresses();
            Promise.all([cards, addresses]).then(([creditCards = [], shippingAddresses = []]) => {
                this.setState(
                    {
                        creditCards,
                        shippingAddresses,
                        selectedCreditCard: this.getSelectedCreditCard(creditCards)
                    },
                    () => {
                        const { setViewMode } = this.props;
                        const { selectedCreditCard, isPaymentReady } = this.state;

                        if (!creditCards.length) {
                            setViewMode(CREATE);
                        } else if (
                            selectedCreditCard?.isExpired ||
                            CreditCardUtils.tokenMigrationFailed(selectedCreditCard) ||
                            !(selectedCreditCard?.isPreApproved || isPaymentReady)
                        ) {
                            setViewMode(SELECT);
                        }
                    }
                );
            });
        };

        loadUserCreditCards = () => {
            const { profileId } = Store.getState().user;
            const result = OnlineReservation.getPaymentOptions(profileId).then(({ creditCards }) => creditCards);

            return result;
        };

        loadUserAddresses = () => {
            const { profileId } = Store.getState().user;
            const result = ProfileApi.getShippingAddresses(profileId).then(({ addressList }) => addressList);

            return result;
        };

        getSelectedCreditCard = creditCards =>
            (creditCards || this.state.creditCards).find(({ selectedForPayment, isMigrated }) => selectedForPayment && isMigrated);

        saveSelectedCreditCard = (creditCard, address, isUpdatingCreditcard) => {
            const isSelectOnly = true;
            const creditCardToSelect = createNewCreditCard(creditCard, isUpdatingCreditcard ? creditCard.address : address, isUpdatingCreditcard);

            return OnlineReservation.updatePaymentCreditCard(
                {
                    creditCard: creditCardToSelect,
                    isSaveCreditCardForFutureUse: false
                },
                isSelectOnly
            ).then(() => {
                this.setState(
                    {
                        selectedCreditCard: creditCard,
                        isPaymentReady: true
                    },
                    this.loadUserData
                );

                return creditCardToSelect;
            });
        };

        setSelectedCreditCard = creditCard => {
            this.setState({ selectedCreditCard: creditCard });
        };

        setPaymentReady = (isPaymentReady = true) => {
            return new Promise(resolve => this.setState({ isPaymentReady }, resolve));
        };

        tryToRecognizeUser = () => {
            // We need to perform any API call to make sure that user is recognized.
            // Can be replaced with any cheaper API call!
            return this.loadUserAddresses();
        };

        processCreditCard = (creditCard, address, isMarkAsDefault, isSaveCreditCardForFutureUse) => {
            const { creditCardToEdit } = this.state;
            withPaymentSectionContentHOC.postponedAction = {
                createOrUpdateAction: {
                    address,
                    creditCard,
                    creditCardToEdit,
                    isMarkAsDefault,
                    isSaveCreditCardForFutureUse
                }
            };

            return this.tryToRecognizeUser().then(() => {
                let result = Promise.resolve();

                if (this.isUnmounted) {
                    return result;
                }

                withPaymentSectionContentHOC.postponedAction = null;
                const isUpdatingCreditcard = Boolean(creditCardToEdit);
                const isAdding = !isUpdatingCreditcard;
                const shouldSelectOnly = !(isAdding || (isUpdatingCreditcard && isSaveCreditCardForFutureUse));

                if (shouldSelectOnly) {
                    if (!creditCard.isExpired) {
                        result = this.saveSelectedCreditCard(creditCard, null, isUpdatingCreditcard)
                            .then(this.hideApiCallErrorMessage)
                            .catch(this.showApiCallErrorMessage);
                    } else {
                        result = Promise.reject(new Error('Credit Card is expired.'));
                    }
                } else {
                    const newCreditCard = createNewCreditCard(creditCard, address, isUpdatingCreditcard);
                    // done this way for testing purposes
                    result = (
                        isUpdatingCreditcard
                            ? OnlineReservation.updatePaymentCreditCard({
                                creditCard: newCreditCard,
                                isMarkAsDefault,
                                isSaveCreditCardForFutureUse
                            })
                            : OnlineReservation.addPaymentCreditCard({
                                creditCard: newCreditCard,
                                isMarkAsDefault,
                                isSaveCreditCardForFutureUse
                            })
                    )
                        .then(() => this.setPaymentReady(true))
                        .then(this.loadUserData)
                        .then(this.hideApiCallErrorMessage)
                        .catch(this.showApiCallErrorMessage);
                }

                return result;
            });
        };

        hideApiCallErrorMessage = () => {
            const { setErrorMessage } = this.props;
            setErrorMessage(null);
        };

        showApiCallErrorMessage = ({ errorMessages = [], errorCode = '' }) => {
            const { setErrorMessage, triggerAnalyticsErrors, getText } = this.props;
            const errorMessage = errorMessages.join(' ');

            if (PAYMENT_ERROR_CODES.includes(errorCode)) {
                setErrorMessage(getText('paymentGenericError'));
            } else if (errorMessage) {
                setErrorMessage(errorMessage);
            }

            triggerAnalyticsErrors([errorMessage]);

            throw new Error(errorMessage);
        };

        deleteCreditCard = (creditCardId, componentFromPostponedAction) => {
            const { contextComponent = componentFromPostponedAction, getText } = this.props;
            withPaymentSectionContentHOC.postponedAction = {
                deleteAction: {
                    contextComponent,
                    creditCardId
                }
            };
            this.tryToRecognizeUser().then(() => {
                if (this.isUnmounted) {
                    return;
                }

                withPaymentSectionContentHOC.postponedAction = null;
                Store.dispatch(Actions.showPaidReservationModal({ isOpen: false }));
                Store.dispatch(
                    Actions.showInfoModal({
                        isOpen: true,
                        title: getText('deleteCreditCardDialogTitle'),
                        message: getText('deleteCreditCardDialogMessage'),
                        buttonText: getText('deleteCreditCardDialogOkButtonText'),
                        callback: () => {
                            OnlineReservation.deletePaymentCreditCard(creditCardId)
                                .then(() => {
                                    Store.dispatch(Actions.showInfoModal({ isOpen: false }));
                                    contextComponent.handleBookNowClick();
                                })
                                .catch(errorData => {
                                    Store.dispatch(Actions.showInfoModal({ isOpen: false }));
                                    ErrorsUtils.collectAndValidateBackEndErrors(errorData, this);
                                });
                        },
                        cancelCallback: contextComponent.handleBookNowClick,
                        dataAtTitle: 'remove_credit_card_title',
                        dataAtButton: 'remove_credit_card_btn',
                        dataAtCancelButton: 'remove_credit_card_cancel_btn',
                        showCancelButton: true,
                        showCloseButton: true
                    })
                );
            });
        };

        performPostponedAction = () => {
            const { createOrUpdateAction, deleteAction } = withPaymentSectionContentHOC.postponedAction;

            if (createOrUpdateAction) {
                const {
                    address, creditCard, creditCardToEdit, isMarkAsDefault, isSaveCreditCardForFutureUse
                } = createOrUpdateAction;
                this.setState({ creditCardToEdit }, () => {
                    this.processCreditCard(creditCard, address, isMarkAsDefault, isSaveCreditCardForFutureUse).catch(() => {
                        // Do nothing here!
                        // We need this empty catch block for suppressing console log error.
                    });
                });
            } else if (deleteAction) {
                const { contextComponent, creditCardId } = deleteAction;
                this.deleteCreditCard(creditCardId, contextComponent);
            }
        };
    }

    withPaymentSectionContentHOC.class = 'withPaymentSectionContentHOC';

    return withPaymentSectionContentHOC; //wrapComponent(withPaymentSectionContentHOC, 'withPaymentSectionContentHOC');
};

export default withPaymentSectionContentViewModel;
