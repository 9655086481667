import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/browse/CE-286

function backInStockSMSOptInForm(payload) {
    const url = '/api/util/skus/notify?type=outOfStock';

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { backInStockSMSOptInForm };
