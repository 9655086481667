import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+BI+Account+History

function getBiAccountHistory(profileId, offset, limit) {
    const url = `/api/bi/profiles/${profileId}/accountHistory` + `?offset=${offset}&limit=${limit}`;

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getBiAccountHistory;
