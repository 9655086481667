import ufeApi from 'services/api/ufeApi';

const getRwdStoreDetailsContent = (token, { language, country, activityId, channel = 'rwd' }) => {
    const languageLowerCase = language.toLowerCase();
    const { sdnApiHost, isRedesignEDPEnabled } = Sephora.configurationSettings;

    const url = `${sdnApiHost}/v1/happening/stores/${activityId}?channel=${channel}&locale=${languageLowerCase}-${country}&country=${country}&isRedesignEDPEnabled=${isRedesignEDPEnabled}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: { 'x-requested-source': 'rwd', authorization: `Bearer ${token}` }
    });
};

export default getRwdStoreDetailsContent;
