export default function getResource(label, vars = []) {
    const resources = {
        autoReplenish: 'Auto-Replenish',
        nextShipment: 'Next Shipment by',
        pausedDelivery: 'Paused Delivery Every',
        manageSubscription: 'Manage Subscription',
        resumeSubscription: 'Resume Subscription',
        unsubscribe: 'Unsubscribe',
        save: 'save',
        annuallyWithSubscription: 'annually with this subscription.',
        deliverEvery: 'Delivery Every',
        paused: 'Paused',
        canceled: 'Canceled',
        viewMore: 'View More',
        viewMoreSubscriptions: 'View More Subscriptions',
        item: `ITEM ${vars[0]}`,
        needHelp: 'Need Help?',
        viewFAQ: 'View FAQs',
        rateOf: `at a rate of ${vars[0]}% off`,
        stillSave: 'you can still save',
        before: 'before',
        autoReplenishPromoInfoModalMsg2: 'After 3 deliveries or expiry, whichever comes first, your subscription renews at a 5% discount rate.*',
        autoReplenishPromoInfoModalButton: 'Got It',
        lastDeliveryLeft: `delivery left at ${vars[0]}% off`,
        deliveriesLeft: `deliveries left at ${vars[0]}% off`,
        discountValidUntil: `Discount valid until ${vars[0]}`,
        discountsValidUntil: `Discounts valid until ${vars[0]}`,
        or: 'Or',
        savePercentage: `save ${vars[0]}%`,
        getItBefore: 'if you get it before',
        canceledSubscriptionsHeading: 'Canceled Subscriptions',
        canceledSubscriptionsSubheading: 'Visit product details to create a new active subscription',
        headerItem: 'Item',
        headerPrice: 'Price',
        viewProductDetails: 'View Product Details',
        cancelledOn: `Canceled on ${vars[0]}`,
        discountRate: `${vars[0]}% Off`,
        activeSubscriptionsCount: `1-${vars[0]} of ${vars[1]} Active Subscriptions`,
        pausedSubscriptionsCount: `1-${vars[0]} of ${vars[1]} Paused Subscriptions`,
        canceledSubscriptionsCount: `1-${vars[0]} of ${vars[1]} Canceled Subscriptions`
    };

    return resources[label];
}
