import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import Banner from 'components/Content/Banner';
import contentConsts from 'constants/content';
import Empty from 'constants/empty';
import {
    Box, Flex, Link, Text
} from 'components/ui';
import { setIntersectionObserver } from 'utils/intersectionObserver';
import { fontSizes } from 'style/config';
import { UserInfoReady } from 'constants/events';

const { BANNER_TYPES, CONTEXTS, COMPONENT_SPACING } = contentConsts;

class PersistentBanner extends BaseClass {
    ref = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps) {
        // Wait until the personalization data has been initialized to make sure
        // we have all p13n contexts to compare our banners against

        if (!prevProps.p13n.isInitialized && this.props.p13n.isInitialized) {
            this.setUpViewableImpression();
        }
    }

    componentDidMount() {
        if (this.props.p13n.isInitialized) {
            this.setUpViewableImpression();
        }
    }

    setUpViewableImpression = () => {
        // The impression event for SOT needs user data, so we need it to be ready before we trigger the impression.
        Sephora.Util.onLastLoadEvent(window, [UserInfoReady], () => {
            setIntersectionObserver(this.ref.current, this.props.triggerImpression);
        });
    };

    render() {
        const content = (this.props.data && Array.isArray(this.props.data) && this.props.data[0]) || Empty.Object;
        const personalization = content?.personalization || Empty.Object;
        const { showUnrecognizedPersistentBanner } = this.props;

        return Object.keys(content).length ? (
            <div ref={this.ref}>
                {showUnrecognizedPersistentBanner ? (
                    <Box
                        backgroundColor={'#C0DCF1'}
                        padding={COMPONENT_SPACING.XS}
                    >
                        <Flex
                            justifyContent='center'
                            flexWrap='wrap'
                            fontSize={[fontSizes.sm, fontSizes.base]}
                        >
                            <Text
                                fontWeight={'bold'}
                                marginRight={1}
                                textAlign={'center'}
                            >
                                Don't miss out!
                            </Text>
                            <Text
                                marginRight={1}
                                textAlign={'center'}
                            >
                                So many deals, trial sizes, sample sets and more.
                            </Text>
                            <Link
                                fontWeight={'bold'}
                                href='/beauty/beauty-offers'
                                textAlign={'center'}
                            >
                                Shop Beauty Offers &#9656;
                            </Link>
                        </Flex>
                    </Box>
                ) : (
                    <Banner
                        {...content}
                        bannerType={BANNER_TYPES.PERSISTENT}
                        marginTop={[0, 0]}
                        marginBottom={[0, 0]}
                        personalization={personalization}
                        context={CONTEXTS.PERSISTENT_BANNER}
                        useMediaHeight={this.props.useMediaHeight}
                    />
                )}
            </div>
        ) : null;
    }
}

export default wrapComponent(PersistentBanner, 'PersistentBanner', true);
