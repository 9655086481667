import ufeApi from 'services/api/ufeApi';

const getEventRSVPConfirmationContent = (token, {
    confirmationNumber, language, country, preferredZipCode, channel = 'rwd'
}) => {
    const languageLowerCase = language.toLowerCase();
    const { sdnApiHost } = Sephora.configurationSettings;

    const url = `${sdnApiHost}/v2/happening/events/confirmation/${confirmationNumber}?zipCode=${preferredZipCode}&locale=${languageLowerCase}-${country}&channel=${channel}&country=${country}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: { 'x-requested-source': 'rwd', authorization: `Bearer ${token}` }
    });
};

export default getEventRSVPConfirmationContent;
