export default function getResource(label, vars = []) {
    const resources = {
        merchandiseSubtotalText: 'Subtotal',
        pointsUsed: 'Points Used',
        discountsText: 'Discounts',
        free: 'FREE',
        tbdText: 'TBD',
        shippingAndHandlingText: 'Shipping & Handling',
        autoReplenishSavings: 'Auto-Replenish Savings',
        gstHstText: 'Estimated GST/HST',
        taxText: 'Estimated Tax',
        pickup: 'Pickup',
        bagFee: 'Pickup Bag Fee'
    };

    return resources[label];
}
