import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

import {
    Box, Flex, Image, Text
} from 'components/ui';
import LegacyGrid from 'components/LegacyGrid/LegacyGrid';
import Radio from 'components/Inputs/Radio/Radio';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import ContentDivider from 'components/RichProfile/EditMyProfile/Content/ContentDivider';
import ContentHeading from 'components/RichProfile/EditMyProfile/Content/ContentHeading';
import localeUtils from 'utils/LanguageLocale';
import { CATEGORY_TYPE } from 'constants/beautyPreferences';

class Hair extends BaseClass {
    constructor(props) {
        super(props);

        const biDataToSave = this.props.biDataToSave || {};
        const biAccountToSave = biDataToSave.biAccount || {};
        const personalizedInfoToSave = biAccountToSave.personalizedInformation || {};

        let savedConcerns = [];

        if (personalizedInfoToSave.hairConcerns) {
            savedConcerns = personalizedInfoToSave.hairConcerns;
        } else {
            const hairConcerns = this.props.biAccount.personalizedInformation.hairConcerns;
            hairConcerns.forEach(hairConcern => {
                if (hairConcern.isSelected) {
                    savedConcerns.push(hairConcern.value);
                }
            });
        }

        let savedHairColor;

        if (personalizedInfoToSave.hairColor) {
            savedHairColor = personalizedInfoToSave.hairColor;
        } else {
            const hairColors = this.props.biAccount.personalizedInformation.hairColor;
            hairColors.forEach(hairColor => {
                if (hairColor.isSelected) {
                    savedHairColor = hairColor.value;
                }
            });
        }

        let savedHairDescriptions = [];

        if (personalizedInfoToSave.hairDescrible) {
            savedHairDescriptions = personalizedInfoToSave.hairDescrible;
        } else {
            const hairDescriptions = this.props.biAccount.personalizedInformation.hairDescrible;
            hairDescriptions.forEach(hairDescription => {
                if (hairDescription.isSelected) {
                    savedHairDescriptions.push(hairDescription.value);
                }
            });
        }

        this.state = {
            hairColor: savedHairColor,
            hairDescriptions: savedHairDescriptions,
            hairConcerns: savedConcerns
        };
    }

    handleHairConcernSelect = hairConcern => e => {
        const concerns = this.state.hairConcerns.slice();

        if (e.target.checked) {
            concerns.push(hairConcern.value);
        } else {
            const itemToRemove = concerns.indexOf(hairConcern.value);
            concerns.splice(itemToRemove, 1);
        }

        this.setState({ hairConcerns: concerns });
    };

    handleHairDescriptionSelect = hairDescription => e => {
        const descriptions = this.state.hairDescriptions.slice();

        if (e.target.checked) {
            descriptions.push(hairDescription.value);
        } else {
            const itemToRemove = descriptions.indexOf(hairDescription.value);
            descriptions.splice(itemToRemove, 1);
        }

        this.setState({ hairDescriptions: descriptions });
    };

    getData = () => {
        const hairData = {
            biAccount: {
                personalizedInformation: {
                    hairColor: this.state.hairColor,
                    hairDescrible: this.state.hairDescriptions,
                    hairConcerns: this.state.hairConcerns
                }
            }
        };

        return hairData;
    };

    handleHairColorChange = hairColor => () => {
        this.setState({
            hairColor: hairColor
        });
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/RichProfile/EditMyProfile/Content/Hair/locales', 'Hair');
        const { excludeHairColor, excludeHairDescribe, excludeHairConcerns } = this.props;
        const hairColors = this.props.biAccount.personalizedInformation.hairColor;
        const hairDescriptions = this.props.biAccount.personalizedInformation.hairDescrible;
        const hairConcerns = this.props.biAccount.personalizedInformation.hairConcerns.filter(
            concern => concern.value.indexOf(CATEGORY_TYPE.NOT_SURE) < 0
        );

        const gutter = 4;
        const cellWidth = Sephora.isMobile() ? 1 / 2 : 1 / 3;

        return (
            <div>
                {excludeHairColor || (
                    <div
                        role='group'
                        aria-labelledby='profile_heading_hairColor'
                    >
                        <ContentHeading
                            id='profile_heading_hairColor'
                            parens={getText('selectOne')}
                        >
                            {getText('hairColor')}
                        </ContentHeading>
                        <LegacyGrid gutter={gutter}>
                            {hairColors.map(hairColor => {
                                return (
                                    <LegacyGrid.Cell
                                        key={hairColor.value}
                                        width={cellWidth}
                                        marginTop={5}
                                    >
                                        <Radio
                                            hasHover={true}
                                            hasDot={false}
                                            paddingY={null}
                                            name='hairColor'
                                            checked={this.state.hairColor === hairColor.value}
                                            onChange={this.handleHairColorChange(hairColor.value)}
                                        >
                                            <Flex alignItems='center'>
                                                <Box
                                                    border='3px solid'
                                                    borderColor={this.state.hairColor === hairColor.value ? 'black' : 'transparent'}
                                                    borderRadius='7px'
                                                >
                                                    <Image
                                                        display='block'
                                                        src={`/img/ufe/rich-profile/haircolor-${hairColor.value}.png`}
                                                        size={36}
                                                    />
                                                </Box>
                                                <Text marginLeft={4}>{hairColor.displayName}</Text>
                                            </Flex>
                                        </Radio>
                                    </LegacyGrid.Cell>
                                );
                            })}
                        </LegacyGrid>
                    </div>
                )}

                {excludeHairDescribe || (
                    <div
                        role='group'
                        aria-labelledby='profile_heading_hairType'
                    >
                        {excludeHairColor || <ContentDivider />}
                        <ContentHeading
                            id='profile_heading_hairType'
                            parens={getText('selectAllType')}
                        >
                            {getText('hairType')}
                        </ContentHeading>
                        <LegacyGrid gutter={gutter}>
                            {hairDescriptions.map((hairDescription, index) => (
                                <LegacyGrid.Cell
                                    key={hairDescription.displayName || index}
                                    width={cellWidth}
                                    marginTop={4}
                                >
                                    <Checkbox
                                        hasHover={true}
                                        /*eslint max-len: [0]*/
                                        checked={this.state.hairDescriptions.indexOf(hairDescription.value) !== -1}
                                        onClick={this.handleHairDescriptionSelect(hairDescription)}
                                    >
                                        {hairDescription.displayName}
                                    </Checkbox>
                                </LegacyGrid.Cell>
                            ))}
                        </LegacyGrid>
                    </div>
                )}

                {excludeHairConcerns || (
                    <div
                        role='group'
                        aria-labelledby='profile_heading_hairConcern'
                    >
                        {(!excludeHairColor || !excludeHairDescribe) && <ContentDivider />}
                        <ContentHeading
                            id='profile_heading_hairConcern'
                            parens={getText('selectAllType')}
                            isMyProfile={true}
                        >
                            {getText('hairConcerns')}
                        </ContentHeading>
                        <LegacyGrid gutter={gutter}>
                            {hairConcerns.map((hairConcern, index) => {
                                let displayName = hairConcern.displayName;

                                // Added this because the API is combining two different properties into one
                                // TODO: Remove this once the API is correct
                                if (displayName === 'Straightening/Smoothing') {
                                    displayName = 'Straightening';
                                }

                                return (
                                    <LegacyGrid.Cell
                                        key={hairConcern.displayName || index}
                                        width={cellWidth}
                                        marginTop={4}
                                    >
                                        <Checkbox
                                            hasHover={true}
                                            checked={this.state.hairConcerns.indexOf(hairConcern.value) !== -1}
                                            onClick={this.handleHairConcernSelect(hairConcern)}
                                        >
                                            {displayName}
                                        </Checkbox>
                                    </LegacyGrid.Cell>
                                );
                            })}
                        </LegacyGrid>
                    </div>
                )}
            </div>
        );
    }
}

export default wrapComponent(Hair, 'Hair');
