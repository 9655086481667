import ufeApi from 'services/api/ufeApi';
const reservationApiBase = '/v1/reservations/confirmation/';
const CANCELLED = 'customer-cancelled';
const WEB = 'web';

// API documentation: https://confluence.sephora.com/wiki/display/ILLUMINATE/Update+BookingStatus+by+ConfirmationNumber

function updateReservation(token, confirmationNumber) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const url = sdnDomain + reservationApiBase + confirmationNumber + '/status';
    const params = {
        status: CANCELLED,
        channel: WEB
    };

    var options = {
        url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
        mode: 'cors',
        credentials: 'omit'
    };

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default updateReservation;
