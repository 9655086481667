export default function getResource(label, vars = []) {
    const resources = {
        loves: 'Favoris',
        signInButton: 'Ouvrir une session',
        createAccountButton: 'Créer un compte',
        recentlyLoved: 'Récemment aimé',
        viewAll: 'Tout afficher',
        shopNow: 'Magasiner',
        itemShip: 'Cet article ne peut pas être expédié vers le pays suivant',
        noLovesDesc: 'Utilisez votre liste de favoris pour faire le suivi de vos produits préférés.',
        getTheseBeforeTheyAreGone: 'Procurez-vous-les avant qu’ils ne partent',
        onSaleNow: 'En solde maintenant',
        lookingForFavBrands: 'Vous cherchez vos marques préférées?',
        goToBeautyPrefs: 'Aller aux préférences beauté',
        item: 'article',
        items: 'articles'
    };
    return resources[label];
}
