import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import basketSelector from 'selectors/basket/basketSelector';
import rewardsSelector from 'selectors/rewards/rewardsSelector';
import BasketUserDataSelector from 'selectors/page/rwdBasket/basketUserDataSelector';
import basketActions from 'actions/BasketActions';
const { basketUserDataSelector } = BasketUserDataSelector;

const { wrapHOC } = FrameworkUtils;
const fields = createSelector(rewardsSelector, basketUserDataSelector, basketSelector, (availableRewards, user, basket) => {
    return {
        availableRewards,
        biAccount: { biPoints: basket.netBeautyBankPointsAvailable, biStatus: user.biStatus, birthdayRewardDaysLeft: user.birthdayRewardDaysLeft }
    };
});

const functions = {
    setFromBazaar: basketActions.setFromBazaar
};

const withRewardsBazaarModalProps = wrapHOC(connect(fields, functions));

export {
    withRewardsBazaarModalProps, fields, functions
};
