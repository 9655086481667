export default function getResource(label, vars = []) {
    const resources = {
        billingAddress: 'Adresse de facturation',
        saveAsDefault: 'Définir comme carte par défaut',
        saveContinueButton: 'Enregistrer et continuer',
        saveForFuture: 'Conserver pour des réservations et des achats futurs',
        cancel: 'Annuler',
        useDiffAddressRadio: 'Utiliser une adresse différente'
    };

    return resources[label];
}
