export default {
    VARIANTS: {
        DEFAULT: 'default',
        GRID: 'grid',
        CAROUSEL: 'carousel',
        SOFTLINK: 'softlink'
    },
    DEFAULT_DIMENSIONS: {
        SMUI: {
            HEIGHT: 120,
            WIDTH: 240
        },
        LGUI: {
            HEIGHT: 120,
            WIDTH: 240
        }
    },
    GRID_DIMENSIONS: {
        SMUI: {
            HEIGHT: 82,
            WIDTH: 164
        },
        LGUI: {
            HEIGHT: 147,
            WIDTH: 294
        }
    },
    CAROUSEL_DIMENSIONS: {
        SMUI: {
            HEIGHT: 150,
            WIDTH: 300
        },
        LGUI: {
            HEIGHT: 200,
            WIDTH: 400
        }
    }
};
