/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import {
    Text, Box, Flex, Image, Link, Grid
} from 'components/ui';
import Chevron from 'components/Chevron';
import BeautyPreferencesContentHeading from 'components/Header/BeautyPreferences/BeautyPreferencesContentHeading/BeautyPreferencesContentHeading';
import { notSureOption, noPreferenceOption } from 'constants/beautyPreferences';
import BeautyProfileHeadingTitle from 'components/Header/BeautyPreferences/BeautyProfileHeading/BeautyProfileHeadingTitle';
import ColorIQContent from 'components/Header/BeautyPreferences/ColorIQContent';
import { CONTAINER_SIZE } from 'constants/beautyPreferences';

function BeautyProfileHeading(props) {
    const {
        profileCategory,
        handleClick,
        expanded,
        imgPrefix,
        isOptionsSelected,
        parenText,
        isColorIQ,
        isColorIQSelected,
        currentBeautyPreference,
        title,
        isPostSignupModal,
        isFirstTrait,
        ofText,
        totalPages,
        currentPage
    } = props;

    const id = 'filter_test';
    const headingId = `${id}_heading`;
    const toggleRef = React.createRef();

    return (
        <>
            <Link
                ref={toggleRef}
                aria-controls={id}
                aria-expanded={expanded}
                onClick={() => (isPostSignupModal ? null : handleClick(profileCategory.type, profileCategory.name))}
                display='flex'
                flexDirection={isPostSignupModal && 'column'}
                marginBottom={isPostSignupModal && 1}
                flexWrap='wrap'
                hoverSelector='.Link-target'
                alignItems='center'
                lineHeight='tight'
                width='100%'
                paddingTop={(!isPostSignupModal || isFirstTrait) && 4}
                paddingBottom={!expanded && 4}
                backgroundColor='white'
                css={[
                    !isPostSignupModal
                        ? expanded
                            ? {
                                rowGap: profileCategory?.openModal ? '16px' : '5px'
                            }
                            : {
                                rowGap: isColorIQ ? '16px' : '9px'
                            }
                        : { pointerEvents: 'none' }
                ]}
            >
                <BeautyProfileHeadingTitle
                    profileCategory={profileCategory}
                    expanded={expanded}
                    title={title}
                    isColorIQ={isColorIQ}
                    isPostSignupModal={isPostSignupModal}
                    ofText={ofText}
                    totalPages={totalPages}
                    currentPage={currentPage}
                />
                {isPostSignupModal ? (
                    <Box
                        ref={this.drawerRef}
                        id={id}
                        aria-labelledby={headingId}
                        width='100%'
                        flex={'1 0 auto'}
                    >
                        <BeautyPreferencesContentHeading
                            isExpanded={expanded}
                            isModal={profileCategory.openModal}
                            isRedirect={profileCategory.isRedirect}
                            parens={parenText}
                        >
                            {props[profileCategory.type]}
                        </BeautyPreferencesContentHeading>
                    </Box>
                ) : (
                    <>
                        {isOptionsSelected && (
                            <Box
                                ref={this.drawerRef}
                                id={id}
                                aria-labelledby={headingId}
                                width={['100%', '100%', null]}
                                paddingLeft={[null, null, 2]}
                                flex={['1 0 auto', null, `1 0 ${CONTAINER_SIZE}px`]}
                                order={[6, null, 2]}
                                alignSelf={'end'}
                                css={{
                                    transition: 'all .3s'
                                }}
                                onTransitionEnd={e => {
                                    if (expanded) {
                                        e.target.style.overflow = 'initial';
                                    }
                                }}
                            >
                                {expanded ? (
                                    <BeautyPreferencesContentHeading
                                        isExpanded={expanded}
                                        isModal={profileCategory.openModal}
                                        isRedirect={profileCategory.isRedirect}
                                        parens={parenText}
                                    >
                                        {props[profileCategory.type]}
                                    </BeautyPreferencesContentHeading>
                                ) : (
                                    <>
                                        {isColorIQ ? (
                                            isColorIQSelected && (
                                                <Grid
                                                    columns={[1, null, 2]}
                                                    columnGap={4}
                                                    maxWidth={[null, null, CONTAINER_SIZE]}
                                                >
                                                    <ColorIQContent />
                                                </Grid>
                                            )
                                        ) : (
                                            <Grid
                                                columns={[2, null, 4]}
                                                columnGap={4}
                                                maxWidth={[null, null, CONTAINER_SIZE]}
                                            >
                                                {currentBeautyPreference.map(profile => {
                                                    const imgSrc = imgPrefix + profile.toLowerCase() + '.' + profileCategory.iconType;
                                                    const isNotSureOrNoPreference =
                                                        profileCategory.showIcon && profile !== notSureOption && profile !== noPreferenceOption;

                                                    return (
                                                        <Flex
                                                            key={profile}
                                                            alignItems='center'
                                                            gap='10px'
                                                        >
                                                            <Box borderRadius='50%'>
                                                                <Image
                                                                    borderRadius='50%'
                                                                    display='block'
                                                                    src={isNotSureOrNoPreference ? imgSrc : '/img/ufe/rich-profile/checkmark.svg'}
                                                                    maxWidth={null}
                                                                    size={[26, isNotSureOrNoPreference ? null : 26, 36]}
                                                                />
                                                            </Box>
                                                            <Text
                                                                is='p'
                                                                css={{ flex: 1 }}
                                                            >
                                                                {props[profile]}
                                                            </Text>
                                                        </Flex>
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Box>
                        )}
                    </>
                )}
                {!isPostSignupModal && (
                    <Chevron
                        isThicker={true}
                        css={{
                            order: '3',
                            alignSelf: 'baseline'
                        }}
                        direction={expanded ? 'up' : 'down'}
                    />
                )}
            </Link>
        </>
    );
}

BeautyProfileHeading.propTypes = {
    profileCategory: PropTypes.object.isRequired,
    handleClick: PropTypes.func,
    expanded: PropTypes.bool,
    isPostSignupModal: PropTypes.bool,
    isFirstTrait: PropTypes.bool
};

BeautyProfileHeading.defaultProps = {
    expanded: false,
    isPostSignupModal: false,
    isFirstTrait: false
};

export default wrapFunctionalComponent(BeautyProfileHeading, 'BeautyProfileHeading');
