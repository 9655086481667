import ufeApi from 'services/api/ufeApi';
const { makeRequest } = ufeApi;
import catalogUtils from 'utils/Catalog';
import languageLocaleUtils from 'utils/LanguageLocale';

const { getCurrentCountry, getCurrentLanguage } = languageLocaleUtils;

// https://jira.sephora.com/browse/INFL-1011

function getGroupedBrandsList() {
    let url = '/api/catalog/groupedBrandsList';

    if (catalogUtils.isBXSServiceEnabled()) {
        const channel = 'rwd';
        const language = getCurrentLanguage()?.toLowerCase();
        const country = getCurrentCountry();
        const paramsString = `?ch=${channel}&loc=${language}-${country}`;

        url = `/api/v2/catalog/groupedBrandsList${paramsString}`;
    }

    return makeRequest(url).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { getGroupedBrandsList };
