import ufeApi from 'services/api/ufeApi';
import headerUtils from 'utils/Headers';

const { userXTimestampHeader } = headerUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Create+User+API

export function createUser(profileData) {
    const url = '/api/users/profile';

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            headers: {
                'x-requested-source': 'web',
                ...userXTimestampHeader()
            },
            body: JSON.stringify(profileData)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { createUser };
