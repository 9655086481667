import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import actions from 'Actions';
import UserActions from 'actions/UserActions';
import { userSelector } from 'selectors/user/userSelector';
import BeautyPreferencesSelector from 'selectors/beautyPreferences/beautyPreferencesSelector';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import ShowForcedSignInTooltipSelector from 'selectors/testTarget/showForcedSignInTooltipSelector';
import authenticationUtils from 'utils/Authentication';
import purchaseHistorySelector from 'selectors/purchaseHistory/purchaseHistorySelector';

const { showForcedSignInTooltipSelector } = ShowForcedSignInTooltipSelector;
const { beautyPreferencesSelector } = BeautyPreferencesSelector;
const { signOut, getUserFull, fetchPurchaseHistory } = UserActions;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/Header/AccountMenu/locales', 'AccountMenu');
const { storeAuthTokens } = authenticationUtils;

export default connect(
    createSelector(
        createStructuredSelector({
            greeting: getTextFromResource(getText, 'greeting'),
            signIn: getTextFromResource(getText, 'signIn'),
            signInPrompt: getTextFromResource(getText, 'signInPrompt'),
            biHeading: getTextFromResource(getText, 'biHeading'),
            biDesc: getTextFromResource(getText, 'biDesc'),
            joinNow: getTextFromResource(getText, 'joinNow'),
            rewardsHeading: getTextFromResource(getText, 'rewardsHeading'),
            rewardsDesc: getTextFromResource(getText, 'rewardsDesc'),
            pointsLabel: getTextFromResource(getText, 'pointsLabel'),
            chooseBirthdayGift: getTextFromResource(getText, 'chooseBirthdayGift'),
            ccHeading: getTextFromResource(getText, 'ccHeading'),
            ccDesc: getTextFromResource(getText, 'ccDesc'),
            ccRewardsLabel: getTextFromResource(getText, 'ccRewardsLabel'),
            ccApplyNow: getTextFromResource(getText, 'ccApplyNow'),
            ordersHeading: getTextFromResource(getText, 'ordersHeading'),
            ordersDesc: getTextFromResource(getText, 'ordersDesc'),
            autoReplenishHeading: getTextFromResource(getText, 'autoReplenishHeading'),
            autoReplenishDesc: getTextFromResource(getText, 'autoReplenishDesc'),
            autoReplenishDescWithSubs: getTextFromResource(getText, 'autoReplenishDescWithSubs'),
            purchasesHeading: getTextFromResource(getText, 'purchasesHeading'),
            purchasesDesc: getTextFromResource(getText, 'purchasesDesc'),
            lovesHeading: getTextFromResource(getText, 'lovesHeading'),
            lovesDesc: getTextFromResource(getText, 'lovesDesc'),
            recHeading: getTextFromResource(getText, 'recHeading'),
            recDesc: getTextFromResource(getText, 'recDesc'),
            reservationsHeading: getTextFromResource(getText, 'reservationsHeading'),
            reservationsDesc: getTextFromResource(getText, 'reservationsDesc'),
            beautyPrefHeading: getTextFromResource(getText, 'beautyPrefHeading'),
            beautyPrefDesc: getTextFromResource(getText, 'beautyPrefDesc'),
            guidedSellingBeautyPrefDesc: getTextFromResource(getText, 'guidedSellingBeautyPrefDesc'),
            accountHeading: getTextFromResource(getText, 'accountHeading'),
            accountDesc: getTextFromResource(getText, 'accountDesc'),
            signOut: getTextFromResource(getText, 'signOut'),
            sameDayUnlimitedHeading: getTextFromResource(getText, 'sameDayUnlimitedHeading'),
            sameDayUnlimitedMemberDesc: getTextFromResource(getText, 'sameDayUnlimitedMemberDesc'),
            sameDayUnlimitedNonMemberDesc: getTextFromResource(getText, 'sameDayUnlimitedNonMemberDesc'),
            subscribeToday: getTextFromResource(getText, 'subscribeToday'),
            createAccount: getTextFromResource(getText, 'createAccount'),
            noAccount: getTextFromResource(getText, 'noAccount'),
            signInForShipping: getTextFromResource(getText, 'signInForShipping'),
            helloBeautiful: getTextFromResource(getText, 'helloBeautiful'),
            beautyChallenges: getTextFromResource(getText, 'beautyChallenges'),
            new: getTextFromResource(getText, 'new'),
            beautyChallengesDescription: getTextFromResource(getText, 'beautyChallengesDescription')
        }),
        userSelector,
        beautyPreferencesSelector,
        showForcedSignInTooltipSelector,
        purchaseHistorySelector,
        (localization, user, { profileCompletionPercentage }, showForcedSignInTooltip, purchaseHistory) => {
            return {
                localization,
                user,
                profileCompletionPercentage,
                showForcedSignInTooltip,
                purchaseHistory
            };
        }
    ),
    {
        showSignInModal: actions.showSignInModal,
        getUserFull,
        showBiRegisterModal: actions.showBiRegisterModal,
        signOut,
        showRegisterModal: actions.showRegisterModal,
        storeAuthTokens,
        fetchPurchaseHistory
    }
);
