/* eslint-disable complexity */
/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import basketUtils from 'utils/Basket';
import {
    Text, Button, Flex, Icon, Link
} from 'components/ui';
import OutOfStockButton from 'components/AddToBasketButton/OutOfStockButton/OutOfStockButton';
import skuUtils from 'utils/Sku';
import LanguageLocale from 'utils/LanguageLocale';
import Location from 'utils/Location';
import Debounce from 'utils/Debounce';
import ReactDOM from 'react-dom';
import rrcUtils from 'utils/RrcTermsAndConditions';
import anaConsts from 'analytics/constants';
import anaUtils from 'analytics/utils';
import { breakpoints, space } from 'style/config';
import quicklookModalUtils from 'utils/Quicklook';
import basketConstants from 'constants/Basket';
import Empty from 'constants/empty';
import DeliveryFrequency from 'utils/DeliveryFrequency';
import storeUtils from 'utils/Store';
import helpersUtils from 'utils/Helpers';
import promoUtils from 'utils/Promos';
import productPageSOTBindings from 'analytics/bindingMethods/pages/productPage/productPageSOTBindings';
import processEvent from 'analytics/processEvent';

const { getLocaleResourceFile } = LanguageLocale;
const { formatFrequencyType } = DeliveryFrequency;
const { formatSiteCatalystPrice, deferTaskExecution } = helpersUtils;
const { BasketType, PROMO_WARNING } = basketConstants;
const { ADD_TO_BASKET_TYPES: ADD_BUTTON_TYPE } = basketUtils;

const THROTTLE_DELAY = 200;
const ADDED_TEXT_TIMEOUT = 1000;
const BASKET_TYPE_TO_TEXT = {
    [BasketType.BOPIS]: 'storePickup',
    [BasketType.SameDay]: 'sameDayDelivery',
    [BasketType.Standard]: 'standardShipping'
};

function getPendingSkus(pendingBasketSkus) {
    return pendingBasketSkus.filter(item => {
        if (item.methodId) {
            item.method();

            return false;
        }

        return true;
    });
}

class AddToBasketButton extends BaseClass {
    state = {
        disabled: true,
        showAddToBasketModal: false,
        isSample: skuUtils.isSample(this.props.sku),
        isAdded: false,
        basketIsBopisKohlsAndHasItems: false,
        clickIsFromBazaar: false
    };

    handleClickButton = e => {
        e.stopPropagation();
        e.preventDefault();

        if (!Location.isBasketPage()) {
            this.props.setBasketType('');
        }

        const { sku, isRRCModal } = this.props;

        const isRougeRewardCard = skuUtils.isRougeRewardCard(sku);
        const isBiReward = skuUtils.isBiReward(sku);

        let handleClick = !isRRCModal && isRougeRewardCard ? this.addClickModal : this.handleAddClick;

        if (this.state.isInBasket && (this.state.isSample || isRougeRewardCard || isBiReward)) {
            handleClick = this.handleAddClick;
        }

        deferTaskExecution(() => {
            handleClick();
        });
    };

    isDisabled = ({ isAppExclusive, isInAutoReplenishmentBasket }) => {
        const {
            sku, product, disabled, basketType, isCustomSets, preferredStore, autoReplenishChecked, isRewardItem, isBIPointsAvailable
        } =
            this.props;
        const { preferredStoreInfo } = preferredStore;
        const { customSetsChoices = Empty.Array } = product || Empty.Object;

        const isBOPISAndUnavailable =
            basketType === BasketType.BOPIS &&
            (skuUtils.isReservationNotOffered(sku) ||
                (!Sephora.configurationSettings.isBOPISEnabled && preferredStoreInfo?.isBopisable) ||
                (!Sephora.configurationSettings.isROPISEnabled && preferredStoreInfo?.isRopisable) ||
                (!preferredStoreInfo?.isBopisable && !preferredStoreInfo?.isRopisable));

        const isRewardAndDisabled = isRewardItem && isBIPointsAvailable && !this.state.isInBasket && skuUtils.isRewardDisabled(sku);
        const isAddedSample = this.state.isInBasket && this.state.isSample;

        return (
            (!this.props.isExperiment &&
                (this.state.disabled ||
                    (disabled && !isAddedSample) ||
                    (skuUtils.isBiExclusive(sku) && !skuUtils.isBiQualify(sku)) ||
                    skuUtils.isGwp(sku) ||
                    isAppExclusive ||
                    isBOPISAndUnavailable ||
                    (isCustomSets && customSetsChoices.length === 0))) ||
            isRewardAndDisabled ||
            (autoReplenishChecked && isInAutoReplenishmentBasket) ||
            skuUtils.hasNotEnoughPointsToRedeem(sku)
        );
    };

    render() {
        const getText = getLocaleResourceFile('components/AddToBasketButton/locales', 'AddToBasketButton');
        /* eslint-disable prefer-const */
        let {
            sku,
            variant,
            text,
            disabled,
            isRRCModal,
            analyticsContext,
            originalContext,
            productId,
            isQuickLook,
            promoPanel,
            containerTitle,
            isBIRBReward,
            isBIRBPageRewardModal,
            productName,
            basketType,
            isAddFullSize,
            callback,
            isCustomSets,
            isAddButton,
            displayQuantityPickerInATB,
            minWidth,
            preferredStore,
            autoReplenishChecked,
            rootContainerName,
            isMultiProductsAdd,
            isRewardItem,
            onlyUseTextProp,
            isBIPointsAvailable,
            isRwdBasketPage,
            onSuccess,
            customStyle,
            productSampleModal,
            ...props
        } = this.props;

        const { basketIsBopisKohlsAndHasItems, isInAutoReplenishmentBasket } = this.state;
        /* eslint-enable prefer-const */

        const isBiReward = skuUtils.isBiReward(sku);

        if (sku.isOutOfStock || (isCustomSets && this.props.product?.currentSku?.isOutOfStock)) {
            return (
                <React.Fragment>
                    <OutOfStockButton
                        {...props}
                        basketType={basketType}
                        variant={variant}
                        analyticsContext={analyticsContext}
                        sku={isCustomSets ? this.props.product?.currentSku : sku}
                        customStyle={customStyle}
                    />
                </React.Fragment>
            );
        } else {
            const { isAdded } = this.state;

            const isRougeRewardCard = skuUtils.isRougeRewardCard(sku);

            if (this.state.isInBasket && (this.state.isSample || isRougeRewardCard || isBiReward)) {
                text = getText('remove');
                disabled = false;
            }

            const isAppExclusive = skuUtils.isAppExclusive(sku);

            // prevent button width shift during `isAdded` state
            if (!minWidth && !text) {
                props.minWidth = isAddButton ? '5.63em' : '10.47em';
            }

            const isDisabled = this.isDisabled({
                isAppExclusive,
                isInAutoReplenishmentBasket
            });

            let variantProp = variant || ADD_BUTTON_TYPE.SECONDARY;

            if (basketType === BasketType.BOPIS) {
                variantProp = ADD_BUTTON_TYPE.SPECIAL;
            }

            return (
                <Button
                    {...props}
                    variant={variantProp}
                    className={isAdded ? 'is-active' : null}
                    disabled={isDisabled || basketIsBopisKohlsAndHasItems}
                    onClick={this.handleClickButton}
                    css={customStyle}
                    maxWidth={productSampleModal ? '80px' : null}
                >
                    {this.renderBasketText({ isAppExclusive, isAdded, removeText: text })}
                </Button>
            );
        }
    }

    // Get the secondary text (the text that appears under the main text, related to Auto Replenishment,
    // Same Day Delivery) for the Add to Basket button
    getSecondaryText = ({ sameDayTitle, supportedBusketType, isBiReward }) => {
        const { autoReplenishChecked, sku } = this.props;
        let secondaryText;

        if (isBiReward) {
            return secondaryText;
        }

        if (autoReplenishChecked && sku.isReplenishmentEligible) {
            secondaryText = 'autoReplenish';
        } else if (sameDayTitle) {
            secondaryText = 'sameDayCustomDelivery';
        } else {
            secondaryText = BASKET_TYPE_TO_TEXT[supportedBusketType];
        }

        if (secondaryText === BASKET_TYPE_TO_TEXT[BasketType.Standard]) {
            secondaryText = 'standardShipping';
        }

        return secondaryText;
    };

    renderTwoLines = ({ getText }) => {
        const { suppressCheckmark } = this.props;

        return (
            <Flex
                flexDirection='column'
                paddingLeft={suppressCheckmark ? 2 : 4}
                paddingRight={2}
            >
                <Flex
                    fontWeight='bold'
                    alignItems='center'
                >
                    {suppressCheckmark ? null : (
                        <Icon
                            name='checkmark'
                            size='1em'
                            css={{
                                position: 'absolute',
                                transform: `translateX(calc(-100% - ${space[1]}px))`
                            }}
                        />
                    )}
                    {getText('added')}
                </Flex>
                <Link
                    color='blue'
                    fontSize='11px'
                    fontWeight='normal'
                    children={getText('remove')}
                />
            </Flex>
        );
    };

    // Renders the text inside the Add to Basket button, including its component structure, based on the
    // button's state and the current context
    renderBasketText = ({ isAppExclusive, isAdded, removeText }) => {
        const { basketIsBopisKohlsAndHasItems, isInAutoReplenishmentBasket } = this.state;
        const {
            isRwdBasketPage,
            onlyUseTextProp,
            sku,
            isExperiment,
            autoReplenishChecked,
            isAddButton,
            isCustomSets,
            basketType,
            isAddTwoLines,
            ctaTwoLines = true
        } = this.props;
        let fontWeightMainText = 'bold';
        let fontWeightSecondaryText = 'normal';
        const getText = getLocaleResourceFile('components/AddToBasketButton/locales', 'AddToBasketButton');

        if ((isRwdBasketPage && removeText === getText('remove')) || (isAddTwoLines && removeText === getText('remove'))) {
            return this.renderTwoLines({ getText });
        }

        if (onlyUseTextProp != null) {
            return removeText || onlyUseTextProp;
        }

        const isBiExclusive = skuUtils.isBiExclusive(sku);

        if (!isExperiment && (isAppExclusive || (isBiExclusive && !skuUtils.isBiQualify(sku)))) {
            return (
                <span
                    css={this.props.size === 'sm' && { fontSize: '.875em' }}
                    children={`${skuUtils.getExclusiveText(sku)} ${getText('exclusive')}`}
                />
            );
        }

        const addPrefix = isAdded ? 'added' : 'add';
        let mainText = getText(isAddButton ? addPrefix : `${addPrefix}ToBasket`);

        if (removeText) {
            mainText = removeText;
        }

        if (isCustomSets) {
            mainText = getText('addAllToBasket');
        }

        if (basketIsBopisKohlsAndHasItems) {
            mainText = getText('alreadyAddedKohls');
            fontWeightMainText = 'normal';
            fontWeightSecondaryText = 'bold';
        }

        if (autoReplenishChecked && isInAutoReplenishmentBasket) {
            mainText = getText('alreadyAddedKohls');
            fontWeightMainText = 'bold';
            fontWeightSecondaryText = 'normal';
        }

        const isBiReward = skuUtils.isBiReward(sku);
        const sameDayTitle = this.props.product?.currentSku?.sameDayTitle;
        const supportedBusketType = isCustomSets || isBiReward ? BasketType.Standard : basketType;

        const secondaryText = this.getSecondaryText({
            sameDayTitle,
            supportedBusketType,
            isBiReward
        });

        const getLocaleLiteralString = () => {
            const dynamicText = autoReplenishChecked
                ? [`${this.props.replenishmentFreqNum} ${formatFrequencyType(this.props.replenishmentFreqNum, this.props.replenishmentFreqType)}`]
                : sameDayTitle
                    ? [sameDayTitle]
                    : '';

            return getText(secondaryText, dynamicText);
        };

        return secondaryText && ctaTwoLines ? (
            <Text
                fontSize='sm'
                fontWeight={fontWeightMainText}
            >
                {mainText}
                <Text
                    display='block'
                    fontWeight={fontWeightSecondaryText}
                    marginTop='.125em'
                >
                    {getLocaleLiteralString()}
                </Text>
            </Text>
        ) : (
            mainText
        );
    };

    setPropertiesFromBasket = () => {
        const { basketItems, itemCount, fromBazaar, sku } = this.props;

        if (basketItems) {
            this.setState({
                isInBasket: skuUtils.isInBasket(sku.skuId, { items: basketItems }),
                disabled: itemCount === null,
                isInAutoReplenishmentBasket: skuUtils.isInAutoReplenishmentBasket(sku.skuId, { items: basketItems }),
                clickIsFromBazaar: fromBazaar
            });
        }
    };

    componentDidMount() {
        const { isNthCategoryPage } = this.props;

        this.setBasketIsBopisKohlsAndHasItems();

        if (isNthCategoryPage) {
            this.setState({ disabled: false });
        } else {
            this.setPropertiesFromBasket();
        }
    }

    componentDidUpdate(prevProps) {
        const { sku, basketItems } = this.props;

        if ((basketItems && basketItems !== prevProps.basketItems) || sku?.skuId !== prevProps.sku?.skuId) {
            this.setPropertiesFromBasket();
        }

        if (this.checkforBopisAtKohls(prevProps)) {
            this.setBasketIsBopisKohlsAndHasItems(prevProps);
        }
    }

    checkforBopisAtKohls = prevProps => {
        const currentStoreInfo = this.props.preferredStore?.preferredStoreInfo;
        const prevStoreInfo = prevProps.preferredStore?.preferredStoreInfo;
        const currentSku = this.props.sku;
        const prevSku = prevProps.sku;
        const diffBasket = this.props.basketItems && this.props.basketItems !== prevProps.basketItems;
        const diffPrefStoreType = currentStoreInfo?.storeType !== prevStoreInfo?.storeType;
        const diffPrefStoreId = currentStoreInfo?.storeId !== prevStoreInfo?.storeId;
        const diffBasketType = this.props.basketType !== prevProps.basketType;
        const diffSkuId = currentSku?.skuId !== prevSku?.skuId;

        return diffBasket || diffPrefStoreType || diffPrefStoreId || diffBasketType || diffSkuId;
    };

    setBasketIsBopisKohlsAndHasItems = () => {
        const { basketType, sku, pickupBasketItems, preferredStore } = this.props;

        const hasKohlsItemsInBopisBasket = pickupBasketItems?.filter(item => item.sku.skuId === sku.skuId).length > 0;
        const isBopisBasket = basketType === BasketType.BOPIS;
        const isKohlsStore = storeUtils.isKohlsStore(preferredStore?.preferredStoreInfo);
        const basketIsBopisKohlsAndHasItems = hasKohlsItemsInBopisBasket && isBopisBasket && isKohlsStore;

        this.setState({ basketIsBopisKohlsAndHasItems });
    };

    getAddToBasketSuccessCallback = (callback = Empty.Function) => {
        const { onSuccess } = this.props;

        return response => {
            processEvent.process(anaConsts.ADD_TO_CART);

            if (typeof onSuccess === 'function') {
                onSuccess();
            }

            callback();

            let errorMessage = '';

            if (response && response.basketLevelMessages) {
                errorMessage = response.basketLevelMessages.find(msg => msg.messageContext === PROMO_WARNING)?.messages[0] || '';
            }

            if (errorMessage.length) {
                promoUtils.showWarningMessage(errorMessage);
            } else if (response && !response.errors && !Location.isBasketPage() && !this.props.suppressAddToCartModal) {
                // Added a property to specifically suppress showing the Add to Cart modal
                if (window.matchMedia(breakpoints.smMin).matches) {
                    this.showAddToBasketModal();
                }
            }
        };
    };

    getAnalyticsData = () => {
        const {
            sku,
            productId,
            productName,
            product = Empty.Object,
            platform,
            containerTitle,
            isBIRBReward,
            origin,
            pageName,
            originalContext,
            isBIRBPageRewardModal = false,
            isAddFullSize = false,
            rootContainerName,
            basketType,
            skuQuantity,
            displayQuantityPickerInATB,
            isMultiProductsAdd,
            autoReplenishChecked,
            analyticsContext,
            personalizedInternalCampaign
        } = this.props;

        const category = product?.parentCategory?.displayName || sku?.parentCategory?.displayName;
        const brandName = product?.productDetails?.brand?.displayName || sku.brandName;
        const productDescription = product?.productDetails?.shortDescription
            ? product.productDetails.shortDescription.replace(/<\/?\w+[^>]*\/?>/g, '')
            : undefined;
        let productStrings = '';

        if (autoReplenishChecked) {
            productStrings = [
                anaUtils.buildAutoReplenishDeliveryFrequencyPoductString({
                    skuId: sku.skuId,
                    qty: skuQuantity,
                    price: formatSiteCatalystPrice(sku.listPrice),
                    frequency: sku.replenishmentFreqNum,
                    frequencyType: sku.replenishmentFreqType?.toLowerCase()
                })
            ];
        } else {
            productStrings = [anaUtils.buildSingleProductString(this.props.sku, false, skuQuantity, displayQuantityPickerInATB)];
        }

        return {
            productId: sku.productId || product?.productDetails?.productId || productId,
            productName: sku.productName || productName || product?.productDetails?.displayName,
            productStrings,
            displayQuantityPickerInATB,
            productDescription,
            brandName,
            category,
            skuType: sku.type,
            origin,
            pageName,
            originalContext,
            platform,
            isBIRBReward,
            containerTitle,
            isBIRBPageRewardModal,
            isAddFullSize,
            rootContainerName,
            isPickup: basketType === BasketType.BOPIS,
            isSameDay: basketType === BasketType.SameDay,
            isMultiProductsAdd,
            sku,
            isSameDayDeliveryEligible: sku.isSameDayEligibleSku,
            isOnlineOnly: sku.isOnlineOnly,
            isAvailablePreferredStore: basketType === BasketType.BOPIS ? 'Yes' : 'No',
            analyticsContext,
            personalizedInternalCampaign
        };
    };

    triggerAddToBasketAction = () => {
        const {
            sku,
            quantity = 1,
            analyticsContext,
            samplePanel,
            basketType,
            showBasketQuickAdd = false,
            product,
            showBasketCarouselErrorModal = false,
            productSampleModal
        } = this.props;

        const productId = sku.productId || product?.productDetails?.productId || this.props.productId;

        if (this.props?.triggerAnalytics) {
            this.props.triggerAnalytics();
        }

        const autoReplenishFrequency = this.props.autoReplenishChecked
            ? `${this.props.replenishmentFreqType}:${this.props.replenishmentFreqNum}`
            : '';

        this.props.addToBasket(
            sku,
            basketType,
            quantity,
            this.getAddToBasketSuccessCallback(),
            analyticsContext,
            samplePanel,
            this.getAnalyticsData(),
            showBasketQuickAdd,
            this.props.autoReplenishChecked,
            autoReplenishFrequency,
            productId,
            this.props.isCommunityGallery,
            showBasketCarouselErrorModal,
            productSampleModal
        );
    };

    showAddToBasketModal = () => {
        const {
            product,
            sku,
            quantity = 1,
            analyticsContext,
            basketType,
            autoReplenishChecked,
            replenishmentFreqNum,
            replenishmentFreqType,
            isAutoReplenMostCommon
        } = this.props;
        const isRewardRemoval = skuUtils.isBiReward(sku) && skuUtils.isInBasket(sku.skuId);
        const preferredStoreName =
            basketType === BasketType.BOPIS ? storeUtils.getStoreDisplayName(this.props.preferredStore?.preferredStoreInfo) : undefined;

        if (this.props?.triggerAnalytics) {
            this.props.triggerAnalytics();
        }

        if (!isRewardRemoval) {
            this.props.showAddToBasketModal({
                analyticsContext,
                basketType,
                isOpen: true,
                preferredStoreName,
                product: product || sku.primaryProduct,
                quantity,
                sku,
                replenishmentFrequency: autoReplenishChecked ? `${replenishmentFreqType}:${replenishmentFreqNum}` : '',
                replenishmentSelected: autoReplenishChecked,
                isAutoReplenMostCommon
            });
        }
    };

    addClickModal = () => {
        const currentSkuType = skuUtils.getProductType(this.props.sku);
        let areTermsAndConditionsAccepted = false;

        if (this.props?.triggerAnalytics) {
            this.props.triggerAnalytics();
        }

        // If the user has clicked “Add to Basket” to add a Rouge Reward Card,
        // make sure she has accepted T&C
        if (currentSkuType === skuUtils.skuTypes.ROUGE_REWARD_CARD) {
            areTermsAndConditionsAccepted = rrcUtils.areRRCTermsConditionsAccepted();
        }

        if (!areTermsAndConditionsAccepted) {
            this.props.showRougeRewardCardModal({
                isOpen: !areTermsAndConditionsAccepted,
                sku: this.props.sku,
                isRougeExclusiveCarousel: this.props.isRougeExclusiveCarousel || false,
                analyticsContext: this.props.analyticsContext
            });
        } else {
            this.triggerAddToBasketAction(this.props);
        }

        this.blurEl();
    };

    addClick = () => {
        if (this.props?.triggerAnalytics) {
            this.props.triggerAnalytics();
        }

        if (this.props.isNthCategoryPage) {
            quicklookModalUtils.dispatchQuicklook({
                productId: this.props.sku.productId,
                skuType: this.props.sku.type,
                options: { addCurrentSkuToProductChildSkus: true },
                sku: this.props.sku,
                rootContainerName: this.props.rootContainerName,
                productStringContainerName: this.props.productStringContainerName,
                origin: this.props.origin,
                analyticsContext: this.props.analyticsContext
            });
        } else if (this.props.sku && Array.isArray(this.props.sku) && this.props.sku.length > 0) {
            const products = this.props.sku.map(item => ({
                ...item,
                qty: item.qty || 1
            }));
            const qty = products
                .map(item => item.qty)
                .reduce((prev, curr) => {
                    return prev + curr;
                });
            this.props.addMultipleSkusToBasket(
                products,
                qty,
                this.getAddToBasketSuccessCallback(() => this.props.clearPendingProductList()),
                this.props.analyticsContext,
                this.getAnalyticsData(),
                undefined,
                true,
                this.props.basketType
            );

            productPageSOTBindings.addAllToBasket({ skus: this.props.sku });
        } else if (this.props.isReplacementOrder) {
            this.props.addRemoveSample(this.props.sku);
        } else {
            const {
                isCustomSets,
                pendingBasketSkus: pendingBasketSkusData,
                promoPanel,
                sku,
                quantity,
                analyticsContext,
                basketType,
                addMultipleSkusToBasket,
                closeParentModal,
                updateMsgPromo,
                clearPendingProductList
            } = this.props;

            this.setState({ isAdded: true }, () => setTimeout(() => this.setState({ isAdded: false }), ADDED_TEXT_TIMEOUT));

            if (!isCustomSets) {
                if (promoPanel === 'promo') {
                    updateMsgPromo(sku);
                } else {
                    let pendingBasketSkus = getPendingSkus(pendingBasketSkusData);

                    if (pendingBasketSkus.length > 0) {
                        const prod = {
                            skuId: sku.skuId,
                            qty: quantity || 1
                        };

                        pendingBasketSkus = [].concat([prod], pendingBasketSkusData);

                        const qty = pendingBasketSkus
                            .map(item => item.qty)
                            .reduce((prev, curr) => {
                                return prev + curr;
                            });

                        addMultipleSkusToBasket(
                            pendingBasketSkus,
                            qty,
                            this.getAddToBasketSuccessCallback(clearPendingProductList),
                            analyticsContext,
                            undefined,
                            undefined,
                            undefined,
                            basketType
                        );
                    } else {
                        if (closeParentModal) {
                            closeParentModal();
                        }

                        this.triggerAddToBasketAction();
                    }
                }
            }
        }

        this.blurEl();

        if (this.props.callback) {
            this.props.callback();
        }
    };

    blurEl = () => {
        const element = ReactDOM.findDOMNode(this);
        // Remove outlined focus state from the button
        element.blur();
    };

    /**
     * This workaround prevents occasional double-click events
     */
    handleAddClick = Debounce.preventDoubleClick(this.addClick, THROTTLE_DELAY);
}

export default wrapComponent(AddToBasketButton, 'AddToBasketButton', true);
