const ACTIVITY_TYPES = {
    EVENT: 'event',
    SERVICE: 'service'
};

const ERROR_URL = '/happening/error';

export {
    ACTIVITY_TYPES, ERROR_URL
};
