import ufeApi from 'services/api/ufeApi';

/* eslint-disable max-len */
// API documentation: https://stage-developer.sephora.com/specs/v1/docs/store.html#cancelling-an-appointment
/* eslint-enable max-len */

function cancelReservation(token, confirmationNumber) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const url = sdnDomain + '/v1/olr/reservations/' + confirmationNumber;

    var options = {
        method: 'DELETE',
        url: url,
        headers: {
            Authorization: 'Bearer ' + token
        },
        mode: 'cors',
        credentials: 'omit'
    };

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default cancelReservation;
