/* eslint-disable class-methods-use-this */
import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { Box, Button, Text } from 'components/ui';
import TextInput from 'components/Inputs/TextInput/TextInput';
import ErrorMsg from 'components/ErrorMsg';
import ReCaptcha from 'components/ReCaptcha/ReCaptcha';
import { mediaQueries } from 'style/config';
import FormValidator from 'utils/FormValidator';
import ErrorConstants from 'utils/ErrorConstants';
import utilityApi from 'services/api/utility';
import ErrorsUtils from 'utils/Errors';

const FIELD_LENGTHS = FormValidator.FIELD_LENGTHS;

class GiftCardBalanceCheckForm extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            giftCardNumber: '',
            pin: '',
            giftCardBalance: null,
            error: null
        };
    }

    validateForm = () => {
        ErrorsUtils.clearErrors();
        const fieldsForValidation = [this.giftCardNumberInput, this.giftCardPinInput];
        ErrorsUtils.collectClientFieldErrors(fieldsForValidation);

        return !ErrorsUtils.validate(fieldsForValidation);
    };

    onCaptchaTokenReady = token => {
        if (token) {
            this.checkBalance(token);
        }

        this.reCaptcha.reset();
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        if (this.reCaptcha) {
            this.reCaptcha.execute();
        } else {
            this.checkBalance();
        }
    };

    checkBalance = token => {
        if (!this.validateForm()) {
            this.setState({
                giftCardBalance: null,
                error: null
            });

            return;
        }

        const giftCardInfo = {
            gcNumber: this.state.giftCardNumber,
            gcPin: this.state.pin
        };

        if (token) {
            giftCardInfo.captchaToken = token;
        }

        utilityApi
            .getGiftCardBalance(giftCardInfo)
            .then(data => {
                this.setState({
                    error: null,
                    giftCardBalance: data.giftCardBalance
                });
            })
            .catch(error => {
                this.setState({
                    error: error.errorMessages[0],
                    giftCardBalance: null
                });
            });
    };

    render() {
        const isCaptchaEnabled = Sephora.configurationSettings.captchaGiftCardBalanceEnabled;
        const {
            localization: { currentBalance, cardNumber, pin, checkBalance }
        } = this.props;

        return (
            <>
                {this.state.giftCardBalance && (
                    <Text
                        is='p'
                        fontWeight='bold'
                        marginBottom={4}
                        paddingX={[4, 5]}
                        role='alert'
                        data-at={Sephora.debug.dataAt('balance_msg')}
                    >
                        {`${currentBalance} = ${this.state.giftCardBalance}`}
                    </Text>
                )}
                {this.state.error && (
                    <ErrorMsg
                        marginBottom={4}
                        paddingX={[4, 5]}
                        children={this.state.error}
                    />
                )}
                <Box
                    is='form'
                    lineHeight='tight'
                    noValidate={true}
                    paddingX={[4, 5]}
                    onSubmit={this.handleSubmit}
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}
                >
                    <TextInput
                        marginBottom={4}
                        autoOff={true}
                        name='giftCardNumber'
                        label={cardNumber}
                        required={true}
                        type='tel'
                        value={this.state.giftCardNumber}
                        onChange={this.handleChange}
                        maxLength={FIELD_LENGTHS.giftCardNumber}
                        ref={comp => (this.giftCardNumberInput = comp)}
                        validateError={giftCardNumber => {
                            if (FormValidator.isEmpty(giftCardNumber)) {
                                return ErrorConstants.ERROR_CODES.GIFT_CARD_NUMBER_REQUIRED;
                            }

                            return null;
                        }}
                        data-at={Sephora.debug.dataAt('gc_card_input')}
                        customStyle={styles}
                    />
                    <TextInput
                        marginBottom={4}
                        autoOff={true}
                        name='pin'
                        label={pin}
                        required={true}
                        type='tel'
                        value={this.state.pin}
                        onChange={this.handleChange}
                        maxLength={FIELD_LENGTHS.giftCardPin}
                        ref={comp => (this.giftCardPinInput = comp)}
                        validateError={giftCardPin => {
                            if (FormValidator.isEmpty(giftCardPin)) {
                                return ErrorConstants.ERROR_CODES.GIFT_CARD_PIN_REQUIRED;
                            }

                            return null;
                        }}
                        data-at={Sephora.debug.dataAt('pin_input')}
                        customStyle={styles}
                    />
                    <Button
                        variant='primary'
                        block={true}
                        type='submit'
                        width={['100%', '274px']}
                        children={checkBalance}
                    />
                </Box>
                {isCaptchaEnabled && (
                    <ReCaptcha
                        ref={c => {
                            if (c !== null) {
                                this.reCaptcha = c;
                            }
                        }}
                        onChange={this.onCaptchaTokenReady}
                    />
                )}
            </>
        );
    }
}

const styles = {
    root: {
        width: '100%',
        [mediaQueries.sm]: {
            width: '274px'
        }
    }
};

export default wrapComponent(GiftCardBalanceCheckForm, 'GiftCardBalanceCheckForm');
