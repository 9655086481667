export default function getResource(label, vars = []) {
    const resources = {
        addNewCard: 'Add a new card',
        cancel: 'Cancel',
        completeBooking: 'Complete Booking',
        creditCardRequiredInfo: 'A credit card is required to hold this reservation. You can redeem a gift card in-store at the time of payment.',
        placingOder: 'By placing this order, I acknowledge the Payment Policy below.',
        cvc: 'CVV/CVC',
        default: 'Default Card',
        deleteCreditCardDialogMessage: 'Are you sure you would like to permanently remove this card?',
        deleteCreditCardDialogOkButtonText: 'Remove',
        deleteCreditCardDialogTitle: 'Remove Credit Card',
        paymentGenericError: 'We could not process your credit card. Please review the card information and try again, or try another card.',
        edit: 'Edit',
        expiredCreditCardMsg: 'This card is expired. Please update it or use a different card.',
        expires: `Expires: ${vars[0]}/${vars[1]}`,
        minutes: 'Min',
        modalTitle: 'Reservation Details',
        paymentMethod: 'Payment Method',
        remove: 'Remove',
        saveAndContinue: 'Save & Continue',
        updateSecurityCode: 'Please enter your security code.',
        virtual: 'Virtual',
        endingIn: 'ending in',
        gotIt: 'Got It'
    };

    return resources[label];
}
