/* eslint-disable no-console */
import localeUtils from 'utils/LanguageLocale';
import urlUtils from 'utils/Url';
import { CONSTRUCTOR_PODS } from 'constants/constructorConstants';

const { addParam } = urlUtils;
const { AUTO_REPLENISH_CHOSEN_FOR_YOU, AUTO_REPLENISH_SEPHORA_COLLECTION } = CONSTRUCTOR_PODS;
const AR_PARAM_NAME = 'source';
const AR_PARAM_VALUE = 'auto-replenish';

const transformConstructorResponseForCarousel = (recommendations, sourceType = {}) => {
    var results = [];
    recommendations.forEach(recommendation => {
        const recommendationData = recommendation.data;
        const transformedRecommendation = {
            badgeAltText: recommendationData.currentSku.imageAltText,
            biExclusiveLevel: recommendationData.currentSku.biExclusivityLevel,
            brandName: recommendationData.brandName,
            starRatings: recommendationData.rating,
            size: recommendationData.size,
            productReviewCount: recommendationData.totalReviews,
            image: recommendationData.image_url,
            isAppExclusive: convertToBoolean(recommendationData.isAppExclusive),
            isBiOnly: convertToBoolean(recommendationData.currentSku.isBI),
            isFirstAccess: convertToBoolean(recommendationData.currentSku.isFirstAccess),
            isFree: convertToBoolean(recommendationData.currentSku.isFree),
            isLimitedEdition: convertToBoolean(recommendationData.currentSku.isLimitedEdition),
            isLimitedQuantity: convertToBoolean(recommendationData.currentSku.isLimitedQuantity),
            isLimitedTimeOffer: convertToBoolean(recommendationData.currentSku.isLimitedTimeOffer),
            isNew: convertToBoolean(recommendationData.currentSku.isNew),
            isOnlineOnly: convertToBoolean(recommendationData.currentSku.isOnlineOnly),
            isOutOfStock: convertToBoolean(recommendationData.currentSku.isOutOfStock),
            isSephoraExclusive: convertToBoolean(recommendationData.currentSku.isSephoraExclusive),
            listPrice: convertPrice(recommendationData.listPrice),
            salePrice: convertPrice(recommendationData.salePrice),
            valuePrice: convertPrice(recommendationData.valuePrice),
            wholeSalePrice: convertPrice(recommendationData.wholesalePrice),
            productId: recommendationData.id,
            productName: recommendation.value,
            skuId: recommendationData.currentSku.skuId,
            skuImages: {
                imageUrl: recommendationData.image_url
            },
            smallImage: recommendationData.currentSku.skuImages.image50,
            targetUrl: recommendationData.url,
            variationType: recommendationData.currentSku.variationValue,
            variationTypeDisplayName: recommendationData.currentSku.variationValue,
            variationValue: recommendationData.currentSku.variationValue,
            highlights: recommendationData.facets.filter(facet => facet.name === 'Ingredient Preferences')[0]?.values || []
        };

        if (sourceType.id === AUTO_REPLENISH_CHOSEN_FOR_YOU || sourceType.id === AUTO_REPLENISH_SEPHORA_COLLECTION) {
            transformedRecommendation.targetUrl = addParam(recommendationData.url, AR_PARAM_NAME, AR_PARAM_VALUE);
        }

        results.push(transformedRecommendation);
    });

    return results;
};

const convertPrice = price => {
    if (price) {
        const convertedPrice = `${price.replaceAll('.', ',').replaceAll('$', '').replaceAll('-', '$ -')} $`;

        return localeUtils.isFrench() ? convertedPrice : price;
    }

    return null;
};

const getConstructorRecommendations = (podId, params) => {
    if (global.constructorio) {
        return global.constructorio.recommendations.getRecommendations(podId, params);
    } else {
        return Promise.reject(new Error('Constructor io not initialized'));
    }
};

const getConstructorCollections = (collectionId, params) => {
    if (global.constructorio) {
        return global.constructorio.browse.getBrowseResults('collection_id', collectionId, params);
    } else {
        return Promise.reject(new Error('Constructor io not initialized'));
    }
};

const convertToBoolean = stringValue => {
    if (typeof stringValue === 'boolean') {
        return stringValue;
    }

    const booleanValue = typeof stringValue === 'string' && stringValue?.toLowerCase() === 'true';

    return booleanValue;
};

export default {
    transformConstructorResponseForCarousel,
    getConstructorRecommendations,
    convertToBoolean,
    getConstructorCollections
};
