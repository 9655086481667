export default function getResource(label, vars = []) {
    const resources = {
        waiverTitle: 'Renonciation de droits et acceptation des risques',
        waiverGeneralMessage: `Veuillez consulter le document ${vars[0]} et vérifier que les options suivantes s’appliquent.`,
        acceptWaiver: `En cochant la case, je reconnais être la personne qui reçoit les services de Sephora.  Je reconnais avoir lu attentivement et compris dans leur intégralité toutes les dispositions du document ${vars[0]}, j’accepte volontairement et sciemment les risques et je renonce à mes droits tels que décrits.`,
        underAgeWaiver: 'Je prends rendez-vous pour une personne mineure.',
        parentalWaiver: `En cochant cette case, je reconnais être le parent ou le tuteur légal d’une personne mineure qui reçoit les services de Sephora.  Je reconnais avoir lu attentivement et compris dans leur intégralité toutes les dispositions du ${vars[0]}, j’accepte volontairement et sciemment les risques et je renonce à tout droit d’intenter une poursuite au nom de la personne mineure tels que décrit.`,
        chooseOne: 'Choisissez :'
    };

    return resources[label];
}
