/* eslint-disable max-len */

/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import store from 'Store';
import Actions from 'Actions';
import ExperienceDetailsActions from 'actions/ExperienceDetailsActions';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import decorators from 'utils/decorators';
import userLocation from 'utils/userLocation/UserLocation';
import { Text } from 'components/ui';
import Modal from 'components/Modal/Modal';
import BookWidget from 'components/OnlineReservation/ExperienceDetailPage/BookWidget/BookWidget';
import localeUtils from 'utils/LanguageLocale';
import ACTIVITY from 'components/OnlineReservation/activityConstants';

const { showBookWidgetModal } = Actions;

let unsubscribe;

class BookWidgetModal extends BaseClass {
    constructor(props) {
        super(props);
        const experienceDetail = this.props.experienceDetail || {};
        this.state = {
            activityPolicies: experienceDetail.activityPolicies,
            location: experienceDetail.location,
            activities: experienceDetail.activities,
            selectedDay: experienceDetail.selectedDay,
            storeList: experienceDetail.storeList,
            selectedTimeSlot: experienceDetail.selectedTimeSlot,
            timeSlotIndex: experienceDetail.timeSlotIndex,
            storeClickedIndex: experienceDetail.storeClickedIndex,
            activityClicked: experienceDetail.activityClicked
        };
    }

    componentDidMount() {
        const defaultDateTimes = ExperienceDetailsUtils.getDefaultDateTimes(null, this.props.experienceDetail.activityType);
        unsubscribe = store.watchAction(ExperienceDetailsActions.TYPES.UPDATE_EXPERIENCE_FOR_MODAL, actionData => {
            const currentLocation = this.state.location;
            const locationToSearch = actionData.location || currentLocation;
            const locationString = locationToSearch ? locationToSearch.display : null;
            const startDate = actionData.selectedDay || defaultDateTimes.start;
            const endDate = actionData.selectedDay || defaultDateTimes.end;

            decorators
                .withInterstice(ExperienceDetailsUtils.getExperienceByStoresAndLocation)(
                    this.props.experienceDetail,
                    locationToSearch,
                    startDate,
                    endDate
                )
                .then(experienceData => {
                    const resetTimeSlotState = {
                        selectedTimeSlot: null,
                        timeSlotIndex: null,
                        storeClickedIndex: null,
                        activityClicked: null
                    };

                    if (experienceData.storeAvailabilities.length) {
                        this.setState({
                            activities: experienceData.storeAvailabilities,
                            location: locationToSearch,
                            selectedDay: startDate,
                            storeList: experienceData.storeList,
                            ...resetTimeSlotState
                        });

                        if (actionData.location) {
                            userLocation.setNewLocation(actionData.location);
                        }
                    } else {
                        if (experienceData.storeList.length) {
                            //set state to display that there are no activities to show
                            this.setState({
                                noActivities: true,
                                ...resetTimeSlotState
                            });
                        } else {
                            //set state to display that there are no stores to show
                            this.setState({
                                noStoreLocation: locationString,
                                ...resetTimeSlotState
                            });
                        }
                    }
                })
                .catch(() => {
                    //set state to display that there are no stores
                    //within given area.
                    this.setState({ noStoreLocation: locationString });
                });
        });
    }

    componentWillUnmount() {
        unsubscribe();
    }

    requestClose = () => {
        store.dispatch(showBookWidgetModal({ isOpen: false }));
    };

    resetErrors = () => {
        this.setState({
            noStoreLocation: null,
            noActivities: null
        });
    };

    getMoreStoreAvailabilities = (firstIndex, lastIndex) => {
        const startDate = this.state.selectedDay;
        const endDate = this.state.selectedDay;
        const storeListSnippet = this.state.storeList.slice(firstIndex, lastIndex);

        decorators
            .withInterstice(ExperienceDetailsUtils.getExperienceByStores)(storeListSnippet, this.props.experienceDetail, startDate, endDate)
            .then(experienceData => {
                if (experienceData.storeAvailabilities.length) {
                    this.setState(prevState => {
                        /* We need to match the store order because these activities are created
                    by concatenating the new activities and the final order might not be correct */
                        return {
                            activities: ExperienceDetailsUtils.matchStoreOrder(
                                prevState.activities.concat(experienceData.storeAvailabilities),
                                prevState.storeList
                            )
                        };
                    });
                }
            });
    };

    render() {
        const { isOpen, experienceDetail = {} } = this.props;

        const {
            location,
            activities,
            selectedDay,
            storeList,
            selectedTimeSlot,
            timeSlotIndex,
            storeClickedIndex,
            activityClicked,
            noActivities,
            noStoreLocation,
            activityPolicies
        } = this.state;

        const getText = localeUtils.getLocaleResourceFile('components/GlobalModals/BookWidgetModal/locales', 'BookWidgetModal');

        return (
            <Modal
                isOpen={isOpen}
                onDismiss={this.requestClose}
                width={4}
            >
                <Modal.Header>
                    <Modal.Title>{experienceDetail.activityTitle}</Modal.Title>
                    {experienceDetail.duration && (
                        <Text
                            is='p'
                            lineHeight='tight'
                            fontWeight='bold'
                            fontSize='xs'
                            color='gray'
                            css={{ textTransform: 'uppercase' }}
                            children={`${experienceDetail.duration} ${getText('min')}`}
                        />
                    )}
                    {experienceDetail.activityType === ACTIVITY.TYPE.SERVICES && experienceDetail.priceInfo && experienceDetail.priceInfo.listPrice && (
                        <Text
                            is='p'
                            fontSize='base'
                            fontWeight='bold'
                            children={experienceDetail.priceInfo.listPrice}
                        />
                    )}
                </Modal.Header>
                <Modal.Body>
                    <BookWidget
                        selectedDay={selectedDay}
                        startDateParam={experienceDetail.startDateParam}
                        selectedTimeSlot={selectedTimeSlot}
                        location={location}
                        activities={activities}
                        questions={experienceDetail.questions}
                        noStoreLocation={noStoreLocation}
                        noActivities={noActivities}
                        storeClickedIndex={storeClickedIndex}
                        timeSlotIndex={timeSlotIndex}
                        activityClicked={activityClicked}
                        resetErrors={this.resetErrors}
                        isModal={true}
                        storeList={storeList}
                        getMoreStoreAvailabilities={this.getMoreStoreAvailabilities}
                        activityPolicies={activityPolicies}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export default wrapComponent(BookWidgetModal, 'BookWidgetModal');
