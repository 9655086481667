export default function getResource(label, vars = []) {
    const resources = {
        bannerButton: 'Inscrivez-vous aux alertes par textos',
        bannerTitle: 'Soyez le premier à connaître les nouveautés.',
        bannerParagraph: 'Ne manquez plus les nouveautés, événements et offres exclusives.',
        bannerRates: '*Des frais de messagerie texte et de données peuvent s’appliquer.'
    };

    return resources[label];
}
