export default function getResource(label, vars = []) {
    const resources = {
        qAndATitle: 'Questions & Answers',
        askAQuestionLink: 'Ask a question',
        askAQuestionTitle: 'Ask a Question',
        mostRecentQuestion: 'Most Recent Question',
        sort: 'Sort',
        noReviewsAvailable: 'No Reviews Available',
        showMoreQandA: 'Show more Questions & Answers',
        postedBy: `by ${vars[0]}`,
        asked: 'Asked',
        moreAnswers: `${vars[0]} more answers`,
        showLess: 'Show less',
        answerThisQuestion: 'Answer this question',
        readMore: 'Read more',
        readLess: 'Read less',
        answered: 'Answered',
        verifiedPurchase: 'Verified Purchase',
        sephoraEmployee: 'Sephora Employee',
        receivedFreeProduct: 'Received free product',
        helpful: 'Helpful',
        fromTo: `Viewing ${vars[0]}-${vars[1]} of ${vars[2]} Questions`,
        enterYourQuestion: 'Enter your question',
        exampleQuestion: 'Example: Is this foundation good for oily skin',
        required: 'Required',
        questionAnswerGuidelines: 'Question & Answer Guidelines',
        note: 'Please note that your question may take up to 72 hours to appear.',
        submit: 'Submit',
        cancel: 'Cancel',
        responsiblity: 'Sephora is not responsible for answers provided by Community Answers, Customers, or other third parties. Accuracy not guaranteed.',
        termsAndConditions: 'Terms of Use',
        errorMessageText: `Min ${vars[0]} characters required`,
        enterQuestion: 'Please enter your question',
        enterAnswer: 'Please enter your answer',
        maxCharacter: 'Max characters reached',
        characterLeft: `${vars[0]} character left`,
        mostAnswers: 'Most Answers',
        mostRecentQuestions: 'Most Recent Questions',
        mostRecentAnswers: 'Most Recent Answers',
        oldestQuestions: 'Oldest Questions',
        answerAQuestionTitle: 'Answer a Question',
        enterYourAnswer: 'Enter your answer',
        iWorkAtSephora: 'I work at Sephora',
        iReceivedFreeSample: 'I received a free sample',
        answerNote: 'Please note that your answer may take up to 72 hours to be posted.',
        question: 'Question',
        answer: 'Answer',
        thankYouQuestion: 'Thank you for submitting a question.',
        thankYouAnswer: 'Thank you for answering this question.',
        postedHour: `Your ${vars[0]} will be posted within 72 hours.`,
        addToLoves: 'Add to Loves',
        back: 'Back to Questions & Answers',
        submissionNotReceived: 'Submission Not Received',
        submissionWrong: 'Something went wrong while trying to process your submission. Please try again later.',
        size: 'Size',
        done: 'Done',
        searchKeyword: 'Search keyword',
        searchKeywordResults: `${vars[0]} Questions & Answers Containing “${vars[1]}”`,
        searchKeywordResult: `${vars[0]} Question or Answer Containing “${vars[1]}”`,
        sorryNoSearchResults: `Sorry, no questions or answers contain “${vars[0]}”`,
        emailNotification: 'Get an email notification when someone answers your question. You will not be notified of responses if you do not check this option.',
        emailAddress: 'Email Address',
        notification: 'Sephora will only use this email to notify you of responses to your question.',
        invalidEmailMessage: 'Please enter a valid email',
        emptyEmailMessage: 'Please enter your email address',
        stopEmailNotification: 'Stop Email Notifications for This Question?',
        areYouSure: 'Are you sure you want to stop receiving an email notification when there’s a new answer to this question?',
        stopSending: 'Stop Sending Email Notification',
        continueShopping: 'Continue Shopping',
        unsubscribeMessage: 'You Have Successfully Unsubscribed.',
        noLonger: 'You will no longer recieve an email notification when there’s a new answer to this quesion.',
        modalGuidelinesModeration: 'All questions and answers are read and moderated to abide by the following Q&A Guidelines:',
        considerBeforeAsking: 'When asking a question, please consider the following guidelines:',
        focusOnProduct: 'Focus on the product and your individual question about it.',
        termsOfUse: 'Terms of Use',
        relevantDetails: 'Provide relevant details that might help others answer your question such as, skin tone, eye color, hair texture or desired look. For example, “I have short eyelashes. Will this mascara be lengthening enough for me?”. All submitted questions are read by our moderators and are subject to the terms set forth in our',
        noMedicalAdvice: 'Do not ask for medical advice.',
        language1: 'To ensure compliance with these guidelines and our',
        language2: ', questions must be submitted in English or French.',
        answersGuidelines: 'You can also share your beauty smarts and submit answers to others’ questions. When answering a question, please consider the following guidelines:',
        answersFocusOnExperience: 'Focus on the product and your individual experience using it.',
        answersDetails: 'Provide details that are relevant to the question being asked.',
        answersModerators: 'All submitted answers are read by our moderators and are subject to the terms set forth in our ',
        answersNoMedicalAdvice: 'Do not provide medical advice.',
        answersLanguage1: 'To ensure compliance with these guidelines and our',
        answersLanguage2: ', answers must be submitted in English or French.',
        rightsReservations: 'We reserve the right not to post any question or answer or to withdraw any posted question or answer for any reason. For example, questions and answers may be subject to removal if written about a product no longer available for sale at Sephora or a previous version of the product (e.g., reformulated). Your question or answer will not be posted if it violates the guidelines provided, or if it contains any of the following types of content: feedback about product selection, pricing, ordering, delivery, or other customer service issues. Do not submit this feedback through a question or answer. Please contact us directly by visiting ',
        contactUsUrl: 'https://www.sephora.com/beauty/contact-us',
        nowAskAndAnswer: 'Now, ask and answer away!',
        yesIAgree: 'Yes, I agree to the ',
        noteLabel: 'Note',
        termsError: 'You must agree to our Terms of Use to continue.',
        searchAriaLabel: 'Search Questions and Answers'
    };
    return resources[label];
}
