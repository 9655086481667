export default function getResource(label, vars = []) {
    const resources = {
        askToCancelCharge: `Êtes-vous sûr de vouloir annuler cette réservation? Des frais d’annulation tardive de ${vars[0]} vous seront facturés.`,
        askToCancel: 'Êtes-vous sûr de vouloir annuler cette réservation?',
        yes: 'Oui',
        no: 'Non'
    };

    return resources[label];
}
