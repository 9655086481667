const ACTION_TYPES = {
    SET_FULL_RANGE_PRICES: 'set-full-range-prices',
    SET_CATALOG_DATA: 'set-catalog-data'
};

const initialState = {
    fullRangePrices: null,
    catalogData: null
};

const reducer = function (state = initialState) {
    return state;
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
