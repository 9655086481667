import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { colors, space } from 'style/config';
import { Container } from 'components/ui';
import TopNavItem from 'components/Header/TopNavItem/TopNavItem';
import keyConsts from 'utils/KeyConstants';
import SkeletonBanner from 'components/Banner/SkeletonBanner/SkeletonBanner';

const SOURCE = 'meganav';

class TopNav extends BaseClass {
    state = {
        openIndex: null,
        hasDelay: true
    };

    reset = () => {
        this.setState({
            openIndex: null,
            hasDelay: true
        });
    };

    render() {
        const { openIndex } = this.state;
        const { items, p13n, user } = this.props;

        return (
            <div css={styles.root}>
                {(!p13n.isInitialized && !p13n.headData) || !user.isInitialized ? (
                    <SkeletonBanner height={44} />
                ) : (
                    <>
                        <div
                            css={styles.backdrop}
                            onTouchStart={this.reset}
                            onMouseEnter={this.reset}
                            onMouseLeave={this.reset}
                            style={
                                openIndex
                                    ? {
                                        opacity: 0.25,
                                        height: '100vh'
                                    }
                                    : null
                            }
                        />
                        <Container>
                            <nav
                                onMouseLeave={this.reset}
                                onKeyDown={e => {
                                    if (e.key === keyConsts.ESC) {
                                        this.reset();
                                    }
                                }}
                                css={styles.inner}
                                data-at={Sephora.debug.dataAt('cat_nav')}
                            >
                                {items?.map((item, i) => {
                                    const index = i.toString();

                                    return (
                                        <TopNavItem
                                            key={index}
                                            source={SOURCE}
                                            onTrigger={(e, isOpen) => {
                                                if (isOpen) {
                                                    this.setState({
                                                        openIndex: index,
                                                        hasDelay: false
                                                    });
                                                }
                                            }}
                                            reset={this.reset}
                                            isOpen={index === openIndex}
                                            hasDelay={this.state.hasDelay}
                                            index={index}
                                            item={item}
                                        />
                                    );
                                })}
                            </nav>
                        </Container>
                    </>
                )}
            </div>
        );
    }
}

const styles = {
    root: {
        position: 'relative',
        background: colors.black,
        color: colors.white
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: -space.container,
        marginRight: -space.container,
        overflow: 'hidden',
        minHeight: 44
    },
    backdrop: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        height: 0,
        opacity: 0,
        backgroundColor: colors.black,
        transition: 'opacity .2s'
    }
};

export default wrapComponent(TopNav, 'TopNav');
