export default function getResource(label, vars = []) {
    const resources = {
        uncheckJoinErrorMessage: `You must accept Beauty Insider Terms and Conditions in order to collect ${vars[0]} points from today's purchases.`,
        popoverContent: `Join Beauty Insider to save ${vars[0]} points from your purchase. Redeem for one-of-a-kind experiences, personalized services, and coveted sample rewards.`,
        linkActionText: 'See current rewards',
        dontForgetText: `Don’t forget to save your ${vars[0]} points!`,
        testDontLosePoints: `Don’t lose your ${vars[0]} points`,
        testSavePointsFreeShip: 'Save them to enjoy great benefits by joining Beauty Insider, our free loyalty program. You’ll also get *FREE standard shipping!*',
        free: 'FREE',
        testNewBiPropBDayGift: 'Birthday Gift',
        freeGift: 'FREE Birthday Gift',
        testNewBiPropPoints: 'Seasonal Savings Events',
        testNewBiPropShipping: 'FREE Shipping',
        testJoinNowButton: 'Join Now',
        testLegalClickingText: 'By clicking “Join Now” you acknowledge that you are a U.S. or Canada resident and (1) have read Sephora’s',
        testLegalPrivacyPolicyLink: 'Privacy Policy ',
        testLegalNoticeOfFinancialIncentiveLink: ' Notice of Financial Incentive',
        testLegalAnd: 'and',
        testLegalAgreeTo: ', (2), agree to',
        testLegalTermsOfUseLink: 'TERMS OF USE',
        testLegalBiTermsLink: 'BEAUTY INSIDER TERMS',
        testLegalAutomaticallyText: ', and to automatically receive Beauty Insider offers and notifications via email.',
        testEnterPasswordToCreateText: `Create a password for ${vars[0]}`,
        enterPasswordToSaveText: 'Enter your Sephora password',
        enterPasswordToSaveDetailsText: `for ${vars[0]} to save your ${vars[1]} points from this purchase.`,
        enterPasswordToCreateText: 'Enter a password to create your account.',
        passwordInputLabel: `Password (${vars[0]} to ${vars[1]} characters)`,
        forgotText: 'Forgot?',
        savePointsButton: 'Save Points',
        createAccountButton: 'Create Account',
        thankYouText: 'Thank you for signing in!',
        browseBazaarText: 'This order will now appear in your order history. Browse the Rewards Bazaar now!',
        viewBazaarText: 'View Rewards Bazaar',
        phoneNumber: 'Phone Number',
        useYourPhoneLabel: 'Use your phone number for faster account lookup in store.',
        marketingCheckbox: 'Sign me up for Sephora marketing text alerts.',
        disclosure: 'Sephora Text Alerts Disclosure:',
        byEntering: ' By entering your phone number, checking the',
        signMeUp: ' Sign me up for Sephora marketing text alerts checkbox,',
        clicking: ' clicking the “\Join Now\” button and confirming sign-up, you consent and agree to receive recurring autodialed marketing texts, including abandoned cart reminders, and confirm that you have read and agree to the',
        textTerm: ' TEXT TERMS.',
        message: ' Message frequency varies. Consent is not a condition of purchase. Message & data rates may apply. See our ',
        privacy: 'PRIVACY POLICY',
        textStop: ' Text STOP to cancel at any time. HELP for help.',
        textStopAddress: ' Sephora: 600 de Maisonneuve Boulevard West, Suite 2400, Montréal, Québec, H3A 3J2, Canada. 1-877-737-4672.',
        byClicking: 'By clicking “\Join Now\” you acknowledge that you are a U.S. or Canada resident and (1) have read Sephora’s',
        agreeTo: ' agree to ',
        subscribeLabel: 'Subscribe to Sephora emails',
        and: ' and ',
        noticeFinancialIncentive: 'NOTICE OF FINANCIAL INCENTIVE'
    };

    return resources[label];
}
