export default function getResource(label, vars = []) {
    const resources = {
        basket: 'basket',
        checkout: 'Checkout',
        item: 'item',
        total: `You have ${vars[0]} in your ${vars[1]}.`
    };

    return resources[label];
}
