const ACTION_TYPES = {};

const initialState = { results: {} };

const reducer = function (state = initialState) {
    return state;
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
