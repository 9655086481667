import * as beautyPreferenceConstants from 'constants/beautyPreferences';
import Empty from 'constants/empty';

const {
    noPreferenceOption,
    notSureOption,
    profileCategories,
    CATEGORY_NO_PREFERENCE_BI_VALUE,
    PREFERENCE_TYPES,
    TOTAL_PREFERENCES,
    PROGRESS_BAR_EXCLUDED_ATTRIBUTES,
    CAROUSEL_ONE_TRAITS,
    PROGRESS_BAR_UPDATE_MESSAGE_ATTRIBUTES,
    SHOPPING_LINKS_BY_CATEGORY,
    CAROUSEL_REQUIRED_TRAITS,
    TRAIT_TYPE: { SKIN_CONCERNS, HAIR_CONCERNS }
} = beautyPreferenceConstants;

function isAllAnswered(beautyPreferences = {}, excluding = []) {
    if (excluding instanceof Array && excluding.length) {
        return Object.keys(beautyPreferences)
            .filter(x => !excluding.includes(x))
            .every(e => beautyPreferences[e].length);
    }

    return Object.values(beautyPreferences).every(e => e.length);
}

function isAtLeastOneAnswered(beautyPreferences = {}) {
    return Object.values(beautyPreferences).some(e => e.length);
}

function initialUnansweredPref(beautyPreferences = {}) {
    const unansweredPrefs = Object.keys(beautyPreferences).filter(key => beautyPreferences[key].length < 1);
    const profileCatArray = profileCategories.map(a => a.type);

    return unansweredPrefs.sort((a, b) => profileCatArray.indexOf(a) - profileCatArray.indexOf(b))[0];
}

function allUnansweredPrefs(beautyPreferences = {}) {
    const unansweredPrefs = Object.keys(beautyPreferences).filter(key => beautyPreferences[key].length < 1);
    const profileCatsArray = profileCategories.map(a => a.type);

    return unansweredPrefs.sort((a, b) => profileCatsArray.indexOf(a) - profileCatsArray.indexOf(b));
}

function calculateProfileCompletionStatus(beautyPreferences = {}) {
    const currentSelectionStatus = Object.keys(beautyPreferences).filter(
        x => beautyPreferences[x]?.length > 0 && PROGRESS_BAR_EXCLUDED_ATTRIBUTES.indexOf(x) === -1
    ).length;

    return Math.ceil((currentSelectionStatus / TOTAL_PREFERENCES) * 100);
}

function getNextUnansweredQuestion(currIndex, categories, isAllAnsweredPrefs, allUnansweredPrefsArr) {
    if (isAllAnsweredPrefs || allUnansweredPrefsArr.length === 1) {
        return allUnansweredPrefsArr[0];
    }

    const reorderedProfileCat = categories.slice(currIndex + 1).concat(categories.slice(0, currIndex));
    const nextUnansweredQuesValue = reorderedProfileCat.find(profileCategory => allUnansweredPrefsArr.indexOf(profileCategory.type) > -1);

    return nextUnansweredQuesValue?.type;
}

function isLastUnansweredIndex(categoryType, isAllAnsweredPrefs, allUnansweredPrefsArr) {
    return isAllAnsweredPrefs ? false : allUnansweredPrefsArr.length === 1 && allUnansweredPrefsArr[0] === categoryType;
}

function getCategory(type) {
    return profileCategories.filter(category => category.type === type)[0];
}

function getCategoryName(type) {
    const category = getCategory(type);

    return category.name || Empty.string;
}

function compareArrayData(val1, val2) {
    let arr1 = [];
    let arr2 = [];

    if (val1 instanceof Array) {
        arr1 = val1;
    } else {
        arr1.push(val1);
    }

    if (val2 instanceof Array) {
        arr2 = val2;
    } else {
        arr2.push(val2);
    }

    if (arr1.length === 0 && arr2.length === 0) {
        return true;
    } else {
        return arr1.sort().toString() === arr2.sort().toString();
    }
}

function getColorIQWizardNextUnansweredQuestion(beautyPreferences) {
    const { COLOR_IQ } = PREFERENCE_TYPES;
    const colorIQindex = profileCategories.findIndex(({ type }) => type === COLOR_IQ);
    const allAnswered = isAllAnswered(beautyPreferences);
    const allUnanswered = allUnansweredPrefs(beautyPreferences);
    const isLastCat = isLastUnansweredIndex(COLOR_IQ, allAnswered, allUnanswered);
    const nextCat = getNextUnansweredQuestion(colorIQindex, profileCategories, allAnswered, allUnanswered);

    return isLastCat ? '' : nextCat;
}

function mapNotSureNoPreferenceWithBIValues(beautyPreferences) {
    const beautyPreferencesWithBIValues = { ...beautyPreferences };
    const isNoPreference = value => value === notSureOption || value === noPreferenceOption;

    for (const key in beautyPreferencesWithBIValues) {
        if (key in beautyPreferencesWithBIValues && key !== PREFERENCE_TYPES.COLOR_IQ) {
            const categoryValue = beautyPreferencesWithBIValues[key];

            if (Array.isArray(categoryValue) && categoryValue.length === 1 && isNoPreference(categoryValue[0])) {
                beautyPreferencesWithBIValues[key] = CATEGORY_NO_PREFERENCE_BI_VALUE[key] ? [CATEGORY_NO_PREFERENCE_BI_VALUE[key]] : categoryValue;
            } else if (isNoPreference(categoryValue)) {
                beautyPreferencesWithBIValues[key] = CATEGORY_NO_PREFERENCE_BI_VALUE[key] || categoryValue;
            }
        }
    }

    return beautyPreferencesWithBIValues;
}

function mapNotSureNoPreferenceToStateValues(biBeautyPreferences) {
    const beautyPreferencesWithStateValues = { ...biBeautyPreferences };
    const isNoPreference = value => value.includes(noPreferenceOption) || value.includes(notSureOption);
    const getNoPreferenceValue = value => {
        return value.includes(noPreferenceOption) ? noPreferenceOption : value.includes(notSureOption) ? notSureOption : value;
    };

    for (const key in beautyPreferencesWithStateValues) {
        if (key in beautyPreferencesWithStateValues && key !== PREFERENCE_TYPES.COLOR_IQ) {
            const categoryValue = beautyPreferencesWithStateValues[key];

            if (Array.isArray(categoryValue) && categoryValue.length === 1 && isNoPreference(categoryValue[0])) {
                beautyPreferencesWithStateValues[key] = [getNoPreferenceValue(categoryValue[0])];
            } else if (isNoPreference(categoryValue)) {
                beautyPreferencesWithStateValues[key] = getNoPreferenceValue(categoryValue);
            }
        }
    }

    return beautyPreferencesWithStateValues;
}

function mapBeautyPreferencesToConstructorValues(beautyPreferences) {
    const prefilterExpression = [];
    let isConcernsFilterCreated = false;

    CAROUSEL_REQUIRED_TRAITS.map(pref => {
        const beautyPreferenceFilter = [];

        if (
            beautyPreferences[pref]?.length === 0 ||
            [noPreferenceOption, notSureOption].includes(beautyPreferences[pref]) ||
            (Array.isArray(beautyPreferences[pref]) && [noPreferenceOption, notSureOption].includes(beautyPreferences[pref][0])) ||
            (isConcernsFilterCreated && [SKIN_CONCERNS, HAIR_CONCERNS].includes(pref))
        ) {
            return false;
        }

        const beautyPrefUpdatedValues = Array.isArray(beautyPreferences[pref])
            ? beautyPreferences[pref].map(beautyPref => {
                return SHOPPING_LINKS_BY_CATEGORY[pref].subcatFilterRef[beautyPref];
            })
            : [SHOPPING_LINKS_BY_CATEGORY[pref].subcatFilterRef[beautyPreferences[pref]]];

        if (!isConcernsFilterCreated && [SKIN_CONCERNS, HAIR_CONCERNS].includes(pref)) {
            isConcernsFilterCreated = true;
            const concernsFilterRemaining = pref === SKIN_CONCERNS ? HAIR_CONCERNS : SKIN_CONCERNS;

            if (![noPreferenceOption, notSureOption].includes(beautyPreferences[concernsFilterRemaining][0])) {
                const beautyPrefUpdatedValuesRemaining = beautyPreferences[concernsFilterRemaining].map(beautyPref => {
                    return SHOPPING_LINKS_BY_CATEGORY[concernsFilterRemaining].subcatFilterRef[beautyPref];
                });
                beautyPrefUpdatedValues.push(...beautyPrefUpdatedValuesRemaining);
            }
        }

        beautyPrefUpdatedValues.forEach(updatedValue => {
            beautyPreferenceFilter.push({
                name: SHOPPING_LINKS_BY_CATEGORY[pref].filterRef,
                value: updatedValue
            });
        });
        beautyPreferenceFilter.push({
            not: {
                name: SHOPPING_LINKS_BY_CATEGORY[pref].filterRef,
                value: '*'
            }
        });

        prefilterExpression.push({ or: beautyPreferenceFilter });

        return beautyPreferenceFilter;
    });
    const mappedData = prefilterExpression.length > 0 ? { and: prefilterExpression } : null;

    return mappedData;
}

function mapFetchedBrandsList(brandsList = {}) {
    const flatBrandsToSingleArray = Object.values(brandsList)
        .map(({ brands }) => brands)
        .flat();

    const mappedBrandsIdsAndNames = flatBrandsToSingleArray.reduce(
        (map, brand) => {
            if (brand.brandId) {
                map.brandIds.push(brand.brandId);
                map.brandNames[brand.brandId] = brand.shortName;
            }

            return map;
        },
        { brandIds: [], brandNames: {} }
    );

    return mappedBrandsIdsAndNames;
}

function getValidColorIQEntries(beautyPreferences = {}) {
    const colorIQ = beautyPreferences[PREFERENCE_TYPES.COLOR_IQ] || [];
    const colorIQWithLabValue = colorIQ.filter(tone => !!tone.labValue);

    const colorIQWithCorrectLAB = colorIQWithLabValue.reduce((acc, entry) => {
        // The LAB API expects 3 values ':' separated (l:a:b), more or less is considered buggy/invalid value
        // Sometimes the labValue comes ',' separated so we replace it with ':'
        const labValueArr = entry.labValue.replaceAll(',', ':').split(':');

        // Only push valid lab entries
        if (labValueArr.length === 3) {
            acc.push(entry);
        }

        return acc;
    }, []);

    return colorIQWithCorrectLAB;
}

function normalizeCssHexCode(hexCode = '') {
    return `#${hexCode.replaceAll('#', '')}`;
}

function mapBIFavoriteBrandsToState(beautyPreferences = {}) {
    // favoriteBrands response API conditions:
    // non existing favoriteBrands object // No selections yet
    // favoriteBrands: [{"brandID": "1234", ...}, {"brandID": "1234", ...}] // Selected
    // favoriteBrands: [] // No Preference -> In this case we map it to the CATEGORY_NO_PREFERENCE_BI_VALUE
    const favoriteBrandsFromFullProfile = beautyPreferences[PREFERENCE_TYPES.FAVORITE_BRANDS];

    const favoriteBrands =
        favoriteBrandsFromFullProfile &&
        (favoriteBrandsFromFullProfile.length
            ? beautyPreferences[PREFERENCE_TYPES.FAVORITE_BRANDS]
                .filter(brand => brand !== null && brand !== undefined)
                .map(brand => (brand.brandId ? brand.brandId : brand))
            : [CATEGORY_NO_PREFERENCE_BI_VALUE[PREFERENCE_TYPES.FAVORITE_BRANDS]]);

    return favoriteBrandsFromFullProfile ? { ...beautyPreferences, favoriteBrands } : beautyPreferences;
}

function shouldUpdateProgressBarMessage(beautyPreferences = {}) {
    return (
        Object.keys(beautyPreferences).filter(x => beautyPreferences[x].length && PROGRESS_BAR_UPDATE_MESSAGE_ATTRIBUTES.includes(x)).length >=
        CAROUSEL_ONE_TRAITS
    );
}

function getUserFavoriteBrandIDs(beautyPreference = {}) {
    const userFavoriteBrandIDs = beautyPreference[PREFERENCE_TYPES.FAVORITE_BRANDS] || [];

    return userFavoriteBrandIDs
        .filter(brand => brand !== null && brand !== undefined && brand !== noPreferenceOption)
        .map(brand => (brand.brandId ? brand.brandId : brand));
}

function getBrandsByIdMap(groupedBrands = {}) {
    /**
     * will return a map of all brand IDs as keys with it's value as the brand object, e.g.:
     * {
     *    12345: { brandId: 12345, shortName: 'The Brand', ... }
     *    23456: { brandId: 23456, shortName: 'Other Brand', ... }
     * }
     */
    return Object.values(groupedBrands)
        .map(({ brands }) => brands)
        .flat()
        .reduce((brandsList, brand) => {
            return {
                ...brandsList,
                ...(brand.brandId && { [brand.brandId]: brand })
            };
        }, {});
}

function filterOutUserFavBrandsSpokeIDs(userFavoriteBrandIDs = [], brandsByIdMap = {}) {
    const newUserFavoriteBrandIDs = userFavoriteBrandIDs.filter(brandId => brandsByIdMap[brandId]);

    return newUserFavoriteBrandIDs;
}

function filterOutUserFavBrandsBPpageIDs(beautyPreferences = {}, brandsByIdMap = {}) {
    const prevFavoriteBrands = beautyPreferences[PREFERENCE_TYPES.FAVORITE_BRANDS];
    const isNoPreference = prevFavoriteBrands.length === 1 && prevFavoriteBrands[0] === noPreferenceOption;

    if (isNoPreference) {
        return prevFavoriteBrands;
    }

    const favoriteBrands = prevFavoriteBrands.filter(brandId => brandsByIdMap[brandId]);

    return favoriteBrands;
}

export default {
    isAllAnswered,
    isAtLeastOneAnswered,
    allUnansweredPrefs,
    getNextUnansweredQuestion,
    isLastUnansweredIndex,
    calculateProfileCompletionStatus,
    getCategory,
    compareArrayData,
    getColorIQWizardNextUnansweredQuestion,
    initialUnansweredPref,
    mapNotSureNoPreferenceWithBIValues,
    mapNotSureNoPreferenceToStateValues,
    mapFetchedBrandsList,
    getValidColorIQEntries,
    normalizeCssHexCode,
    mapBIFavoriteBrandsToState,
    shouldUpdateProgressBarMessage,
    getUserFavoriteBrandIDs,
    getBrandsByIdMap,
    filterOutUserFavBrandsSpokeIDs,
    filterOutUserFavBrandsBPpageIDs,
    mapBeautyPreferencesToConstructorValues,
    getCategoryName
};
