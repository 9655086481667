const { connect } = require('react-redux');
const { createStructuredSelector } = require('reselect');
const { userSelector } = require('selectors/user/userSelector');
const { isAnonymousSelector } = require('viewModel/selectors/user/isAnonymousSelector');
const Actions = require('Actions').default;
const userActions = require('actions/UserActions').default;
const { wrapHOC } = require('utils/framework').default;
const { getTextFromResource, getLocaleResourceFile } = require('utils/LanguageLocale').default;

const getText = getLocaleResourceFile('components/Brand/BrandLaunch/locales', 'BrandLaunch');
const getErrorsText = getLocaleResourceFile('utils/locales', 'Errors');

const fields = createStructuredSelector({
    user: userSelector,
    isAnonymous: isAnonymousSelector,
    stepOne: getTextFromResource(getText, 'stepOne'),
    buttonSignIn: getTextFromResource(getText, 'buttonSignIn'),
    buttonSendAlerts: getTextFromResource(getText, 'buttonSendAlerts'),
    noAccount: getTextFromResource(getText, 'noAccount'),
    createAccount: getTextFromResource(getText, 'createAccount'),
    emailAddressLabel: getTextFromResource(getText, 'emailAddressLabel'),
    notYouMessage: getTextFromResource(getText, 'notYouMessage'),
    passwordLabel: getTextFromResource(getText, 'passwordLabel'),
    hidePasswordLinkAriaLabel: getTextFromResource(getText, 'hidePasswordLinkAriaLabel'),
    showPasswordLinkAriaLabel: getTextFromResource(getText, 'showPasswordLinkAriaLabel'),
    enterPasswordErrorMessage: getTextFromResource(getText, 'enterPasswordErrorMessage'),
    enterMobileErrorMessage: getTextFromResource(getText, 'enterMobileErrorMessage'),
    forgotPassword: getTextFromResource(getText, 'forgotPassword'),
    stepTwo: getTextFromResource(getText, 'stepTwo'),
    mobileLabel: getTextFromResource(getText, 'mobileLabel'),
    submissionError: getTextFromResource(getErrorsText, 'submissionError'),
    lead: getTextFromResource(getText, 'lead')
});

const functions = {
    showSignInModal: Actions.showSignInModal,
    showRegisterModal: Actions.showRegisterModal,
    showForgotPasswordModal: Actions.showForgotPasswordModal,
    submitSMSForm: userActions.submitSMSOptInForm,
    submitSignInForm: userActions.signIn
};

const withSMSOptInProps = wrapHOC(connect(fields, functions));

module.exports = {
    withSMSOptInProps,
    fields,
    functions
};
