import ufeApi from 'services/api/ufeApi';
import jsUtils from 'utils/javascript';
import urlUtils from 'utils/Url';
const reservationsApiBase = '/v1/olr/users/profiles';
const reservationsApiSuffix = '/reservations';

/* eslint-disable max-len */
// API documentation: https://stage-developer.sephora.com/specs/v1/docs/profile.html#get-reservations
/* eslint-enable max-len */

function getReservations(token, params) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    let url;

    if (params.profileId) {
        url = sdnDomain + reservationsApiBase + '/' + params.profileId + reservationsApiSuffix;
        delete params.profileId;
        url += urlUtils.buildQuery(jsUtils.buildMap(params));
    } else {
        url = sdnDomain + reservationsApiBase + urlUtils.buildQuery(jsUtils.buildMap(params));
    }

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            },
            mode: 'cors',
            credentials: 'omit'
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getReservations;
