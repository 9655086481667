const DEFAULT_ZIP_CODE = {
    US: '20147',
    CA: 'M5B 2H1'
};

const DEFAULT_STORE_ID = {
    US: '1876',
    CA: '0500'
};

export default {
    DEFAULT_STORE_ID,
    DEFAULT_ZIP_CODE
};
