export default function getResource(label, vars = []) {
    const resources = {
        reservationDetails: 'Détails de la réservation',
        pickupLocation: 'Lieu du ramassage',
        contactInformation: 'Coordonnées',
        storeDetails: 'Détails du magasin'
    };

    return resources[label];
}
