import { createSelector } from 'reselect';

import { userSelector } from 'selectors/user/userSelector';

import userUtils from 'utils/User';

const happeningUserDataSelector = createSelector(userSelector, user => {
    return {
        isSignedIn: user.isInitialized && !userUtils.isAnonymous(user),
        clientExternalId: user.login || user.userName || user.email,
        email: user.login || user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        smsEnabled: user.smsEnabled,
        profileId: user.profileId
    };
});

export { happeningUserDataSelector };
