export default function getResource(label, vars = []) {
    const resources = {
        near: 'Near',
        ok: 'Ok',
        chooseLocation: 'Choose a location',
        cancel: 'Cancel',
        shareLocationAvailability: 'Share your location to see availability',
        chooseLocationTime: 'Choose Location & Time',
        chooseArtist: 'Choose an Available Artist',
        chooseNewArtistTitle: 'Choose new available artist',
        chooseNewArtistText: '**Your artist is no longer available at that time.** Please select a new available artist',
        chooseLocationTimeArtist: 'Select Location & Time to see available artist',
        chooseTime: 'Choose a Time',
        notAbleToFindStore: 'We were not able to find a store near',
        tryDifferentLocation: 'Please try a different location.',
        changeLocation: 'Change Location',
        noAppointmentsAvailable1: 'Sorry, there are no appointments',
        noAppointmentsAvailable2: 'available right now.',
        tryDifferentExperience: 'Please try a different experience.',
        seeWhatsHappening: 'See what’s happening',
        bookReservation: 'Book a Reservation',
        signUpForThisClass: 'Sign Up for This Class',
        reschedule: 'Reschedule',
        continueToBooking: 'Continue to Booking',
        artistDisclaimer: 'Artist might change depending on availability that day.',
        giftCardLink: 'Want to gift a service? Buy a Sephora Gift Card',
        genericErrorTimeSlots: 'Oops! This service is in high demand and the time you selected is now taken, please select an alternative time to complete booking.'
    };

    return resources[label];
}
