import { forms } from 'style/config';
import { Fragment } from 'react';
import {
    Link, Grid, Button, Icon, Divider, Text
} from 'components/ui';
import CreditCard from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/CreditCard';
import CreditCardUtils from 'utils/CreditCard';
import ErrorConstants from 'utils/ErrorConstants';
import agentAwareUtils from 'utils/AgentAware';
import ErrorMsg from 'components/ErrorMsg';
import FormValidator from 'utils/FormValidator';
import PaymentLogo from 'components/Checkout/PaymentLogo/PaymentLogo';
import Radio from 'components/Inputs/Radio/Radio';
import React from 'react';
import TextInput from 'components/Inputs/TextInput/TextInput';
import { wrapComponent } from 'utils/framework';
import viewModes from 'components/GlobalModals/PaidReservationModal/PaymentSection/viewModes';

const { CREATE, DEFAULT, SELECT } = viewModes;

class CreditCardsList extends React.Component {
    cvvRef = React.createRef();

    showCVCInput = creditCard => {
        const { selectedCreditCard, isPaymentReady } = this.props;

        if (selectedCreditCard?.creditCardId !== creditCard.creditCardId) {
            return false;
        }

        if (CreditCardUtils.tokenMigrationFailed(creditCard)) {
            return false;
        }

        const showCVC = !CreditCardUtils.isCreditCardReady({ isPaymentReady, selectedCreditCard });

        return showCVC;
    };

    securityCodeValidation = securityCode => {
        const { selectedCreditCard } = this.props;

        if (!selectedCreditCard) {
            return null;
        }

        if (FormValidator.isEmpty(securityCode)) {
            return ErrorConstants.ERROR_CODES.CREDIT_CARD_SECURITY_CODE;
        } else if (securityCode.length < CreditCardUtils.getSecurityCodeLength(selectedCreditCard?.cardType)) {
            return ErrorConstants.ERROR_CODES.CREDIT_CARD_SECURITY_CODE_LENGTH;
        }

        return null;
    };

    clickHandler = event => {
        const {
            selectedCreditCard, updatedSecurityCode, saveSelectedCreditCard, updateSecurityCode, setViewMode, setErrorMessage, setPaymentError
        } =
            this.props;

        event.preventDefault();
        setErrorMessage(null);
        setPaymentError(null);
        const error = this.cvvRef.current?.validateErrorWithCode();

        if (!selectedCreditCard?.isExpired && !error && !CreditCardUtils.tokenMigrationFailed(selectedCreditCard)) {
            const creditCardToUpdate = {
                ...selectedCreditCard,
                securityCode: updatedSecurityCode
            };
            saveSelectedCreditCard(creditCardToUpdate, null, true);
            updateSecurityCode(null);
            setViewMode(DEFAULT);
        }
    };

    render() {
        const {
            creditCards,
            deleteCreditCard,
            getText,
            refreshData,
            selectedCreditCard,
            setCreditCardToEdit,
            setErrorMessage,
            setSelectedCreditCard,
            setViewMode,
            showCVCHelp,
            updatedSecurityCode,
            updateSecurityCode
        } = this.props;

        const securityCodeErrors = this.securityCodeValidation(updatedSecurityCode || selectedCreditCard?.securityCode);

        return (
            <Fragment>
                {creditCards.map(creditCard => (
                    <Fragment key={creditCard.creditCardId}>
                        <Radio
                            dotOffset={8}
                            disabled={creditCard.isExpired || CreditCardUtils.tokenMigrationFailed(creditCard)}
                            checked={
                                selectedCreditCard?.creditCardId === creditCard.creditCardId && !CreditCardUtils.tokenMigrationFailed(creditCard)
                            }
                            onChange={() => {
                                setSelectedCreditCard(creditCard);
                                updateSecurityCode(null);
                            }}
                            paddingY={null}
                        >
                            <CreditCard
                                creditCard={creditCard}
                                onCreditCardDelete={deleteCreditCard}
                                setCreditCardToEdit={setCreditCardToEdit}
                                setErrorMessage={setErrorMessage}
                                setViewMode={setViewMode}
                                viewMode={SELECT}
                            />
                        </Radio>
                        {CreditCardUtils.showExpiredMessage(creditCard) && (
                            <ErrorMsg
                                marginTop={3}
                                marginLeft={forms.RADIO_SIZE + forms.RADIO_MARGIN}
                            >
                                {getText('expiredCreditCardMsg')}
                            </ErrorMsg>
                        )}
                        {CreditCardUtils.tokenMigrationFailed(creditCard) && (
                            <Grid
                                columns='1fr auto'
                                fontSize='sm'
                                lineHeight='tight'
                                backgroundColor='nearWhite'
                                alignItems='center'
                                padding={3}
                                borderRadius={2}
                                marginTop={10}
                            >
                                <Text>{creditCard.message}</Text>
                                <Button
                                    variant='secondary'
                                    size='xs'
                                    children={getText('gotIt')}
                                    onClick={() => deleteCreditCard(creditCard.creditCardId)}
                                />
                            </Grid>
                        )}
                        {this.showCVCInput(creditCard) && (
                            <Grid
                                marginTop={3}
                                marginLeft={forms.RADIO_SIZE + forms.RADIO_MARGIN}
                                columns={2}
                                gap={4}
                            >
                                <TextInput
                                    marginBottom={null}
                                    autoComplete='cc-csc'
                                    autoCorrect='off'
                                    data-at={Sephora.debug.dataAt('cvv_input')}
                                    error={securityCodeErrors && getText('updateSecurityCode')}
                                    infoAction={showCVCHelp}
                                    infoLabel='More info about CVV'
                                    inputMode='numeric'
                                    invalid={securityCodeErrors}
                                    label={getText('cvc')}
                                    maxLength={CreditCardUtils.getSecurityCodeLength(selectedCreditCard?.cardType)}
                                    name='securityCode'
                                    onChange={event => updateSecurityCode(event.target.value)}
                                    onKeyDown={FormValidator.inputAcceptOnlyNumbers}
                                    onPaste={FormValidator.pasteAcceptOnlyNumbers}
                                    pattern='\d*'
                                    ref={this.cvvRef}
                                    required={true}
                                    validateError={this.securityCodeValidation}
                                    value={updatedSecurityCode}
                                />
                            </Grid>
                        )}
                        <Divider marginY={[4, 5]} />
                    </Fragment>
                ))}
                <Link
                    display='flex'
                    alignItems='center'
                    data-at={Sephora.debug.dataAt('add_new_card_btn')}
                    onClick={() => {
                        setViewMode(CREATE);
                        setCreditCardToEdit(null);
                    }}
                    className={agentAwareUtils.applyHideAgentAwareClassToTiers(['1', '2'])}
                >
                    <Icon
                        name='plus'
                        size={forms.RADIO_SIZE}
                        marginRight={forms.RADIO_MARGIN}
                    />
                    <PaymentLogo
                        width={50}
                        height={32}
                    />
                    <Text
                        marginLeft={3}
                        fontWeight='bold'
                        children={getText('addNewCard')}
                    />
                </Link>
                <Grid
                    marginTop={6}
                    alignItems='center'
                    columns='auto 1fr'
                    gap={5}
                >
                    <Button
                        hasMinWidth={true}
                        variant='primary'
                        onClick={this.clickHandler}
                        disabled={!selectedCreditCard}
                        data-at={Sephora.debug.dataAt('save_and_continue_btn')}
                    >
                        {getText('saveAndContinue')}
                    </Button>
                    {selectedCreditCard && !selectedCreditCard.isExpired && !CreditCardUtils.tokenMigrationFailed(selectedCreditCard) && (
                        <div>
                            <Link
                                color='blue'
                                padding={2}
                                margin={-2}
                                onClick={event => {
                                    event.preventDefault();
                                    setErrorMessage(null);
                                    refreshData();
                                    setViewMode(DEFAULT);
                                }}
                                data-at={Sephora.debug.dataAt('payment_cancel_btn')}
                                children={getText('cancel')}
                            />
                        </div>
                    )}
                </Grid>
            </Fragment>
        );
    }
}

export default wrapComponent(CreditCardsList, 'CreditCardsList');
