import { makeRequest } from 'services/api/ufeApi';
import languageLocale from 'utils/LanguageLocale';
import rougeExclusiveUtils from 'utils/rougeExclusive';

const { getCurrentCountry, getCurrentLanguage } = languageLocale;

const SOURCE_TYPES = {
    CHECKOUT: 'checkout',
    PROFILE: 'profile',
    SNAPSHOT: 'snapshot',
    ORDER_CONFIRMATION: 'orderConfirmation'
};

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+BI+Rewards+Group+API
function _getBiRewardsGroup(source, options) {
    let url = '/api/bi/rewards?source=' + source;

    if (rougeExclusiveUtils.isRougeExclusiveEnabled && source === SOURCE_TYPES.PROFILE && options?.userId) {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();

        url = `/gapi/loyalty-rewards/${options.userId}/elegible-rewards?ch=web&loc=${language}-${country}`;
    }

    return makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

function getBiRewardsGroupForCheckout() {
    return _getBiRewardsGroup(SOURCE_TYPES.CHECKOUT);
}

function getBiRewardsGroupForProfile(options) {
    return _getBiRewardsGroup(SOURCE_TYPES.PROFILE, options);
}

function getBiRewardsGroupForSnapshot() {
    return _getBiRewardsGroup(SOURCE_TYPES.SNAPSHOT);
}

function getBiRewardsGroupForOrderConf() {
    return _getBiRewardsGroup(SOURCE_TYPES.ORDER_CONFIRMATION);
}

export default {
    getBiRewardsGroupForCheckout,
    getBiRewardsGroupForProfile,
    getBiRewardsGroupForSnapshot,
    getBiRewardsGroupForOrderConf
};
