import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import { PREFERENCE_TYPES } from 'constants/beautyPreferences';
import Empty from 'constants/empty';
import BeautyPreferencesSelector from 'selectors/beautyPreferences/beautyPreferencesSelector';
import { tiledProfileContentSelector } from 'viewModel/header/beautyPreferences/tiledProfileContent/withTiledProfileContentProps';
import { localization } from 'viewModel/header/beautyPreferences/preferencesModal/withPreferencesModalProps';

const { wrapHOC } = FrameworkUtils;
const { beautyPreferencesSelector } = BeautyPreferencesSelector;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const preferencesModalTextResources = localization;
const getText = getLocaleResourceFile('components/Header/BeautyPreferences/BeautyProfileHeading/locales', 'BeautyProfileHeading');

const textItemsSelector = createStructuredSelector({
    selectOne: getTextFromResource(getText, 'selectOne'),
    selectAllThatApply: getTextFromResource(getText, 'selectAllThatApply'),
    skinType: getTextFromResource(getText, 'skinType'),
    skinConcerns: getTextFromResource(getText, 'skinConcerns'),
    skinTone: getTextFromResource(getText, 'skinTone'),
    hairDescrible: getTextFromResource(getText, 'hairDescrible'),
    hairTexture: getTextFromResource(getText, 'hairTexture'),
    hairConcerns: getTextFromResource(getText, 'hairConcerns'),
    hairConcernsBenefits: getTextFromResource(getText, 'hairConcernsBenefits'),
    hairColor: getTextFromResource(getText, 'hairColor'),
    eyeColor: getTextFromResource(getText, 'eyeColor'),
    ageRange: getTextFromResource(getText, 'ageRange'),
    fragrancePreferences: getTextFromResource(getText, 'fragrancePreferences'),
    shoppingPreferences: getTextFromResource(getText, 'shoppingPreferences'),
    ingredientPreferences: getTextFromResource(getText, 'ingredientPreferences'),
    favoriteBrands: getTextFromResource(getText, 'favoriteBrands'),
    colorIQ: getTextFromResource(getText, 'colorIQ'),
    ofText: getTextFromResource(getText, 'ofText')
});

const fields = createSelector(
    beautyPreferencesSelector,
    (_, ownProps) => ownProps.profileCategory,
    (_, ownProps) => ownProps.beautyPreferences,
    (_, ownProps) => ownProps.expanded,
    textItemsSelector,
    preferencesModalTextResources,
    tiledProfileContentSelector,
    (state, ownProps) => {
        const categoryType = ownProps?.profileCategory?.type;
        const isPostSignupModal = ownProps?.isPostSignupModal;
        const postSignupTitle = ownProps?.profileCategory?.postSignupTitle;
        const categoryTitle = isPostSignupModal && postSignupTitle ? postSignupTitle : categoryType;

        return getTextFromResource(getText, categoryTitle)(state);
    },
    (beautyPreferencesState, profileCategory, beautyPreferences, expanded, textResources, preferencesModalTexts, tiledTextResources, title) => {
        const brandNames = beautyPreferencesState?.mappedBrandsList?.brandNames || Empty.Object;
        const categoryType = profileCategory.type;
        const isFavoriteBrands = categoryType === PREFERENCE_TYPES.FAVORITE_BRANDS;
        const favoriteBrandsNames = isFavoriteBrands ? brandNames : Empty.Object;
        const imgPrefix = `/img/ufe/rich-profile/${categoryType.toLowerCase()}-`;
        const isOptionsSelected = expanded || (beautyPreferences && beautyPreferences[categoryType]?.length > 0);
        const { selectOne, selectAllThatApply, ...restTextResources } = textResources;
        const parenText = profileCategory.singleSelect ? selectOne : selectAllThatApply;
        const isColorIQ = categoryType === PREFERENCE_TYPES.COLOR_IQ;
        const isColorIQSelected = beautyPreferences?.colorIQ?.length > 0;
        const currentBeautyPreferenceTemp = (beautyPreferences && beautyPreferences[categoryType]) || Empty.Array;
        const currentBeautyPreference = Array.isArray(currentBeautyPreferenceTemp) ? currentBeautyPreferenceTemp : [currentBeautyPreferenceTemp];

        return {
            ...preferencesModalTexts,
            ...tiledTextResources,
            ...restTextResources,
            title,
            imgPrefix,
            isOptionsSelected,
            parenText,
            isColorIQ,
            isColorIQSelected,
            currentBeautyPreference,
            ...favoriteBrandsNames
        };
    }
);

const withBeautyProfileHeadingProps = wrapHOC(connect(fields));

export {
    textItemsSelector, fields, withBeautyProfileHeadingProps
};
