import shoppingListApi from 'services/api/profile/shoppingList';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import Storage from 'utils/localStorage/Storage';
const LOVES_DATA_EXPIRY = Storage.MINUTES * 15;

import lovesReducer from 'reducers/loves';
const { ACTION_TYPES: TYPES } = lovesReducer;

function setShoppingListIds(shoppingListItems) {
    return shoppingListItems.map(love => love.sku.skuId);
}

function updateLovesList(shoppingList) {
    Storage.local.setItem(LOCAL_STORAGE.LOVES_DATA, shoppingList, LOVES_DATA_EXPIRY);

    return {
        type: TYPES.UPDATE_LOVES_LIST,
        currentLoves: shoppingList.loves,
        totalLovesListItemsCount: shoppingList.totalLovesListItemsCount,
        shareLink: shoppingList.shareLink
    };
}

function updatePublicLovesList(data) {
    return {
        type: TYPES.UPDATE_PUBLIC_LOVES_LIST,
        publicLoves: data.loves,
        totalPublicLovesListItemsCount: data.totalPublicLovesListItemsCount
    };
}

function updateShoppingListIds(skuIdArray) {
    /* See notes for this in reducers/loves.js */
    return {
        type: TYPES.UPDATE_SHOPPING_LIST_IDS,
        shoppingListIds: skuIdArray
    };
}

function getLovesList(profileId, callback, isUpdateShoppingList) {
    return dispatch => {
        shoppingListApi.getShoppingList(profileId).then(json => {
            const loves = json.shoppingListItems;
            const totalLovesListItemsCount = json.shoppingListItemsCount;

            if (typeof callback === 'function') {
                callback(loves);
            }

            //prevents loop for loves list
            if (isUpdateShoppingList) {
                const loveIds = setShoppingListIds(loves);
                dispatch(updateShoppingListIds(loveIds));
            }

            return dispatch(
                updateLovesList({
                    loves,
                    totalLovesListItemsCount,
                    shareLink: json.shareLink
                })
            );
        });
    };
}

function setLovesList(shoppingList) {
    return dispatch => {
        const loves = (shoppingList && shoppingList.shoppingListItems) || shoppingList.loves;

        if (loves) {
            const loveIds = setShoppingListIds(loves);
            const totalLovesListItemsCount = shoppingList.shoppingListItemsCount || shoppingList.totalLovesListItemsCount;

            dispatch(updateShoppingListIds(loveIds));

            return dispatch(
                updateLovesList({
                    loves,
                    shareLink: shoppingList.shareLink,
                    totalLovesListItemsCount: totalLovesListItemsCount
                })
            );
        }

        return null;
    };
}

function lovesListChanged(data, profileId, dispatch, callback, cleanup = () => {}) {
    if (typeof callback === 'function') {
        callback(data);
    }

    dispatch(
        getLovesList(profileId, lovesRes => {
            cleanup();

            return dispatch(updateShoppingListIds(lovesRes.map(loveItem => loveItem.sku.skuId)));
        })
    );

    // TODO 17.2: Handle optimistically
}

function addLove(data, cbSuccess, cbCleanup = () => {}) {
    return (dispatch, getState) => {
        const profileId = getState().user.profileId;
        const dataArray = data instanceof Array ? data : [data];
        shoppingListApi
            .addItemsToShoppingList(dataArray.filter(value => Object.keys(value).length !== 0))
            .then(res => {
                lovesListChanged(res[dataArray.length - 1], profileId, dispatch, cbSuccess, cbCleanup);
            })
            .catch(cbCleanup);
    };
}

function removeLove(data, cbSuccess, cbCleanup = () => {}, productId) {
    return (dispatch, getState) => {
        const profileId = getState().user.profileId;
        const dataArray = data instanceof Array ? data : [data];
        shoppingListApi
            .removeItemsFromShoppingList(dataArray, profileId, productId)
            .then(res => {
                lovesListChanged(res[dataArray.length - 1], profileId, dispatch, cbSuccess, cbCleanup);
            })
            .catch(cbCleanup);
    };
}

export default {
    TYPES,
    getLovesList,
    setLovesList,
    updateLovesList,
    updatePublicLovesList,
    updateShoppingListIds,
    addLove,
    removeLove
};
