/**
   Local Storage Namespaces

   Naming Conventions:
   - The namespace is made of three parts, one mandatory and two optional.
   - Structure: [HEAD]_[BODY]_[MODIFIER]
   - Head is mandatory whilst body and modifier are optional.
   - The value should be written using camelCase,
     whilst the key should be written as a constant with underscore separators.

   Example:
      Key: CART_USER_HAS_ACCESS -> [CART]_[USER]_[HAS_ACCESS]
      Value: cartUserHasAccess
 */

export default {
    CATNAV: 'CatNav',
    CAT_NAV_LOCALE: 'CatNavLocale',
    USER_DATA: 'UserData',
    BASKET: 'basket',
    BASKET_TYPE: 'basketType',
    INIT_ORDER_ID: 'lastUfeInitOrderId',
    PAGE_LOAD_FLAG: 'PageLoadFlag',
    LITHIUM_API_TOKEN: 'LithiumApiToken',
    LITHIUM_DATA: 'LITHIUM_DATA',
    LOVES_DATA: 'LOVES_DATA',
    PAGE_DISPLAY_COUNT: 'PageDisplayCount',
    REFERRER: 'Referrer',
    SIGN_IN_SEEN: 'SignInSeen',
    SAVE_POINTS_FOR_GUEST: 'SavePointsForGuest',
    PROFILE_POPOVER_DISMISSED: 'ProfilePopoverDismissed',
    PROFILE_POPOVER_DISPLAY_COUNT: 'ProfilePopoverDisplayCount',
    BI_PANEL_HAS_BEEN_OPENED: 'BIPanelHasBeenOpened',
    APPLE_PAY_ELIGIBILITY: 'applePayEligibility',
    RRC_TERMS_CONDITIONS_ACCEPTED: 'rrcTermsConditionsAccepted',
    RRC_TERMS_CONDITIONS_CHECKED: 'rrcTermsConditionsChecked',
    INITIAL_LOAD_WELCOME_MAT: 'initialLoadWelcomeMat',
    WELCOME_MAT_LAST_TIME_SEEN: 'welcomeMatLastTimeSeen',
    OLR_AUTH_TOKEN: 'olrAuthToken',
    BV_AUTH_TOKEN: 'bvAuthToken',
    EXPERIENCE_LOCATION: 'userLocation',
    PREVIOUS_EXPERIENCE_LOCATIONS: 'previousUserLocations',
    OLR_RESCHEDULE_EXPERIENCE_NUMBER: 'olrRescheduleExperienceNumber',
    CREDIT_CARD_REALTIME_PRESCREEN: 'realtimePrescreen',
    CREDIT_CARD_TARGETERS: 'ccTargeters',
    HARMONY_CONVERSION_ID: 'harmonyConversionId',
    TARGET_CAMPAIGN: 'targetCampaign',
    TARGET_PROMO: 'targetPromo',
    TARGET_REFERRER: 'targetReferrer',
    TARGET_PRODUCT_PAGE_TYPE: 'targetProductPageType',
    HAS_PREVIOUSLY_LOGGED_IN: 'hasPreviouslyLoggedIn',
    CREATED_NEW_USER: 'createdNewUser',
    USER_HAS_SEEN_UPDATED_SHIPPING_CALCULATIONS: 'userHasSeenUpdatedShippingCalculations',
    CONSTRUCTOR_PURCHASE_EVENT_ORDER_CONF: 'constructorPurchaseEventFired',
    SELECTED_STORE: 'selectedStore',
    IS_BIREGISTER_MODAL_DISMISSED: 'isBiRegisterModalDismissed',
    PICKUP_STORE: 'pickupStore',
    CUSTOMER_CHAT_SESSION_STARTED: 'customerChatSessionStarted',
    CUSTOMER_CHAT_PANE_OPEN: 'customerChatPaneOpen',
    CUSTOMER_CHAT_IS_PROACTIVE_CHAT: 'customerChatIsProactiveChat',
    IS_CALIFORNIA_CONSUMER: 'isCaliforniaConsumer',
    PREFERRED_ZIP_CODE: 'preferredZipCode',
    SAME_DAY_DELIVERY_AVAILABLE: 'sameDayDeliveryAvailable',
    EDIT_SEPHORA_CARD: 'editSephoraCard',
    MAX_ADDRESS_VALIDATION_COUNT: 'maxAddressValidationCount',
    SEARCH_TERM_PERSIST: 'searchTermPersist',
    EDP_POLICIES: 'edpPolicies',
    PERSONALIZED_PROMOTIONS: 'personalizedPromotions',
    PERSONALIZED_PROMOTIONS_V2: 'personalizedPromotionsV2',
    PRODUCT_PAGE_BOPIS_TOOLTIP: 'productPageBopisTooltip',
    JWT_AUTH_TOKEN: 'jwtAuthToken',
    HIDE_OAFL_NOTIFICATION: 'hideOnlyAFewLeftNotification',
    LAST_ORDER_TYPE: 'lastOrderType',
    AUTH_ACCESS_TOKEN: 'accessToken',
    AUTH_REFRESH_TOKEN: 'refreshToken',
    NCR_ORDER: 'ncrOrder',
    NCR_ORDER_ERROR_MESSAGE: 'ncrOrderErrorMessage',
    NCR_PRODUCTS_STRING: 'ncrProductsString',
    CAPTURED_COLOR_IQ_PREF: 'capturedColorIQPref',
    USER_ACCOUNT_CLOSED: 'userAccountClosed',
    GALLERY_GREETINGS_DISPLAYED: 'galleryGreetingsDisplayed',
    LOGIN_PROFILE_WARNINGS: 'loginProfileWarnings',
    POSTPONE_LOGIN_PROFILE_WARNINGS: 'postponeLoginProfileWarnings',
    ANALYTICS_NEXT_PAGE_DATA: 'anaNextPageData',
    BI_ACCOUNT_ID: 'biAccountId',
    PROFILE_ID: 'profileId',
    USER_EMAIL: 'userEmail',
    CAN_CHECKOUT_PAZE: 'canCheckoutPaze',
    P13N_DATA: 'P13N_DATA',
    AUTO_REPLENISH_CONTENT: 'autoReplenishContent',
    PDP_SAMPLES_MODAL: 'pdpSamplesModal'
};
