// API documentation: https://stage-developer.sephora.com/specs/v1/docs/store.html#get-activities-for-stores
import ufeApi from 'services/api/ufeApi';
import jsUtils from 'utils/javascript';
import localeUtils from 'utils/LanguageLocale';
import urlUtils from 'utils/Url';
const experiencesApiBase = '/v1/olr/activities';

function getExperiences(token, params) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const paramsWithValue = JSON.parse(JSON.stringify(params));
    const url =
        sdnDomain + experiencesApiBase + (params.activityId ? '/' + params.activityId : '') + urlUtils.buildQuery(jsUtils.buildMap(paramsWithValue));

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token,
                // prettier-ignore
                'site_locale': localeUtils.getCurrentCountry()
            },
            mode: 'cors',
            credentials: 'omit'
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getExperiences;
