import sdnApi from 'services/api/sdn';
import {
    SET_APPLIED_EVENTS_FILTERS, SET_IS_LOADING, SET_STORES_LIST, SET_CURRENT_LOCATION
} from 'constants/actionTypes/events';
import {
    SET_HAPPENING,
    SET_HAPPENING_NON_CONTENT,
    RESET_HAPPENING_IS_INITIALIZED,
    SET_FILTERED_EVENTS,
    SET_HAPPENING_RESERVATIONS_CONTENT
} from 'constants/actionTypes/happening';

import actions from 'actions/Actions';
import eventsReducer from 'reducers/page/events';
import headerAndFooterReducer from 'reducers/headerAndFooter';

import urlUtils from 'utils/Url';
import Location from 'utils/Location';
import happeningFilters from 'utils/happeningFilters';
import languageLocaleUtils from 'utils/LanguageLocale';
import storeUtils from 'utils/Store';
import HappeningLocation from 'utils/HappeningLocation';
import isFunction from 'utils/functions/isFunction';
import Empty from 'constants/empty';
import { getRebookingInfo } from 'utils/happening';

const { getCurrentCountry, getCurrentLanguage } = languageLocaleUtils;
const { getFiltersQueryString, defaultFilters } = happeningFilters;
const { ACTION_TYPES: TYPES } = eventsReducer;

const {
    getServicesAndEventsContent,
    getActivityEDPContent,
    getRwdStoreDetailsContent,
    getReservationsContent,
    getServiceBookingDetails,
    getEventRSVPConfirmationContent,
    getServicesConfirmationContent,
    getReservationsDetails,
    getSeasonalContent
} = sdnApi;

const ERROR_URL = '/happening/error';

// TO DO: This additional setters are not meant to be on this action file

const setAppliedEventsFilters = data => ({
    type: SET_APPLIED_EVENTS_FILTERS,
    payload: data
});

const setIsLoading = data => ({
    type: SET_IS_LOADING,
    payload: data
});

const setStoresList = data => ({
    type: SET_STORES_LIST,
    payload: data
});

const setCurrentLocation = data => ({
    type: SET_CURRENT_LOCATION,
    payload: data
});

const setFilteredEventsData = data => ({
    type: SET_FILTERED_EVENTS,
    payload: { data }
});

const setHappeningContent = data => ({
    type: SET_HAPPENING,
    payload: { data }
});

const setHappeningNonContent = data => ({
    type: SET_HAPPENING_NON_CONTENT,
    payload: { data }
});

const setHappeningReservationsNonContent = data => ({
    type: SET_HAPPENING_RESERVATIONS_CONTENT,
    payload: { data }
});

const resetHappeningIsInitialized = () => ({
    type: RESET_HAPPENING_IS_INITIALIZED
});

const setCompactHeaderAndFooter = data => ({
    type: headerAndFooterReducer.ACTION_TYPES.SET_COMPACT_HEADER_FOOTER,
    payload: { data }
});

const onErrorRedirect = () => urlUtils.redirectTo(ERROR_URL);

const isNewPage = () => true;

const openPage = ({ events: { onPageUpdated, onDataLoaded, onError }, newLocation }) => {
    return (dispatch, getState) => {
        try {
            // setCompactHeaderAndFooter to false here whenever we do SPA navigation on any happening route
            dispatch(setCompactHeaderAndFooter(false));

            const isEventsPage = Location.isEventsPage();
            const isServiceAndEventsPage = Location.isServiceAndEventsPage();
            const isRwdEventsEDP = Location.isRwdEventsEDP();
            const isRwdServicesEDP = Location.isRwdServicesEDP();
            const isServicesBookingFlow = Location.isServicesBookingFlow();
            const isRwdStoreDetailsPage = Location.isRwdStoreDetailsPage();
            const isRwdReservationsPage = Location.isRwdReservationsPage();
            const isRwdReservationsDetailsPage = Location.isRwdReservationsDetailsPage();
            const isEventRSVPConfirmationPage = Location.isEventRSVPConfirmationPage();
            const isRwdServicesConfirmationPage = Location.isRwdServicesConfirmationPage();
            const isSeasonalPage = Location.isSeasonalPage();

            const { country, language } = Sephora.renderQueryParams;
            const { activityType, activityId } = Location.getHappeningActivityInfo();

            const setOnDataCallback = (response, isNonContent = false) => {
                const data = response?.data;

                if (!data || (data.responseStatus && data.responseStatus !== 200)) {
                    onErrorRedirect();
                } else {
                    onDataLoaded(data);
                    dispatch(isNonContent ? setHappeningNonContent(data) : setHappeningContent(data));
                    onPageUpdated(data);
                }
            };

            if (isRwdEventsEDP) {
                const user = getState().user;
                const email = user.login || user.email;
                const { storeId, zipCode } = Sephora.Util.getQueryStringParams();

                return getActivityEDPContent({
                    language,
                    country,
                    activityType,
                    activityId,
                    storeId: storeId[0],
                    zipCode: zipCode[0],
                    ...(email && { email })
                })
                    .then(setOnDataCallback)
                    .catch(onErrorRedirect);
            }

            if (isRwdReservationsPage) {
                const user = getState().user;
                const email = user.login || user.email;

                return getReservationsContent({ country, language, email, status: 'UPCOMING' })
                    .then(response => {
                        const data = {
                            UPCOMING: response?.data || Empty.Array,
                            PAST: [],
                            CANCELLED: []
                        };

                        setOnDataCallback({ data }, true);
                    })
                    .catch(onErrorRedirect);
            }

            if (isRwdServicesConfirmationPage) {
                return getServicesConfirmationContent({
                    confirmationNumber: activityId,
                    country,
                    language
                })
                    .then(setOnDataCallback)
                    .catch(onErrorRedirect);
            }

            if (isRwdReservationsDetailsPage) {
                const confirmationNumber = urlUtils.getParamsByName('id');
                const zipCode = urlUtils.getParamsByName('zipCode');
                const reservationCountry = urlUtils.getParamsByName('country');

                dispatch(resetHappeningIsInitialized());

                return getReservationsDetails({
                    country,
                    language,
                    reservationCountry,
                    zipCode,
                    confirmationNumber
                })
                    .then(setOnDataCallback)
                    .catch(onErrorRedirect);
            }

            return HappeningLocation(async (preferredStoreId, preferredZipCode, sephoraStores) => {
                let getContentApi;

                if (isEventsPage || isServiceAndEventsPage) {
                    if (isEventsPage) {
                        dispatch(setStoresList(sephoraStores));
                        dispatch(setCurrentLocation({ display: preferredZipCode, storeId: preferredStoreId }));
                    }

                    getContentApi = getServicesAndEventsContent({
                        language,
                        country,
                        preferredStoreId,
                        preferredZipCode,
                        endpoint: isEventsPage ? 'events' : 'services'
                    });
                } else if (isRwdServicesEDP) {
                    getContentApi = getActivityEDPContent({
                        language,
                        country,
                        activityType,
                        activityId
                    });
                } else if (isRwdStoreDetailsPage) {
                    getContentApi = getRwdStoreDetailsContent({
                        language,
                        country,
                        activityId
                    });
                } else if (isServicesBookingFlow) {
                    const { rebookingStoreId, rebookingZipCode } = getRebookingInfo();

                    getContentApi = getServiceBookingDetails({
                        language,
                        country,
                        bookingId: activityId,
                        zipCode: rebookingZipCode ? rebookingZipCode : preferredZipCode,
                        selectedStoreId: rebookingStoreId ? rebookingStoreId : preferredStoreId
                    });
                } else if (isEventRSVPConfirmationPage) {
                    const { id } = Sephora.Util.getQueryStringParams();

                    getContentApi = getEventRSVPConfirmationContent({
                        confirmationNumber: id[0],
                        country,
                        language,
                        preferredZipCode
                    });
                } else if (isSeasonalPage) {
                    getContentApi = getSeasonalContent({
                        language,
                        country,
                        zipCode: preferredZipCode
                    });
                }

                try {
                    const response = await getContentApi;

                    setOnDataCallback(isServicesBookingFlow ? { data: response } : response, isServicesBookingFlow);
                } catch {
                    onErrorRedirect();
                }
            });
        } catch (error) {
            onError(error, newLocation, true);

            return Promise.reject(error);
        }
    };
};

const getEventsEDPClientSideContent = user => {
    return dispatch => {
        const { country, language } = Sephora.renderQueryParams;
        const { storeId, zipCode } = Sephora.Util.getQueryStringParams();
        const { activityType, activityId } = Location.getHappeningActivityInfo();

        getActivityEDPContent({
            language,
            country,
            activityType,
            activityId,
            storeId: storeId[0],
            zipCode: zipCode[0],
            ...(user.isSignedIn && { email: user.email })
        })
            .then(response => {
                if (response?.data) {
                    dispatch(setHappeningContent(response.data));
                } else {
                    onErrorRedirect();
                }
            })
            .catch(onErrorRedirect);
    };
};

const getReservationsDetail = () => {
    return dispatch => {
        const { country, language } = Sephora.renderQueryParams;
        const confirmationNumber = urlUtils.getParamsByName('id');
        const zipCode = urlUtils.getParamsByName('zipCode');
        const reservationCountry = urlUtils.getParamsByName('country');

        getReservationsDetails({
            country,
            language,
            zipCode,
            confirmationNumber,
            reservationCountry
        })
            .then(response => {
                dispatch(setHappeningContent(response.data));
            })
            .catch(onErrorRedirect);
    };
};

const getReservationsClientSideContent = (email, status) => {
    return dispatch => {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();

        if (email) {
            getReservationsContent({ country, language, email, status })
                .then(response => {
                    const data = {
                        [status]: response.data
                    };
                    dispatch(setHappeningReservationsNonContent(data));
                })
                .catch(onErrorRedirect);
        } else {
            dispatch(setHappeningReservationsNonContent({ UPCOMING: [] }));
        }
    };
};

const getBookingFlowClientSideContent = (storeId, zipCode) => {
    return dispatch => {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();
        const { activityId } = Location.getHappeningActivityInfo();
        const { rebookingStoreId, rebookingZipCode } = getRebookingInfo();

        getServiceBookingDetails({
            country,
            language,
            bookingId: activityId,
            zipCode: rebookingZipCode ? rebookingZipCode : zipCode,
            selectedStoreId: rebookingStoreId ? rebookingStoreId : storeId
        })
            .then(data => {
                if (data.responseStatus && data.responseStatus !== 200) {
                    onErrorRedirect();
                } else {
                    dispatch(setHappeningNonContent(data));
                }
            })
            .catch(onErrorRedirect);
    };
};

const getClientSidePageContent = ({ preferredStoreId, preferredZipCode, sephoraStores, successCallback }) => {
    return dispatch => {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();

        const isEventsPage = Location.isEventsPage();

        if (isEventsPage) {
            dispatch(setStoresList(sephoraStores));
            dispatch(setCurrentLocation({ display: preferredZipCode, storeId: preferredStoreId }));
        }

        if (preferredStoreId && preferredZipCode) {
            getServicesAndEventsContent({
                country,
                language,
                preferredStoreId,
                preferredZipCode,
                endpoint: isEventsPage ? 'events' : 'services'
            })
                .then(response => {
                    if (response?.data) {
                        dispatch(setHappeningContent(response.data));

                        if (isFunction(successCallback)) {
                            successCallback();
                        }
                    } else {
                        onErrorRedirect();
                    }
                })
                .catch(onErrorRedirect);
        }
    };
};

const getFilteredEvents = ({ appliedFilters, storeId, zipCode, discard }) => {
    return dispatch => {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();

        const filters = getFiltersQueryString(appliedFilters);

        dispatch(setIsLoading(true));
        dispatch(resetHappeningIsInitialized());

        getServicesAndEventsContent({
            country,
            language,
            preferredStoreId: storeId,
            preferredZipCode: zipCode,
            filters,
            endpoint: 'events',
            eventsOnly: true
        })
            .then(data => {
                if (data) {
                    dispatch(setFilteredEventsData(data));
                    dispatch(setAppliedEventsFilters(appliedFilters));
                } else {
                    discard && discard();
                }
            })
            .catch(() => discard && discard())
            .finally(() => dispatch(setIsLoading(false)));
    };
};

const getEventRSVPConfirmationClientSideContent = ({ zipCode, cb }) => {
    return dispatch => {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();
        const { id } = Sephora.Util.getQueryStringParams();

        getEventRSVPConfirmationContent({
            confirmationNumber: id[0],
            country,
            language,
            preferredZipCode: zipCode
        })
            .then(response => {
                if (response?.data) {
                    dispatch(setHappeningContent(response.data));

                    if (isFunction(cb)) {
                        cb();
                    }
                } else {
                    onErrorRedirect();
                }
            })
            .catch(onErrorRedirect);
    };
};

const getServicesConfirmationClientSideContent = () => {
    return dispatch => {
        const country = getCurrentCountry();
        const language = getCurrentLanguage();
        const { activityId } = Location.getHappeningActivityInfo();

        getServicesConfirmationContent({
            confirmationNumber: activityId,
            country,
            language
        })
            .then(response => {
                if (response?.data) {
                    dispatch(setHappeningContent(response.data));
                } else {
                    onErrorRedirect();
                }
            })
            .catch(onErrorRedirect);
    };
};

const getStores = locationObj => dispatch => {
    storeUtils
        .getStores(locationObj, true, false, false, true, true)
        .then(stores => {
            dispatch(setStoresList(stores));
        })
        .catch(() => {
            dispatch(setStoresList([]));
        });
};

const showLocationAndStores = () => actions.showLocationAndStoresModal({ isOpen: true });

const closeLocationAndStores = () => actions.showLocationAndStoresModal({ isOpen: false });

const resetFiltersToDefault = () => dispatch => dispatch(setAppliedEventsFilters(defaultFilters));

const getClientSideSeasonalContent = zipCode => {
    return dispatch => {
        const apiOptions = {
            country: getCurrentCountry(),
            language: getCurrentLanguage(),
            zipCode
        };

        getSeasonalContent(apiOptions)
            .then(response => {
                if (response?.data) {
                    dispatch(setHappeningContent(response.data));
                } else {
                    onErrorRedirect();
                }
            })
            .catch(onErrorRedirect);
    };
};

export default {
    isNewPage,
    openPage,
    TYPES,
    getFilteredEvents,
    showLocationAndStores,
    closeLocationAndStores,
    setStoresList,
    getStores,
    setCurrentLocation,
    getClientSidePageContent,
    resetFiltersToDefault,
    getEventsEDPClientSideContent,
    getReservationsClientSideContent,
    getBookingFlowClientSideContent,
    getEventRSVPConfirmationClientSideContent,
    getServicesConfirmationClientSideContent,
    getReservationsDetail,
    setCompactHeaderAndFooter,
    getClientSideSeasonalContent
};
