import React from 'react';
import { modal } from 'style/config';
import { ModalContextConsumer, ModalContextProvider } from 'components/GlobalModals/PaidReservationModal/modalContext';
import {
    Text, Button, Divider, Link
} from 'components/ui';
import resourceWrapper from 'utils/framework/resourceWrapper';
import { withModalContext } from 'components/GlobalModals/PaidReservationModal/modalContext';
import Actions from 'Actions';
import BaseClass from 'components/BaseClass';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import localeUtils from 'utils/LanguageLocale';
import Modal from 'components/Modal/Modal';
import store from 'store/Store';
import bookArtist from 'utils/bookArtist';
import { wrapComponent } from 'utils/framework';
import analyticsUtils from 'analytics/utils';
import analyticsConstants from 'analytics/constants';
import processEvent from 'analytics/processEvent';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import HelpersUtils from 'utils/Helpers';
import ErrorMsg from 'components/ErrorMsg';
import withInnerModal from 'components/withInnerModal/withInnerModal';
import MediaPopup from 'components/GlobalModals/MediaPopup/MediaPopup';
import { renderActivityPolicies } from 'components/OnlineReservation/ExperienceDetailPage/ExperienceInfo';
import { PAYMENT_ERROR_CODES } from 'components/Checkout/constants';
import servicesBindings from 'analytics/bindingMethods/pages/happeningAtSephora/servicesBindings';
import UserInfoSectionComponent from 'components/GlobalModals/PaidReservationModal/UserInfoSection/UserInfoSection';
import WaiverSectionComponent from 'components/GlobalModals/PaidReservationModal/WaiverSection/WaiverSection';
import PaymentSectionComponent from 'components/GlobalModals/PaidReservationModal/PaymentSection';

const UserInfoSection = withModalContext(UserInfoSectionComponent);
const WaiverSection = withModalContext(WaiverSectionComponent);
const PaymentSection = withModalContext(PaymentSectionComponent);

const { getProp } = HelpersUtils;
const { englishUSA, englishCA, frenchCA } = localeUtils.LANGUAGE_CODES;
const TERMS_CONST_PER_COUNTRY = {
    [englishUSA]: 'https://seph.me/3lHCGqH',
    [englishCA]: 'https://seph.me/39lnd9Q',
    [frenchCA]: 'https://seph.me/3tUwOhv'
};

class PaidReservationModal extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errorMessage: '',
            paymentError: ''
        };
        this.modalAnalytics = null;
        this.paymentRef = React.createRef();
        this.sendAnalytics();
    }

    requestClose = () => {
        store.dispatch(Actions.showPaidReservationModal({ isOpen: false }));
    };

    getTermsAndConsLink = () => {
        const targetUrl = TERMS_CONST_PER_COUNTRY[localeUtils.getCurrentLanguageCountryCode()] || TERMS_CONST_PER_COUNTRY[englishUSA];

        return (
            <Link
                display='inline'
                lineHeight='tight'
                color='blue'
                href={targetUrl ? targetUrl : null}
                children={targetUrl}
            />
        );
    };

    handleSubmit = (userInfo, selectedCreditCard, waiverInfo) => () => {
        const {
            reservation,
            displayErrorMessage,
            activityDetails: {
                selectedActivity: { activityId, type: activityType, storeId, name }
            }
        } = this.props;
        let errorMessage = '';

        servicesBindings.completeBooking({ activityId, activityType, storeId, reservationName: name.toLowerCase() });

        reservation(userInfo, selectedCreditCard, waiverInfo).catch(error => {
            if (PAYMENT_ERROR_CODES.includes(error?.errorCode)) {
                errorMessage = ExperienceDetailsUtils.getText('genericPaymentMessage');
                this.setState({ error: true, paymentError: errorMessage }, () => {
                    this.paymentRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                });
            } else if (error?.errorMessages[0] === 'error.reservationservice.sm.booking.timeslot') {
                this.requestClose();
                displayErrorMessage();
            } else {
                errorMessage = ExperienceDetailsUtils.getText('genericErrorMessage');
                this.setState({
                    error: true,
                    errorMessage
                });
            }
        });
    };

    sendAnalytics = () => {
        const { activityDetails, analyticsData } = this.props;
        const { PAGE_TYPES, ASYNC_PAGE_LOAD, PAGE_DETAIL, Event } = analyticsConstants;
        const activityTitle = activityDetails?.selectedActivity?.name;
        const activityType = activityDetails?.selectedActivity?.type;
        const pageDetail = PAGE_DETAIL.RESERVATION_DETAILS;
        const pageType = PAGE_TYPES.OLR;
        const mappedActivityType = ACTIVITY.ANALYTICS_OLR_EXPERIENCE[activityType];
        const prop55 = `happening:continue booking:${mappedActivityType}:${activityTitle}`;
        const { context } = analyticsData || {};
        const contextEvent = (context && analyticsUtils.getLastAsyncPageLoadData({ pageType: context })) || {};
        const previousPage = contextEvent.pageName || getProp(digitalData, 'page.attributes.sephoraPageInfo.pageName');
        const pageName = `${pageType}:${pageDetail}:n/a:*title=${activityTitle}`;
        this.modalAnalytics = {
            pageDetail,
            pageType,
            pageName,
            previousPage
        };
        processEvent.process(ASYNC_PAGE_LOAD, {
            data: {
                ...this.modalAnalytics,
                linkData: prop55,
                eventStrings: [Event.EVENT_214]
            }
        });
    };

    handleSetPaymentStatus = () => {
        this.setState({ paymentError: null, error: false });
    };

    render() {
        const {
            contextComponent,
            activityDetails: { activityPolicies }
        } = this.props;
        const getText = resourceWrapper(
            localeUtils.getLocaleResourceFile('components/GlobalModals/PaidReservationModal/locales', 'PaidReservationModal')
        );
        const {
            selectedActivity, selectedTimeSlot, storeName, hasSpecialRequests, selectedArtistSlot
        } = this.props.activityDetails;
        const { error, errorMessage, paymentError } = this.state;
        const { isVirtual, priceInfo, activityId } = selectedActivity;
        const isPersonalService = activityId === ACTIVITY.PERSONAL_SHOPPING_SERVICE_ACTIVITY_ID;
        const { listPrice: price } = priceInfo || {};
        const storeNameText = isVirtual ? ExperienceDetailsUtils.getText('virtual') : `Sephora ${storeName}`;
        const { openModal, innerModal, closeModal } = this.props;

        const artistName = bookArtist.getArtistName(selectedArtistSlot);
        let latestSelectedCreditcard;

        return (
            <ModalContextProvider contextComponent={contextComponent}>
                <Modal
                    dataAt={Sephora.debug.dataAt('reservation_details_modal')}
                    isOpen={this.props.isOpen}
                    onDismiss={this.requestClose}
                    width={1}
                    hasBodyScroll={true}
                >
                    <Modal.Header>
                        <Modal.Title>{getText('modalTitle')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {innerModal}
                        <Text
                            is='h2'
                            fontSize='md'
                            lineHeight='tight'
                            fontWeight='bold'
                            children={selectedActivity.name}
                        />
                        <Text
                            data-at={Sephora.debug.dataAt('location_info_label')}
                            is='p'
                            lineHeight='tight'
                            children={storeNameText}
                        />
                        <Text
                            data-at={Sephora.debug.dataAt('date_time_label')}
                            is='p'
                            lineHeight='tight'
                            children={ExperienceDetailsUtils.getBookingModalDate(this.props.activityDetails, false)}
                        />
                        {artistName && (
                            <Text
                                data-at={Sephora.debug.dataAt('selected_artist_label')}
                                is='p'
                                lineHeight='tight'
                                children={`${artistName}*`}
                            />
                        )}
                        <Text
                            data-at={Sephora.debug.dataAt('duration_price_label')}
                            is='p'
                            lineHeight='tight'
                            marginTop={4}
                            fontWeight='bold'
                            children={`${selectedTimeSlot.durationMin} ${getText('minutes')} • ${price || ''}`}
                        />
                        <Divider
                            marginY={4}
                            marginX={modal.outdentX}
                            thick={true}
                        />
                        <UserInfoSection
                            isPersonalService={isPersonalService}
                            isVirtual={isVirtual}
                            modalAnalytics={this.modalAnalytics}
                            hasSpecialRequests={hasSpecialRequests}
                            getTermsAndConsLink={this.getTermsAndConsLink}
                        />
                        {isPersonalService || (
                            <div ref={this.paymentRef}>
                                <React.Fragment>
                                    <Divider
                                        marginY={4}
                                        marginX={modal.outdentX}
                                        thick={true}
                                    />
                                    <PaymentSection
                                        paymentError={paymentError}
                                        setPaymentStatus={this.handleSetPaymentStatus}
                                        getText={getText}
                                    />
                                </React.Fragment>
                            </div>
                        )}
                        <Divider
                            marginY={4}
                            marginX={modal.outdentX}
                            thick={true}
                        />
                        {renderActivityPolicies(activityPolicies, priceInfo, true)}
                        {isVirtual || (
                            <WaiverSection
                                openModal={openModal}
                                closeModal={closeModal}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {error && (
                            <ErrorMsg
                                marginBottom={4}
                                children={errorMessage}
                            />
                        )}
                        <ModalContextConsumer>
                            {({ readyToSubmit, userInfo, selectedCreditCard, waiverInfo }) => {
                                latestSelectedCreditcard = { ...selectedCreditCard };

                                return (
                                    <Button
                                        disabled={!readyToSubmit || error}
                                        width={['100%']}
                                        hasMinWidth
                                        variant='primary'
                                        data-at={Sephora.debug.dataAt('complete_booking_btn')}
                                        onClick={this.handleSubmit(userInfo, latestSelectedCreditcard, waiverInfo)}
                                        children={getText('completeBooking')}
                                    />
                                );
                            }}
                        </ModalContextConsumer>
                    </Modal.Footer>
                </Modal>
            </ModalContextProvider>
        );
    }
}

export default withInnerModal(wrapComponent(PaidReservationModal, 'PaidReservationModal', true), MediaPopup);
