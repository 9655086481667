import ufeApi from 'services/api/ufeApi';

const getUserReservationPaymentInfo = (token, { creditCardId = '' }) => {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const url = `${sdnDomain}/v1/happening/payment/${creditCardId}`;

    return ufeApi.makeRequest(
        url,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            }
        },
        { isHappening: true }
    );
};

export default getUserReservationPaymentInfo;
