export default function getResource(label, vars = []) {
    const resources = {
        savedAddresses: 'Saved Addresses',
        defaultShippingAddress: 'Default shipping address',
        setAsDefaultAddress: 'Set as default address',
        edit: 'Edit',
        addShippingAddress: 'Add shipping address',
        title: 'Delete Address',
        message: 'You have exceeded the maximum amount of saved addresses. Please delete one and try to add again.',
        buttonText: 'Continue'
    };
    return resources[label];
}
