export default function getResource(label, vars=[]) {
    const resources = {
        getItShippedTitle: 'Faites livrer',
        freeShipping: 'Livraison GRATUITE',
        shippingAndReturns: 'Livraison et retours',
        reserveAndPickUp: 'Réservation et ramassage',
        buyOnlineAndPickUp: 'Achat en ligne et ramassage',
        selectForStoreAvailability: 'Sélectionner pour voir la disponibilité',
        limitedStock: 'Quantités limitées',
        outOfStock: 'Rupture de stock',
        inStock: 'En stock',
        stores: 'magasins',
        nearYou: 'près de chez vous',
        checkOtherStores: 'Voir les autres magasins',
        notOffered: 'Réservation non offerte dans ce magasin',
        pickUpNotOffered: 'Le ramassage n’est pas offert dans ce magasin',
        atThisStore: 'dans ce magasin',
        availableInAllStores: 'Offert dans tous les magasins',
        onlineOnly: 'En ligne seulement',
        reserveNotAvailable: 'Option Réservation et ramassage non offerte',
        pickUpNotAvailable: 'Option Achetez en ligne et Ramassez en magasin non offerte',
        reservationNotOfferedForItem: 'Réservation non offerte pour cet article',
        pickUpNotOfferedForItem: 'Le ramassage n’est pas offert pour cet article',
        at: 'à',
        for: 'pour',
        sameDayDelivery: 'Livraison le jour même',
        standard: 'Standard',
        sameDayDeliveryNotAvailable: 'Non disponible pour cet article',
        sddTemporarilyUnavailableAtLocation: 'La livraison le jour même n’est pas disponible pour le moment. Veuillez réessayer plus tard.',
        sameDayNotAvailableFor: 'La livraison le jour même n’est pas disponible',
        yourLocation: 'votre emplacement',
        changeLocation: 'Changer votre emplacement',
        autoReplenishTitle: 'Réapprovisionnement automatique',
        deliveryEvery: 'Livraison chaque',
        months: 'mois',
        mostCommon: 'le plus courant',
        subscriptionNotAvailable: 'Non disponible pour cette sélection',
        save: 'Enregistrer',
        rougeMemberYouGet: 'En tant que membre Rouge, vous pouvez aussi essayer',
        rougeMemberFreeSameDayDelivery: 'gratuitement la livraison le jour même',
        autoReplenishLegalOptIn: 'En vous inscrivant, vous consentez aux frais récurrents de votre abonnement.',
        freeShipSignIn: `${vars[0]} ou ${vars[1]} pour profiter de ${vars[2]}.`,
        signIn: 'Ouvrir une session',
        createAccount: 'créer un compte',
        freeStandardShipping: 'livraison standard GRATUITE',
        storesNearYou: 'magasins près de chez vous',
        inStorePickup: 'Ramassage en magasin',
        curbsidePickup: 'Ramassage à l’extérieur',
        curbsideConcierge: 'Concierge pour ramassage à l’extérieur',
        aboutSameDayDelivery: 'À propos de la livraison le jour même',
        notAvailable: 'Non disponible',
        sddRougeFreeShipMessage: 'En tant que membre Rouge, vous pouvez aussi essayer gratuitement la livraison le jour même!',
        aboutBuyOnlineAndPickUp: 'À propos de l’option Achetez en ligne et ramassez',
        aboutAutoReplen: 'À propos du réapprovisionnement automatique',
        sddRougeTestV2Message: `En tant que membre Rouge, vous êtes à ${vars[0]} de pouvoir profiter de la livraison le jour même GRATUITE.`,
        sddRougeTestV2FreeShippingMessage: 'En tant que membre Rouge, vous pouvez essayer la livraison le jour même GRATUITE.',
        autoReplenishLegalText1: `*Offre valable uniquement pour les nouveaux abonnés. Pour chaque produit en super aubaine, l’abonnement et le nombre d’unités sont limités à 1 par membre de la clientèle. Les réductions expirent ${vars[0]} mois après l’inscription initiale.`,
        autoReplenishLegalText2: `Après ${vars[0]} commandes au montant plus élevé ou passées ${vars[1]} mois après la date d’inscription initiale, selon ce qui arrive en premier, l’abonnement se renouvelle avec une réduction de ${vars[2]} %.`,
        autoReplenishLegalText3: `L’offre prend fin le ${vars[0]}.`,
        autoReplenishFirstTimeLegalShown: `*Les nouveaux abonnés obtiennent une réduction de ${vars[0]} sur leurs ${vars[1]} premières commandes, livrée dans les ${vars[2]} mois suivant la commande initiale. La période de promotion se termine le ${vars[3]}.`,
        autoReplenishOnThisItem: 'sur cet article',
        autoReplenishTermsApply: ', des modalités s’appliquent*',
        autoReplenishFirstTimeLegalDetails: 'Détails',
        autoReplenishFirstTimeLegalMoreDetails: `Pour chaque produit en super aubaine, l’abonnement et le nombre d’unités sont limités à 1 par membre de la clientèle. Après ${vars[0]} commandes de réapprovisionnement à ${vars[1]} de réduction ou après ${vars[2]} mois à compter de la date d’inscription initiale, selon la première éventualité, l’abonnement est renouvelé à ${vars[3]} de réduction.`,
        autoReplenishCheckoutTermsConditions1: `*Prix valide pour les nouveaux abonnés seulement, pour les ${vars[0]} premières commandes, livrées dans les ${vars[1]} mois suivant la commande initiale.`,
        autoReplenishCheckoutTermsConditions2: `Après ${vars[0]} livraisons ou après l’expiration, l’abonnement se renouvelle à un taux de ${vars[1]} % de réduction.`,
        autoReplenishCheckoutTermsConditions3: 'Quantités limitées à 1.',
        autoReplenishSubTitle: `Économisez ${vars[0]} % sur cet article`,
        getItShippedShipMessage: 'Livraison par',
        to: 'à',
        forFreeShipping: 'pour profiter de l’expédition GRATUITE',
        getItToday: 'recevez-la aujourd’hui',
        readyToday: 'prête aujourd’hui',
        readyWithinTwoHours: 'Prête dans les deux prochaines heures',
        readyTomorrow: 'prête demain',
        getItTomorrow: 'Recevez-la demain'
    };

    return resources[label];
}
