import biApi from 'services/api/beautyInsider/getBiRewardsGroup';
import UtilActions from 'utils/redux/Actions';

export function fetchBiRewards(options) {
    return dispatch => {
        biApi
            .getBiRewardsGroupForProfile(options)
            .then(data => {
                const { biRewardGroups } = data;
                dispatch(UtilActions.merge('beautyInsider', 'biRewardGroups', biRewardGroups));
            })
            // eslint-disable-next-line no-console
            .catch(console.error);
    };
}

export default { fetchBiRewards };
