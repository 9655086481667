import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import styles from 'components/OnlineReservation/ExperienceDetailPage/SlotList/styles';

function SlotList({ ...restProps }) {
    return (
        <div
            css={styles.listBox}
            {...restProps}
            role='listbox'
            tabIndex='0'
        />
    );
}

export default wrapFunctionalComponent(SlotList, 'SlotList');
