const {
    borders,
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    shadows,
    space,
    radii,
    zIndices
} = require('./config');

const theme = {
    borders,
    breakpoints,
    colors,
    fonts,
    fontSizes,
    fontWeights,
    letterSpacings,
    lineHeights,
    shadows,
    space,
    radii,
    zIndices
};

export default theme;
