export default function getResource(label, vars = []) {
    const resources = {
        needAssistanceText: 'Besoin d’aide?',
        ttyText: 'ATS',
        freeShippingText: 'Retour gratuit par expédition ou retour en magasin',
        weAcceptText: 'Nous acceptons'
    };

    return resources[label];
}
