export const BASKET_TYPES = {
    PREBASKET: 'prebasket',
    DC_BASKET: 'distribution center basket',
    ROPIS_BASKET: 'reserve and pick up basket',
    BOPIS_BASKET: 'buy online and pick up basket',
    SAMEDAY_BASKET: 'SameDay',
    STANDARD_BASKET: 'ShipToHome'
};

export const FULFILLMENT_TYPES = {
    PICKUP: 'PICKUP',
    SAMEDAY: 'SAMEDAY',
    SHIP: 'SHIP'
};
