const resources = {
    checkYourBalance: 'Check Your Balance',
    enterGiftCardNumber: 'Enter your gift card’s 16 digit number and PIN.',
    reCaptcha: 'Sephora uses Google ReCaptcha and users are subject to Google’s',
    privacyPolicy: 'privacy policy',
    terms: 'terms',
    balanceCall: 'To hear your balance, call',
    currentBalance: 'Current Balance',
    cardNumber: 'Card Number',
    pin: 'PIN',
    checkBalance: 'Check Balance'
};

export default function getResource(label) {
    return resources[label];
}
