export default function getResource(label, vars = []) {
    const resources = {
        smsSignInModalTitle: 'Subscribe to Sephora',
        greeting: `Hi ${vars[0]},`,
        ModalTextHeading: 'Sign up for Sephora text updates.',
        ModalTextInputHeading: 'Enter your mobile phone number below to sign up, and never miss out on exclusive releases, events, and offers.',
        enterMobileNumber: 'Mobile Phone Number',
        signUpNow: 'Complete Sign Up',
        TermsAndConditon: 'By entering your phone number, clicking Complete Sign Up, you consent to the ',
        textTerms: 'TEXT TERMS',
        TermsAndConditon2: ' and to receive autodialed marketing texts, including abandoned cart reminders. Message frequency varies. Consent is not a condition of purchase. Message and data rates may apply. See our ',
        privacyPolicy: 'PRIVACY POLICY',
        TermsAndConditon3: ' and ',
        notice: 'NOTICE OF FINANCIAL INCENTIVE.',
        TermsAndConditon4: ' Text STOP to cancel at any time. Text HELP for help.',
        TermsAndConditon5: ' Sephora: 600 de Maisonneuve Boulevard West, Suite 2400, Montréal, Quebec, H3A 3J2, Canada. 1-877-737-4672.'
    };

    return resources[label];
}
