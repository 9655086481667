import BaseClass from 'components/BaseClass';
import CreditCard from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/CreditCard';
import CreditCardUtils from 'utils/CreditCard';
import PaymentInfo from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo';
import React from 'react';
import PropTypes from 'prop-types';
import withCreditCardFormViewModel from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm/withCreditCardFormViewModel';
import { wrapComponent } from 'utils/framework';
import CreditCardsListComponent from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/CreditCardsList';
import viewModes from 'components/GlobalModals/PaidReservationModal/PaymentSection/viewModes';

const { CREATE, DEFAULT, EDIT, SELECT } = viewModes;

const CreditCardsList = withCreditCardFormViewModel(CreditCardsListComponent);

class PaymentSectionContent extends BaseClass {
    state = { updatedSecurityCode: null };

    componentDidUpdate(prevProps) {
        const { setReadytoUseCreditcard, selectedCreditCard, editCreditCard, viewMode } = this.props;

        if (prevProps.selectedCreditCard !== selectedCreditCard && !selectedCreditCard?.isExpired) {
            setReadytoUseCreditcard(selectedCreditCard);

            if (viewMode === SELECT) {
                this.verifyCreditCardError();
            }
        }

        if (prevProps.viewMode !== viewMode) {
            if (viewMode === SELECT) {
                this.verifyCreditCardError(true);
            }

            editCreditCard(viewMode !== DEFAULT);
        }
    }

    verifyCreditCardError = isSelectedModeLoaded => {
        const { isPaymentReady, selectedCreditCard } = this.props;

        if (
            !CreditCardUtils.isCreditCardReady({
                isPaymentReady,
                selectedCreditCard
            })
        ) {
            this.triggerCreditCardErrors(isSelectedModeLoaded);
        }
    };

    triggerCreditCardErrors = isSelectedModeLoaded => {
        const { creditCards, getText, selectedCreditCard, triggerAnalyticsErrors } = this.props;
        const { updatedSecurityCode } = this.state;
        const errors = [];

        if (
            selectedCreditCard &&
            !selectedCreditCard.isExpired &&
            (!updatedSecurityCode || updatedSecurityCode.length < CreditCardUtils.getSecurityCodeLength(selectedCreditCard.cardType))
        ) {
            errors.push(getText('updateSecurityCode'));
        }

        // We don't need to send these analytics everytime we change cards on Select Mode
        if (isSelectedModeLoaded) {
            const expiredCreditCards = creditCards.filter(cc => cc.isExpired);
            expiredCreditCards.forEach(() => {
                errors.push(getText('expiredCreditCardMsg'));
            });
        }

        triggerAnalyticsErrors(errors);
    };

    render() {
        const {
            creditCards,
            creditCardToEdit,
            deleteCreditCard,
            getDefaultAddress,
            getText,
            isAnonymous,
            isPaymentReady,
            processCreditCard,
            refreshData,
            saveSelectedCreditCard,
            selectedCreditCard,
            setCreditCardToEdit,
            setErrorMessage,
            setPaymentError,
            setSelectedCreditCard,
            setViewMode,
            triggerAnalyticsErrors,
            viewMode
        } = this.props;
        const { updatedSecurityCode } = this.state;
        const defaultAddress = getDefaultAddress();

        switch (viewMode) {
            case SELECT: {
                return (
                    <CreditCardsList
                        creditCards={creditCards}
                        deleteCreditCard={deleteCreditCard}
                        getText={getText}
                        isPaymentReady={isPaymentReady}
                        refreshData={refreshData}
                        saveSelectedCreditCard={saveSelectedCreditCard}
                        selectedCreditCard={selectedCreditCard}
                        setCreditCardToEdit={setCreditCardToEdit}
                        setErrorMessage={setErrorMessage}
                        setPaymentError={setPaymentError}
                        setSelectedCreditCard={setSelectedCreditCard}
                        setViewMode={setViewMode}
                        triggerAnalyticsErrors={triggerAnalyticsErrors}
                        updatedSecurityCode={updatedSecurityCode}
                        updateSecurityCode={securityCode => this.setState({ updatedSecurityCode: securityCode })}
                    />
                );
            }
            case CREATE:
            case EDIT: {
                return (
                    <PaymentInfo
                        creditCardToEdit={creditCardToEdit}
                        defaultAddress={defaultAddress}
                        hasCreditCards={creditCards.length > 0}
                        isAnonymous={isAnonymous}
                        processCreditCard={processCreditCard}
                        setPaymentError={setPaymentError}
                        setErrorMessage={setErrorMessage}
                        setViewMode={setViewMode}
                        triggerAnalyticsErrors={triggerAnalyticsErrors}
                        viewMode={viewMode}
                    />
                );
            }
            case DEFAULT:
            default: {
                return (
                    <CreditCard
                        creditCard={selectedCreditCard}
                        setErrorMessage={setErrorMessage}
                        setPaymentError={setPaymentError}
                    />
                );
            }
        }
    }
}

PaymentSectionContent.defaultProps = {
    creditCardToEdit: null,
    selectedCreditCard: null
};
PaymentSectionContent.propTypes = {
    creditCards: PropTypes.array.isRequired,
    creditCardToEdit: PropTypes.object,
    editCreditCard: PropTypes.func.isRequired,
    getDefaultAddress: PropTypes.func.isRequired,
    getText: PropTypes.func.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
    processCreditCard: PropTypes.func.isRequired,
    refreshData: PropTypes.func.isRequired,
    selectedCreditCard: PropTypes.object,
    setErrorMessage: PropTypes.func.isRequired,
    setReadytoUseCreditcard: PropTypes.func.isRequired,
    setSelectedCreditCard: PropTypes.func.isRequired,
    setViewMode: PropTypes.func.isRequired,
    triggerAnalyticsErrors: PropTypes.func.isRequired,
    viewMode: PropTypes.oneOf([CREATE, DEFAULT, EDIT, SELECT]).isRequired
};

export default wrapComponent(PaymentSectionContent, 'PaymentSectionContent', true);
