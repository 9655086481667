export default function getResource(label, vars = []) {
    const resources = {
        addNewCard: 'Ajouter une nouvelle carte',
        cancel: 'Annuler',
        completeBooking: 'Compléter la réservation',
        creditCardRequiredInfo: 'Une carte de crédit est requise pour garantir cette réservation. Vous pouvez échanger une carte-cadeau en magasin au moment du paiement.',
        placingOder: 'En passant cette commande, je reconnais la politique de paiement ci-dessous.',
        cvc: 'CVV/CVC',
        default: 'Carte par défaut',
        deleteCreditCardDialogMessage: 'Êtes-vous sûr de vouloir supprimer cette carte de façon définitive?',
        deleteCreditCardDialogOkButtonText: 'Retirer',
        deleteCreditCardDialogTitle: 'Supprimer la carte de crédit',
        paymentGenericError: 'Nous n’avons pas été en mesure de traiter votre carte de crédit. Veuillez vérifier les renseignements relatifs à la carte et essayer à nouveau, ou utilisez une autre carte.',
        edit: 'Modifier',
        expiredCreditCardMsg: 'Cette carte est expirée. Veuillez le mettre à jour ou utiliser une autre carte.',
        expires: `Expire le : ${vars[0]}/${vars[1]}`,
        minutes: 'Min',
        modalTitle: 'Détails de la réservation',
        paymentMethod: 'Mode de paiement',
        remove: 'Retirer',
        saveAndContinue: 'Enregistrer et continuer',
        updateSecurityCode: 'Veuillez saisir votre code de sécurité.',
        virtual: 'Services virtuels',
        endingIn: 'se terminant par',
        gotIt: 'Compris'
    };

    return resources[label];
}
