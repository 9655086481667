export default function getResource(label) {
    const resources = {
        openingAccountTitle: '4. Ouverture de compte',
        importantInfoText: '*RENSEIGNEMENTS IMPORTANTS À PROPOS DE L’OUVERTURE DE COMPTE :* Pour aider le gouvernement à lutter contre le financement du terrorisme et le blanchiment d’argent, la loi fédérale exige que toutes les institutions financières recueillent, vérifient et consignent des renseignements permettant d’identifier toute personne qui ouvre un compte. Ce que cela signifie pour vous : Lorsque vous ouvrirez un compte, nous vous demanderons de nous fournir votre nom, votre adresse, votre date de naissance et tout autre renseignement qui nous permettra de vous identifier. Il se peut également que nous demandions de voir votre permis de conduire ou une autre pièce d’identité.',
        caResidentsText: '*Résidents de la CA :* Si vous êtes marié, vous pouvez demander un compte distinct.',
        nyRiVtResidentsText: '*Résidents de NY, du RI et du VT :* Nous pouvons demander des rapports de solvabilité aux fins de traitement des demandes ou sollicitations et de toute mise à jour, tout renouvellement ou toute extension de crédit. Sur demande, nous vous indiquerons le nom et l’adresse de toute agence de renseignements sur le crédit à la consommation nous fournissant un rapport vous concernant. Vous consentez à l’obtention de tels rapports en signant ou en soumettant de quelque façon une demande ou une sollicitation.',
        ohResidentsText: '*Résidents de l’OH :* Les lois de l’Ohio contre la discrimination exigent que tous les créanciers rendent le crédit également disponible à tous les clients solvables et que les agences d’évaluation du crédit maintiennent, sur demande, des historiques de crédit distincts pour chaque personne. La commission sur les droits civils de l’Ohio se charge de la conformité avec cette loi.',
        wiResidentsText: '*Résidents de la WI :* Aucune disposition d’une convention de biens matrimoniaux, d’une déclaration unilatérale en vertu de l’article 766.59 ou d’une ordonnance du tribunal en vertu de l’article 766.70 ne peut avoir une incidence défavorable sur les intérêts de Comenity Capital Bank, à moins que Comenity Capital Bank obtienne une copie de la convention, déclaration ou ordonnance ou ait connaissance de cette incidence défavorable avant de contracter cette obligation.',
        nyResidentsText: '*Résidents de New York :* Comenity Capital Bank 1-866-412-5563 (TDD/ATS 1-888-819-1918). Les résidents de New York peuvent communiquer avec le New York State Department of Financial Services par téléphone ou visiter son site Web pour obtenir les renseignements gratuitement sur les taux des cartes de crédit, les frais et les délais de grâce comparés. New York State Department of Financial Services 1-800-518-8866 ou [www.dfs.ny.gov|https://www.dfs.ny.gov]'
    };

    return resources[label];
}
