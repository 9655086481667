export default function getResource(label, vars = []) {
    const resources = {
        modalTitle: 'Please sign in to Sephora',
        termsOfUseLink: 'Terms of Use',
        privacyPolicyLink: 'Privacy Policy',
        wantToSaveYourPoints: `Want to Save Your ${vars[0]} Points?`
    };

    return resources[label];
}
