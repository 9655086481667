const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
const UPDATE_SUBSCRIPTION_LIST = 'UPDATE_SUBSCRIPTION_LIST';
const UPDATE_ACTIVE_SUBSCRIPTIONS = 'UPDATE_ACTIVE_SUBSCRIPTIONS';
const UPDATE_PAUSED_SUBSCRIPTIONS = 'UPDATE_PAUSED_SUBSCRIPTIONS';
const UPDATE_CANCELLED_SUBSCRIPTIONS = 'UPDATE_CANCELLED_SUBSCRIPTIONS';
const UNSUBSCRIBE_AUTOREPLENISHMENT = 'UNSUBSCRIBE_AUTOREPLENISHMENT';
const LOAD_SHIPPING_AND_PAYMENT_INFO = 'LOAD_SHIPPING_AND_PAYMENT_INFO';
const LOAD_CREDIT_CARD_LIST = 'LOAD_CREDIT_CARD_LIST';
const UPDATE_AUTOREPLENISHMENT_SUBSCRIPTION = 'UPDATE_AUTOREPLENISHMENT_SUBSCRIPTION';
const CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS';
const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
const LOAD_CONTENT = 'LOAD_CONTENT';

export {
    UPDATE_SUBSCRIPTIONS,
    UPDATE_SUBSCRIPTION_LIST,
    UPDATE_ACTIVE_SUBSCRIPTIONS,
    UPDATE_PAUSED_SUBSCRIPTIONS,
    UPDATE_CANCELLED_SUBSCRIPTIONS,
    UNSUBSCRIBE_AUTOREPLENISHMENT,
    LOAD_SHIPPING_AND_PAYMENT_INFO,
    LOAD_CREDIT_CARD_LIST,
    UPDATE_AUTOREPLENISHMENT_SUBSCRIPTION,
    CLEAR_SUBSCRIPTIONS,
    LOAD_COUNTRIES,
    LOAD_CONTENT
};
