const TYPES = {
    UPDATE_EXPERIENCE: 'UPDATE_EXPERIENCE',
    UPDATE_EXPERIENCE_FOR_MODAL: 'UPDATE_EXPERIENCE_FOR_MODAL',
    SHOW_MORE_STORES_ON_MAP: 'SHOW_MORE_STORES_ON_MAP',
    OPEN_INFO_WINDOW: 'OPEN_INFO_WINDOW'
};

function updateExperience({ location, selectedDay }) {
    return {
        type: TYPES.UPDATE_EXPERIENCE,
        location,
        selectedDay
    };
}

function updateExperienceForModal({ location, selectedDay }) {
    return {
        type: TYPES.UPDATE_EXPERIENCE_FOR_MODAL,
        location,
        selectedDay
    };
}

function showMoreStoresOnMap(stores) {
    return {
        type: TYPES.SHOW_MORE_STORES_ON_MAP,
        stores: stores
    };
}

function openInfoWindow(storeId) {
    return {
        type: TYPES.OPEN_INFO_WINDOW,
        storeId
    };
}

export default {
    TYPES,
    updateExperience,
    updateExperienceForModal,
    showMoreStoresOnMap,
    openInfoWindow
};
