export default function getResource(label, vars=[]) {
    const resources = {
        changeCountry: 'Changer de pays',
        changeCountryMessage: `Pour sélectionner un magasin au ${vars[0]}, allez dans vos ${vars[1]} et changez votre pays pour ${vars[0]}.`,
        switchCountryBasketMessage: `Une fois le pays modifié, tous les articles dont la vente est restreinte au ${vars[0]} ou les articles Réservation et ramassage seront retirés de votre panier.`,
        bottomOfTheSite: 'au bas de la page',
        ok: 'Ok',
        joinUsUntil: 'Joignez-vous à nous jusqu’au'
    };

    return resources[label];
}
