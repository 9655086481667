import frameworkUtils from 'utils/framework';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { happeningUserDataSelector } from 'selectors/page/happening/happeningUserDataSelector';

const { wrapHOC } = frameworkUtils;

const fields = createSelector(happeningUserDataSelector, user => ({ user }));

const functions = {};

const withHappeningDetailsProps = wrapHOC(connect(fields, functions));

export {
    withHappeningDetailsProps, fields, functions
};
