export default function getResource(label, vars = []) {
    const resources = {
        title: 'Profitez de la livraison GRATUITE le jour même',
        trialContentStart: 'Commencez à économiser avec un',
        freeTrial: 'essai GRATUIT de 30 jours',
        sameDayUnlimited: 'Livraison le jour même illimitée',
        trialContentEnding: 'de la livraison le jour même illimitée de Sephora.',
        subscriptionContentStart: 'Commencez à économiser avec la livraison le jour même illimitée de Sephora. Abonnez-vous pour seulement ',
        annually: 'annuellement.',
        tryNowForFree: 'Essayez-la gratuitement'
    };

    return resources[label];
}
