import anaConsts from 'analytics/constants';
import processEvent from 'analytics/processEvent';

class storeSelectorModalBindings {
    static triggerAnalytics = (stockStatus = null, storeId = '', skuId = '') => {
        processEvent.process(anaConsts.LINK_TRACKING_EVENT, {
            data: {
                actionInfo: `${anaConsts.ACTION_INFO.SEPHORA_KOHLS_EXIT_LINK}:${stockStatus}`,
                storeId,
                ...(skuId.length && {
                    productStrings: `;${skuId};;;;eVar26=${skuId}`
                })
            }
        });
    };
}

export default storeSelectorModalBindings;
