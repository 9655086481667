import {
    Grid, Box, Flex, Image, Text, Link
} from 'components/ui';
import CreditCardUtils from 'utils/CreditCard';
import localeUtils from 'utils/LanguageLocale';
import OrderUtils from 'utils/Order';
import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import viewModes from 'components/GlobalModals/PaidReservationModal/PaymentSection/viewModes';

const { CREATE, DEFAULT, EDIT, SELECT } = viewModes;

const CreditCard = ({
    creditCard, onCreditCardDelete, setCreditCardToEdit, setErrorMessage, setViewMode, viewMode
}) => {
    if (!creditCard) {
        return null;
    }

    const logoFileName = OrderUtils.getThirdPartyCreditCard(creditCard) || 'placeholder';
    const {
        cardNumber, cardType, creditCardId, expirationMonth, expirationYear, isDefault
    } = creditCard;
    const getText = localeUtils.getLocaleResourceFile('components/GlobalModals/PaidReservationModal/locales', 'PaidReservationModal');
    const showTextPipe = isDefault && expirationMonth && expirationYear;

    return (
        <Grid
            lineHeight='tight'
            alignItems='center'
            gap={[4, 3]}
            columns={viewMode === SELECT && [null, '1fr auto']}
        >
            <Flex alignItems='center'>
                <Image
                    height={32}
                    width={50}
                    marginRight={3}
                    src={`/img/ufe/payments/${logoFileName}.svg`}
                />
                <div>
                    <strong data-at={Sephora.debug.dataAt('card_ending_in')}>
                        {`${CreditCardUtils.getCardName(cardType)} ${getText('endingIn')} ${CreditCardUtils.shortenCardNumber(cardNumber)}`}
                    </strong>
                    <div>
                        {isDefault && <span data-at={Sephora.debug.dataAt('card_state')}>{getText('default')}</span>}
                        {showTextPipe && (
                            <Text
                                marginX={2}
                                children='|'
                            />
                        )}
                        {expirationMonth && expirationYear ? (
                            <span data-at={Sephora.debug.dataAt('card_expires')}>{getText('expires', [expirationMonth, expirationYear])}</span>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </Flex>
            {viewMode === SELECT && CreditCardUtils.tokenMigrationDisabledOrSucceed(creditCard) && (
                <Flex>
                    <Link
                        padding={2}
                        margin={-2}
                        color='blue'
                        onClick={event => {
                            event.stopPropagation();

                            if (onCreditCardDelete) {
                                onCreditCardDelete(creditCardId);
                            }
                        }}
                        data-at={Sephora.debug.dataAt('payment_edit_remove_btn')}
                        children={getText('remove')}
                    />
                    <Box
                        backgroundColor='lightGray'
                        marginX={3}
                        width='1px'
                    />
                    <Link
                        padding={2}
                        margin={-2}
                        color='blue'
                        onClick={event => {
                            event.stopPropagation();
                            setErrorMessage(null);
                            setViewMode(EDIT);
                            setCreditCardToEdit(creditCard);
                        }}
                        data-at={Sephora.debug.dataAt('payment_edit_edit_btn')}
                        children={getText('edit')}
                    />
                </Flex>
            )}
        </Grid>
    );
};

CreditCard.defaultProps = {
    creditCard: null,
    onCreditCardDelete: null,
    setCreditCardToEdit: null,
    setViewMode: null,
    viewMode: DEFAULT
};
CreditCard.propTypes = {
    creditCard: PropTypes.shape({
        cardNumber: PropTypes.string.isRequired,
        cardType: PropTypes.string.isRequired,
        creditCardId: PropTypes.string.isRequired,
        expirationMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        expirationYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }),
    onCreditCardDelete: PropTypes.func,
    setCreditCardToEdit: PropTypes.func,
    setErrorMessage: PropTypes.func.isRequired,
    setViewMode: PropTypes.func,
    viewMode: PropTypes.oneOf([CREATE, DEFAULT, EDIT, SELECT])
};

export default wrapFunctionalComponent(CreditCard, 'CreditCard');
