export default function getResource(label, vars = []) {
    const resources = {
        location: 'Emplacement',
        getDirections: 'Obtenir un itinéraire',
        callStore: 'Appeler le magasin',
        closed: 'Fermé',
        away: 'absent',
        hours: 'Heures',
        openUntil: 'Ouvert aujourd’hui jusqu’à',
        monday: 'Lun',
        tuesday: 'Mar',
        wednesday: 'Mer',
        thursday: 'Jeu',
        friday: 'Ven',
        saturday: 'Sam',
        sunday: 'Dim'

    };

    return resources[label];
}
