export default function getResource(label, vars = []) {
    const resources = {
        loves: 'Loves',
        signInButton: 'Sign In',
        createAccountButton: 'Create Account',
        recentlyLoved: 'Recently Loved',
        viewAll: 'View all',
        shopNow: 'Shop Now',
        itemShip: 'This item cannot be shipped to',
        noLovesDesc: 'Use your Loves list to keep track of your favorite products.',
        getTheseBeforeTheyAreGone: 'Get These Before They’re Gone',
        onSaleNow: 'On Sale Now',
        lookingForFavBrands: 'Looking for your Favorite Brands?',
        goToBeautyPrefs: 'Go to Beauty Preferences',
        item: 'item',
        items: 'items'
    };
    return resources[label];
}
