import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Remove+BI+Reward+from+Basket+API

function removeBiRewardFromBasket(orderId, skuId, productId) {
    let url = '/api/bi/orders/' + orderId + '/rewards/' + skuId;

    const queryParams = [];

    queryParams.push('includeBasket=true');

    if (productId) {
        queryParams.push(`productId=${productId}`);
    }

    if (queryParams.length > 0) {
        url = url + '?' + queryParams.join('&');
    }

    return ufeApi.makeRequest(url, { method: 'DELETE' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default removeBiRewardFromBasket;
