const EXPERIENCE_TYPE = {
    CURRENT: 'current',
    NEW: 'new'
};

const COLOR_SWATCH_DISPLAY = {
    CURRENT: 'current',
    SHOW_HYBRID: 'showHybrid'
};

const NEXT_DAY_DELIVERY_MESSAGE = {
    ORDER_NOW: 'Order now, get it tomorrow',
    ORDER_TODAY: 'Order today'
};

const ORDER_TYPE = {
    STANDARD: 'standard',
    BOPIS: 'bopis',
    SDD: 'sdd'
};

const SHOW_ML_DRIVEN_PROMOS = {
    CURRENT: 'hidePromos',
    NEW: 'showPromos'
};

const ML_VS_CONSTRUCTOR = {
    ML: 'ml',
    CONSTRUCTOR: 'constructor'
};

const SHOW_ALT_IMAGE_ON_PRODUCT_TILES = {
    CONTROL: 'control',
    CHALLENGER_ONE: 'challengerOne'
};

const SHOW_FORCED_SIGN_IN_TOOLTIP = {
    CONTROL: 'control',
    CHALLENGER_ONE: 'challengerOne',
    CHALLENGER_TWO: 'challengerTwo'
};

const SHOW_BASKET_CC_BANNER_8_PERCENT_BACK = {
    CONTROL: 'control',
    CHALLENGER_ONE: 'challengerOne',
    CHALLENGER_TWO: 'challengerTwo'
};

const FILTERS_SIDEBAR_WIDTH = {
    CONTROL: 208,
    CHALLENGER: 236
};

const SHOW_PRODUCT_THUMBNAILS_CHECKOUT = {
    CONTROL: 'control',
    CHALLENGER_ONE: 'challengerOne',
    CHALLENGER_TWO: 'challengerTwo'
};

const HOMEPAGE_UGC_WIDGET_EXPERIENCE = {
    CHALLENGER_ONE: 'challengerOne',
    CHALLENGER_TWO: 'challengerTwo'
};

export {
    EXPERIENCE_TYPE,
    COLOR_SWATCH_DISPLAY,
    NEXT_DAY_DELIVERY_MESSAGE,
    ORDER_TYPE,
    SHOW_ML_DRIVEN_PROMOS,
    ML_VS_CONSTRUCTOR,
    SHOW_ALT_IMAGE_ON_PRODUCT_TILES,
    SHOW_FORCED_SIGN_IN_TOOLTIP,
    SHOW_BASKET_CC_BANNER_8_PERCENT_BACK,
    FILTERS_SIDEBAR_WIDTH,
    SHOW_PRODUCT_THUMBNAILS_CHECKOUT,
    HOMEPAGE_UGC_WIDGET_EXPERIENCE
};
