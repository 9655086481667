export default function getResource(label, vars = []) {
    const resources = {
        moreInformation: 'More information',
        ccRewards: 'Credit Card Rewards Earned',
        yearEarnings: `Your ${vars[0]} earnings will appear here.`,
        keepShopping: 'Keep shopping to get rewards, benefits, and discounts!',
        pointsEarned: 'Points Earned',
        basePoints: 'Base Points',
        bonusPoints: 'Bonus Points',
        dollarsSaved: 'Promos & Discount Applied',
        rougeRcDollar: 'Rouge Rewards Earned',
        cashApplied: 'Beauty Insider Cash Applied',
        shopNow: 'Shop Now',
        referralPtsEarned: 'Referral Points Earned',
        points: 'points',
        yearGlance: `${vars[0]} at a Glance`
    };

    return resources[label];
}
