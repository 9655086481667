import ufeApi from 'services/api/ufeApi';
import urlUtils from 'utils/Url';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+Purchase+History

function getPurchaseHistory(userProfileId, options) {
    let url = `/api/bi/profiles/${userProfileId}/purchases`;

    if (options) {
        const {
            sortBy, itemsPerPage, currentPage, groupBy, excludeSamples, purchaseFilter, excludeRewards, includeNonBiUserPurchases
        } = options;

        const qsParams = {
            sortBy,
            itemsPerPage,
            currentPage,
            groupBy,
            excludeSamples,
            purchaseFilter,
            excludeRewards,
            includeNonBiUserPurchases
        };

        const qs = urlUtils.makeQueryString(qsParams);

        if (qs) {
            url += '?' + qs;
        }
    }

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getPurchaseHistory;
