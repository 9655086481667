/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { Box, Text } from 'components/ui';
import localeUtils from 'utils/LanguageLocale';
import uiUtils from 'utils/UI';
import RMN_BANNER_TYPES from 'components/Rmn/constants';
import sponsoredProducts from 'services/api/sponsoredProducts/sponsoredProducts';
import cookieUtils from 'utils/Cookies';
import productUtils from 'utils/product';
import { PostLoad } from 'constants/events';
import { mountBannerEventData } from 'analytics/utils/eventName';
import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';
import Location from 'utils/Location';
import RmnUtils from 'utils/rmn';

const {
    getLocaleResourceFile, isCanada, isFrench, isFRCanada, LANGUAGES
} = localeUtils;
const { SKELETON_ANIMATION } = uiUtils;
const { TYPES } = RMN_BANNER_TYPES;
const getText = getLocaleResourceFile('components/Rmn/locales', 'RmnBanner');

let Events;

const PLACEHOLDER_IMG = '/img/ufe/placeholder_grey.svg'; // to prevent an LCP issue UA-1407

class RmnBanner extends BaseClass {
    state = {
        bannerData: null,
        showSkeleton: true
    };
    displayRef = React.createRef();

    componentDidUpdate(prevProps) {
        const {
            contextId, isSearchPage, node, keyword, onUpdate
        } = this.props;

        if (Sephora.configurationSettings.RMNEnableDisplay) {
            if (isSearchPage) {
                if ((node || keyword) && (prevProps.keyword !== keyword || prevProps.node !== node)) {
                    this.setState({ bannerData: null, showSkeleton: true }, this.handleResize);
                    onUpdate?.();
                }
            } else if (prevProps.contextId !== contextId) {
                this.setState({ bannerData: null, showSkeleton: true }, this.handleResize);
                onUpdate?.();
            }
        }
    }

    componentDidMount() {
        if (Sephora.configurationSettings.RMNEnableDisplay) {
            this.handleResize();
            Events = require('utils/framework/Events').default;
            window.addEventListener(Events.DebouncedResize, this.handleResize);
        }
    }

    componentWillUnmount() {
        if (Sephora.configurationSettings.RMNEnableDisplay) {
            window.removeEventListener(Events.DebouncedResize, this.handleResize);
        }
    }

    setFallback = () => {
        const { type } = this.props;
        const width = TYPES[type].WIDTH;
        const height = TYPES[type].HEIGHT;
        const canada = isCanada();
        const french = isFrench();
        const canadaFrench = isFRCanada();
        const imageFile = canadaFrench ? 'jpg' : 'png';
        const bannerType = canadaFrench ? '_FR' : '';
        const asset_url = `/img/ufe/rmn-fallback-${width}x${height}${bannerType}.${imageFile}`;
        const canadianLanguage = french ? LANGUAGES.FR : LANGUAGES.EN;
        const urlprefix = canada ? `/ca/${canadianLanguage.toLowerCase()}` : '';
        const clickthru = `${urlprefix}/beauty/giftcards`;

        this.setState({
            bannerData: {
                clickthru,
                asset_url
            },
            showSkeleton: false
        });
    };

    handleFallback = () => {
        const { hasFallback } = this.props;

        if (hasFallback) {
            this.setFallback();
        } else {
            this.setState({ showSkeleton: false, bannerData: {} });
        }
    };

    handleResize = () => {
        const {
            type, slot, targets, handleRmnBanner, section
        } = this.props;
        const { bannerData } = this.state;

        // check for visibility
        if (this.displayRef.current?.offsetParent !== null && !bannerData) {
            const requestParams = {
                width: TYPES[type].WIDTH,
                height: TYPES[type].HEIGHT,
                targets,
                slot,
                count: 1,
                count_fill: 1
            };

            if (cookieUtils.read(cookieUtils.KEYS.SEPH_SESSION)) {
                sponsoredProducts(requestParams)
                    .then(response => {
                        if (response.responseStatus === 200 && response[0]) {
                            this.setState({
                                bannerData: response[0],
                                showSkeleton: false
                            });

                            // Adding sponsored products information on the digitalData object
                            const sponsorBannerProduct = response[0] || {};

                            // Adds the props passed by the parent component
                            const sponsorBannerProductWithComponentInfo = {
                                ...sponsorBannerProduct,
                                slot,
                                type,
                                section
                            };

                            const bannerTrackingInfo = this.getBannerTrackingInfo(sponsorBannerProductWithComponentInfo);

                            if (handleRmnBanner) {
                                // Extracts the tracker information and sets it on the digitalData object
                                handleRmnBanner(bannerTrackingInfo);
                            }

                            // Fires the Viewable Impression (checks if the component should fire it).
                            // The viewable impression is fired here to make sure the product is setup within the
                            // digitaData object for SOT.
                            if (this.checkTrackingShouldFire()) {
                                this.fireViewableImpressionEvent();
                            }
                        } else {
                            this.handleFallback();
                        }
                    })
                    .catch(() => {
                        this.handleFallback();
                    });
            } else {
                this.handleFallback();
            }
        }
    };

    // This is the information used in the Page View event. SOT extracts the information directly from the digitalData
    getBannerTrackingInfo = sponsorBannerProduct => {
        // Extracts the click tracking info from the RMN sponsor product
        const clickTrackerInfo = productUtils.getClickTrackerInformation(sponsorBannerProduct);
        const { slot, section, type } = this.props;
        const sponsorBannerProductData = {
            ...sponsorBannerProduct,
            ...clickTrackerInfo
        };

        // Builds the tracking information object
        return {
            slot,
            section,
            type,
            skuId: '',
            clickTrackerId: sponsorBannerProductData?.clickTrackerId || '',
            impressionTrackerId: sponsorBannerProductData?.impression_id || '',
            impressionPayload: sponsorBannerProductData?.impression_payload || '',
            clickPayload: sponsorBannerProductData?.clickPayload || ''
        };
    };

    // This is the tracking information that is sent when events like click and viewable impression are fired
    getBannerEventTrackinfo = bannerData => {
        const bannerTrackingData = this.getBannerTrackingInfo(bannerData);

        return {
            sku: '',
            sponsored: true,
            clickTrackerId: bannerTrackingData?.clickTrackerId || '',
            impressionTrackerId: bannerTrackingData?.impressionTrackerId || '',
            impressionPayload: bannerTrackingData?.impressionPayload || '',
            clickPayload: bannerTrackingData?.clickPayload || '',
            isSponsoredProduct: true
        };
    };

    // Verifies if tracking information should be added. When its mobile or desktop
    checkTrackingShouldFire = () => {
        const { type } = this.props;
        const isMobile = Sephora.isMobile();
        let addBannerTrackingInfo = true;

        // Checks if the desktop version is showing to add the right tracking data
        if (!isMobile && type === TYPES.MOBILE_LEADERBOARD.NAME) {
            addBannerTrackingInfo = false;
        }

        // Checks if the mobile version is showing to add the right tracking data
        if (isMobile && type !== TYPES.MOBILE_LEADERBOARD.NAME) {
            addBannerTrackingInfo = false;
        }

        return addBannerTrackingInfo;
    };

    handleEventName = type => {
        const { section } = this.props;

        const sotEvent = mountBannerEventData({
            section,
            type
        });

        return sotEvent;
    };

    handleFallbackClickthru = event => {
        const { bannerData } = this.state;
        event.preventDefault();
        window.location.href = bannerData.clickthru;
    };

    // Called when the user clicks on the banner
    handleClick = event => {
        // Handle Viewable Impression Code.
        const { source, slot, section, contextId } = this.props;
        const { bannerData } = this.state;

        digitalData.page.attributes.previousPageData.pageType = source;
        // reset internalCampaign so `getInternalCampaign` in generalBindings will set it
        // to the icid2 queryParam in the pPage URL
        digitalData.page.attributes.previousPageData.internalCampaign = '';

        Events = require('utils/framework/Events').default;

        // Waits until the page is completely loaded to fire the event for Signal/TMS.
        Events.onLastLoadEvent(window, [PostLoad], () => {
            const trackingInformation = this.getBannerEventTrackinfo(bannerData);
            let sotType;

            switch (contextId) {
                case RmnUtils.RMN_SOURCES.HOMEPAGE:
                    sotType = anaConsts.EVENT_NAMES.PLA_HOME_SPONSORED_BANNER_CLICK;

                    break;
                default:
                    sotType = this.handleEventName(anaConsts.EVENTS_TYPES_NAME.CLICK);
            }

            // Verifies the sponsored product information is available and that the product is a sponsored one.
            if (trackingInformation && trackingInformation.isSponsoredProduct) {
                processEvent.process(
                    anaConsts.SOT_LINK_TRACKING_EVENT,
                    {
                        data: {
                            linkName: sotType,
                            actionInfo: sotType,
                            specificEventName: sotType,
                            sponsoredProductInformation: trackingInformation,
                            eventStrings: anaConsts.Event.EVENT_254,
                            internalCampaign: anaConsts.CAMPAIGN_STRINGS.RMN_BANNER,
                            productStrings: `;non-product click;;;${anaConsts.Event.EVENT_254};eVar124=[${slot}]:[${section}]:[${bannerData.campaign_id}]`
                        }
                    },
                    { specificEventName: this.handleEventName(anaConsts.EVENTS_TYPES_NAME.CLICK) }
                );
            }

            // This code makes sure the tracking event for the click action is fired before navigating to the banner url
            Location.navigateTo(event, bannerData.clickthru);
        });
    };

    // Fired from componentDidMount, this method dispatches the event that indicates the banner has been shown to the client
    fireViewableImpressionEvent = () => {
        Events = require('utils/framework/Events').default;
        const { contextId } = this.props;

        // Waits until the page is completely loaded to fire the event for Signal/TMS.
        Events.onLastLoadEvent(window, [PostLoad], () => {
            const { bannerData } = this.state;

            // Extras the viewable tracking information from the sponsored product
            const {
                sku, sponsored, impressionTrackerId, impressionPayload, isSponsoredProduct
            } = this.getBannerEventTrackinfo(bannerData);

            let sotType;

            switch (contextId) {
                case RmnUtils.RMN_SOURCES.HOMEPAGE:
                    sotType = anaConsts.EVENT_NAMES.PLA_HOME_SPONSORED_BANNER_VIEWABLE_IMPR;

                    break;
                default:
                    sotType = this.handleEventName(anaConsts.EVENTS_TYPES_NAME.VIEW);
            }

            // When firing the viewable impression, only the impression tracking id and payload are required
            const trackingInformation = {
                sku,
                sponsored,
                impressionTrackerId,
                impressionPayload,
                isSponsoredProduct
            };

            // Verifies the sponsored product information is available and that the product is a sponsored one.
            if (trackingInformation && trackingInformation.isSponsoredProduct) {
                processEvent.process(
                    anaConsts.SOT_LINK_TRACKING_EVENT,
                    {
                        data: {
                            linkName: sotType,
                            actionInfo: sotType,
                            specificEventName: sotType,
                            sponsoredProductInformation: trackingInformation
                        }
                    },
                    { specificEventName: sotType }
                );
            }
        });
    };

    render() {
        const { type } = this.props;
        const { bannerData, showSkeleton } = this.state;
        const hasBannerData = bannerData && Object.keys(bannerData).length !== 0;

        if (!Sephora.configurationSettings.RMNEnableDisplay) {
            return null;
        }

        return hasBannerData || showSkeleton ? (
            <Box
                display={TYPES[type].DISPLAY}
                marginX={this.props.isCentered && 'auto'}
                marginTop={this.props.marginTop}
                marginBottom={this.props.marginBottom}
                width='100%'
                maxWidth={TYPES[type].WIDTH}
            >
                <div
                    ref={this.displayRef}
                    css={[
                        {
                            position: 'relative',
                            paddingBottom: `${Math.round((TYPES[type].HEIGHT / TYPES[type].WIDTH) * 100)}%`
                        },
                        !hasBannerData && SKELETON_ANIMATION
                    ]}
                >
                    <a
                        onClick={hasBannerData ? this.handleClick : undefined}
                        css={styles.anchor}
                    >
                        <img
                            src={hasBannerData ? bannerData.asset_url : PLACEHOLDER_IMG}
                            width={TYPES[type].WIDTH}
                            height={TYPES[type].HEIGHT}
                            css={styles.img}
                            alt={getText('alt')}
                        />
                    </a>
                </div>
                <Text
                    is='p'
                    color='gray'
                    fontSize='sm'
                    lineHeight='tight'
                    marginTop={2}
                    children={getText('sponsored')}
                    style={!hasBannerData ? { visibility: 'hidden' } : null}
                />
            </Box>
        ) : null;
    }
}

const styles = {
    anchor: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    },
    img: {
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        height: '100%'
    }
};

RmnBanner.propTypes = {
    type: PropTypes.oneOf([TYPES.SUPER_LEADERBOARD.NAME, TYPES.MOBILE_LEADERBOARD.NAME, TYPES.WIDE_SIDESCRAPER.NAME]).isRequired,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
    isCentered: PropTypes.bool,
    hasFallback: PropTypes.bool,
    showSkeleton: PropTypes.bool,
    isSearchPage: PropTypes.bool,
    node: PropTypes.string,
    keyword: PropTypes.string
};

RmnBanner.defaultProps = {
    marginTop: null,
    marginBottom: null,
    isCentered: false,
    hasFallback: false,
    showSkeleton: false
};

export default wrapComponent(RmnBanner, 'RmnBanner', true);
