export default function getResource(label, vars = []) {
    const resources = {
        freeSamples: 'Free Samples',
        freeSamplesCTA: `Get ${vars[0]} free sample(s) with every order`,
        samplesAddedText: ` sample${vars[0]} added`,
        done: 'Done'
    };

    return resources[label];
}
