export default function getResource(label, vars=[]) {
    const resources = {
        changeCountry: 'Change Country',
        changeCountryMessage: `In order to search for stores in ${vars[0]}, please go to the ${vars[1]} and change your country to ${vars[0]}.`,
        switchCountryBasketMessage: `Once you switch, any ${vars[0]}-restricted and/or Reserve & Pickup items will be removed from your basket.`,
        bottomOfTheSite: 'bottom of the site',
        ok: 'Ok',
        joinUsUntil: 'Join us until'
    };

    return resources[label];
}
