export default function getResource(label, vars = []) {
    const resources = {
        services: 'Services',
        classes: 'Classes',
        events: 'Events',
        whatsNew: 'What’s new',
        allStores: 'All Stores',
        bannerText: 'Services, classes, can’t-miss events & more—see what’s going on in store near you.',
        defaultDate: 'Next 2 weeks',
        cityStateZip: 'City & State or ZIP',
        store: 'Store',
        date: 'Date',
        storePickerDescription: 'Updating the location will automatically update the experiences that are displayed.'
    };
    return resources[label];
}
