export default function getResource(label, vars = []) {
    const resources = {
        personalInfo: 'Personal Information',
        edit: 'Edit',
        phone: 'Phone Number',
        sendMeTexts: 'Yes, send me text message reminders about my appointment.',
        specialRequests: 'Special Request (optional)',
        specialRequestsPlaceholder: 'Our Beauty Advisors will do their best to accommodate your concerns or requests (e.g., type of look, occasion, favorite brands or products)',
        firstName: 'First Name',
        lastName: 'Last Name',
        emailAddress: 'Email Address',
        saveAndContinue: 'Save & Continue'
    };

    return resources[label];
}
