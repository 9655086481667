export default function getResource(label, vars = []) {
    const resources = {
        youNowHaveText: 'You now have ',
        youAreExceeding: 'You are exceeding by',
        beautyInsiderPointsText: ' Beauty Insider points',
        earnText: 'and earn ',
        todayText: ' today',
        redeemYourRewardsText: 'Redeem your rewards',
        redeemYourRewardsLowerCaseText: 'redeem your rewards',
        joinBeautyInsiderText: 'Join Beauty Insider',
        joinText: 'Join',
        beautyInsiderText: 'Beauty Insider',
        signInText: 'Sign in',
        toSeeYourBeautyInsiderPointsText: 'to see your Beauty Insider points',
        pointsRemainingText: ' points remaining',
        viewAllRewardsText: 'View all rewards',
        pointsText: 'points'
    };

    return resources[label];
}
