const EVENT_BUTTONS = {
    UPCOMING: ['addToCal', 'cancelRsvp'],
    CANCELLED: ['rsvp']
};

const SERVICE_BUTTONS = {
    UPCOMING: ['addToCal', 'reschedule', 'cancel'],
    PAST: ['bookAgain', 'productRecs'],
    IS_DAY_AFTER: ['bookAgain', 'productRecs'],
    CANCELLED: ['bookAgain']
};

const MY_RESERVATIONS_SERVICE_BUTTONS = {
    UPCOMING: ['reschedule', 'cancel'],
    PAST: ['bookAgain', 'productRecs'],
    IS_DAY_AFTER: ['bookAgain', 'productRecs'],
    CANCELLED: ['bookAgain']
};

const MY_RESERVATIONS_EVENT_BUTTONS = {
    UPCOMING: ['cancelRsvp'],
    CANCELLED: ['rsvp']
};

const HOURS_BEFORE_START_TIME = 24;
const SERVICES_FAQ_URL = '/happening/services-faq?icid2=happening_services_faq_link_external';
const BEAUTY_SERVICES_FAQ_URL = '/beauty/beauty-services-faq?icid2=customer_service_beauty_services_faqs_action';

export {
    EVENT_BUTTONS,
    SERVICE_BUTTONS,
    HOURS_BEFORE_START_TIME,
    SERVICES_FAQ_URL,
    BEAUTY_SERVICES_FAQ_URL,
    MY_RESERVATIONS_SERVICE_BUTTONS,
    MY_RESERVATIONS_EVENT_BUTTONS
};
