import store from 'Store';
import Storage from 'utils/localStorage/Storage';
import userUtils from 'utils/User';
import jsUtils from 'utils/javascript';

// prettier-ignore
const INITIAL_DATA_BRAZE = {
    birth: '',
    brazeEmail: '',
    'current_points': 0,
    'user_id': '',
    'ytd_spend_amount': 0
};

// If we haven't already cached the data, cache it
if (Storage.session.getItem && Storage.session.getItem('lastDataForBraze') === null) {
    Storage.session.setItem('lastDataForBraze', JSON.stringify(INITIAL_DATA_BRAZE));
}

const isNewData = function (key, value) {
    const cachedData = Storage.session.getItem('lastDataForBraze');

    if (!cachedData) {
        // If no data is cached, we need to initialize the cache again
        Storage.session.setItem('lastDataForBraze', JSON.stringify(INITIAL_DATA_BRAZE));

        return false;
    } else {
        const jsonCachedData = JSON.parse(cachedData);

        if (jsonCachedData[key] !== value) {
            return true;
        }

        return false;
    }
};

// Sets only updated data in storage so redundant values are not sent to Braze
const setNewData = function (key, value) {
    if (isNewData(key, value)) {
        const cachedData = Storage.session.getItem('lastDataForBraze');
        const jsonCachedData = JSON.parse(cachedData);
        jsonCachedData[key] = value;
        const stringCachedData = JSON.stringify(jsonCachedData);
        Storage.session.setItem('lastDataForBraze', stringCachedData);

        return true;
    }

    return false;
};

const sendRegistrationData = function () {
    if (global.braze) {
        const appboyUser = braze.getUser();
        const { ccCardType, beautyInsiderAccount, profileId, bankRewards = {} } = store.getState().user;

        if (profileId) {
            braze.changeUser(profileId);
        }

        if (beautyInsiderAccount !== undefined) {
            const { email, promotionPoints = 0, vibSpendingForYear = 0, ccAccountandPrescreenInfo = {} } = beautyInsiderAccount;

            if (!userUtils.isDefaultBIBirthDay(beautyInsiderAccount)) {
                const { birthYear, birthMonth, birthDay } = beautyInsiderAccount;

                if (setNewData('birth', birthYear + birthMonth + birthDay)) {
                    appboyUser.setDateOfBirth(parseInt(birthYear), parseInt(birthMonth), parseInt(birthDay));
                }
            }

            if (setNewData('brazeEmail', email)) {
                appboyUser.setEmail(email);
            }

            appboyUser.setCustomUserAttribute('current_points', promotionPoints);

            if (!jsUtils.isObjectEmpty(ccAccountandPrescreenInfo)) {
                const { ccApprovalStatus, preScreenStatus } = ccAccountandPrescreenInfo;
                const prescreenedCardType = ccApprovalStatus === 'APPROVED' ? ccCardType : ccAccountandPrescreenInfo.prescreenedCardType;

                if (prescreenedCardType && setNewData('cc_type', prescreenedCardType)) {
                    appboyUser.setCustomUserAttribute('cc_type', prescreenedCardType);
                }

                if (setNewData('cc_approvalStatus', ccApprovalStatus)) {
                    appboyUser.setCustomUserAttribute('cc_approvalStatus', ccApprovalStatus);
                }

                if (setNewData('cc_preScreenStatus', preScreenStatus)) {
                    appboyUser.setCustomUserAttribute('cc_preScreenStatus', preScreenStatus);
                }
            }

            if (!jsUtils.isObjectEmpty(bankRewards)) {
                const ccRewardStatus = bankRewards.ccRewardStatus === 'CreditCard_REWARDS_ALREADY_REDEEMED' ? 'true' : 'false';

                if (setNewData('cc_rewardRedeemed', ccRewardStatus)) {
                    appboyUser.setCustomUserAttribute('cc_rewardRedeemed', ccRewardStatus);
                }
            } else {
                if (setNewData('cc_rewardRedeemed', 'false')) {
                    appboyUser.setCustomUserAttribute('cc_rewardRedeemed', 'false');
                }
            }

            appboyUser.setCustomUserAttribute('ytd_spend_amount', vibSpendingForYear);
        }
    }
};

export default {
    setNewData,
    sendRegistrationData
};
