export default function getResource(label, vars = []) {
    const resources = {
        needAssistanceText: 'Need assistance?',
        ttyText: 'TTY',
        freeShippingText: 'Free return shipping or return in store',
        weAcceptText: 'We accept'
    };

    return resources[label];
}
