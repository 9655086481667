import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import { Box, Flex } from 'components/ui';
import Radio from 'components/Inputs/Radio/Radio';

function NotSureOrPreference({
    profileCategory, setBeautyPreferencesState, selectedOption, selectedOptionName, isSelected
}) {
    return (
        <Box
            key={selectedOptionName}
            borderWidth='1px'
            borderStyle='solid'
            borderColor={isSelected ? 'black' : 'midGray'}
            borderRadius={2}
            padding={0}
            overflow='hidden'
            css={{ boxShadow: isSelected ? 'inset 0px 0px 0px 1px black' : 'none' }}
        >
            <Radio
                hasHover={false}
                hasDot={false}
                margin='0 auto'
                paddingY={null}
                name={selectedOption}
                height='100%'
                width='100%'
                onClick={() => setBeautyPreferencesState(profileCategory.type, profileCategory.singleSelect, selectedOption)}
            >
                <Flex
                    height='4em'
                    alignItems='center'
                    justifyContent='center'
                >
                    {selectedOptionName}
                </Flex>
            </Radio>
        </Box>
    );
}

NotSureOrPreference.propTypes = {
    profileCategory: PropTypes.object.isRequired,
    setBeautyPreferencesState: PropTypes.func.isRequired
};

export default wrapFunctionalComponent(NotSureOrPreference, 'NotSureOrPreference');
