import ufeApi from 'services/api/ufeApi';

/* eslint-disable max-len */
// API documentation: https://confluence.sephora.com/wiki/pages/viewpage.action?spaceKey=NM&title=Happening@Sephora+Phase+2+APIs+and+API+updates
/* eslint-enable max-len */

function eventsRSVP(token, params) {
    const {
        country, language, activityId, payload, channel = 'rwd'
    } = params;
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const locale = `${language.toLowerCase()}-${country}`;

    const url = `${sdnDomain}/v2/happening/events/${activityId}/reservation?locale=${locale}&channel=${channel}&country=${country}`;

    var options = {
        url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    };

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default eventsRSVP;
