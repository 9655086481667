import ufeApi from 'services/api/ufeApi';
import RCPSCookies from 'utils/RCPSCookies';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Update+Profile+API

function updateProfile(profileData) {
    let url = '/api/users/profile';
    const isUpdatingPassword = profileData.fragmentForUpdate === 'PASSWORD';

    if (RCPSCookies.isRCPSAccountAPIEnabled() && !isUpdatingPassword) {
        url = '/gway/v2/users/profiles';
    }

    return ufeApi
        .makeRequest(url, {
            method: 'PUT',
            body: JSON.stringify(profileData),
            headers: {
                'x-requested-source': 'web'
            }
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default updateProfile;
