import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+Shipping+Countries+List+API

function getShippingCountryList() {
    const url = '/api/util/shippingCountries';

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data.countries));
}

export default getShippingCountryList;
