export default function getResource(label, vars = []) {
    const resources = {
        happeningNearYou: 'Happening Near You',
        rsvpThisEvent: 'RSVP for This Event',
        map: 'Map',
        rsvp: 'RSVP',
        noTimesAvailable: 'No times available',
        addToCal: 'Add to Cal',
        showMoreTimesLocations: 'Show more times & locations',
        chooseTime: 'Choose a time',
        noAppointmentsAvailable1: 'Sorry, there are no appointments',
        noAppointmentsAvailable2: 'available right now.',
        tryDifferentExperience: 'Please try a different experience.',
        seeWhatsHappening: 'See what’s happening'
    };

    return resources[label];
}
