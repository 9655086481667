import {
    radii, colors, fontSizes, lineHeights, space, mediaQueries
} from 'style/config';
const SHADOW = '0 0 0 2px';

const styles = {
    listBox: {
        margin: `0 -${space[4]}px`,
        padding: `0 ${space[3]}px`,
        outline: 0,
        [mediaQueries.xsMax]: {
            whiteSpace: 'nowrap',
            overflow: 'auto'
        }
    },
    slot: {
        display: 'inline-block',
        outline: 0,
        minWidth: 79,
        maxWidth: 350,
        cursor: 'pointer',
        verticalAlign: 'top',
        margin: space[1],
        borderRadius: radii[2],
        transition: 'box-shadow .2s'
    },
    slotHover: {
        boxShadow: `${SHADOW} ${colors.midGray}`
    },
    slotSelected: {
        boxShadow: `${SHADOW} ${colors.black}`
    },
    slotOutline: {
        outline: `1px dashed ${colors.black}`,
        outlineOffset: 3
    },
    slotInner: {
        padding: `0 ${space[4]}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.tight,
        borderRadius: radii[2],
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
    },
    slotUnavailable: {
        textAlign: 'center',
        margin: space[1],
        fontSize: fontSizes.sm,
        fontWeight: 'var(--font-weight-bold)',
        textTransform: 'uppercase',
        backgroundColor: colors.lightGray,
        color: colors.gray,
        borderRadius: radii[2],
        padding: '1em 2em',
        [mediaQueries.sm]: {
            display: 'inline-block'
        }
    },
    waitlist: {
        display: 'block',
        fontWeight: 'var(--font-weight-normal)'
    }
};

export default styles;
