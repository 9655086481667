import ufeApi from 'services/api/ufeApi';
import cookieUtils from 'utils/Cookies';

const AKAMWEB = 'akamweb';

const sendServiceReservation = (token, {
    language, country, channel = 'rwd', activityId, payload
}) => {
    const locale = `${language.toLowerCase()}-${country}`;

    const url = `/gway/v2/happening/services/${activityId}/reservation?channel=${channel}&locale=${locale}&country=${country}`;

    const akamwebCookie = cookieUtils.read(AKAMWEB);

    return ufeApi.makeRequest(url, {
        method: 'POST',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...(akamwebCookie && { akamweb: akamwebCookie })
        },
        body: JSON.stringify(payload)
    });
};

export default sendServiceReservation;
