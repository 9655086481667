export default function getResource(label, vars = []) {
    const resources = {
        checkoutPickupItems: 'Checkout Pickup Items',
        checkoutShippedItems: 'Checkout Shipped Items',
        freeShippingModalTitle: 'Get FREE 2-day shipping',
        beautyInsiderPageText: 'Beauty Insider page',
        freeShippingModalBody2: 'to learn more.',
        freeShippingModalButtonText: 'Got it',
        merchandiseSubtotalText: 'Merchandise Subtotal',
        discountsText: 'Discounts',
        shippingAndHandlingText: 'Shipping & Handling',
        pickupText: 'Pickup',
        free: 'FREE',
        tbdText: 'TBD',
        gstHstText: 'Estimated GST/HST',
        taxText: 'Estimated Tax & Other Fees',
        estimatedTotalText: 'Estimated Total ',
        shippingAndTaxesText: 'Shipping & taxes calculated during checkout',
        shippingHandlingInfo: 'Shipping & Handling Information',
        salesTax: 'Sales Tax',
        item: 'item',
        items: 'items',
        pointsUsed: 'Used',
        accountCredit: 'Account Credit',
        bagFee: 'Bag Fee',
        specialFee: 'Special Fee',
        gotIt: 'Got It',
        continueToCheckout: 'Continue to Checkout',
        autoReplenishSavings: 'Auto-Replenish Savings',
        youSave: 'You save',
        sduSavingsUS: '$6.95',
        sduSavingsCA: '$9.95',
        withSDUUnlimited: 'with Same-Day Unlimited',
        checkout: 'Checkout'
    };

    return resources[label];
}
