export default function getResource(label, vars = []) {
    const resources = {
        sectionTitle: '5. Consentement électronique',
        electronicConsentTextWithCardType: `En signant ou en soumettant la présente demande ou sollicitation, chaque demandeur (\"Je\", \"moi\" or \"mon/ma\" ci-dessous) accepte et certifie que (1) j’ai lu et accepte la communication des renseignements fournis sur la demande ou la sollicitation, ou les accompagnant; (2) les renseignements que j’ai fournis sont véridiques et exacts; (3) je soumets une demande à Comenity Capital Bank, C.P. 183003, Columbus, OH 43218-3003 (\"Banque\") pour un compte de carte de crédit Visa Sephora ${vars[0]}; (4) j’autorise la Banque à obtenir des rapports sur ma solvabilité; (5) si ma demande est approuvée, mon compte sera régi par la présente convention de paiement de carte de crédit; (6) je comprends que je peux payer l’intégralité du solde de mon compte à tout moment, et ce, sans pénalité; et (7) cette demande/sollicitation, ainsi que toute information que je communique à la Banque, ainsi que la décision finale prise par la Banque relativement à ma demande/sollicitation, peuvent être communiquées à Sephora et conservées par Sephora.`,
        electronicConsentText: 'En signant ou en soumettant la présente demande ou sollicitation, chaque demandeur ("Je", "moi" or "mon/ma" ci-dessous) accepte et certifie que : (1) j’ai lu et accepte la communication des renseignements fournis sur la demande ou la sollicitation, ou les accompagnant; (2) les renseignements que j’ai fournis sont véridiques et exacts; (3) je soumets une demande à Comenity Capital Bank, C.P. 183003, Columbus, OH 43218-3003 ("Banque") pour un compte de carte de crédit Visa Sephora ou un compte de carte de crédit Sephora, et la Banque décidera quel compte, le cas échéant, me proposer; (4) j’autorise la Banque à obtenir des rapports sur ma solvabilité; (5) si ma demande est approuvée, mon compte sera régi par la présente convention de paiement de carte de crédit; (6) je comprends que je peux payer l’intégralité du solde de mon compte à tout moment, et ce, sans pénalité; et (7) cette demande/sollicitation, ainsi que toute information que je communique à la Banque, ainsi que la décision finale prise par la Banque relativement à ma demande/sollicitation, peuvent être communiquées à Sephora et conservées par Sephora.',
        mustReadText: `Vous devez lire les renseignements fournis dans la section Consentement électronique dans les boîtes ci-dessous avant de cocher la case Consentement. Veuillez également lire la convention relative à la carte de crédit, la déclaration de confidentialité et les autres renseignements présentés dans la boîte Modalités avant de soumettre la présente demande et ${vars[0]} pour vos dossiers.`,
        printCopy: 'imprimer une copie',
        checkboxCopyText: 'Je comprends qu’en cochant cette case et en cliquant sur \"Soumettre la demande\", j’accepte les modalités, j’accuse réception de l’avis de confidentialité, je consens à recevoir les documents par voie électronique et j’appose ma signature électronique sur la présente demande/sollicitation.',
        consetToAccountTermsAndConditions: 'Consentement aux modalités du compte',
        consentToFinancialTermsOfTheAccount: 'Consentement aux conditions financières du compte',
        openDisclosureIn: 'Divulgations ouvertes dans',
        newTab: 'le nouvel onglet'
    };

    return resources[label];
}
