/* eslint-disable camelcase */
// prettier-ignore
const recommendedSKUs = [
    {
        'sku_number': '2162493',
        'sku_size': '',
        'sku_type': 'Standard',
        'list_price': 549,
        'primary_product_id': 'P437979',
        'additional_sku_desc': 'Airwrap™ Styler Complete styler – for multiple hair types and styles',
        'grid_images': '/productimages/sku/s2162493-main-zoom.jpg?imwidth=135&pb=allure-best-2020',
        'hero_images': '/productimages/sku/s2162493-main-zoom.jpg?imwidth=270&pb=allure-best-2020',
        'large_hero_images': '/productimages/sku/s2162493-main-zoom.jpg?imwidth=450&pb=allure-best-2020',
        'is_sephora_exclusive': false,
        'is_online_only': false,
        'is_limited_edition': false,
        'is_app_exclusive': false,
        'is_first_access': false,
        'is_limited_time_offer': false,
        'is_new': false,
        'variation_value': 'Complete styler – for multiple hair types and styles',
        'deep_link': 'https://sephora.test-app.link/UXdkezs6m9?fallbackurl=/product/dyson-airwrap-styler-P437979?skuId=2162493',
        'badgeAltText': 'allure 2020 Best of Beauty Award Winner ',
        'highlights': [
            'allure 2020 Best of Beauty Award Winner ',
            'Community Favorite'
        ],
        'skuImages': {
            'image': '/productimages/sku/s2162493-main-zoom.jpg?imwidth=135&pb=allure-best-2020'
        },
        'brandName': 'dyson',
        'productName': 'Airwrap™ Styler',
        'primaryProduct': {
            'id': 'P437979',
            'display_name': 'Airwrap™ Styler',
            'variation_type': 'Type',
            'product_type': 'standard',
            'product_url': '/product/dyson-airwrap-styler-P437979',
            'brand_name': 'dyson',
            'default_sku_id': '2162493',
            'reviews': 8,
            'rating': 4.75,
            'heroImageAltText': ''
        },
        'productId': 'P437979',
        'skuId': '2162493',
        'targetUrl': '/product/dyson-airwrap-styler-P437979',
        'starRatings': 4.75,
        'productReviewCount': 8,
        'isLimitedEdition': false,
        'isNew': false,
        'isSephoraExclusive': false,
        'isOnlineOnly': false,
        'isAppExclusive': false,
        'isFirstAccess': false,
        'isLimitedTimeOffer': false,
        'listPrice': '$549.00',
        'salePrice': '',
        'valuePrice': '',
        'wholeSalePrice': '',
        'heroImageAltText': '',
        'variationValue': 'Complete styler – for multiple hair types and styles'
    },
    {
        'sku_number': '1359553',
        'sku_size': '1 oz/ 30 mL',
        'sku_type': 'Standard',
        'list_price': 64,
        'primary_product_id': 'P393401',
        'additional_sku_desc': 'Luminous Silk Foundation 3',
        'grid_images': '/productimages/sku/s1359553-main-zoom.jpg?imwidth=135',
        'hero_images': '/productimages/sku/s1359553-main-zoom.jpg?imwidth=270',
        'large_hero_images': '/productimages/sku/s1359553-main-zoom.jpg?imwidth=450',
        'is_sephora_exclusive': false,
        'is_online_only': false,
        'is_limited_edition': false,
        'is_app_exclusive': false,
        'is_first_access': false,
        'is_limited_time_offer': false,
        'is_new': false,
        'variation_value': '3',
        'deep_link': 'https://sephora.test-app.link/wNtm1YwHD9?fallbackurl=/product/luminous-silk-foundation-P393401?skuId=1359553',
        'badgeAltText': '',
        'highlights': [
            'Natural Finish',
            'Medium Coverage',
            'Oil Free',
            'Liquid Formula',
            'Community Favorite'
        ],
        'skuImages': {
            'image': '/productimages/sku/s1359553-main-zoom.jpg?imwidth=135'
        },
        'brandName': 'Armani Beauty',
        'productName': 'Luminous Silk Foundation',
        'primaryProduct': {
            'id': 'P393401',
            'display_name': 'Luminous Silk Foundation',
            'variation_type': 'Color',
            'product_type': 'standard',
            'product_url': '/product/luminous-silk-foundation-P393401',
            'brand_name': 'Armani Beauty',
            'default_sku_id': '1359553',
            'reviews': 41,
            'rating': 3.829,
            'heroImageAltText': ''
        },
        'productId': 'P393401',
        'skuId': '1359553',
        'targetUrl': '/product/luminous-silk-foundation-P393401',
        'starRatings': 3.829,
        'productReviewCount': 41,
        'isLimitedEdition': false,
        'isNew': false,
        'isSephoraExclusive': false,
        'isOnlineOnly': false,
        'isAppExclusive': false,
        'isFirstAccess': false,
        'isLimitedTimeOffer': false,
        'listPrice': '$64.00',
        'salePrice': '',
        'valuePrice': '',
        'wholeSalePrice': '',
        'heroImageAltText': '',
        'variationValue': '3'
    }
];

const placeholderSkus = [
    {
        skuId: 2326759,
        brandName: 'SEPHORA COLLECTION',
        productName: 'Best Skin Ever Liquid Foundation',
        listPrice: '$20.00',
        starRatings: 4.3011,
        reviewsCount: 983
    },
    {
        skuId: 2161636,
        brandName: 'SEPHORA COLLECTION',
        productName: 'Triple Action Cleansing Water',
        listPrice: '$12.00',
        salePrice: '$8.00',
        starRatings: 4.2072,
        reviewsCount: 1173
    },
    {
        skuId: 2087013,
        brandName: 'SEPHORA COLLECTION',
        productName: 'Instant Masks',
        listPrice: '$8.00',
        starRatings: 3.5379,
        reviewsCount: 448
    },
    {
        skuId: 2464048,
        brandName: 'SEPHORA COLLECTION',
        productName: 'Big By Definition Defining & Volumizing Mascara',
        listPrice: '$12.00',
        starRatings: 4.0828,
        reviewsCount: 1280
    },
    {
        skuId: 2452050,
        brandName: 'SEPHORA COLLECTION',
        productName: 'Brightening Eye Cream with Caffeine and Hyaluronic Acid',
        listPrice: '$18.00',
        starRatings: 4.5523,
        reviewsCount: 440
    },
    {
        skuId: 2368223,
        brandName: 'SEPHORA COLLECTION',
        productName: 'Resurfacing Peel Mask',
        listPrice: '$18.00',
        starRatings: 4.8664,
        reviewsCount: 217
    }
];

const modalData = {
    title: 'WELCOME TO SEPHORA.COM.AU',
    link: 'https://www.sephora.com.au/',
    country: 'Australia'
};

const notSureOption = 'notSure';

const noPreferenceOption = 'noPreference';

const fragrancePreferences = ['floral', 'fresh', 'warmspicy', 'woodyEarthy'];

const shoppingPreferences = [
    'bestOfAllure',
    'bipocOwnedBrands',
    'blackOwnedBrands',
    'luxuryFragrance',
    'luxuryMakeup',
    'luxurySkincare',
    'luxuryHair',
    'onlyAtSephora',
    'planetAware'
];

const ingredientPreferences = [
    'aha_glycolic_acid',
    'alcoholFree',
    'antioxidants',
    'benzoyl_peroxide',
    'bondBuilding',
    'cbd',
    'cleanAtSephora',
    'crueltyFree',
    'fragrance_free',
    'hyaluronic_acid',
    'hydroquinone',
    'mineral',
    'mineralPhysical',
    'naturallyDerived',
    'niacinamide',
    'oil_free',
    'organic',
    'paraben_free',
    'peptides',
    'retinoid',
    'salicylicAcid',
    'siliconeFree',
    'squalane',
    'sulfate_free',
    'sulfur',
    'vegan',
    'vitamin_c',
    'nonComedogenic',
    'zinc'
];

const hairColor = ['black', 'brown', 'blonde', 'auburn', 'red', 'gray'];

const ageRange = ['16', '20s', '30s', '40s', '50s'];

const skinTone = ['rich', 'deep', 'tan', 'mediumTan', 'medium', 'lightMedium', 'light', 'fair', 'fairLight'];

const skinType = ['comboSk', 'drySk', 'normalSk', 'oilySk'];

const eyeColor = ['blue', 'brown', 'green', 'gray', 'hazel'];

const hairConcerns = [
    'brassiness',
    'colorFading',
    'colorSafe',
    'curlEnhancing',
    'damageSplitEnds',
    'dandruff',
    'dryness',
    'flakyDryScalp',
    'frizzHr',
    'heatProtection',
    'hold_&style_extending',
    'oily_scalp',
    'scalp_build_up',
    'shine',
    'straighteningSmoothing',
    'thinning',
    'uvprorection',
    'volumizing'
];

const hairTexture = ['straight', 'wavy', 'curly', 'coily'];

const hairDescrible = ['fine', 'medium', 'thick'];

const skinConcerns = [
    'acneBlemishes',
    'antiAging',
    'darkCircles',
    'darkSpots',
    'dryness',
    'unevenTexture',
    'fineLinesWrinkles',
    'lossOfFirmnessElasticity',
    'pores',
    'puffiness',
    'redness',
    'unevenSkinTone'
];

const recommendedTitle = 'Your Personalized Product Picks';

const profileCategories = [
    {
        type: 'skinType',
        showIcon: true,
        iconType: 'svg',
        singleSelect: true,
        name: 'skin type'
    },
    {
        type: 'skinConcerns',
        showIcon: true,
        iconType: 'svg',
        singleSelect: false,
        hasNotSureOption: true,
        name: 'skin concerns',
        postSignupCategoryName: 'skin concerns'
    },
    {
        type: 'skinTone',
        showIcon: true,
        showModelIcon: true,
        iconType: 'png',
        singleSelect: true,
        canSkip: true,
        hasNotSureOption: true,
        name: 'skin tone'
    },
    {
        type: 'colorIQ',
        singleSelect: false,
        canSkip: true,
        isRedirect: true,
        name: 'color iq'
    },
    {
        type: 'ingredientPreferences',
        showIcon: false,
        singleSelect: false,
        openModal: true,
        hasNoPreferenceOption: true,
        name: 'ingredient preferences'
    },
    {
        type: 'fragrancePreferences',
        showIcon: true,
        iconType: 'svg',
        singleSelect: false,
        hasNoPreferenceOption: true,
        name: 'fragrance preferences'
    },
    {
        type: 'hairDescrible',
        showIcon: true,
        iconType: 'svg',
        singleSelect: false,
        name: 'hair type'
    },
    {
        type: 'hairTexture',
        showIcon: true,
        iconType: 'svg',
        singleSelect: false,
        name: 'hair texture'
    },
    {
        type: 'hairConcerns',
        showIcon: true,
        iconType: 'svg',
        singleSelect: false,
        hasNotSureOption: true,
        name: 'hair concerns',
        postSignupTitle: 'hairConcernsBenefits',
        postSignupCategoryName: 'hair concerns and benefits'
    },
    {
        type: 'hairColor',
        showIcon: true,
        iconType: 'png',
        singleSelect: true,
        canSkip: true,
        name: 'hair color'
    },
    {
        type: 'shoppingPreferences',
        showIcon: false,
        singleSelect: false,
        hasDesc: true,
        hasNoPreferenceOption: true,
        name: 'shopping preferences'
    },
    {
        type: 'favoriteBrands',
        showIcon: false,
        singleSelect: false,
        openModal: true,
        hasNoPreferenceOption: true,
        name: 'favorite brands'
    },
    {
        type: 'eyeColor',
        showIcon: true,
        iconType: 'png',
        singleSelect: true,
        canSkip: true,
        name: 'eye color'
    },
    {
        type: 'ageRange',
        showIcon: false,
        singleSelect: true,
        canSkip: true,
        name: 'age range'
    }
];

const PREFERENCE_TYPES = {
    INGREDIENT_PREFERENCES: 'ingredientPreferences',
    SHOPPING_PREFERENCES: 'shoppingPreferences',
    AGE_RANGE: 'ageRange',
    FAVORITE_BRANDS: 'favoriteBrands',
    COLOR_IQ: 'colorIQ'
};

const CATEGORY_TYPE = {
    NOT_SURE: notSureOption,
    NO_PREFERENCE: noPreferenceOption
};

const CONTAINER_SIZE = '716';

const CONFETTI_MOMENT_ONE_REQUIRED_TRAITS = [
    'skinType',
    'skinConcerns',
    'ingredientPreferences',
    'fragrancePreferences',
    'hairDescrible',
    'hairConcerns',
    'shoppingPreferences'
];

const CAROUSEL_REQUIRED_TRAITS = [
    'skinType',
    'skinConcerns',
    'ingredientPreferences',
    'fragrancePreferences',
    'hairDescrible',
    'hairTexture',
    'hairConcerns',
    'shoppingPreferences'
];

const CONFETTI_MOMENT_TWO_WAIT_MS = 1000;

const categorySharedShoppingLinks = [
    {
        copy: 'makeupLink',
        url: '/shop/makeup-cosmetics'
    },
    {
        copy: 'skincareLink',
        url: '/shop/skincare'
    },
    {
        copy: 'hairLink',
        url: '/shop/hair-products'
    },
    {
        copy: 'fragranceLink',
        url: '/shop/fragrance'
    }
];

// The categories and susbcategories keys below must match library key names above
// Up to date Constructor Backend Names can be found at:
// https://docs.google.com/spreadsheets/d/1Q2l890sDrLRujlb-oNr1hrpLmaYqJzYYy-GnrQBs4Gk/edit#gid=7732312
const SHOPPING_LINKS_BY_CATEGORY = {
    skinType: {
        copy: 'skinTypeLink',
        url: '/shop/skincare',
        filterRef: 'Skin Type',
        isMultiLink: false,
        subcatFilterRef: {
            // These are Constructor Backend Names
            normalSk: 'Normal',
            oilySk: 'Oily',
            drySk: 'Dry',
            comboSk: 'Combination'
        }
    },
    skinConcerns: {
        copy: 'skinConcernsLink',
        url: '/shop/skincare',
        filterRef: 'Concerns',
        isMultiLink: false,
        subcatFilterRef: {
            // These are Constructor Backend Names
            acneBlemishes: 'AcneBlemishes',
            antiAging: 'Anti-aging',
            darkCircles: 'Dark circles',
            darkSpots: 'Dark spots',
            dryness: 'Dryness',
            unevenTexture: 'Uneven texture',
            fineLinesWrinkles: 'Fine linesWrinkles',
            lossOfFirmnessElasticity: 'Loss of firmnessElasticity',
            pores: 'Pores',
            puffiness: 'Puffiness',
            redness: 'Redness',
            unevenSkinTone: 'Uneven skin tone'
        }
    },
    colorIQ: {
        copy: 'colorIQLink',
        url: '/beauty/makeup-color-match',
        isMultiLink: false
    },
    fragrancePreferences: {
        copy: 'fragrancePreferencesLink',
        url: '/shop/fragrance',
        filterRef: 'Fragrance Family',
        isMultiLink: false,
        subcatFilterRef: {
            // These are Constructor Backend Names
            floral: 'Floral',
            fresh: 'Fresh',
            warmspicy: 'Warm Spicy',
            woodyEarthy: 'Woody Earthy'
        }
    },
    hairDescrible: {
        copy: 'hairDescribleLink',
        url: '/shop/hair-products',
        filterRef: 'hairType',
        isMultiLink: false,
        subcatFilterRef: {
            // These are Constructor Backend Names
            fine: 'Fine',
            medium: 'Medium',
            thick: 'Thick'
        }
    },
    hairTexture: {
        copy: 'hairTextureLink',
        url: '/shop/hair-products',
        filterRef: 'hairTexture',
        isMultiLink: false,
        subcatFilterRef: {
            // These are Constructor Backend Names
            straight: 'Straight',
            wavy: 'Wavy',
            curly: 'Curly',
            coily: 'Coily'
        }
    },
    hairConcerns: {
        copy: 'hairConcernsLink',
        url: '/shop/hair-products',
        filterRef: 'Concerns',
        isMultiLink: false,
        subcatFilterRef: {
            // These are Constructor Backend Names
            brassiness: 'Brassiness',
            colorFading: 'Color Fading',
            colorSafe: 'Color Safe',
            curlEnhancing: 'Curl enhancing',
            damageSplitEnds: 'DamageSplit Ends',
            dandruff: 'Dandruff',
            dryness: 'Dryness',
            flakyDryScalp: 'FlakyDry Scalp',
            frizzHr: 'Frizz',
            heatProtection: 'Heat Protection',
            'hold_&style_extending': 'Hold Style Extending',
            oily_scalp: 'Oily Scalp',
            scalp_build_up: 'Scalp Build Up',
            shine: 'Shine',
            straighteningSmoothing: 'StraighteningSmoothing',
            thinning: 'Thinning',
            uvprorection: 'UV Protection',
            volumizing: 'Volumizing'
        }
    },
    shoppingPreferences: {
        copy: 'shoppingPreferencesDesc',
        isMultiLink: true,
        links: categorySharedShoppingLinks,
        filterRef: 'shoppingPreference',
        subcatFilterRef: {
            // These are Constructor Backend Names
            bipocOwnedBrands: 'BIPOC-Owned Brands',
            bestOfAllure: 'Best of Allure',
            blackOwnedBrands: 'Black-Owned Brands',
            luxuryFragrance: 'Luxury Fragrance',
            luxuryHair: 'Luxury Hair',
            luxuryMakeup: 'Luxury Makeup',
            luxurySkincare: 'Luxury Skincare',
            onlyAtSephora: 'Only at Sephora',
            planetAware: 'Planet Aware'
        }
    },
    ingredientPreferences: {
        copy: 'ingredientPreferencesDesc',
        isMultiLink: true,
        links: categorySharedShoppingLinks,
        filterRef: 'Ingredient Preferences',
        subcatFilterRef: {
            // These are Constructor Backend Names
            aha_glycolic_acid: 'AHAGlycolic Acid',
            alcoholFree: 'Alcohol Free',
            antioxidants: 'Antioxidants',
            benzoyl_peroxide: 'Benzoyl Peroxide',
            bondBuilding: 'Bond Building',
            cbd: 'CBD',
            cleanAtSephora: 'Clean at Sephora',
            crueltyFree: 'Cruelty-Free',
            fragrance_free: 'Fragrance Free',
            hyaluronic_acid: 'Hyaluronic Acid',
            hydroquinone: 'Hydroquinone',
            mineral: 'Mineral',
            mineralPhysical: 'Mineral Physical',
            naturallyDerived: 'Naturally Derived',
            niacinamide: 'Niacinamide',
            oil_free: 'Oil-free',
            organic: 'Organic',
            paraben_free: 'Paraben-free',
            peptides: 'Peptides',
            retinoid: 'Retinoid',
            salicylicAcid: 'Salicylic Acid',
            siliconeFree: 'Silicone Free',
            squalane: 'Squalane',
            sulfate_free: 'Sulfate-free',
            sulfur: 'Sulfur',
            vegan: 'Vegan',
            vitamin_c: 'Vitamin C',
            nonComedogenic: 'Non-Comedogenic',
            zinc: 'Zinc'
        }
    }
};

const ACCEPTABLE_ANSWERED_TRAITS_CAROUSEL = [4, 8];

const CAROUSEL_ONE_TRAITS = 4;

const CAROUSEL_TWO_TRAITS = 8;

const TOTAL_PREFERENCES = 8;

const PROGRESS_BAR_EXCLUDED_ATTRIBUTES = ['skinTone', 'colorIQ', 'hairColor', 'hairTexture', 'eyeColor', 'ageRange'];

const PROGRESS_BAR_UPDATE_MESSAGE_ATTRIBUTES = [
    'skinType',
    'skinConcerns',
    'ingredientPreferences',
    'fragrancePreferences',
    'hairDescrible',
    'hairConcerns',
    'shoppingPreferences',
    'favoriteBrands'
];

const SIGNUP_MODAL_REQUIRED_TRAITS = ['skinConcerns', 'hairConcerns'];

const CATEGORY_NO_PREFERENCE_BI_VALUE = {
    skinConcerns: 'notSureSC',
    skinTone: 'notSureST',
    ingredientPreferences: 'noPreferenceIP',
    fragrancePreferences: 'noPreferenceFP',
    hairConcerns: 'notSureHC',
    shoppingPreferences: 'noPreferenceSP',
    favoriteBrands: 'noPreferenceFB'
};

const COLORIQ_BP_PAGE_COMPONENT_NAME = 'BeautyPreferences';
const COLORIQ_SPOKE_COMPONENT_NAME = 'ColorIQSpoke';

const SHOPPING_LINKS_EXCLUDED_ATTRIBUTES = ['colorIQ'];

const categoryData = {
    fragrancePreferences,
    shoppingPreferences,
    ingredientPreferences,
    ageRange,
    hairColor,
    skinTone,
    skinType,
    eyeColor,
    hairConcerns,
    hairTexture,
    hairDescrible,
    skinConcerns
};

const TRAIT_TYPE = {
    SKIN_CONCERNS: 'skinConcerns',
    HAIR_CONCERNS: 'hairConcerns'
};

export {
    recommendedSKUs,
    placeholderSkus,
    modalData,
    recommendedTitle,
    profileCategories,
    notSureOption,
    noPreferenceOption,
    categoryData,
    PREFERENCE_TYPES,
    CATEGORY_TYPE,
    CONTAINER_SIZE,
    CONFETTI_MOMENT_ONE_REQUIRED_TRAITS,
    SHOPPING_LINKS_BY_CATEGORY,
    ACCEPTABLE_ANSWERED_TRAITS_CAROUSEL,
    TOTAL_PREFERENCES,
    CAROUSEL_ONE_TRAITS,
    CAROUSEL_TWO_TRAITS,
    PROGRESS_BAR_EXCLUDED_ATTRIBUTES,
    PROGRESS_BAR_UPDATE_MESSAGE_ATTRIBUTES,
    CATEGORY_NO_PREFERENCE_BI_VALUE,
    SIGNUP_MODAL_REQUIRED_TRAITS,
    CONFETTI_MOMENT_TWO_WAIT_MS,
    COLORIQ_BP_PAGE_COMPONENT_NAME,
    COLORIQ_SPOKE_COMPONENT_NAME,
    SHOPPING_LINKS_EXCLUDED_ATTRIBUTES,
    CAROUSEL_REQUIRED_TRAITS,
    TRAIT_TYPE
};
