import getGiftMessageThemes from 'services/api/giftMessage/getGiftMessageThemes';
import addOrUpdateGiftMessage from 'services/api/giftMessage/addOrUpdateGiftMessage';
import deleteGiftMessage from 'services/api/giftMessage/deleteGiftMessage';
import getGiftMessage from 'services/api/giftMessage/getGiftMessage';

export default {
    getGiftMessageThemes,
    addOrUpdateGiftMessage,
    deleteGiftMessage,
    getGiftMessage
};
