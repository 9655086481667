export default function getResource(label, vars = []) {
    const resources = {
        reservePickUpTitle: `Reserve & Pick Up (${vars[0]}) `,
        storeDetails: 'Store Details',
        ropisFAQTitle: 'Reserve & Pick Up Store',
        PromosAndRewardsText: 'Promos and rewards can be added at the store at the time of payment, if eligible.'
    };

    return resources[label];
}
