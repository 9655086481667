import userLocation from 'utils/userLocation/UserLocation';
import scriptUtils from 'utils/LoadScripts';
import storeUtils from 'utils/Store';
import locationUtils from 'utils/Location';
import languageLocaleUtils from 'utils/LanguageLocale';
import userUtils from 'utils/User';

import defaultStoreZipCode from 'constants/defaultStoreZipCode';

const { getPreferredStoreId, getZipCodeOfPrefferedStore, getPreferredStoreInfo } = userUtils;
const { isEventsPage } = locationUtils;
const { DEFAULT_STORE_ID, DEFAULT_ZIP_CODE } = defaultStoreZipCode;
const { isUS } = languageLocaleUtils;

const HappeningLocation = (callback, state = {}) => {
    const defaultStoreId = isUS() ? DEFAULT_STORE_ID.US : DEFAULT_STORE_ID.CA;
    const defaultZipCode = isUS() ? DEFAULT_ZIP_CODE.US : DEFAULT_ZIP_CODE.CA;

    const getPreferredValues = () => {
        const preferredStoreId = getPreferredStoreId() || state.storeId;
        const preferredZipCode = getZipCodeOfPrefferedStore() || state.storeZipCode;

        return { preferredStoreId, preferredZipCode };
    };

    const getStores = locationObj => {
        return storeUtils
            .getStores(locationObj)
            .then(storeItem => {
                const sephoraStores = storeItem.filter(item => item.storeType.toUpperCase() === 'SEPHORA');

                if (sephoraStores.length > 0) {
                    const { storeId, address } = sephoraStores[0];
                    const { postalCode } = address;

                    if (typeof callback === 'function') {
                        callback(storeId || preferredStoreId || defaultStoreId, postalCode || preferredZipCode || defaultZipCode, sephoraStores);
                    }
                }
            })
            .catch(() => {
                callback(defaultStoreId, defaultZipCode, []);
            });
    };

    const getUserLocation = () => {
        userLocation.determineLocation(locationObj => getStores(locationObj), null, {
            sequence: userLocation.getDefaultStrategiesSequence()
        });
    };

    const loadGoogleScript = () => {
        if (!window.google) {
            scriptUtils.loadScripts([scriptUtils.SCRIPTS.GOOGLE], getUserLocation);
        } else {
            getUserLocation();
        }
    };

    const preferredInfo = getPreferredStoreInfo();

    const { preferredStoreId, preferredZipCode } = getPreferredValues();

    if (preferredStoreId && preferredZipCode && preferredInfo.storeType === 'SEPHORA') {
        if (isEventsPage()) {
            getStores({ lat: preferredInfo.latitude, lon: preferredInfo.longitude });

            return;
        } else {
            if (typeof callback === 'function') {
                callback(preferredStoreId, preferredZipCode);

                return;
            }
        }
    }

    // This seems redundant and of no real use as loadGoogleScript is called anyways on line 84
    // if (preferredStoreId && preferredInfo.storeType !== 'SEPHORA') {
    //     loadGoogleScript();
    //     return;
    // }

    loadGoogleScript();
};

export default HappeningLocation;
