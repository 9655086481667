
export default function getResource(label, vars = []) {
    const resources = {
        getItShippedTitle: `Faites livrer (${vars[0]})`,
        reserveAndPickUpTitle: `Réservation et ramassage (${vars[0]})`,
        buyOnlineAndPickUpTitle: `Achat en ligne et ramassage (${vars[0]})`,
        shippedSubTotal: 'Sous-total des articles expédiés',
        pickUpSubTotal: 'Sous-total des articles à ramasser',
        viewItemsAndCheckout: 'Voir les articles et passer à la caisse',
        viewAndReserve: 'Voir les articles et réserver',
        chooseRewards: 'Les promotions, les récompenses et les échantillons peuvent être ajoutés à la page suivante.',
        chooseRewardsInStore: 'Les promotions et les récompenses peuvent être ajoutées en magasin au moment du paiement, s’il y a lieu.',
        bopisPromoMessaging: 'Les promotions et les récompenses admissibles peuvent être ajoutées à la page suivante.',
        noItemsText: 'Il n’y a aucun article dans votre panier d’expédition.',
        viewBasket: 'Voir le panier',
        payInStore: 'Le paiement sera perçu en magasin',
        seeAll: 'Voir tous les',
        items: 'articles',
        ropisInfoTitle: 'Réservez et ramassez en magasin',
        bopisInfoTitle: 'Achetez en ligne et ramassez en magasin',
        noStandardItems: 'Vous n’avez pas d’articles pour la livraison standard.'
    };

    return resources[label];
}
