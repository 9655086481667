export default function getResource(label, vars = []) {
    const resources = {
        addToCal: 'Ajouter au calendrier',
        cancel: 'Annuler',
        reschedule: 'Changer la date',
        copyLinkShare: 'Copier le lien suivant pour partager',
        share: 'Partager',
        cancelReservation: 'Annuler la réservation'
    };

    return resources[label];
}
