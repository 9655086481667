export default function getResource(label, vars = []) {
    const resources = {
        myReservations: 'My Reservations',
        upcomingReservations: 'Upcoming Reservations',
        noReservationsLabel: 'You don’t have any reservations yet.',
        noReservationsLabelRedesign: 'No reservations yet? Let’s change that!',
        noReservationsButtonLabel: 'See what’s happening',
        noReservationsButtonLabelRedesign: 'Explore Services and Events',
        noReservationsText: 'Check out Happening at Sephora to see what’s available near you. Beauty Insider members, take advantage of all the experiences Sephora has to offer—book your first service today!',
        noReservationsTextRedesign: 'Explore our Services and Events to discover a wide array of offerings in-store nearby. Book your first service today and be sure not to miss our upcoming events!'
    };

    return resources[label];
}
