export default function getResource(label, vars = []) {
    const resources = {
        billingAddress: 'Billing Address',
        saveAsDefault: 'Set as my default card',
        saveContinueButton: 'Save & Continue',
        saveForFuture: 'Save for future reservations and purchases',
        cancel: 'Cancel',
        useDiffAddressRadio: 'Use a different address'
    };

    return resources[label];
}
