const resources = {
    storesAndServices: 'Magasins et services',
    home: 'Accueil',
    shop: 'Magasiner',
    offers: 'Offres',
    me: 'Moi',
    signIn: 'Ouvrir une session',
    community: 'Collectivité',
    store: 'Magasins',
    tooltip: 'Ouvrez une session (ou inscrivez-vous!) pour les offres, les points et plus encore.',
    homepage: 'Page d’accueil Sephora'
};

export default function getResource(label) {
    return resources[label];
}
