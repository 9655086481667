const ACTION_TYPES = {
    SHOW_EDIT_MY_PROFILE_MODAL: 'SHOW_EDIT_MY_PROFILE_MODAL',
    SHOW_EDIT_FLOW_MODAL: 'SHOW_EDIT_FLOW_MODAL',
    SHOW_SAVED_MESSAGE: 'SHOW_SAVED_MESSAGE',
    UPDATE_BI_ACCOUNT: 'UPDATE_BI_ACCOUNT',
    GET_ACCOUNT_HISTORY_SLICE: 'GET_ACCOUNT_HISTORY_SLICE',
    SET_ACCOUNT_HISTORY_SLICE: 'SET_ACCOUNT_HISTORY_SLICE',
    SHOW_SOCIAL_REGISTRATION_MODAL: 'SHOW_SOCIAL_REGISTRATION_MODAL',
    SHOW_SOCIAL_REOPT_MODAL: 'SHOW_SOCIAL_REOPT_MODAL'
};

const initialState = {
    biAccount: null,
    accountHistorySlice: null,
    showSavedMessage: false
};

const reducer = function (state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_BI_ACCOUNT:
            return Object.assign({}, state, { biAccount: action.biAccount });

        case ACTION_TYPES.SET_ACCOUNT_HISTORY_SLICE:
            return Object.assign({}, state, { accountHistorySlice: action.accountHistorySlice });

        case ACTION_TYPES.SHOW_SAVED_MESSAGE:
            return Object.assign({}, state, { showSavedMessage: action.isSave });

        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
