const SET_EVENTS = 'SET_EVENTS';
const SET_APPLIED_EVENTS_FILTERS = 'SET_APPLIED_EVENTS_FILTERS';
const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_STORES_LIST = 'SET_STORES_LIST';
const UPDATE_LOCATION_FILTERS = 'UPDATE_LOCATION_FILTERS';
const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
const SET_SERVICES_AND_EVENTS = 'SET_SERVICES_AND_EVENTS';

export {
    SET_EVENTS,
    SET_APPLIED_EVENTS_FILTERS,
    SET_IS_LOADING,
    SET_STORES_LIST,
    UPDATE_LOCATION_FILTERS,
    SET_CURRENT_LOCATION,
    SET_SERVICES_AND_EVENTS
};
