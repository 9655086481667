export default function getResource(label, vars = []) {
    const resources = {
        qAndATitle: 'Questions et réponses',
        askAQuestionLink: 'Poser une question',
        askAQuestionTitle: 'Poser une question',
        mostRecentQuestion: 'Question la plus récente',
        sort: 'Trier',
        noReviewsAvailable: 'Aucun commentaire',
        showMoreQandA: 'Afficher plus de questions et réponses',
        postedBy: `par ${vars[0]}`,
        asked: 'Question posée',
        moreAnswers: `${vars[0]} réponses de plus`,
        showLess: 'Afficher moins',
        answerThisQuestion: 'Répondre à cette question',
        readMore: 'En lire plus',
        readLess: 'En lire moins',
        answered: 'Résolue',
        verifiedPurchase: 'Achat vérifié',
        sephoraEmployee: 'Employé de Sephora',
        receivedFreeProduct: 'Produit gratuit reçu',
        helpful: 'Utile',
        fromTo: `Questions ${vars[0]}-${vars[1]} de ${vars[2]}`,
        enterYourQuestion: 'Saisissez votre question',
        exampleQuestion: 'Exemple : Ce fond de teint convient-il à une peau grasse',
        required: 'Requis',
        questionAnswerGuidelines: 'Directives relatives aux questions et réponses',
        note: 'Veuillez noter qu’il peut s’écouler jusqu’à 72 heures avant que votre question ne soit affichée.',
        submit: 'Soumettre',
        cancel: 'Annuler',
        responsiblity: 'Sephora n’est pas responsable des réponses données par la collectivité, les clients ou d’autres tierces parties. Exactitude non garantie.',
        termsAndConditions: 'Conditions d’utilisation',
        errorMessageText: `${vars[0]} caractères minimum requis`,
        enterQuestion: 'Veuillez saisir votre question',
        enterAnswer: 'Veuillez saisir votre réponse',
        maxCharacter: 'Nombre maximal de caractères atteint',
        characterLeft: `${vars[0]} caractères restants`,
        mostAnswers: 'La plupart des réponses',
        mostRecentQuestions: 'Questions les plus récentes',
        mostRecentAnswers: 'Réponses les plus récentes',
        oldestQuestions: 'Questions les plus anciennes',
        answerAQuestionTitle: 'Répondre à une question',
        enterYourAnswer: 'Saisissez votre réponse',
        iWorkAtSephora: 'Je travaille chez Sephora',
        iReceivedFreeSample: 'J’ai reçu un échantillon gratuit',
        answerNote: 'Veuillez noter que cela peut prendre jusqu’à 72 heures avant que votre réponse ne soit affichée.',
        question: 'Question',
        answer: 'Réponse',
        thankYouQuestion: 'Merci d’avoir soumis une question.',
        thankYouAnswer: 'Merci d’avoir répondu à cette question.',
        postedHour: `Votre ${vars[0]} sera affichée d’ici 72 heures.`,
        addToLoves: 'Ajouter aux favoris',
        back: 'Retour aux questions et réponses',
        submissionNotReceived: 'Soumission non reçue',
        submissionWrong: 'Un problème est survenu lors du traitement de votre soumission. Veuillez réessayer plus tard.',
        size: 'Format',
        done: 'Terminé',
        searchKeyword: 'Rechercher un mot-clé',
        searchKeywordResults: `${vars[0]} Questions et réponses contenant « ${vars[1]} »`,
        searchKeywordResult: `${vars[0]} question ou réponse contenant « ${vars[1]} »`,
        sorryNoSearchResults: `Désolés, aucune question ni réponse ne contient « ${vars[0]} »`,
        emailNotification: 'Recevez une notification par courriel lorsqu’on aura répondu à votre question. Vous ne recevrez pas de notification si vous ne cochez pas cette option.',
        emailAddress: 'Adresse de courriel',
        notification: 'Sephora n’utilisera ce courriel que pour vous aviser qu’on a répondu à votre question.',
        invalidEmailMessage: 'Veuillez saisir une adresse courriel valide',
        emptyEmailMessage: 'Veuillez saisir votre adresse courriel',
        stopEmailNotification: 'Cesser les notifications par courriel pour cette question?',
        areYouSure: 'Voulez-vous vraiment ne plus recevoir de notification par courriel lorsqu’il y a une nouvelle réponse à cette question?',
        stopSending: 'Cesser d’envoyer des notifications par courriel',
        continueShopping: 'Continuer à magasiner',
        unsubscribeMessage: 'Votre désinscription est prise en compte.',
        noLonger: 'Vous ne recevrez plus de notifications par courriel lorsqu’il y aura une nouvelle réponse à cette question.',
        modalGuidelinesModeration: 'Toutes les questions et réponses sont lues et modérées pour respecter les directives relatives aux questions et réponses ci-après :',
        considerBeforeAsking: 'Lorsque vous posez une question, veuillez tenir compte des directives suivantes :',
        focusOnProduct: 'Concentrez-vous sur le produit et sur la question à propos de celui-ci.',
        termsOfUse: 'Conditions d’utilisation',
        relevantDetails: 'Fournissez des détails pertinents qui peuvent aider les autres à répondre à votre question, comme le teint de la peau, la couleur des yeux, la texture des cheveux ou le look recherché. Par exemple : « J’ai des cils courts. Ce mascara les allongera-t-il suffisamment? » Toutes les questions soumises sont lues par nos modérateurs et sont assujetties aux modalités décrites dans nos',
        noMedicalAdvice: 'Ne demandez pas de conseils médicaux.',
        language1: 'Pour assurer la conformité à ces directives et à nos',
        language2: ', les questions doivent être soumises en anglais ou en français.',
        answersGuidelines: 'Vous pouvez aussi partager vos astuces en matière de beauté et soumettre des réponses aux questions des autres. Lorsque vous répondez à une question, veuillez tenir compte des directives suivantes :',
        answersFocusOnExperience: 'Concentrez-vous sur le produit et sur votre expérience personnelle d’utilisation.',
        answersDetails: 'Donnez des détails pertinents à la question posée.',
        answersModerators: 'Toutes les réponses soumises sont lues par nos modérateurs et sont assujetties aux modalités décrites dans nos ',
        answersNoMedicalAdvice: 'Ne donnez pas de conseils médicaux.',
        answersLanguage1: 'Pour assurer la conformité à ces directives et à nos',
        answersLanguage2: ', les réponses doivent être soumises en anglais ou en français.',
        rightsReservations: 'Nous nous réservons le droit de ne pas publier des questions ou réponses, ou d’en retirer pour quelque raison que ce soit. Par exemple, les questions et réponses peuvent être supprimées si elles concernent un produit qui n’est plus disponible à la vente chez Sephora ou une version antérieure du produit (par exemple, une nouvelle formule). Votre question ou réponse ne sera pas publiée si elle contrevient aux directives fournies, ou si elle contient l’un des types de contenu suivants : commentaires sur la sélection des produits, les prix, les commandes, la livraison ou tout autre problème lié au service à la clientèle. Ne soumettez pas cette rétroaction sous la forme d’une question ou d’une réponse. Veuillez communiquer directement avec nous en visitant le site ',
        contactUsUrl: 'https://www.sephora.com/ca/fr/beauty/contact-us',
        nowAskAndAnswer: 'Maintenant, demandez et répondez!',
        yesIAgree: 'Oui, j’accepte ',
        noteLabel: 'Remarque',
        termsError: 'Vous devez accepter nos conditions d’utilisation pour continuer.',
        searchAriaLabel: 'Rechercher dans les questions et réponses'
    };
    return resources[label];
}
