export default function getResource(label, vars = []) {
    const resources = {
        askToCancelCharge: `Are you sure you want to cancel this reservation? You will be charged a ${vars[0]}  late cancellation fee.`,
        askToCancel: 'Are you sure you want to cancel this reservation?',
        yes: 'Yes',
        no: 'No'
    };

    return resources[label];
}
