export default function getResource(label, vars = []) {
    const resources = {
        reservePickUpTitle: `Réservation et ramassage (${vars[0]}) `,
        storeDetails: 'Détails du magasin',
        ropisFAQTitle: 'Magasin de la réservation et du ramassage',
        PromosAndRewardsText: 'Les promotions et les récompenses peuvent être ajoutées en magasin au moment du paiement, s’il y a lieu.'
    };

    return resources[label];
}
