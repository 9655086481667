import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';

import Store from 'Store';
const { dispatch } = Store;

import Actions from 'Actions';
const { showInfoModal } = Actions;

import Location from 'utils/Location';
import catalogUtils from 'utils/Catalog';

import * as catalogConstantsUtils from 'utils/CatalogConstants';
const { EXTRACT_KEY_FROM_FILTER_REGEX } = catalogConstantsUtils;

import { colors } from 'style/config';

import {
    Text, Flex, Box, Link
} from 'components/ui';
import InfoButton from 'components/InfoButton/InfoButton';
import Flag from 'components/Flag/Flag';

import FilterGroup from 'components/Catalog/Filters/FilterGroup/FilterGroup';

import localeUtils from 'utils/LanguageLocale';
const getText = localeUtils.getLocaleResourceFile('components/Catalog/Filters/Custom/BeautyPreferencesFilter/locales', 'BeautyPreferencesFilter');

import auth from 'utils/Authentication';

function infoModalMessageOnClick(e) {
    Location.navigateTo(e, '/profile/BeautyPreferences');
}

function InfoModalMessage() {
    return (
        <Box>
            {getText('infoModalMessage')}
            <Link
                onClick={infoModalMessageOnClick}
                color={colors.blue}
                children={getText('infoModalMessageEndLink')}
                underline={true}
            />
        </Box>
    );
}

function showBeautyPreferenceInfoModal(e) {
    e.stopPropagation();
    dispatch(
        showInfoModal({
            isOpen: true,
            title: getText('infoModalTitle'),
            message: <InfoModalMessage />,
            buttonText: getText('gotIt'),
            bodyFooterPaddingX: 4,
            bodyPaddingBottom: 4
        })
    );
}

function FilterTitle({ title, isModal }) {
    return (
        <Flex
            gap={'6px'}
            alignItems={'end'}
        >
            <Text maxWidth={isModal ? '168px' : '82px'}>{title}</Text>
            <Flex
                gap={'6px'}
                alignItems={'center'}
            >
                <InfoButton
                    size={16}
                    onClick={showBeautyPreferenceInfoModal}
                />
                <Flag
                    backgroundColor={'black'}
                    children={getText('new')}
                    marginLeft={'1px'}
                />
            </Flex>
        </Flex>
    );
}

function signInHandler(e, anaData) {
    e.stopPropagation();
    auth.requireAuthentication(null, null, anaData, null, true).catch(() => {});
}

function AnonymousState() {
    return (
        <Box paddingBottom={4}>
            <Link
                onClick={signInHandler}
                color={colors.blue}
                children={getText('signIn')}
                underline={true}
            />
            {getText('signInToAdd')}
        </Box>
    );
}

function NoBeautyPreferencesSavedState() {
    return <Box paddingBottom={4}>{getText('applyFilters')}</Box>;
}

function onFilterSelect({ title, categorySpecificMasterList, selectFilters }) {
    return (selectedFilters, applyFilters) => {
        const filters = selectedFilters[title];

        const selectedFiltersByKey = filters.reduce((acc, filter) => {
            const { key } = EXTRACT_KEY_FROM_FILTER_REGEX.exec(filter)?.groups;

            const ufeFilterKey = Object.values(categorySpecificMasterList.attributes).find(
                refinement => refinement.queryParamKey === key
            ).refinementKey;

            if (acc[ufeFilterKey]) {
                acc[ufeFilterKey].push(filter);
            } else {
                acc[ufeFilterKey] = [filter];
            }

            return acc;
        }, {});

        const unselectedFiltersByKey = Object.values(categorySpecificMasterList.attributes).reduce((acc, refinement) => {
            acc[refinement.refinementKey] = [];

            return acc;
        }, {});

        return selectFilters(
            {
                ...unselectedFiltersByKey,
                ...selectedFiltersByKey
            },
            applyFilters
        );
    };
}

function onSelectAll({ selectFilters, categorySpecificMasterList, values }) {
    const allSelected = values.reduce((acc, value) => {
        const { key } = EXTRACT_KEY_FROM_FILTER_REGEX.exec(value.refinementValue)?.groups;
        const map = Object.values(categorySpecificMasterList.attributes).find(refinement => refinement.queryParamKey === key);

        if (map) {
            if (!acc[map.refinementKey]) {
                acc[map.refinementKey] = [];
            }

            acc[map.refinementKey].push(value.refinementValue);
        }

        return acc;
    }, {});

    return () => {
        selectFilters(allSelected, true);
    };
}

function isAllSelected({ values }) {
    return values.every(value => value.refinementValueStatus === 2);
}

function onDeselectAll({ selectFilters, categorySpecificMasterList, values }) {
    const allDeselected = values.reduce((acc, value) => {
        const { key } = EXTRACT_KEY_FROM_FILTER_REGEX.exec(value.refinementValue)?.groups;
        const map = Object.values(categorySpecificMasterList.attributes).find(refinement => refinement.queryParamKey === key);

        if (map) {
            if (!acc[map.refinementKey]) {
                acc[map.refinementKey] = [];
            }
        }

        return acc;
    }, {});

    return () => {
        selectFilters(allDeselected, true);
    };
}

function SelectAllCTA(props) {
    const allSelected = isAllSelected(props);

    return (
        <Link
            display='block'
            color='blue'
            lineHeight='tight'
            width='100%'
            marginBottom={2}
            onClick={allSelected ? onDeselectAll(props) : onSelectAll(props)}
            children={getText(allSelected ? 'deselectAll' : 'selectAll')}
        />
    );
}

function BeautyPreferencesFilter(props) {
    const commonFilterGroupProps = {
        customTitle: (
            <FilterTitle
                title={props.title}
                isModal={props.isModal}
            />
        ),
        customHeight: props.isModal ? 50 : 68
    };

    if (props.userInfo.isAnonymous) {
        return (
            <FilterGroup
                {...props}
                {...commonFilterGroupProps}
                customChild={<AnonymousState />}
            />
        );
    }

    if (!props.userInfo.hasBeautyPreferencesSet) {
        return (
            <FilterGroup
                {...props}
                {...commonFilterGroupProps}
                customChild={<NoBeautyPreferencesSavedState />}
            />
        );
    }

    const groupingInfo = catalogUtils.getFilterGroupingInfo(props.values, props.categorySpecificMasterList);

    return (
        <FilterGroup
            {...props}
            {...commonFilterGroupProps}
            valueGrouping={{
                isGrouped: true,
                ordering: groupingInfo.ordering
            }}
            values={groupingInfo.values}
            customChild={null}
            customCTA={<SelectAllCTA {...props} />}
            selectFilters={onFilterSelect(props)}
        />
    );
}

export default wrapFunctionalComponent(BeautyPreferencesFilter, 'BeautyPreferencesFilter');
