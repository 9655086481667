import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

import store from 'Store';
import Actions from 'Actions';
import Modal from 'components/Modal/Modal';
import SignInFormNew from 'components/GlobalModals/SignInModal/SignInFormNew/SignInFormNew';

class SignInModal extends BaseClass {
    requestClose = () => {
        store.dispatch(Actions.showSignInModal({ isOpen: false }));

        //currently errback passed in only from requireLoggedInAuthentication function
        if (this.props?.errback) {
            this.props.errback();
        }
    };

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onDismiss={this.requestClose}
                width={1}
                closeDataAt={Sephora.debug.dataAt('close_button')}
                dataAt={Sephora.debug.dataAt('sign_in')}
            >
                <Modal.Body>
                    <SignInFormNew {...this.props} />
                </Modal.Body>
            </Modal>
        );
    }
}

export default wrapComponent(SignInModal, 'SignInModal');
