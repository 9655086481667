import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Empty from 'constants/empty';
import FrameworkUtils from 'utils/framework';
import { p13nSelector } from 'selectors/p13n/p13nSelector';
import { coreUserDataSelector } from 'viewModel/selectors/user/coreUserDataSelector';
import cookieUtils from 'utils/Cookies';
import PersonalizationUtils from 'utils/Personalization';
import P13nUtils from 'utils/localStorage/P13n';
import { sendCmsComponentEvent } from 'analytics/utils/cmsComponents';

import constants from 'constants/content';
import anaConsts from 'analytics/constants';

const {
    COMPONENT_TYPES: { BANNER }
} = constants;

const {
    CMS_COMPONENT_EVENTS: { IMPRESSION, ITEM_CLICK }
} = anaConsts;

const triggerImpression = function (banner) {
    const eventName = IMPRESSION;
    sendCmsComponentEvent({
        items: [banner],
        eventName,
        sid: '',
        component: BANNER
    });
};

const triggerClick = function (banner) {
    const { sid: componentSid, variationData } = banner;
    const eventName = ITEM_CLICK;

    sendCmsComponentEvent({
        items: [variationData],
        eventName,
        sid: '',
        componentSid,
        component: BANNER
    });
};

const { getPersonalizedComponent, checkDataToDisplay } = PersonalizationUtils;
const { setPersonalizationCache } = P13nUtils;
const { wrapHOC } = FrameworkUtils;
const fields = createSelector(
    p13nSelector,
    coreUserDataSelector,
    (_state, ownProps) => ownProps.personalization,
    (p13n, user, personalization) => {
        let personalizedComponent = Empty.Array;

        if (!Sephora.isNodeRender && Sephora.Util.InflatorComps.services.loadEvents.HydrationFinished) {
            const prvCookie = cookieUtils.read(cookieUtils.KEYS.P13N_PRV);

            if (prvCookie && p13n.data?.length > 0) {
                personalizedComponent = p13n.data.find(item => item.context === personalization?.context) || Empty.Array;
            } else if (p13n.headData?.length) {
                setPersonalizationCache(p13n.headData);
                const headItemData = p13n.headData.find(item => (item.p13n?.context || item.context) === personalization?.context);
                personalizedComponent = checkDataToDisplay(headItemData, personalization);
            } else if (user.isAnonymous) {
                personalizedComponent = Empty.Array;
            } else {
                personalizedComponent = getPersonalizedComponent(personalization, user, p13n, true);
            }
        }

        return {
            p13n,
            user,
            personalizedComponent,
            triggerImpression,
            triggerClick
        };
    }
);

const withBannerProps = wrapHOC(connect(fields));

export {
    withBannerProps, fields
};
