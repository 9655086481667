import anaConsts from 'analytics/constants';
import processEvent from 'analytics/processEvent';

const {
    EVENT_NAMES: {
        ORDER_DETAILS,
        ORDER_DETAILS: { CURBSIDE, SMS_MODAL }
    },
    PAGE_TYPES: { GENERIC_MODAL },
    ASYNC_PAGE_LOAD,
    SOT_LINK_TRACKING_EVENT,
    LINK_TRACKING_EVENT
} = anaConsts;

class OrderConfirmationSOTBindings {
    static STORE_STATUSES = {
        OPEN: 'open',
        CLOSED: 'closed',
        UNAVAILABLE: 'unavailable'
    };

    static triggerSOTAsyncAnalytics = ({ eventType = ASYNC_PAGE_LOAD, ...data }) => {
        const eventData = {
            data: {
                ...data
            }
        };

        processEvent.process(eventType, eventData);
    };

    static triggerSOTAnalytics = ({ eventName, ...data }) => {
        const eventData = {
            data: {
                linkName: eventName,
                actionInfo: eventName,
                specificEventName: eventName,
                ...data
            }
        };

        processEvent.process(SOT_LINK_TRACKING_EVENT, eventData);
    };

    static returnLinkClick = ({ shippingMethod }) => {
        const eventData = {
            data: {
                actionInfo: `${shippingMethod}:start a return`
            }
        };

        processEvent.process(LINK_TRACKING_EVENT, eventData);
    };

    static cancelOrderModal = () => {
        const { CANCEL_ORDER_MODAL, REASONS } = ORDER_DETAILS;
        OrderConfirmationSOTBindings.triggerSOTAsyncAnalytics({
            pageType: CANCEL_ORDER_MODAL,
            pageTypeDetail: REASONS
        });
    };

    static curbsidePickupCheckInModal = ({ storeStatus, activeScreenIndex }) => {
        const {
            PICKUP, HOURS_CLOSED, HERE_FOR_CURBSIDE, VERIFICATION, DETAILS
        } = CURBSIDE;
        digitalData.page.category.pageType = CURBSIDE.CURBSIDE;
        digitalData.page.attributes.world = digitalData.page.attributes.world || 'n/a';

        const world = digitalData.page.attributes.world;
        const pageType = digitalData.page.category.pageType;
        let pageName = '';
        const data = {};

        switch (storeStatus) {
            case OrderConfirmationSOTBindings.STORE_STATUSES.CLOSED:
                digitalData.page.pageInfo.pageName = `${PICKUP} ${HOURS_CLOSED}`;

                break;
            case OrderConfirmationSOTBindings.STORE_STATUSES.OPEN:
                if (activeScreenIndex === 0) {
                    digitalData.page.pageInfo.pageName = PICKUP;

                    data.linkData = `${CURBSIDE.CURBSIDE}:${HERE_FOR_CURBSIDE}`;
                    data.pageTypeDetail = VERIFICATION;
                } else if (activeScreenIndex === 1) {
                    digitalData.page.pageInfo.pageName = DETAILS;
                    data.pageTypeDetail = DETAILS;
                }

                break;
            case OrderConfirmationSOTBindings.STORE_STATUSES.UNAVAILABLE:
                digitalData.page.pageInfo.pageName = CURBSIDE.ORDER_NOT_AVAILABLE;

                break;
            default:
                return;
        }

        pageName = digitalData.page.pageInfo.pageName;

        data.pageName = `${pageType}:${pageName}:${world}:*`;
        data.pageType = digitalData.page.category.pageType;
        data.pageDetail = digitalData.page.pageInfo.pageName;

        OrderConfirmationSOTBindings.triggerSOTAsyncAnalytics(data);
    };

    static curbsideModalSuccess = () => {
        const { SUCCESS } = CURBSIDE;
        digitalData.page.pageInfo.pageName = SUCCESS;
        const world = digitalData.page.attributes.world;
        const pageType = digitalData.page.category.pageType;

        OrderConfirmationSOTBindings.triggerSOTAsyncAnalytics({
            pageName: `${pageType}:${SUCCESS}:${world}:*`,
            pageType: CURBSIDE.CURBSIDE,
            pageTypeDetail: SUCCESS
        });
    };

    static hereForCurbsideClick = ({ orderId }) => {
        const { HERE_FOR_CURBSIDE_CLICK } = CURBSIDE;
        OrderConfirmationSOTBindings.triggerSOTAnalytics({
            eventName: HERE_FOR_CURBSIDE_CLICK,
            orderId
        });
    };

    static SMSSignupModal = ({ orderId, orderShippingMethod }) => {
        const { SING_UP } = SMS_MODAL;
        OrderConfirmationSOTBindings.triggerSOTAsyncAnalytics({
            linkData: `${GENERIC_MODAL}:${SING_UP}`,
            pageName: `${GENERIC_MODAL}:${SING_UP}:n/a:*`,
            pageDetail: SING_UP,
            pageType: GENERIC_MODAL,
            orderId,
            orderShippingMethod
        });
    };

    static SMSSignupConfirmation = ({ orderId, orderShippingMethod }) => {
        const { CONFIRMATION } = SMS_MODAL;
        OrderConfirmationSOTBindings.triggerSOTAsyncAnalytics({
            linkData: `${GENERIC_MODAL}:${CONFIRMATION}`,
            pageName: `${GENERIC_MODAL}}:${CONFIRMATION}:n/a:*`,
            pageDetail: CONFIRMATION,
            pageType: GENERIC_MODAL,
            orderId,
            orderShippingMethod
        });
    };

    static SMSButtonClick = () => {
        const { SMS_BUTTON_CLICK } = SMS_MODAL;
        OrderConfirmationSOTBindings.triggerSOTAnalytics({
            eventName: SMS_BUTTON_CLICK
        });
    };

    static buildProductString = (orderItems, deliveryMethod) => {
        const productString = orderItems.map(item => {
            if (item.substituteItem || item.isSubstituted !== undefined) {
                const fulfilledItem = item.isSubstituted ? item.substituteItem : item;

                return (
                    `;${fulfilledItem.sku.skuId};;;${OrderConfirmationSOTBindings.getEvents(item)};eVar26=${fulfilledItem.sku.skuId}` +
                    `|eVar131=${item.substituteItem ? item.substituteItem?.sku?.skuId : 'do not substitute'}|eVar132=${
                        item.sku.skuId
                    }|eVar133=${deliveryMethod};`
                );
            } else {
                return '';
            }
        });

        return productString;
    };

    static getEvents = item => {
        let events = '';
        const isFirstChoiceCancelled = item.status === 'Canceled';
        const isSubItemCancelled = item.substituteItem?.status === 'Canceled';

        if (isFirstChoiceCancelled && isSubItemCancelled) {
            digitalData.page.attributes.tempProps.isItemAndSubItemCanceled = true;
            events = `${anaConsts.Event.CANCELED_ITEM_AND_SUB_ITEM}=1`;
        } else if (isFirstChoiceCancelled && item.isSubstituted && !isSubItemCancelled) {
            digitalData.page.attributes.tempProps.isItemSubstituted = true;
            events = `${anaConsts.Event.ITEM_SUBSTITUTED}=1`;
        }

        return events;
    };
}

export default OrderConfirmationSOTBindings;
