import ufeApi from 'services/api/ufeApi';
const { makeRequest } = ufeApi;

function checkin(token, body) {
    const url = `${Sephora.configurationSettings.sdnDomainBaseUrl}/v1/reservations/event/checkin`;

    return makeRequest(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token
        }
    })
        .then(data => (data.errorCode ? Promise.reject(data) : data))
        .catch(error => Promise.reject(error));
}

export default checkin;
