import React from 'react';
import { Flex, Button, Link } from 'components/ui';
import { mediaQueries } from 'style/config';
import localeUtils from 'utils/LanguageLocale';
import framework from 'utils/framework';
import { addToCalendar, getHoursDifference, showCountryMissMatchModal } from 'utils/happening';
import cookieUtils from 'utils/Cookies';
import sdnApi from 'services/api/sdn';
import HappeningBindings from 'analytics/bindingMethods/components/Content/Happening/HappeningBindings';
import anaUtils from 'analytics/utils';
import Location from 'utils/Location';
import { HOURS_BEFORE_START_TIME, SERVICES_FAQ_URL, BEAUTY_SERVICES_FAQ_URL } from 'components/Content/Happening/ActionButtons/constants';
import { EXPERIENCES } from 'components/Content/Happening/ReservationDetails/constants';

const { wrapFunctionalComponent } = framework;
const { getLocaleResourceFile, getCurrentCountry } = localeUtils;
const { cancelServiceOrEventReservation } = sdnApi;

const prop55Map = {
    [EXPERIENCES.SERVICES]: 'happening:canceled booking:service',
    [EXPERIENCES.EVENTS]: 'happening:canceled booking:event'
};

function ActionButtons({
    reservationDetails = {},
    calendarInfo,
    showInfoModal,
    showEDPConfirmRsvpModal,
    user,
    variantStyle = null,
    width = 186,
    size = 'md',
    buttons = [],
    buttonProps = {},
    wrapperProps = {}
}) {
    const getText = getLocaleResourceFile('components/Content/Happening/ActionButtons/locales', 'ActionButtons');

    const {
        confirmationNumber,
        artist: { employeeNumber } = {},
        store: { timeZone, storeId, address = {} },
        serviceInfo: { activityId } = {}
    } = reservationDetails;

    const startDateTime = reservationDetails.startDateTime.replace('[UTC]', '');

    const localCountry = getCurrentCountry();

    const isSameCountry = address.country === localCountry;

    const handleOnOpenRsvpModal = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        HappeningBindings.actionButtonsCancelBookingLinkAnalytics(calendarInfo.activityName);

        showInfoModal({
            isOpen: true,
            title: getText('cancelRsvp'),
            message: getText('areYouSure'),
            buttonText: getText('no'),
            cancelText: getText('yes'),
            showCancelButton: true,
            cancelButtonCallback: handleCancelReservation
        });
    };

    const handleCancelReservation = async () => {
        const { activityName, type } = calendarInfo || reservationDetails;
        const { postalCode, country } = address;

        showInfoModal({ isOpen: false });

        try {
            await cancelServiceOrEventReservation({ confirmationNumber, type });

            if (Location.isRwdReservationsDetailsPage()) {
                Location.reload();
            } else {
                if (prop55Map[type]) {
                    anaUtils.setNextPageData({
                        prop55: `${prop55Map[type]}:${activityName.toLowerCase()}`
                    });
                }

                Location.navigateTo(null, `/happening/reservations/confirmation?id=${confirmationNumber}&zipCode=${postalCode}&country=${country}`);
            }
        } catch {
            //handle error
        }
    };

    const showCancelModal = ({ message, cancelButtonCallback, cancelText = '', title = '' }) => {
        showInfoModal({
            isOpen: true,
            title,
            message,
            buttonText: getText('no'),
            cancelText,
            showCancelButton: true,
            cancelButtonCallback
        });
    };

    const handleRedirect = (e, isReschedule = false) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const artistId = employeeNumber ? `&artistId=${employeeNumber}` : '';
        let rescheduleConfirmationNumber = '';

        if (isReschedule) {
            rescheduleConfirmationNumber = `&rescheduleConfirmationNumber=${confirmationNumber}`;
            showInfoModal({ isOpen: false });
        }

        Location.navigateTo(
            e,
            `happening/services/booking/${activityId}?storeId=${storeId}&zipCode=${address.postalCode}${rescheduleConfirmationNumber}${artistId}`
        );
    };

    const redirectToReschedule = e => {
        handleRedirect(e, true);
    };

    const redirectToBookAgain = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!isSameCountry) {
            showCountryMissMatchModal();

            return;
        }

        handleRedirect(e);
    };

    const confirmEventRSVP = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!isSameCountry) {
            showCountryMissMatchModal();

            return;
        }

        const { eventInfo = {}, store } = reservationDetails;

        showEDPConfirmRsvpModal({
            isOpen: true,
            user,
            timeZone,
            storeId,
            eventDisplayName: eventInfo.displayName,
            storeDisplayName: store.displayName,
            edpInfo: { ...eventInfo, type: 'event' },
            timeSlot: { startDateTime, durationMin: eventInfo.durationInt, bookingId: reservationDetails.bookingId }
        });
    };

    const getWithin24HoursMessage = (textKey, policiesUrl) => {
        const { serviceFees: { possibleCancellationFee = '' } = {} } = reservationDetails;

        return (
            <>
                {`${getText(textKey, [possibleCancellationFee])} `}
                <Link
                    color={'blue'}
                    css={{ textDecoration: 'underline' }}
                    href={policiesUrl}
                    children={getText('seePolicies')}
                />
                {` ${getText('moreDetails')}`}
            </>
        );
    };

    const handleReschedule = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!isSameCountry) {
            showCountryMissMatchModal();

            return;
        }

        const hoursDifference = getHoursDifference(startDateTime, timeZone);

        if (hoursDifference > 0) {
            let message = getText('areYouSureReschedule');

            if (hoursDifference <= HOURS_BEFORE_START_TIME) {
                const isQuebec = Boolean(cookieUtils.read(cookieUtils.KEYS.QUEBEC_YES));

                if (!isQuebec) {
                    message = getWithin24HoursMessage('areYouSureRescheduleWithin24Hours', SERVICES_FAQ_URL);
                }
            }

            showCancelModal({
                message,
                cancelButtonCallback: redirectToReschedule,
                cancelText: getText('yesReschedule'),
                title: getText('rescheduleService')
            });
        }
    };

    const handleOnOpenCancelModal = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (!isSameCountry) {
            showCountryMissMatchModal();

            return;
        }

        const hoursDifference = getHoursDifference(startDateTime, timeZone);

        if (hoursDifference > 0) {
            let message = getText('areYouSureCancel');

            if (hoursDifference <= HOURS_BEFORE_START_TIME) {
                const isQuebec = Boolean(cookieUtils.read(cookieUtils.KEYS.QUEBEC_YES));

                if (!isQuebec) {
                    message = getWithin24HoursMessage('areYouSureWithin24Hours', BEAUTY_SERVICES_FAQ_URL);
                }
            }

            showCancelModal({ message, cancelButtonCallback: handleCancelReservation, cancelText: getText('yes'), title: getText('cancelService') });
        }

        const {
            serviceInfo: { displayName }
        } = reservationDetails;

        HappeningBindings.actionButtonsCancelBookingLinkAnalytics(displayName);
    };

    const handleAddToCalendar = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { activityName, storeName, duration, type } = calendarInfo;

        addToCalendar(startDateTime, duration, activityName, storeName, {
            activityId: confirmationNumber,
            type
        });

        HappeningBindings.actionButtonsAddToCalendarLinkAnalytics(activityName);
    };

    const handleBeautyAdvisorRecsPage = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();

            Location.navigateTo(e, '/in-store-services');
        } else {
            Location.navigateTo(null, '/in-store-services');
        }
    };

    const padding = size === 'md' ? 20 : 12;

    const buttonsInventory = {
        addToCal: {
            download: 'zoom-meeting.ics',
            onClick: handleAddToCalendar
        },
        cancelRsvp: {
            onClick: handleOnOpenRsvpModal
        },
        reschedule: {
            onClick: handleReschedule
        },
        cancel: {
            onClick: handleOnOpenCancelModal
        },
        bookAgain: {
            onClick: redirectToBookAgain
        },
        productRecs: {
            onClick: handleBeautyAdvisorRecsPage
        },
        rsvp: {
            onClick: confirmEventRSVP
        }
    };

    return (
        <Flex
            display={'flex'}
            alignItems={'center'}
            flexDirection={['row']}
            flexWrap={['wrap', 'wrap', 'nowrap']}
            gap={2}
            {...wrapperProps}
        >
            {buttons.map((buttonName, index) => {
                const defaultButtonProps = buttonsInventory[buttonName];
                const defaultVariant = index === 0 ? 'primary' : 'secondary';

                const variant = styles[variantStyle] ? variantStyle : defaultVariant;

                return (
                    <Button
                        key={`reservation-detail-btn-${buttonName}-${index}`}
                        width={['100%', '100%', 'fit-content']}
                        minWidth={['100%', '100%', width]}
                        size={size}
                        paddingLeft={padding}
                        paddingRight={padding}
                        variant={variant}
                        {...(!buttonProps?.css && { css: styles[variant] })}
                        {...defaultButtonProps}
                        {...buttonProps}
                    >
                        {getText(buttonName)}
                    </Button>
                );
            })}
        </Flex>
    );
}

const styles = {
    primary: {
        border: 0,
        [mediaQueries.smMax]: {
            width: '100%'
        }
    },
    secondary: {
        [mediaQueries.smMax]: {
            flex: 1
        }
    }
};

export default wrapFunctionalComponent(ActionButtons, 'ActionButtons');
