export default function getResource(label, vars = []) {
    const resources = {
        modalTitle: 'Ramasser',
        itemOutOfStock: 'Cet article est en rupture de stock au magasin sélectionné.',
        pickupConfirmation: 'Voulez-vous ramasser cet article au magasin ci-dessous?',
        choosePickupStore: 'Veuillez choisir un autre magasin pour le ramassage.',
        chooseStoreWithPickup: 'Veuillez choisir un magasin qui offre le ramassage.',
        checkAvailability: 'Vérifiez la disponibilité',
        chooseStore: 'Choisir un magasin',
        confirm: 'Confirmer',
        cancel: 'Annuler'
    };

    return resources[label];
}
