export default function getResource(label, vars = []) {
    const resources = {
        services: 'Services',
        classes: 'Ateliers',
        events: 'Événements',
        whatsNew: 'Nouveautés',
        allStores: 'Tous les magasins',
        bannerText: 'Services, ateliers, événements incontournables et plus—consultez le programme des magasins à proximité.',
        defaultDate: '2 prochaines semaines',
        cityStateZip: 'Ville et état ou code postal',
        store: 'Magasin',
        date: 'Date',
        storePickerDescription: 'La mise à jour de l’emplacement mettra automatiquement à jour les expériences affichées.'
    };
    return resources[label];
}
