import ufeApi from 'services/api/ufeApi';
import biProfile from 'utils/BiProfile';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+BI+Points+API

function getBiPoints(userProfileId, source = 'profile') {
    let url = `/api/bi/profiles/${userProfileId}/points?source=${source}`;

    if (source === biProfile.SOURCES.ORDER_CONFIRMATION) {
        url += '&isLastOrder=true';
    }

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getBiPoints;
