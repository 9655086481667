import { connect } from 'react-redux';
import FrameworkUtils from 'utils/framework';
import ProductActions from 'actions/ProductActions';

const { wrapHOC } = FrameworkUtils;
const { fireProductViewableImpressionTracking } = ProductActions;
const { createStructuredSelector } = require('reselect');
const { openRewardsBazaarModal } = require('actions/RwdBasketActions').default;
const { getTextFromResource, getLocaleResourceFile } = require('utils/LanguageLocale').default;
const itemLevelBasketErrors = require('selectors/basket/itemLevelBasketErrors').default;
const getText = getLocaleResourceFile('components/Product/ProductCard/locales', 'ProductCard');

const localization = createStructuredSelector({
    free: getTextFromResource(getText, 'free'),
    value: getTextFromResource(getText, 'value')
});

const fields = createStructuredSelector({ localization, itemLevelBasketErrors });

const functions = {
    fireProductViewableImpressionTracking,
    openRewardsBazaarModal
};

const withProductCardProps = wrapHOC(connect(fields, functions));

export {
    functions, withProductCardProps
};
