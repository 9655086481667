import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

import {
    Box, Flex, Image, Text
} from 'components/ui';
import LegacyGrid from 'components/LegacyGrid/LegacyGrid';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import Radio from 'components/Inputs/Radio/Radio';
import ContentDivider from 'components/RichProfile/EditMyProfile/Content/ContentDivider';
import ContentHeading from 'components/RichProfile/EditMyProfile/Content/ContentHeading';
import localeUtils from 'utils/LanguageLocale';
import { CATEGORY_TYPE } from 'constants/beautyPreferences';

class Skin extends BaseClass {
    constructor(props) {
        super(props);

        const biDataToSave = this.props.biDataToSave || {};
        const biAccountToSave = biDataToSave.biAccount || {};
        const personalizedInfoToSave = biAccountToSave.personalizedInformation || {};

        let savedConcerns = [];

        if (personalizedInfoToSave.skinConcerns) {
            savedConcerns = personalizedInfoToSave.skinConcerns;
        } else {
            const skinConcerns = this.props.biAccount.personalizedInformation.skinConcerns;
            skinConcerns.forEach(skinConcern => {
                if (skinConcern.isSelected) {
                    savedConcerns.push(skinConcern.value);
                }
            });
        }

        let savedSkinTone;

        if (personalizedInfoToSave.skinTone) {
            savedSkinTone = personalizedInfoToSave.skinTone;
        } else {
            const skinTones = this.props.biAccount.personalizedInformation.skinTone;
            skinTones.forEach(skinTone => {
                if (skinTone.isSelected) {
                    savedSkinTone = skinTone.value;
                }
            });
        }

        let savedSkinType;

        if (personalizedInfoToSave.skinType) {
            savedSkinType = personalizedInfoToSave.skinType;
        } else {
            const skinTypes = this.props.biAccount.personalizedInformation.skinType;
            skinTypes.forEach(skinType => {
                if (skinType.isSelected) {
                    savedSkinType = skinType.value;
                }
            });
        }

        this.state = {
            skinTone: savedSkinTone,
            skinType: savedSkinType,
            skinConcerns: savedConcerns
        };
    }

    handleSkinConcernSelect = (skinConcern, e) => {
        const concerns = this.state.skinConcerns.slice();

        if (e.target.checked) {
            concerns.push(skinConcern.value);
        } else {
            const itemToRemove = concerns.indexOf(skinConcern.value);
            concerns.splice(itemToRemove, 1);
        }

        this.setState({ skinConcerns: concerns });
    };

    getData = () => {
        const skinData = {
            biAccount: {
                personalizedInformation: {
                    skinType: this.state.skinType,
                    skinTone: this.state.skinTone,
                    skinConcerns: this.state.skinConcerns
                }
            }
        };

        return skinData;
    };

    handleSkinToneChange = skinTone => () => {
        this.setState({
            skinTone: skinTone
        });
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/RichProfile/EditMyProfile/Content/Skin/locales', 'Skin');
        const { excludeSkinType, excludeSkinConcerns, excludeSkinTone } = this.props;
        const { NOT_SURE } = CATEGORY_TYPE;

        const skinTones = this.props.biAccount.personalizedInformation.skinTone.filter(tone => tone.value.indexOf(NOT_SURE) < 0);
        const skinTypes = this.props.biAccount.personalizedInformation.skinType;
        const skinConcerns = this.props.biAccount.personalizedInformation.skinConcerns.filter(concern => concern.value.indexOf(NOT_SURE) < 0);

        const gutter = 4;
        const cellWidth = Sephora.isMobile() ? 1 / 2 : 1 / 3;

        return (
            <div>
                {excludeSkinTone || (
                    <div
                        role='group'
                        aria-labelledby='profile_heading_skinTone'
                    >
                        <ContentHeading
                            id='profile_heading_skinTone'
                            parens={getText('selectOne')}
                        >
                            {getText('skinTone')}
                        </ContentHeading>
                        <LegacyGrid gutter={gutter}>
                            {skinTones.map(skinTone => (
                                //revisit this
                                <LegacyGrid.Cell
                                    key={skinTone.value}
                                    width={cellWidth}
                                    marginTop={5}
                                >
                                    <Radio
                                        hasHover={true}
                                        hasDot={false}
                                        paddingY={null}
                                        name='skinTone'
                                        checked={this.state.skinTone === skinTone.value}
                                        onChange={this.handleSkinToneChange(skinTone.value)}
                                    >
                                        <Flex alignItems='center'>
                                            <Box
                                                border='3px solid'
                                                borderColor={this.state.skinTone === skinTone.value ? 'black' : 'transparent'}
                                                borderRadius='50%'
                                            >
                                                <Image
                                                    display='block'
                                                    src={`/img/ufe/rich-profile/skintone-${skinTone.value.toLowerCase()}.png`}
                                                    size={36}
                                                />
                                            </Box>
                                            <Text marginLeft={4}>{skinTone.displayName}</Text>
                                        </Flex>
                                    </Radio>
                                </LegacyGrid.Cell>
                            ))}
                        </LegacyGrid>
                    </div>
                )}

                {excludeSkinType || (
                    <div
                        role='group'
                        aria-labelledby='profile_heading_skinType'
                    >
                        {excludeSkinTone || <ContentDivider />}
                        <ContentHeading
                            id='profile_heading_skinType'
                            parens={getText('selectOne')}
                        >
                            {getText('skinType')}
                        </ContentHeading>
                        <LegacyGrid gutter={gutter}>
                            {skinTypes.map(skinType => (
                                <LegacyGrid.Cell
                                    key={skinType.value}
                                    width={cellWidth}
                                    marginTop={4}
                                >
                                    <Radio
                                        hasHover={true}
                                        name='skinType'
                                        checked={this.state.skinType === skinType.value}
                                        onChange={() =>
                                            this.setState({
                                                skinType: skinType.value
                                            })
                                        }
                                    >
                                        {skinType.displayName}
                                    </Radio>
                                </LegacyGrid.Cell>
                            ))}
                        </LegacyGrid>
                    </div>
                )}

                {excludeSkinConcerns || (
                    <div
                        role='group'
                        aria-labelledby='profile_heading_skinConcern'
                    >
                        {(!excludeSkinType || !excludeSkinTone) && <ContentDivider />}
                        <ContentHeading
                            id='profile_heading_skinConcern'
                            parens={getText('selectAllApply')}
                            isMyProfile={true}
                        >
                            {getText('skincareConcerns')}
                        </ContentHeading>
                        <LegacyGrid gutter={gutter}>
                            {skinConcerns.map(skinConcern => (
                                <LegacyGrid.Cell
                                    key={skinConcern.value}
                                    width={cellWidth}
                                    marginTop={4}
                                >
                                    <Checkbox
                                        hasHover={true}
                                        checked={this.state.skinConcerns.indexOf(skinConcern.value) !== -1}
                                        onClick={e => {
                                            this.handleSkinConcernSelect(skinConcern, e);
                                        }}
                                    >
                                        {skinConcern.displayName}
                                    </Checkbox>
                                </LegacyGrid.Cell>
                            ))}
                        </LegacyGrid>
                    </div>
                )}
            </div>
        );
    }
}

export default wrapComponent(Skin, 'Skin');
