import ufeApi from 'services/api/ufeApi';

function getStoreDetailsPageContent(token, { country, storeName }) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    // TO DO: Need to get updated API URL
    const url = `${sdnDomain}/v1/util/stores/${storeName}/seoName?showContent=true&includeRegionsMap=true&countryCode=${country}`;

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: { 'x-requested-source': 'rwd', authorization: `Bearer ${token}` }
        })
        .then(data => data);
}

export default { getStoreDetailsPageContent };
