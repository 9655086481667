import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import dateUtils from 'utils/Date';
import { Flex, Text, Link } from 'components/ui';
import Calendar from 'components/Calendar/Calendar';
import localeUtils from 'utils/LanguageLocale';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import DateUtils from 'utils/Date';

const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/ExperienceDetailPage/DatePickerWidget/locales', 'DatePickerWidget');

const DatePickerWidget = props => {
    const {
        selectedDay, onDayClick, isModal, disabledDays, availableDays, onSeeMoreDaysClick
    } = props;

    const isMobile = Sephora.isMobile();
    const lastAvailableDay = DateUtils.addDays(new Date(), ACTIVITY.ACTIVITIES_STARTDATE_MAX);

    let displaySelectedDay = '';

    if (selectedDay) {
        displaySelectedDay = isModal
            ? dateUtils.getWeekdaysArray()[selectedDay.getDay()] +
              ', ' +
              dateUtils.getMonthArray()[selectedDay.getMonth()] +
              ' ' +
              selectedDay.getDate()
            : dateUtils.getShortenedWeekdaysArray()[selectedDay.getDay()] +
              ', ' +
              dateUtils.getShortenedMonthArray()[selectedDay.getMonth()] +
              ' ' +
              selectedDay.getDate();
    }

    return (
        <div
            aria-labelledby='date_picker_label'
            aria-describedby='date_picker_desc'
        >
            <p
                id='date_picker_desc'
                style={{ display: 'none' }}
            >
                {getText('automaticUpdate')}
            </p>
            <Flex
                lineHeight='tight'
                justifyContent='space-between'
                alignItems='baseline'
            >
                <Flex
                    id='date_picker_label'
                    data-at={Sephora.debug.dataAt('selected_date')}
                    alignItems='baseline'
                >
                    <Text
                        fontWeight='bold'
                        children={`1. ${getText('chooseDate')}`}
                    />
                    {!(isMobile && isModal) && (
                        <React.Fragment>
                            <Text
                                marginX={2}
                                children='•'
                            />
                            {displaySelectedDay}
                        </React.Fragment>
                    )}
                </Flex>
                {isModal || (
                    <Link
                        color='blue'
                        padding={2}
                        margin={-2}
                        onClick={onSeeMoreDaysClick}
                        children={getText('seeMoreDates')}
                    />
                )}
            </Flex>
            <Calendar
                selectedDay={selectedDay}
                isWeekView={!isModal}
                disabledDays={disabledDays}
                monthsInView={isMobile || !isModal ? 1 : 2}
                maxMonths={4}
                availableDays={availableDays}
                lastAvailableDay={lastAvailableDay}
                onDayClick={onDayClick}
            />
        </div>
    );
};

export default wrapFunctionalComponent(DatePickerWidget, 'DatePickerWidget');
