import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import { notSureOption, noPreferenceOption, PREFERENCE_TYPES } from 'constants/beautyPreferences';

const { wrapHOC } = FrameworkUtils;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/Header/BeautyPreferences/NotSureOrPreference/locales', 'NotSureOrPreference');

const fields = createSelector(
    (_state, ownProps) => ownProps.profileCategory,
    (_state, ownProps) => ownProps.selectedOptions,
    createStructuredSelector({
        selectedOptionName: (state, ownProps) => {
            const selectedOptionValue = ownProps.profileCategory.hasNotSureOption ? notSureOption : noPreferenceOption;

            return getTextFromResource(getText, selectedOptionValue)(state);
        }
    }),
    (profileCategory, selectedOptions, textResources) => {
        const selectedOption = profileCategory.hasNotSureOption ? notSureOption : noPreferenceOption;
        const isIngredientPref = profileCategory.type === PREFERENCE_TYPES.INGREDIENT_PREFERENCES;
        const isFavBrands = profileCategory.type === PREFERENCE_TYPES.FAVORITE_BRANDS;
        const isSelected = selectedOptions.find(option => option === selectedOption) && !isIngredientPref && !isFavBrands;

        return {
            ...textResources,
            selectedOption,
            isSelected
        };
    }
);

const withNotSureOrPreferenceProps = wrapHOC(connect(fields));

export {
    fields, withNotSureOrPreferenceProps
};
