import personalizedPromotions from 'reducers/personalizedPromotions';
const { ACTION_TYPES: TYPES } = personalizedPromotions;
import ProfileApi from 'services/api/profile';
import UserUtils from 'utils/User';
import Storage from 'utils/localStorage/Storage';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import CacheUtils from 'utils/Cache';
const DEFAULT_EXPIRY = Storage.MINUTES * 60;
const DELAY = Storage.MINUTES * 5;
const PST_OFFSET = 8;
import localeUtils from 'utils/LanguageLocale';

const updatePersonalizedPromotions = data => ({
    type: TYPES.UPDATE_PERSONALIZED_PROMOTIONS,
    data: data
});

const createEmptyPayload = profileId => ({
    profileId: profileId,
    items: [],
    loading: false
});

const { isBeautyOffersEnabled } = Sephora.configurationSettings;

const getNonNullPromos = allPromos => {
    const nonNullPromos = [];

    for (const promo of allPromos) {
        if (promo !== null) {
            nonNullPromos.push(promo);

            if (nonNullPromos.length === 5) {
                break;
            }
        }
    }

    return nonNullPromos;
};

const loadPersonalizedPromotions = (showSephoraMLModelV2Promos, isTestTargetReady) => (dispatch, getState) => {
    const {
        user,
        user: { profileId }
    } = getState();

    const payload = createEmptyPayload();
    payload.loading = true;
    dispatch(updatePersonalizedPromotions(payload));

    let cachedPromotions = null;

    if (showSephoraMLModelV2Promos) {
        cachedPromotions = Storage.local.getItem(LOCAL_STORAGE.PERSONALIZED_PROMOTIONS_V2, false, true);
    } else {
        cachedPromotions = Storage.local.getItem(LOCAL_STORAGE.PERSONALIZED_PROMOTIONS, false, true);
    }

    const isUS = localeUtils.isUS();

    if (UserUtils.isAnonymous(user)) {
        dispatch(updatePersonalizedPromotions(createEmptyPayload()));
    } else if (cachedPromotions && cachedPromotions.profileId === profileId) {
        dispatch(updatePersonalizedPromotions(cachedPromotions));
    } else {
        isTestTargetReady &&
            ProfileApi.getPersonalizedPromotions(profileId, isUS, showSephoraMLModelV2Promos)
                .then(data => {
                    const nonNullPromos = getNonNullPromos(data.allPromos);
                    const showPromos = isBeautyOffersEnabled && isUS;
                    const freshData = {
                        profileId,
                        items: showPromos ? nonNullPromos : data.personalizedPromotions || [],
                        loading: false,
                        hasNewPersonalizedPromotions: false
                    };
                    const userData = Storage.local.getItem(LOCAL_STORAGE.USER_DATA, false, false, true);

                    if (userData?.data?.personalizedPromotions?.hasNewPersonalizedPromotions) {
                        userData.data.personalizedPromotions.hasNewPersonalizedPromotions = false;
                        Storage.local.setItem(LOCAL_STORAGE.USER_DATA, userData.data, userData.expiry);
                    }

                    const expiry = CacheUtils.getLessOfTillMidnightOrDefault(DEFAULT_EXPIRY, DELAY, PST_OFFSET);

                    if (showSephoraMLModelV2Promos) {
                        Storage.local.setItem(LOCAL_STORAGE.PERSONALIZED_PROMOTIONS_V2, freshData, expiry);
                    } else {
                        Storage.local.setItem(LOCAL_STORAGE.PERSONALIZED_PROMOTIONS, freshData, expiry);
                    }

                    dispatch(updatePersonalizedPromotions(freshData));
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    dispatch(updatePersonalizedPromotions(createEmptyPayload(profileId)));
                });
    }
};

export default {
    TYPES,
    loadPersonalizedPromotions,
    updatePersonalizedPromotions
};
