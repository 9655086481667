import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';

import {
    radii, colors, fontSizes, space, modal
} from 'style/config';

import AccountGreeting from 'components/Header/AccountGreeting';
import {
    Box, Flex, Link, Image, Divider, Text, Icon, Grid, Button
} from 'components/ui';
import Markdown from 'components/Markdown/Markdown';
import Dropdown from 'components/Dropdown/Dropdown';
import Avatar from 'components/Avatar';
import MyOffersLink from 'components/MyOffers/MyOffersLink';

/* utils */
import userUtils from 'utils/User';
import localeUtils from 'utils/LanguageLocale';
import anaUtils from 'analytics/utils';
import authUtils from 'utils/Authentication';
import Location from 'utils/Location';

import anaConsts from 'analytics/constants';
import store from 'store/Store';
import { DebouncedScroll, HydrationFinished } from 'constants/events';

import { APPROVAL_STATUS } from 'constants/CreditCard';
import { SHOW_FORCED_SIGN_IN_TOOLTIP } from 'constants/TestTarget';

import NotificationDot from 'components/NotificationDot';
import Badge from 'components/Badge';

const {
    PAGE_NAMES: { BEAUTY_PREFERENCES, BEAUTY_CHALLENGES }
} = anaConsts;

const LINK_PROPS = {
    display: 'flex',
    paddingY: 3,
    paddingX: 4,
    width: '100%',
    hoverSelector: '.Link-target'
};

const ICON_PROPS = {
    size: 24,
    marginRight: 3,
    marginTop: '2px'
};

const BUY_IT_AGAIN_IMAGE_PROPS = {
    size: 48,
    marginRight: 3,
    marginTop: '8px'
};

class AccountMenu extends BaseClass {
    state = {
        isDropOpen: false,
        isAnonymous: null,
        user: {},
        isUserReady: false,
        isBirthdayGiftEligible: null,
        creditCardInfo: null,
        isScrollActive: false,
        showForcedSignIn: false,
        showBuyItAgainImages: Sephora.isMobile() && !userUtils.isAnonymous(),
        purchaseHistory: {},
        hasFetchedPurchaseHistory: false
    };

    scrollRef = React.createRef();
    signInTooltipRef = React.createRef();
    signInTooltipCreateAccountRef = React.createRef();
    signInTooltipSignInRef = React.createRef();

    joinBi = e => {
        e.preventDefault();
        this.props.showBiRegisterModal({
            isOpen: true,
            callback: this.props.onDismiss ? this.props.onDismiss : null
        });
    };

    signIn = () => {
        const navInfo = anaUtils.buildNavPath(['top nav', 'account', 'sign-in']);
        this.props.showSignInModal({
            isOpen: true,
            analyticsData: { navigationInfo: navInfo },
            source: authUtils.SIGN_IN_SOURCES.ACCOUNT_GREETING
        });
    };

    /* eslint-disable-next-line complexity */
    render() {
        const {
            isAnonymous, isDropOpen, isScrollActive, showForcedSignIn, showBuyItAgainImages
        } = this.state;

        const { user, localization, showForcedSignInTooltip, showBlackSearchHeader } = this.props;
        const beautyInsiderAccount = user && user.beautyInsiderAccount;
        const biPoints = (beautyInsiderAccount && beautyInsiderAccount.promotionPoints) || 0;
        const biStatus = beautyInsiderAccount && userUtils.displayBiStatus(beautyInsiderAccount.vibSegment);
        const userFirstName = user?.firstName ?? beautyInsiderAccount?.firstName;
        const userLastName = user?.lastName ?? beautyInsiderAccount?.lastName;

        const menu = this.renderMenu({
            biPoints,
            biStatus,
            showBuyItAgainImages
        });

        const signInTest = showForcedSignIn && showForcedSignInTooltip && this.renderForcedSignTest();

        const logoSrc = beautyInsiderAccount && `/img/ufe/bi/logo-${biStatus.toLowerCase()}${showBlackSearchHeader ? '-white' : ''}.svg`;

        if (!this.props.dropWidth) {
            return menu;
        } else {
            return (
                <div>
                    <Dropdown
                        id='account_drop'
                        hasCustomScroll={true}
                        hasMaxHeight={true}
                        height='100%'
                        style={!this.state.isUserReady ? { visibility: 'hidden' } : null}
                        onTrigger={(e, isDropdownOpen) => {
                            if (this.scrollRef && this.scrollRef.current) {
                                this.scrollRef.current.scrollTop = 0;
                            }

                            this.setState({ isDropOpen: isDropdownOpen });
                        }}
                    >
                        <Dropdown.Trigger
                            display='flex'
                            alignItems='center'
                            paddingX={4}
                            lineHeight='tight'
                            height='100%'
                            minWidth='13em'
                            onClick={isAnonymous ? () => this.signIn() : null}
                        >
                            <Avatar
                                {...(isAnonymous && { src: `/img/ufe/icons/me${isDropOpen || showBlackSearchHeader ? '-active' : '32'}.svg` })}
                                marginY={-1}
                                marginRight={3}
                                size={32}
                                isOutlined={isDropOpen}
                                style={{ opacity: !isDropOpen && isAnonymous && !showBlackSearchHeader ? 0.6 : null }}
                            />
                            <span style={isDropOpen ? { boxShadow: `0 2px 0 0 ${showBlackSearchHeader ? colors.white : colors.black}` } : null}>
                                {user && userFirstName ? (
                                    <>
                                        {localization.greeting},{' '}
                                        {userFirstName.length > 8 ? `${userFirstName.charAt(0)}.${userLastName.charAt(0)}.` : userFirstName}
                                        <NotificationDot
                                            marginTop='-1px'
                                            marginLeft='1px'
                                        />
                                        {beautyInsiderAccount ? (
                                            <Text
                                                fontSize='sm'
                                                display='block'
                                            >
                                                <Image
                                                    src={logoSrc}
                                                    alt={biStatus}
                                                    height='.725em'
                                                />{' '}
                                                <strong>{biPoints.toLocaleString()}</strong> pt{biPoints !== 1 ? 's' : ''}
                                            </Text>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        <Text
                                            fontSize='sm'
                                            fontWeight='bold'
                                            display='block'
                                            data-at={Sephora.debug.dataAt('sign_in_header')}
                                        >
                                            {localization.signIn}
                                            <NotificationDot
                                                marginTop='-1px'
                                                marginLeft='1px'
                                            />
                                        </Text>
                                        <Text
                                            fontSize='xs'
                                            display='block'
                                            children={`${localization.signInPrompt} 🚚`}
                                        />
                                    </>
                                )}
                            </span>
                        </Dropdown.Trigger>
                        <Dropdown.Menu
                            width={this.props.dropWidth}
                            align='center'
                            paddingTop={4}
                        >
                            <Box
                                paddingX={4}
                                paddingBottom={2}
                                style={
                                    isScrollActive
                                        ? {
                                            borderBottom: `1px solid ${colors.lightGray}`
                                        }
                                        : null
                                }
                            >
                                <AccountGreeting />
                            </Box>
                            <Box
                                paddingTop={1}
                                paddingBottom={4}
                                lineHeight='tight'
                                overflowY='auto'
                                onScroll={this.handleScroll}
                                ref={this.scrollRef}
                            >
                                {menu}
                            </Box>
                        </Dropdown.Menu>
                    </Dropdown>
                    {signInTest}
                </div>
            );
        }
    }

    handleScroll = () => {
        const refData = this.scrollRef && this.scrollRef.current;
        const { isScrollActive } = this.state;

        if (refData && refData.scrollTop > 0) {
            !isScrollActive &&
                this.setState({
                    isScrollActive: true
                });
        } else {
            isScrollActive &&
                this.setState({
                    isScrollActive: false
                });
        }
    };

    /* eslint-disable-next-line complexity */
    renderMenu = ({ biPoints, biStatus, showBuyItAgainImages }) => {
        const { isAnonymous, creditCardInfo, isBirthdayGiftEligible } = this.state;
        const { user, localization, profileCompletionPercentage } = this.props;
        const { biCashLockUpMsg, beautyInsiderAccount, ccRewards } = user;
        const { bankRewards } = ccRewards ?? {};
        const ccRewardsAmount = userUtils.getRewardsAmount(bankRewards);
        const isUserSignedInRecognized = userUtils.isSignedIn() || userUtils.isRecognized();
        const sameDayUnlimitedSubscription = user.userSubscriptions?.filter(
            subscription => subscription.type === 'SDU' && subscription.status === 'ACTIVE'
        );
        const isSDUMember = isUserSignedInRecognized && sameDayUnlimitedSubscription?.length > 0;
        const isSDUNonMember = isUserSignedInRecognized && sameDayUnlimitedSubscription?.length === 0;
        const SDUUrl = isSDUMember ? '/profile/MyAccount/SameDayUnlimited' : '/product/subscription-same-day-unlimited-P483900';
        const isMyOffersModuleEnabled = Sephora?.configurationSettings?.isMyOffersModuleEnabled;
        const hasBINotifications = isBirthdayGiftEligible || biCashLockUpMsg || isMyOffersModuleEnabled;
        const hasAutoReplenishSubscriptions =
            Array.isArray(user.subscriptionSummary) &&
            user.subscriptionSummary?.some(subscription => subscription.type === 'REPLENISHMENT' && (subscription.active || subscription.paused));
        const showAutoReplenishDescWithSubs = (isUserSignedInRecognized && hasAutoReplenishSubscriptions) || false;
        const isAutoReplenishEmptyHubEnabled = Sephora.configurationSettings.isAutoReplenishEmptyHubEnabled || false;

        return this.state.isUserReady ? (
            <>
                <Divider />
                <Link
                    href='/profile/BeautyPreferences'
                    onClick={() => this.trackNavClick(BEAUTY_PREFERENCES)}
                    {...LINK_PROPS}
                >
                    <div css={styles.linkInner}>
                        <Flex alignItems='center'>
                            <Image
                                src='/img/ufe/icons/beauty-traits.svg'
                                size={24}
                                marginRight={2}
                            />
                            <div
                                className='Link-target'
                                children={localization.beautyPrefHeading}
                            />
                            <Box
                                backgroundColor='lightGray'
                                borderRadius={2}
                                overflow='hidden'
                                height={9}
                                width='36.75%'
                                marginLeft='auto'
                            >
                                <Box
                                    backgroundColor='green'
                                    height='100%'
                                    style={{
                                        width: `${profileCompletionPercentage}%`
                                    }}
                                />
                            </Box>
                        </Flex>
                        <div
                            css={[styles.desc, { marginTop: '.375em' }]}
                            children={
                                Sephora.configurationSettings.enableGuidedSelling
                                    ? localization.guidedSellingBeautyPrefDesc
                                    : localization.beautyPrefDesc
                            }
                        />
                    </div>
                </Link>
                <Divider />
                {beautyInsiderAccount ? (
                    <Link
                        href='/profile/BeautyInsider'
                        onClick={() => this.trackNavClick('Beauty Insider')}
                        {...LINK_PROPS}
                    >
                        <div
                            css={styles.linkInner}
                            data-at={Sephora.debug.dataAt('rb_link_text')}
                        >
                            <div
                                className='Link-target'
                                children={localization.biHeading}
                            />
                            <div
                                css={styles.desc}
                                className='Link-target'
                                children={localization.biDesc}
                            />
                            {isBirthdayGiftEligible && (
                                <div
                                    css={styles.pill}
                                    data-at={Sephora.debug.dataAt('rb_birth_text')}
                                    children={localization.chooseBirthdayGift}
                                />
                            )}
                            {biCashLockUpMsg && (
                                <div css={styles.pill}>
                                    <Markdown
                                        content={biCashLockUpMsg.replace(/\*{2}/g, '*')}
                                        data-at={Sephora.debug.dataAt('cbr_message')}
                                    />
                                </div>
                            )}
                            {isMyOffersModuleEnabled && (
                                <Box
                                    marginTop={1}
                                    fontSize='sm'
                                    css={{ '&:empty': { display: 'none' } }}
                                >
                                    <MyOffersLink
                                        onDismiss={this.props.onDismiss}
                                        data-at={Sephora.debug.dataAt('view_exclusive_offers_link')}
                                    />
                                </Box>
                            )}
                        </div>
                        <Image
                            display='inline-block'
                            data-at={Sephora.debug.dataAt('bi_logo')}
                            src={`/img/ufe/bi/logo-${biStatus.toLowerCase()}.svg`}
                            alt={biStatus}
                            height={12}
                            {...(!hasBINotifications && { marginY: 'auto' })}
                            {...(hasBINotifications && { marginTop: 3 })}
                        />
                    </Link>
                ) : (
                    <Link
                        href='/profile/BeautyInsider'
                        onClick={!isAnonymous ? this.joinBi : null}
                        {...LINK_PROPS}
                    >
                        <div
                            css={styles.linkInner}
                            data-at={Sephora.debug.dataAt('rb_link_text')}
                        >
                            <div
                                className='Link-target'
                                children={localization.biHeading}
                            />
                            <div
                                css={styles.desc}
                                className={isAnonymous ? 'Link-target' : null}
                                children={localization.biDesc}
                            />
                        </div>
                        {isAnonymous ? (
                            <Image
                                display='block'
                                src='/img/ufe/bi/logo-insider.svg'
                                alt='Beauty Insider'
                                height={12}
                                marginY='auto'
                            />
                        ) : (
                            <Text
                                color='blue'
                                fontSize='sm'
                                className='Link-target'
                                children={localization.joinNow}
                            />
                        )}
                    </Link>
                )}
                <Divider />
                <Link
                    href='/rewards'
                    onClick={() => this.trackNavClick('rewards bazaar')}
                    {...LINK_PROPS}
                >
                    <div
                        css={styles.linkInner}
                        data-at={Sephora.debug.dataAt('rb_link_text')}
                    >
                        <div
                            className='Link-target'
                            children={localization.rewardsHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.rewardsDesc}
                        />
                    </div>
                    <Flex
                        flexDirection='column'
                        alignItems='end'
                    >
                        <strong>{`${biPoints.toLocaleString()} `}</strong>
                        <span>
                            {localization.pointsLabel}
                            {biPoints !== 1 ? 's' : ''}
                        </span>
                    </Flex>
                </Link>
                {this.isCreditCardEnabled() && creditCardInfo && creditCardInfo.ccApprovalStatus === APPROVAL_STATUS.APPROVED ? (
                    <>
                        <Divider />
                        <Link
                            href='/profile/CreditCard'
                            onClick={() => this.trackNavClick('credit card section join')}
                            {...LINK_PROPS}
                        >
                            <div className='Link-target'>
                                {localization.ccHeading}
                                {bankRewards && (
                                    <>
                                        <b> • </b>
                                        <span>
                                            <strong>{`$${ccRewardsAmount} `}</strong>
                                            {localization.ccRewardsLabel}
                                        </span>
                                    </>
                                )}
                            </div>
                            <div
                                css={styles.desc}
                                className='Link-target'
                                children={localization.ccDesc}
                            />
                        </Link>
                    </>
                ) : null}
                {Sephora.configurationSettings.isGamificationEnabled && (
                    <>
                        <Divider />
                        <Link
                            href='/beauty/challenges'
                            className='ActionableTarget'
                            onClick={e => {
                                this.trackNavClick(BEAUTY_CHALLENGES);
                                Location.navigateTo(e, '/beauty/challenges');
                                this.props.onDismiss();
                            }}
                            {...LINK_PROPS}
                        >
                            <div css={styles.linkInner}>
                                <div className='Link-target'>
                                    <Flex>
                                        <p>{localization.beautyChallenges}</p>
                                        <Box marginLeft={2}>
                                            <Badge
                                                badge={localization.new}
                                                color={colors.black}
                                            />
                                        </Box>
                                    </Flex>
                                </div>
                                <div
                                    css={[styles.desc]}
                                    children={localization.beautyChallengesDescription}
                                />
                            </div>
                        </Link>
                    </>
                )}
                <Divider thick={true} />
                <Link
                    href='/purchase-history'
                    onClick={() => this.trackNavClick('purchase history')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/subscription.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.purchasesHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.purchasesDesc}
                        />
                        {showBuyItAgainImages && this.renderBuyItAgainImages()}
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/profile/MyAccount/Orders'
                    onClick={() => this.trackNavClick('orders')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/track.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.ordersHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.ordersDesc}
                        />
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/profile/MyAccount/AutoReplenishment'
                    onClick={() => this.trackNavClick('auto-replenish')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/auto-replenish.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.autoReplenishHeading}
                        />
                        <div
                            data-at={Sephora.debug.dataAt('auto-replenish-description')}
                            css={styles.desc}
                            children={
                                !isAutoReplenishEmptyHubEnabled || showAutoReplenishDescWithSubs
                                    ? localization.autoReplenishDescWithSubs
                                    : localization.autoReplenishDesc
                            }
                        />
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/shopping-list'
                    onClick={() => this.trackNavClick('loves')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/heart.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.lovesHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.lovesDesc}
                        />
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/profile/MyAccount'
                    onClick={() => this.trackNavClick('account settings')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/account.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.accountHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.accountDesc}
                        />
                    </div>
                </Link>
                {this.isCreditCardEnabled() &&
                (isAnonymous || !userUtils.isBI() || (creditCardInfo && creditCardInfo.ccApprovalStatus !== APPROVAL_STATUS.APPROVED)) ? (
                        <>
                            <Divider />
                            <Link
                                href='/profile/CreditCard'
                                onClick={() => this.trackNavClick('Credit Card Section Join')}
                                {...LINK_PROPS}
                            >
                                <Image
                                    src='/img/ufe/icons/cc-outline.svg'
                                    {...ICON_PROPS}
                                />
                                <div css={styles.linkInner}>
                                    <div
                                        className='Link-target'
                                        children={localization.ccHeading}
                                    />
                                    <div
                                        css={styles.desc}
                                        children={localization.ccApplyNow}
                                    />
                                </div>
                            </Link>
                        </>
                    ) : null}
                <Divider
                    color='nearWhite'
                    height={3}
                />
                <>
                    <Link
                        onClick={() => this.trackNavClick('same-day-unlimited')}
                        href={SDUUrl}
                        {...LINK_PROPS}
                    >
                        <Icon
                            name='bag'
                            {...ICON_PROPS}
                        />
                        <div css={styles.linkInner}>
                            <div
                                className='Link-target'
                                children={localization.sameDayUnlimitedHeading}
                            />
                            <div
                                css={styles.desc}
                                children={
                                    localization[
                                        isSDUMember
                                            ? 'sameDayUnlimitedMemberDesc'
                                            : isSDUNonMember
                                                ? 'subscribeToday'
                                                : 'sameDayUnlimitedNonMemberDesc'
                                    ]
                                }
                            />
                        </div>
                    </Link>
                    <Divider />
                </>
                <Link
                    onClick={this.handleOnTrackNavClick('reservations', '/happening/reservations')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/reservations.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.reservationsHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.reservationsDesc}
                        />
                    </div>
                </Link>
                <Divider />
                <Link
                    href='/in-store-services'
                    onClick={() => this.trackNavClick('beauty advisor recommendations')}
                    {...LINK_PROPS}
                >
                    <Image
                        src='/img/ufe/icons/recommendations.svg'
                        {...ICON_PROPS}
                    />
                    <div css={styles.linkInner}>
                        <div
                            className='Link-target'
                            children={localization.recHeading}
                        />
                        <div
                            css={styles.desc}
                            children={localization.recDesc}
                        />
                    </div>
                </Link>
                {isAnonymous || (
                    <>
                        <Divider />
                        <Link
                            {...LINK_PROPS}
                            hoverSelector={null}
                            data-at={Sephora.debug.dataAt('sign_out_button')}
                            onClick={this.signOutHandler}
                            children={localization.signOut}
                        />
                    </>
                )}
            </>
        ) : null;
    };

    getFirstSkuImage = skuImages => {
        for (const key in skuImages) {
            if (Object.prototype.hasOwnProperty.call(skuImages, key)) {
                return skuImages[key];
            }
        }

        return null;
    };

    renderBuyItAgainImages = () => {
        const { purchaseHistory: propsPurchaseHistory } = this.props;
        const { purchaseHistory: statePurchaseHistory } = this.state;
        const purchaseHistory = propsPurchaseHistory || statePurchaseHistory;

        return (
            Array.isArray(purchaseHistory.items) && (
                <Flex flexDirection='row'>
                    {purchaseHistory.items.map(product => (
                        <Box key={product.sku.skuId}>
                            <Image
                                src={this.getFirstSkuImage(product.sku.skuImages)}
                                disableLazyLoad
                                {...BUY_IT_AGAIN_IMAGE_PROPS}
                            />
                        </Box>
                    ))}
                </Flex>
            )
        );
    };

    renderForcedSignTest = () => {
        const { localization, showForcedSignInTooltip } = this.props;
        const isChallengerOne = showForcedSignInTooltip === SHOW_FORCED_SIGN_IN_TOOLTIP.CHALLENGER_ONE;

        return (
            <Box
                width={this.props.dropWidth}
                marginLeft={-this.props.dropWidth / 2}
                css={styles.signInContainer}
                ref={this.signInTooltipRef}
            >
                <Grid
                    gap={2}
                    columns='1fr auto'
                    marginX={16}
                    marginY={16}
                    lineHeight='tight'
                    alignItems='flex-end'
                >
                    <Box>
                        {isChallengerOne && <strong>{localization.helloBeautiful} 👋</strong>}
                        <br />
                        <Markdown content={localization.signInForShipping} />
                        <Text fontSize={fontSizes.sm}>
                            {localization.noAccount}{' '}
                            <Link
                                color='blue'
                                onClick={this.signInTooltipCreateAccountHandler}
                                ref={this.signInTooltipCreateAccountRef}
                            >
                                {localization.createAccount}
                            </Link>
                        </Text>
                    </Box>
                    <Button
                        width={98}
                        height={32}
                        variant='primary'
                        size='sm'
                        onClick={this.signInTooltipSignInHandler}
                        ref={this.signInTooltipSignInRef}
                        children={localization.signIn}
                    />
                </Grid>
                <button
                    type='button'
                    css={styles.close}
                    onClick={this.closeSignInTooltipHandler}
                >
                    <Icon
                        size={modal.xSize}
                        name='x'
                    />
                </button>
            </Box>
        );
    };

    closeSignInTooltip = (e, isClose) => {
        if (
            e?.target !== this.signInTooltipRef &&
            e?.target !== this.signInTooltipSignInRef &&
            e?.target !== this.signInTooltipCreateAccountRef &&
            isClose
        ) {
            window.removeEventListener(DebouncedScroll, this.closeSignInTooltip);
            window.removeEventListener('click', this.closeSignInTooltip);
            this.setState({ showForcedSignIn: false });
        }
    };

    signInTooltipSignInHandler = e => {
        this.closeSignInTooltip(e, true);
        this.props.showSignInModal({
            isOpen: true,
            analyticsData: { linkData: 'sign-in-tooltip:sign-in' }
        });
    };

    signInTooltipCreateAccountHandler = e => {
        this.closeSignInTooltip(e, true);
        this.props.showRegisterModal({
            isOpen: true,
            analyticsData: { linkData: 'sign-in-tooltip:create account' }
        });
    };

    closeSignInTooltipHandler = e => {
        this.closeSignInTooltip(e, true);
    };

    signOutHandler = () => {
        let redirectTo = window.location.href;

        // This should be read as: users that sign out while on Rich Profile pages,
        // should always get landed onto the homepage.
        if (
            (Sephora.pagePath && Sephora.pagePath.split('/')[1] === 'RichProfile' && Sephora.pagePath.split('/')[2] !== 'PurchaseHistory') ||
            Location.isRwdCreditCardPage()
        ) {
            redirectTo = '/';
        }

        this.props.signOut(redirectTo);
        this.trackNavClick('sign out');
    };

    handleOnTrackNavClick = (navClickArg, targetUrl) => () => {
        this.trackNavClick(navClickArg);
        Location.navigateTo(null, targetUrl);
    };

    trackNavClick = link => {
        const { isBottomNav, localization } = this.props;
        const path = [isBottomNav ? 'bottom nav' : 'top nav', 'account', link];
        const analyticsData = {
            navigationInfo: anaUtils.buildNavPath(path)
        };

        if (link === BEAUTY_PREFERENCES) {
            analyticsData.isMySephoraPage = true;
        } else if (link === BEAUTY_CHALLENGES) {
            analyticsData.linkData = `gamification:flyout:${localization.beautyChallenges.toLowerCase()}`;
        }

        anaUtils.setNextPageData(analyticsData);
    };

    isCreditCardEnabled = () => {
        const { isAnonymous, creditCardInfo } = this.state;
        const isGlobalEnabled = Sephora.fantasticPlasticConfigurations.isGlobalEnabled;

        return (
            !localeUtils.isCanada() &&
            isGlobalEnabled &&
            (!userUtils.isBI() ||
                (creditCardInfo &&
                    creditCardInfo.ccApprovalStatus !== APPROVAL_STATUS.DECLINED &&
                    creditCardInfo.ccApprovalStatus !== APPROVAL_STATUS.CLOSED) ||
                isAnonymous)
        );
    };

    setUserInfoState = () => {
        const { user } = this.props;

        this.setState({
            isUserReady: true,
            isAnonymous: userUtils.isAnonymous(user),
            isBirthdayGiftEligible: userUtils.isBirthdayGiftEligible(user),
            creditCardInfo: userUtils.getSephoraCreditCardInfo(user)
        });
    };

    openSignInModal = () => {
        store.dispatch(
            this.props.showSignInModal({
                isOpen: true,
                callback: tokens => {
                    const { accessToken, refreshToken: refreshedToken } = tokens.tokens;
                    this.props.storeAuthTokens(accessToken, refreshedToken);
                    this.props.getUserFull();
                }
            })
        );
    };

    handlePurchaseHistoryFetch = () => {
        const { purchaseHistory, user } = this.props;
        const { showBuyItAgainImages, hasFetchedPurchaseHistory } = this.state;

        if (showBuyItAgainImages && !hasFetchedPurchaseHistory) {
            const purchaseHistoryCount = purchaseHistory?.items?.length || this.state.purchaseHistory?.items?.length || 0;

            if (purchaseHistoryCount < 1) {
                this.props.fetchPurchaseHistory({ userId: user.profileId, requiredData: { purchaseHistory: {} } }, showBuyItAgainImages);
                this.setState({ hasFetchedPurchaseHistory: true });
            }
        }
    };

    handlePurchaseHistoryUpdate = (purchaseHistory, prevPurchaseHistory) => {
        if (purchaseHistory && purchaseHistory !== prevPurchaseHistory && purchaseHistory.items && purchaseHistory.items.length > 0) {
            this.setState({ purchaseHistory: purchaseHistory });
        }
    };

    componentDidMount() {
        if (this.props.user?.isInitialized) {
            this.setUserInfoState();
            this.handlePurchaseHistoryFetch();
        }

        const onLastLoadEvent = Sephora.Util.onLastLoadEvent;

        onLastLoadEvent(window, [HydrationFinished], () => {
            window.addEventListener('promptUserToSignIn', () => {
                this.openSignInModal();
            });
        });
    }

    componentDidUpdate(prevProps) {
        const { user, showForcedSignInTooltip, purchaseHistory } = this.props;

        if (user && user !== prevProps.user && user.isInitialized) {
            this.setUserInfoState();
            this.handlePurchaseHistoryFetch();
        }

        this.handlePurchaseHistoryUpdate(purchaseHistory, prevProps.purchaseHistory);

        if (showForcedSignInTooltip !== prevProps.showForcedSignInTooltip) {
            this.setState(
                {
                    showForcedSignIn: !!showForcedSignInTooltip
                },
                () => {
                    if (this.state.showForcedSignIn) {
                        window.addEventListener(DebouncedScroll, this.closeSignInTooltip);
                        window.addEventListener('click', this.closeSignInTooltip, true);
                    }
                }
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener('promptUserToSignIn', this.openSignInModal);
    }
}

AccountMenu.propTypes = {
    user: PropTypes.object.isRequired,
    localization: PropTypes.object.isRequired,
    profileCompletionPercentage: PropTypes.number.isRequired,
    onDismiss: PropTypes.func,
    isBottomNav: PropTypes.bool,
    showSignInModal: PropTypes.func.isRequired,
    showBiRegisterModal: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    getUserFull: PropTypes.func.isRequired,
    storeAuthTokens: PropTypes.func.isRequired,
    dropWidth: PropTypes.number
};

AccountMenu.defaultProps = {
    onDismiss: null,
    isBottomNav: false,
    dropWidth: null
};

const styles = {
    desc: {
        fontSize: fontSizes.sm,
        color: colors.gray,
        marginTop: '.125em'
    },
    linkInner: {
        flex: 1,
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    pill: {
        display: 'inline-block',
        padding: '.25em .625em',
        marginTop: space[1],
        marginRight: 'auto',
        borderRadius: radii[2],
        backgroundColor: colors.lightBlue,
        fontSize: fontSizes.sm
    },
    signInContainer: {
        zIndex: 3,
        position: 'relative',
        backgroundColor: colors.white,
        borderRadius: radii[1],
        left: '50%',
        border: `1px solid ${colors.midGray}`
    },
    close: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 3,
        lineHeight: 0,
        marginTop: space[2],
        marginRight: space[2]
    }
};

export default wrapComponent(AccountMenu, 'AccountMenu', true);
