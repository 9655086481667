import ufeApi from 'services/api/ufeApi';

const getServicesAndEventsContent = (
    token,
    {
        language,
        country,
        preferredStoreId,
        preferredZipCode,
        filters,
        endpoint, // events OR services
        eventsOnly = false
    }
) => {
    const languageLowerCase = language.toLowerCase();
    const { sdnApiHost, isRedesignEDPEnabled } = Sephora.configurationSettings;

    let url = `${sdnApiHost}/v1/store-experience/service/event/${endpoint}?storeId=${preferredStoreId}&zipCode=${preferredZipCode}&channel=web&locale=${languageLowerCase}-${country}&country=${country}&isRedesignEDPEnabled=${!!isRedesignEDPEnabled}`;

    if (endpoint === 'events') {
        url += `&eventsOnly=${eventsOnly}${filters ? `&${filters}` : ''}`;
    }

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: { 'x-requested-source': 'rwd', authorization: `Bearer ${token}` }
    });
};

export default getServicesAndEventsContent;
