import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { screenReaderOnlyStyle } from 'style/config';
import Select from 'components/Inputs/Select/Select';
import { lineItem } from 'utils/LineItem';
import languageLocale from 'utils/LanguageLocale';
import skuUtils from 'utils/Sku';
import { forms } from 'style/config';
import constants from 'components/Product/SkuQuantity/constants';

const { getLocaleResourceFile } = languageLocale;
const { MIN_WIDTH } = constants;

class SkuQuantity extends BaseClass {
    state = {
        isInReplenishBasket: false
    };
    componentDidMount() {
        this.setState({
            isInReplenishBasket: skuUtils.isInAutoReplenishmentBasket(this.props.currentSku.skuId)
        });
    }
    componentDidUpdate(prevProps) {
        if ((this.props.basket && prevProps.basket !== this.props.basket) || this.props.currentSku.skuId !== prevProps.currentSku.skuId) {
            this.setState({
                isInReplenishBasket: skuUtils.isInAutoReplenishmentBasket(this.props.currentSku.skuId)
            });
        }
    }
    render() {
        const getText = getLocaleResourceFile('components/Product/SkuQuantity/locales', 'SkuQuantity');
        const { currentSku, inputProps, customStyle } = this.props;
        const isOutOfStock = currentSku.isOutOfStock;
        const id = 'qty_' + currentSku.skuId;
        const quantities = skuUtils.purchasableQuantities(this.props.currentSku);

        return (
            <div>
                <label
                    htmlFor={id}
                    children={getText('qty')}
                    css={screenReaderOnlyStyle}
                />
                <Select
                    id={id}
                    {...inputProps}
                    value={isOutOfStock ? lineItem.OOS_QTY : this.props.skuQuantity}
                    aria-labelledby={id}
                    disabled={this.props.disabled}
                    onChange={e => this.props.handleSkuQuantity(e.target.value)}
                    marginBottom={null}
                    isNotSelectable={this.props.isNotSelectable}
                    isInReplenishBasket={this.state.isInReplenishBasket}
                    customStyle={{
                        input: {
                            minWidth: inputProps?.size ? MIN_WIDTH.SM : MIN_WIDTH.BASE,
                            textAlign: 'center',
                            ...customStyle
                        }
                    }}
                    data-at={Sephora.debug.dataAt('sku_qty')}
                >
                    {quantities.concat(isOutOfStock ? [lineItem.OOS_QTY] : []).map((option, index) => (
                        <option
                            css={{ color: forms.COLOR }}
                            key={index.toString()}
                            value={option}
                        >
                            {option}
                        </option>
                    ))}
                </Select>
            </div>
        );
    }
}

SkuQuantity.prototype.shouldUpdatePropsOn = ['currentSku.skuId', 'skuQuantity', 'basket'];

export default wrapComponent(SkuQuantity, 'SkuQuantity', true);
