export default function getResource(label, vars = []) {
    const resources = {
        modalTitle: 'Change Method',
        standard: 'Standard',
        sameDayDelivery: 'Same-Day Delivery',
        bopis: 'Buy Online & Pick Up',
        autoReplenish: 'Auto-Replenish',
        autoReplenishEnroll: 'Please enroll from the product page.',
        autoReplenishDisclaim:
            'If you switch to another fulfillment method and you would like Auto-Replenish again please enroll from the product details page.',
        checkAvailability: 'Check availability',
        confirm: 'Confirm',
        cancel: 'Cancel',
        ok: 'OK',
        bopisUnavailable: 'Buy Online & Pick Up is temporarily unavailable.',
        sameDayUnavailable: 'Same-Day Delivery is temporarily unavailable.',
        qtyWarningMessage: 'Item quantity will be reduced to 1.',
        getItSooner: 'Get It Sooner',
        withSddOrBopis: ' with Same-Day Delivery or Buy Online & Pickup'
    };

    return resources[label];
}
