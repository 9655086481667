export default function getResource(label, vars = []) {
    const resources = {
        addToCal: 'Add to Cal',
        cancel: 'Cancel',
        reschedule: 'Reschedule',
        copyLinkShare: 'Copy the following link to share',
        share: 'Share',
        cancelReservation: 'Cancel reservation'
    };

    return resources[label];
}
