export default {
    TYPES: {
        SUPER_LEADERBOARD: {
            NAME: 'SUPER_LEADERBOARD',
            WIDTH: 970,
            HEIGHT: 90,
            DISPLAY: ['none', 'block']
        },
        MOBILE_LEADERBOARD: {
            NAME: 'MOBILE_LEADERBOARD',
            WIDTH: 320,
            HEIGHT: 50,
            DISPLAY: [null, 'none']
        },
        WIDE_SIDESCRAPER: {
            NAME: 'WIDE_SIDESCRAPER',
            WIDTH: 160,
            HEIGHT: 600,
            DISPLAY: ['none', null, 'block']
        }
    }
};
