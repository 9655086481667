import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import userUtils from 'utils/User';
import p13nUtils from 'utils/localStorage/P13n';
import PersistentBannerBindings from 'analytics/bindingMethods/components/bccComponents/PersistentBanner/PersistentBannerBindings';
import { p13nSelector } from 'selectors/p13n/p13nSelector';
import { showUnrecognizedPersistentBannerSelector } from 'viewModel/selectors/testTarget/showUnrecognizedPersistentBannerSelector';
import { sendCmsComponentEvent, matchContexts } from 'analytics/utils/cmsComponents';
import constants from 'constants/content';
import anaConsts from 'analytics/constants';

const {
    COMPONENT_TYPES: { BANNER }
} = constants;

const {
    CMS_COMPONENT_EVENTS: { IMPRESSION, ITEM_CLICK }
} = anaConsts;

const { wrapHOC } = FrameworkUtils;

const functions = (dispatch, ownProps) => ({
    triggerImpression: function () {
        const { data } = ownProps;

        if (data && data.length > 0) {
            const firstBanner = data[0];
            const personalizationData = p13nUtils.getAllPersonalizedCache();
            const biStatus = userUtils.getBiStatus();
            const profileId = userUtils.getProfileId();
            const profileStatus = userUtils.getProfileStatus();
            const userData = {
                biStatus,
                profileId,
                profileStatus
            };

            const { personalization } = firstBanner;

            if (personalization) {
                const personalizationLocalData = matchContexts(personalization, personalizationData);

                if (personalizationLocalData) {
                    userData.bannersPersonalizedData = [personalizationLocalData];
                    PersistentBannerBindings.bannerItemsImpression(userData);
                }
            }

            const eventName = IMPRESSION;

            sendCmsComponentEvent({
                items: [firstBanner],
                eventName,
                sid: '',
                component: BANNER
            });
        }
    },
    triggerClick: function (banner) {
        const { sid: componentSid } = banner;
        const { data } = ownProps;

        if (data && data.length > 0) {
            const eventName = ITEM_CLICK;

            sendCmsComponentEvent({
                items: data,
                eventName,
                sid: '',
                componentSid,
                component: BANNER
            });
        }
    }
});

const connectedPersistentBanner = connect(
    createStructuredSelector({
        p13n: p13nSelector,
        showUnrecognizedPersistentBanner: showUnrecognizedPersistentBannerSelector
    }),
    functions
);

const withPersistentBannerProps = wrapHOC(connectedPersistentBanner);

export {
    functions, withPersistentBannerProps
};
