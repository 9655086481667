import { UPDATE_SMARTCHAT_STATE } from 'constants/actionTypes/smartChat';

const initialState = {};

const smartChat = function (state = initialState, action) {
    // eslint-disable-next-line object-curly-newline
    const { type, payload } = action;

    switch (type) {
        case UPDATE_SMARTCHAT_STATE: {
            return payload;
        }
        default: {
            return state;
        }
    }
};

export default smartChat;
