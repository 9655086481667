export default function getResource(label, vars = []) {
    const resources = {
        signUp: 'Inscrivez-vous aux mises à jour par textos de Sephora',
        mobile: 'Numéro de téléphone cellulaire',
        continueBtn: 'Continuer',
        emptyError: 'Veuillez saisir un numéro de téléphone cellulaire.',
        invalidError: 'Veuillez saisir un numéro de téléphone cellulaire valable.'
    };

    return resources[label];
}
