export default function getResource(label, vars = []) {
    const resources = {
        shareExperience: 'Share this experience',
        description: 'Description',
        map: 'Map',
        copyLinkShare: 'Copy the following link to share',
        minutesAbbr: 'min',
        beauty: `Beauty ${vars[0]}`,
        inCityState: ` in ${vars[0]}, ${vars[1]}`
    };

    return resources[label];
}
