export default function getResource(label, vars = []) {
    const resources = {
        youNowHaveText: 'Vous avez actuellement ',
        rewardsAddedText: 'Utiliser vos points Beauty Insider',
        pointsRemainingText: ' points',
        applyText: 'Appliquer',
        upToText: 'Jusqu’à ',
        availableText: ' disponible',
        addRewardsBazaarItemsText: 'Ajouter des articles Rewards Bazaar',
        rewardsBazaarText: 'Rewards Bazaar',
        itemAddedText: ' article ajouté',
        youAreExceeding: 'Vous dépassez de',
        points: 'points'
    };

    return resources[label];
}
