import ufeApi from 'services/api/ufeApi';

const getSeasonalContent = (token, { language, country = 'US', channel = 'rwd', zipCode }) => {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const locale = `${language.toLowerCase()}-${country}`;

    const url = `${sdnDomain}/v2/happening/services/seasonal?country=${country}&channel=${channel}&locale=${locale}&zipCode=${zipCode}`;

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: {
                'x-requested-source': 'rwd',
                authorization: `Bearer ${token}`
            }
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
};

export default getSeasonalContent;
