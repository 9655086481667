const PHOTOS_COL_LGUI_WIDTH = 624;

const EDP_IMG_SIZES = {
    SMUI_CAROUSEL_WIDTH: 316,
    SMUI_CAROUSEL_HEIGHT: 211,

    SMUI_SINGLE_WIDTH: '100%',
    SMUI_SINGLE_HEIGHT: 250,
    SMUI_EVENTS_SINGLE_HEIGHT: 187,

    LGUI_CAROUSEL_WIDTH: 544,
    LGUI_CAROUSEL_HEIGHT: 363,

    LGUI_SINGLE_WIDTH: 624,
    LGUI_SINGLE_HEIGHT: 416,
    LGUI_EVENTS_SINGLE_HEIGHT: 312,

    THUMBNAIL_MEDIA_ITEM: 48,

    ZOOM: 1500
};

const SHADOW = ['light', null, 'none'];

const THUMB_COUNT = 6;

export {
    PHOTOS_COL_LGUI_WIDTH, EDP_IMG_SIZES, SHADOW, THUMB_COUNT
};
