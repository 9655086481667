const resources = {
    title: 'Customer Service',
    available: 'Available',
    unavailable: 'Chat is unavailable',
    inHours: 'All agents are busy',
    offline: 'We are offline',
    unavailablePopoverText: 'Sorry we missed you! Please try us again later.',
    marketingMsgText: 'Chat with our experts for personalized tips and product recommendations.',
    marketingMsgLink: 'Learn More',
    marketingMsgButton: 'Chat Now'
};

export default function getResource(label) {
    return resources[label];
}
