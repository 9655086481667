
export default function getResource(label, vars = []) {
    const resources = {
        getItShippedTitle: `Get It Shipped (${vars[0]})`,
        reserveAndPickUpTitle: `Reserve & Pick Up (${vars[0]})`,
        buyOnlineAndPickUpTitle: `Buy Online & Pick Up (${vars[0]})`,
        shippedSubTotal: 'Shipped Items Subtotal',
        pickUpSubTotal: 'Pickup Items Subtotal',
        viewItemsAndCheckout: 'View Items & Checkout',
        viewAndReserve: 'View Items & Reserve',
        chooseRewards: 'Promos, rewards, and samples can be added on the next screen.',
        chooseRewardsInStore: 'Promos and rewards can be added at the store at the time of payment, if eligible.',
        bopisPromoMessaging: 'Qualifying promos and rewards can be added on the next screen.',
        noItemsText: 'There are no items in your shipping basket.',
        viewBasket: 'View Basket',
        payInStore: 'Payment will be collected at the store',
        seeAll: 'See all',
        items: 'items',
        ropisInfoTitle: 'Reserve & Pick Up in Store',
        bopisInfoTitle: 'Buy Online & Pick Up in Store',
        noStandardItems: 'You have no items for standard delivery.'
    };

    return resources[label];
}
