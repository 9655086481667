import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import FrameworkUtils from 'utils/framework';
import {
    basketItemsSelector,
    basketPendingBasketSkusSelector,
    basketItemCountSelector,
    basketPickupBasketItemsSelector,
    isFromBazaarSelector,
    isBIPointsAvailableSelector
} from 'selectors/basket';
import preferredStoreSelector from 'selectors/user/preferredStoreSelector';
import { isTestTargetReadySelector } from 'viewModel/selectors/testTarget/isTestTargetReadySelector';
import actions from 'actions/Actions';
import basketActions from 'actions/BasketActions';
import promoActions from 'actions/PromoActions';
import ReplacementOrderActions from 'actions/ReplacementOrderActions';

const { wrapHOC } = FrameworkUtils;
const fields = createStructuredSelector({
    basketItems: basketItemsSelector,
    pendingBasketSkus: basketPendingBasketSkusSelector,
    itemCount: basketItemCountSelector,
    pickupBasketItems: basketPickupBasketItemsSelector,
    preferredStore: preferredStoreSelector,
    isTestTargetReady: isTestTargetReadySelector,
    isBIPointsAvailable: isBIPointsAvailableSelector,
    fromBazaar: isFromBazaarSelector
});

const functions = {
    setBasketType: basketActions.setBasketType,
    addToBasket: basketActions.addToBasket,
    showAddToBasketModal: actions.showAddToBasketModal,
    showRougeRewardCardModal: actions.showRougeRewardCardModal,
    updateMsgPromo: promoActions.updateMsgPromo,
    addMultipleSkusToBasket: basketActions.addMultipleSkusToBasket,
    clearPendingProductList: basketActions.clearPendingProductList,
    addRemoveSample: ReplacementOrderActions.addRemoveSample
};

const withAddToBasketButtonProps = wrapHOC(connect(fields, functions));

export {
    withAddToBasketButtonProps, fields, functions
};
