import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LanguageLocaleUtils from 'utils/LanguageLocale';
import FrameworkUtils from 'utils/framework';

const { wrapHOC } = FrameworkUtils;

const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/Content/ProductList/locales', 'ProductList');

const fields = createStructuredSelector({
    localization: createStructuredSelector({
        showMore: getTextFromResource(getText, 'showMore'),
        rougeBadgeText: getTextFromResource(getText, 'rougeBadge')
    })
});

const withProductListProps = wrapHOC(connect(fields));

export { withProductListProps };
