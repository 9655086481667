import React from 'react';
import store from 'Store';
import Actions from 'Actions';
import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';
import anaUtils from 'analytics/utils';
import { Text } from 'components/ui';
import Modal from 'components/Modal/Modal';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import localeUtils from 'utils/LanguageLocale';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

class GoogleMapModal extends BaseClass {
    requestClose = () => {
        const onRequestClose = this.props.onRequestClose;

        store.dispatch(Actions.showGoogleMapModal({ isOpen: false }));

        if (onRequestClose) {
            onRequestClose();
        }
    };

    // eslint-disable-next-line class-methods-use-this
    componentDidMount() {
        const activity = this.props.activity;
        const { context } = this.props.analyticsData || {};
        const contextEvent = (context && anaUtils.getLastAsyncPageLoadData({ pageType: context })) || {};
        const pageDetail = 'book a reservation-map';
        const pageName = `${anaConsts.PAGE_TYPES.OLR}:${pageDetail}:n/a:*title=${activity.name}`;

        processEvent.process(anaConsts.ASYNC_PAGE_LOAD, {
            data: {
                pageType: anaConsts.PAGE_TYPES.OLR,
                pageName,
                pageDetail,
                previousPageName: contextEvent.pageName
            }
        });
    }

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/GlobalModals/EDP/GoogleMapModal/locales', 'GoogleMapModal');

        const { selectedStore, activity } = this.props;

        return (
            <Modal
                isOpen={this.props.isOpen}
                onDismiss={this.requestClose}
                noScroll={true}
                width={4}
            >
                <Modal.Header>
                    <Modal.Title>{activity.name}</Modal.Title>
                    {activity.timeSlots[0] && (
                        <Text
                            is='p'
                            lineHeight='tight'
                            fontWeight='bold'
                            fontSize='xs'
                            color='gray'
                            css={{ textTransform: 'uppercase' }}
                        >
                            {activity.timeSlots[0].durationMin} {getText('minutes')}
                        </Text>
                    )}
                </Modal.Header>
                <Modal.Body
                    paddingX={null}
                    paddingTop={null}
                    paddingBottom={null}
                >
                    <GoogleMap
                        isZoomControlShown={true}
                        showFirstMarkerInfoBox={true}
                        isAbsoluteFill={Sephora.isMobile()}
                        selectedStore={selectedStore}
                        stores={[selectedStore]}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export default wrapComponent(GoogleMapModal, 'GoogleMapModal', true);
