export default function getResource(label, vars = []) {
    const resources = {
        birthdayIconText: 'Cadeau d’anniversaire gratuit',
        earnPointsIconText: 'Accumulez des points par dollar',
        redeemTrialIconText: 'Obtenez des produits et des ensembles en format découverte',
        accessIconText: 'Services beauté offerts en magasin',
        applePaySignIn: 'Ouvrez une session ou créez un compte Sephora pour terminer votre commande avec Apple Pay.',
        emailAddressLabel: 'Adresse de courriel',
        emailAddressPlaceholder: 'Adresse de courriel',
        whatsYourEmailAddressLabel: 'Quelle est votre adresse courriel?',
        signInToSaveBIPointsMessage: 'Ouvrez une session pour enregistrer vos points Beauty Insider et les échanger contre des récompenses gratuites.',
        signInFasterBookingMessage: 'Inscrivez-vous pour réserver plus rapidement et enregistrer votre réservation dans votre compte.',
        haveABIAccountMessage: 'Vous avez un compte Beauty Insider? Utilisez l’adresse courriel avec laquelle vous vous êtes inscrit.',
        notYouMessage: 'Ce n’est pas vous?',
        haveAPasswordMessage: 'Avez-vous un mot de passe sephora.com?',
        imNewMessage: 'Non, c’est la première fois que je visite le site',
        iHaveAPasswordMessage: 'Oui, j’ai un mot de passe',
        passwordLabel: 'Mot de passe',
        passwordPlaceholder: 'Mot de passe',
        enterPasswordErrorMessage: 'Veuillez saisir votre mot de passe.',
        forgotPasswordLink: 'Mot de passe oublié?',
        staySignedInLabel: 'Garder ma session ouverte',
        createAccountLabel: 'Créer votre compte Sephora',
        applePaySephoraAccountAgreement: 'En choisissant « Acheter avec Apple Pay, » vous vous inscrivez sur Sephora.com et vous acceptez notre',
        privacyPolicyLink: 'Politique de confidentialité',
        andText: 'et',
        sephoraTermsOfUse: 'Conditions d’utilisation de Sephora',
        automaticallyEmailsMessage: ' Vous recevrez automatiquement les courriels de Sephora.',
        giftCardsApplePayMessage: 'Les cartes-cadeaux ne peuvent être combinées avec Apple Pay.',
        signIn: 'Ouvrir une session',
        buyWithLabel: 'Acheter avec',
        continueButtonLabel: 'Continuer',
        newAccountHeading: 'Nouveau client Sephora?',
        createAccount: 'Créer un compte',
        existingAccountErrorMessage: 'Un compte existe déjà pour l’adresse électronique que vous venez de saisir. Veuillez vous connecter ou choisir une autre adresse courriel.',
        showPasswordLinkAriaLabel: 'Montrer le mot de passe',
        hidePasswordLinkAriaLabel: 'Cacher le mot de passe',
        staySignedInTooltip: 'En sélectionnant cette option, vous n’aurez pas à ouvrir de session aussi souvent sur cet appareil. Pour votre sécurité, nous vous recommandons de le faire uniquement sur vos appareils personnels.',
        lead: 'Ouvrez une session ou créez un compte pour profiter de la *livraison standard GRATUITE* sur toutes les commandes.',
        pointsAndFreeShip: `Ouvrez une session pour enregistrer vos *${vars[0]} points Beauty Insider*, profiter de la *livraison standard GRATUITE* et échanger vos points pour des récompenses gratuites.`,
        unableToLogYouIn: 'Nous n’avons pas été en mesure de vous connecter. Veuillez appeler le service à la clientèle pour obtenir de l’aide.',
        genericErrorMessage: 'Oups! Un problème est survenu. Veuillez réessayer plus tard.'
    };

    return resources[label];
}
