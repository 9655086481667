import ufeApi from 'services/api/ufeApi';
import headerUtils from 'utils/Headers';

const { purchaseEventTimeStamp } = headerUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Place+Order+API

function placeOrder(params = {}) {
    const url = '/api/checkout/submitOrder';

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            headers: purchaseEventTimeStamp(),
            body: JSON.stringify(params)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default placeOrder;
