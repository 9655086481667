export default function getResource(label, vars = []) {
    const resources = {
        autoReplenish: 'Réapprovisionnement automatique',
        nextShipment: 'Prochaine livraison le',
        pausedDelivery: 'Livraison en pause chaque',
        manageSubscription: 'Gérer l’abonnement',
        resumeSubscription: 'Reprendre l’abonnement',
        unsubscribe: 'Se désabonner',
        save: 'économisez',
        annuallyWithSubscription: 'chaque année avec cet abonnement.',
        deliverEvery: 'Livraison chaque',
        paused: 'En pause',
        canceled: 'Annulée',
        viewMore: 'En voir plus',
        viewMoreSubscriptions: 'Afficher plus d’abonnements',
        item: `ARTICLE ${vars[0]}`,
        needHelp: 'Besoin d’aide?',
        viewFAQ: 'Voir la FAQ',
        rateOf: `à un taux de ${vars[0]} % de réduction`,
        stillSave: 'vous pouvez quand même économiser',
        before: 'avant',
        autoReplenishPromoInfoModalMsg2: 'Après trois livraisons ou après expiration, selon la première éventualité, votre abonnement se renouvelle à un taux de réduction de 5 %.*',
        autoReplenishPromoInfoModalButton: 'Compris',
        lastDeliveryLeft: `livraison restante à ${vars[0]} % de réduction`,
        deliveriesLeft: `livraisons restantes à ${vars[0]} % de réduction`,
        discountValidUntil: `Réduction en vigueur jusqu’au ${vars[0]}`,
        discountsValidUntil: `Réductions en vigueur jusqu’au ${vars[0]}`,
        or: 'Ou',
        savePercentage: `économisez ${vars[0]} %`,
        getItBefore: 'si vous le vous procurez avant le',
        canceledSubscriptionsHeading: 'Abonnements annulés',
        canceledSubscriptionsSubheading: 'Voir les détails du produit pour activer un nouvel abonnement',
        headerItem: 'Article',
        headerPrice: 'Prix',
        viewProductDetails: 'Voir les détails du produit',
        cancelledOn: `Annulé le ${vars[0]}`,
        discountRate: `${vars[0]} % de réduction`,
        activeSubscriptionsCount: `1-${vars[0]} de ${vars[1]} Abonnements actifs`,
        pausedSubscriptionsCount: `1-${vars[0]} de ${vars[1]} Abonnements en pause`,
        canceledSubscriptionsCount: `1-${vars[0]} de ${vars[1]} Abonnements annulés`
    };

    return resources[label];
}
