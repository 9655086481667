const ACTION_TYPES = {
    SET_INITIALIZATION: 'SET_INITIALIZATION',
    SET_P13N_DATA_FOR_PREVIEW: 'SET_P13N_DATA_FOR_PREVIEW',
    SET_P13N_DATA: 'SET_P13N_DATA'
};

const initialState = {
    isInitialized: false
};

const reducer = function (state = initialState, { type, payload }) {
    switch (type) {
        case ACTION_TYPES.SET_INITIALIZATION:
            return Object.assign({}, state, {
                isInitialized: payload
            });

        case ACTION_TYPES.SET_P13N_DATA_FOR_PREVIEW:
            return Object.assign({}, state, {
                isInitialized: true,
                data: payload
            });

        case ACTION_TYPES.SET_P13N_DATA:
            return Object.assign({}, state, {
                isInitialized: true,
                headData: payload
            });

        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
