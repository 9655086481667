import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import RecapItem from 'components/Content/Recap/RecapItem';
import RecapGrid from 'components/Content/Recap/RecapGrid/RecapGrid';

function RecapProductList(props) {
    const { skuList, ...rest } = props;

    return (
        <RecapItem {...rest}>
            <RecapGrid skuList={skuList} />
        </RecapItem>
    );
}

export default wrapFunctionalComponent(RecapProductList, 'RecapProductList');
