import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import SingleSelect from 'components/Catalog/Filters/SingleSelect/SingleSelect';
import MultiSelect from 'components/Catalog/Filters/MultiSelect/MultiSelect';
import CustomRange from 'components/Catalog/Filters/CustomRange/CustomRange';
import { REFINEMENT_STATES, SINGLE_SELECTS } from 'utils/CatalogConstants';
import catalogUtils from 'utils/Catalog';

function createFilterItemLabel({ refinementValueDisplayName, refinementValueDisplayNameComp, count }) {
    if (refinementValueDisplayNameComp) {
        return refinementValueDisplayNameComp;
    }

    const displayName = refinementValueDisplayName?.replace('/', ' / ');

    if (count != null) {
        return `${displayName} (${count})`;
    }

    return displayName;
}

function FilterItem({
    type, title, value, selected, contextId, isModal, onClick, selectFilters, isHappening
}) {
    const isSingleSelect = SINGLE_SELECTS.indexOf(type) >= 0;
    const disabled = value.refinementValueStatus === REFINEMENT_STATES.IMPLICIT;
    const isDefault = value.isDefault;

    const Component = isSingleSelect ? (catalogUtils.isCustomRange(value.refinementValue) ? CustomRange : SingleSelect) : MultiSelect;

    return (
        <Component
            {...(isSingleSelect && {
                name: title.toLowerCase().replace(' ', '')
            })}
            displayName={value.refinementValueDisplayName}
            type={type}
            title={title}
            isDefault={isDefault}
            isModal={isModal}
            contextId={contextId}
            value={value.refinementValue}
            valueStatus={value.refinementValueStatus}
            label={createFilterItemLabel({
                type,
                ...value
            })}
            disabled={disabled}
            checked={selected || disabled}
            onClick={(e, refinement) => onClick(e, refinement ? refinement : value)}
            selectFilters={selectFilters}
            isHappening={isHappening}
        />
    );
}

export default wrapFunctionalComponent(FilterItem, 'FilterItem');
