import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+Preview+Settings+API

function getPreviewSettings() {
    const url = '/api/util/getPreviewSettings';

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getPreviewSettings;
