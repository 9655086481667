export default function getResource(label, vars = []) {
    const resources = {
        shareExperience: 'Partagez cette expérience',
        copyLinkShare: 'Copier le lien suivant pour partager',
        minutesAbbr: 'min',
        upcomingReservation: 'Votre réservation à venir',
        at: 'à',
        registerWithSephora: 'S’inscrire auprès de Sephora',
        createAccountText: 'Créez un compte pour accéder facilement à cette réservation plus tard.',
        createAccountBtn: 'Créer un compte',
        close: 'Fermer',
        whatToExpect: 'À quoi s’attendre',
        description: 'Description',
        bookingMessage: 'Un courriel avec les détails de votre réservation vous sera envoyé.',
        creditCardRequired: 'Votre carte de crédit est requise pour garantir cette réservation. Veuillez consulter les politiques relatives aux frais d’annulation et d’absence ci-dessous.',
        virtualMessage: 'Vous recevrez un courriel de confirmation avec les détails de la réservation, le lien de la rencontre virtuelle ainsi que les instructions pour vous connecter.'
    };

    return resources[label];
}
