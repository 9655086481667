import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import {
    Text, Box, Flex, Image, Grid
} from 'components/ui';
import Radio from 'components/Inputs/Radio/Radio';
import NotSureOrPreference from 'components/Header/BeautyPreferences/NotSureOrPreference';
import { CONTAINER_SIZE } from 'constants/beautyPreferences';

function TiledProfileContent({
    profileCategory,
    selectedOptions,
    setBeautyPreferencesState,
    imgPrefix,
    isNotSureOrNoPreference,
    isNotSkinTone,
    isShoppingPreferences,
    isAgeRange,
    currentPreferenceData,
    isPostSignupModal,
    ...props
}) {
    return (
        <Grid
            columns={profileCategory.hasDesc ? [null, null, 2] : [2, null, 4]}
            gap={null}
            columnGap={['14px', 4]}
            rowGap={[3, '14px']}
            margin={!isPostSignupModal && [null, null, '0 auto']}
            maxWidth={!isPostSignupModal && [null, null, CONTAINER_SIZE]}
        >
            {currentPreferenceData.map(profile => {
                const isOptionsSelected = selectedOptions.find(option => option === profile);
                const modelSrcImage = imgPrefix + profile.toLowerCase() + '-model.' + profileCategory.iconType;
                const iconSrcImage = imgPrefix + profile.toLowerCase() + '.' + profileCategory.iconType;

                return (
                    <Box
                        key={profile}
                        borderWidth='1px'
                        borderStyle='solid'
                        borderColor={isOptionsSelected ? 'black' : 'midGray'}
                        borderRadius={2}
                        padding={0}
                        overflow='hidden'
                        css={{ boxShadow: isOptionsSelected ? 'inset 0px 0px 0px 1px black' : 'none' }}
                    >
                        <Radio
                            hasHover={false}
                            hasDot={false}
                            margin={isAgeRange ? '0 auto' : null}
                            paddingY={profileCategory.showModelIcon ? 0 : isAgeRange ? 4 : 2}
                            paddingX={profileCategory.showModelIcon ? 0 : 3}
                            height='100%'
                            width='100%'
                            name={profile}
                            onClick={() => setBeautyPreferencesState(profileCategory.type, profileCategory.singleSelect, profile)}
                        >
                            <Flex
                                alignItems='center'
                                justifyContent={isAgeRange ? 'center' : null}
                            >
                                {profileCategory.showModelIcon && (
                                    <Image
                                        display='block'
                                        marginRight={2}
                                        src={modelSrcImage}
                                        size={56}
                                        css={{ flexShrink: 0, zIndex: -1 }}
                                    />
                                )}
                                {profileCategory.showIcon && (
                                    <Image
                                        marginRight={profileCategory.showModelIcon ? '6px' : null}
                                        minWidth='auto'
                                        borderRadius='full'
                                        display='block'
                                        src={iconSrcImage}
                                        css={{ flexShrink: 0 }}
                                        size={profileCategory.showModelIcon ? 20 : 36}
                                    />
                                )}
                                {!profileCategory.hasDesc && (
                                    <Text
                                        marginRight={[profileCategory.showModelIcon && 2, null, null]}
                                        marginLeft={isNotSkinTone ? null : 2}
                                    >
                                        {props[profile]}
                                    </Text>
                                )}
                                {profileCategory.hasDesc && (
                                    <Flex
                                        flexDirection='column'
                                        alignItems='flex-start'
                                        marginLeft={!isShoppingPreferences ? 2 : null}
                                    >
                                        <Text fontWeight='bold'>{props[profile]}</Text>
                                        <Text color='gray'>{props[profile + 'Desc']}</Text>
                                    </Flex>
                                )}
                            </Flex>
                        </Radio>
                    </Box>
                );
            })}
            {isNotSureOrNoPreference && (
                <NotSureOrPreference
                    profileCategory={profileCategory}
                    selectedOptions={selectedOptions}
                    setBeautyPreferencesState={setBeautyPreferencesState}
                />
            )}
        </Grid>
    );
}

TiledProfileContent.propTypes = {
    profileCategory: PropTypes.object.isRequired,
    setBeautyPreferencesState: PropTypes.func.isRequired,
    selectedOptions: PropTypes.array,
    isPostSignupModal: PropTypes.bool
};

TiledProfileContent.defaultProps = {
    selectedOptions: [],
    isPostSignupModal: false
};

export default wrapFunctionalComponent(TiledProfileContent, 'TiledProfileContent');
