export default function getResource(label, vars = []) {
    const resources = {
        waiverTitle: 'Waiver of Rights and Assumption of Risk',
        waiverGeneralMessage: `Please review ${vars[0]}, and check the following boxes that apply.`,
        acceptWaiver: `By checking the box, I acknowledge I am the individual who is receiving Sephora’s services.  I acknowledge I have carefully read and fully understand all provisions of the ${vars[0]}, and freely and knowingly assume the risks and waive my rights as described.`,
        underAgeWaiver: 'I am booking for someone who is a minor.',
        parentalWaiver: `By checking the box, I acknowledge I am the parent or legal guardian of a minor who is receiving Sephora’s services.  I acknowledge I have carefully read and fully understand all provisions of the ${vars[0]}, and freely and knowingly assume the risks and waive any right to bring suit on behalf of the minor as described.`,
        chooseOne: 'Choose one:'
    };

    return resources[label];
}
