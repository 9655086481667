import ufeApi from 'services/api/ufeApi';

const getReservationsContent = (token, { country = 'US', language = 'en', email, status }) => {
    const locale = `${language.toLowerCase()}-${country}`;
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const url = `${sdnDomain}/v1/happening/reservations/user/${email}?locale=${locale}&status=${status}`;

    return ufeApi.makeRequest(
        url,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            }
        },
        { isHappening: true }
    );
};

export default getReservationsContent;
