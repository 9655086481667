export default function getResource(label, vars = []) {
    const resources = {
        happeningNearYou: 'Événement près de chez vous',
        rsvpThisEvent: 'S’inscrire à cet événement',
        map: 'Plan',
        rsvp: 'Réserver',
        noTimesAvailable: 'Pas d’heures disponibles',
        addToCal: 'Ajouter au calendrier',
        showMoreTimesLocations: 'Afficher plus d’heures et d’emplacements',
        chooseTime: 'Choisir une heure',
        noAppointmentsAvailable1: 'Nous sommes désolés, aucun rendez-vous n’est disponible',
        noAppointmentsAvailable2: 'pour l’instant',
        tryDifferentExperience: 'Veuillez essayer une autre expérience.',
        seeWhatsHappening: 'Voir ce qui se passe'
    };

    return resources[label];
}
