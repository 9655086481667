const resources = {
    title: 'Create Account',
    joinBiProgram: 'Join the Beauty Insider loyalty program. Earn points, get *FREE standard shipping*, redeem rewards, and more.',
    email: 'Email Address',
    confirmButton: 'Continue',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign In',
    existingUserError: 'We’re sorry, there is an error with your email and/or password. Remember passwords are 6 to 12 characters (letters or numbers) long. Please try again or click Forgot Password.'
};

export default function getResource(label, vars = []) {
    return resources[label];
}
