import ufeApi from 'services/api/ufeApi';
import cookieUtils from 'utils/Cookies';

function getPersonalizedPromotions(profileId, isUS, showSephoraMLModelV2Promos) {
    const { isBeautyOffersEnabled, sdnUfeAPIUserKey, sdnDomainBaseUrl } = Sephora.configurationSettings;

    const showMLPromos = isBeautyOffersEnabled && isUS;
    const showML2Promos = isBeautyOffersEnabled && showSephoraMLModelV2Promos;

    const DYN_USER_ID = cookieUtils.read(cookieUtils.KEYS.DYN_USER_ID);
    const DYN_USER_CONFIRM = cookieUtils.read(cookieUtils.KEYS.DYN_USER_CONFIRM);

    const params = `${profileId}/${DYN_USER_ID}/${DYN_USER_CONFIRM}`;
    const ML_URL = `${sdnDomainBaseUrl}/beauty-offers/v1/${params}`;
    const ML2_URL = `${sdnDomainBaseUrl}/beauty-offers/v2/${params}`;
    const url = showML2Promos ? ML2_URL : showMLPromos ? ML_URL : '/api/users/profiles/current/promotions?includeBCCData=true';

    const options = {
        method: 'GET',
        headers:
            showMLPromos || showML2Promos
                ? {
                    'X-Api-Key': sdnUfeAPIUserKey
                }
                : {}
    };
    const getPromosData = data => (showMLPromos || showML2Promos ? data.data : data);

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : getPromosData(data)));
}

export default { getPersonalizedPromotions };
