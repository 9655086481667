import React, { forwardRef, Fragment } from 'react';
import framework from 'utils/framework';
const { wrapFunctionalComponent } = framework;

import EDPImage from 'components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/EDPImage';

import skuUtils from 'utils/Sku';

// import { Flex } from 'components/ui';
import { mediaQueries, radii, colors } from 'style/config';

// import analyticsConstants from 'analytics/constants';
// const { MEDIA_TYPE } = analyticsConstants;

// import BccVideo from 'components/Bcc/BccVideo/BccVideo';

const THUMB_SIZE = [44, 52];

import { EDP_IMG_SIZES } from 'components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/constants';
const { THUMBNAIL_MEDIA_ITEM, ZOOM } = EDP_IMG_SIZES;

const MediaZoomItem = forwardRef(
    (
        {
            isThumbnail, mediaItem, mediaItemIndex, selectedItemIndex, onClick
        },
        // eslint-disable-next-line no-unused-vars
        ref
    ) => {
        const item = { ...mediaItem.media };
        let listItem;
        const renderHeroVideo = false;

        switch (mediaItem.type) {
            // ToDo - to be worked on when services videos gets activated
            // case MEDIA_TYPE.VIDEO:
            //     if (mediaItem.source === 'tiktok') {
            //         listItem = (
            //             <iframe
            //                 src={`https://www.tiktok.com/embed/v2/${mediaItem.media?.id}`}
            //                 style={{
            //                     minHeight: '98vh',
            //                     width: '100%',
            //                     paddingTop: '20px'
            //                 }}
            //             />
            //         );
            //     } else {
            //         listItem = (
            //             <Flex
            //                 flexDirection='column'
            //                 justifyContent='center'
            //                 width='100%'
            //                 height='100%'
            //                 {...(isThumbnail && {
            //                     borderRadius: 'full',
            //                     overflow: 'hidden'
            //                 })}
            //             >
            //                 <BccVideo
            //                     isContained={false}
            //                     videoId={item.filePath}
            //                     name={item.name}
            //                     ref={ref}
            //                     startImagePath={item.startImagePath}
            //                     thumbnailRatio={isThumbnail ? 1 : 9 / 16}
            //                     thumbnailWidth={isThumbnail ? THUMBNAIL_MEDIA_ITEM : 612}
            //                     overlayFlag={!!isThumbnail}
            //                     isSmallThumb={isThumbnail}
            //                     isButton={false}
            //                     hideDescription={true}
            //                 />
            //             </Flex>
            //         );
            //     }

            //     renderHeroVideo = !isThumbnail;

            //     break;
            default:
                listItem = (
                    <EDPImage
                        alt={item.altText}
                        width={isThumbnail ? THUMBNAIL_MEDIA_ITEM : [375, null, 624]}
                        height={isThumbnail ? THUMBNAIL_MEDIA_ITEM : [250, null, 416]}
                        src={skuUtils.getImgSrc(ZOOM, item)}
                        disableLazyLoad={true}
                        display={'block'}
                        isThumbnail={isThumbnail}
                        {...(isThumbnail && {
                            objectFit: 'cover',
                            borderRadius: 'full',
                            overflow: 'hidden'
                        })}
                    />
                );

                break;
        }

        return listItem ? (
            <Fragment>
                {renderHeroVideo === true && (
                    <button
                        children={listItem}
                        onClick={onClick}
                    />
                )}
                {renderHeroVideo === false && (
                    <button
                        key={mediaItemIndex.toString()}
                        type='button'
                        css={[
                            isThumbnail && styles.thumbListItem,
                            mediaItemIndex === selectedItemIndex ? styles.thumbListItemActive : styles.thumbListItemInactive
                        ]}
                        onClick={onClick}
                        children={listItem}
                    />
                )}
            </Fragment>
        ) : null;
    }
);

const styles = {
    thumbListItem: {
        display: 'inline-flex',
        verticalAlign: 'top',
        width: THUMB_SIZE[0],
        height: THUMB_SIZE[0],
        borderRadius: radii.full,
        overflow: 'hidden',
        padding: 2,
        border: '2px solid transparent',
        transition: 'border-color .2s',
        [mediaQueries.sm]: {
            width: THUMB_SIZE[1],
            height: THUMB_SIZE[1]
        }
    },
    thumbListItemActive: {
        borderColor: colors.black
    },
    thumbListItemInactive: {
        '.no-touch &:hover, :focus': {
            borderColor: colors.midGray
        }
    }
};

export default wrapFunctionalComponent(MediaZoomItem, 'MediaZoomItem');
