import Location from 'utils/Location';

const shouldServiceRun = {
    testTarget: function () {
        return true;
    },

    addStructure: function () {
        return Location.isProductPage();
    },

    search: function () {
        return Location.isSearchPage();
    },

    catalog: function () {
        return true;
    },
    refreshBasket: function () {
        return true;
    }
};

const POST_LOAD_TIMEOUT = 15000;

export default {
    shouldServiceRun,
    POST_LOAD_TIMEOUT
};
