export default function getResource(label, vars = []) {
    const resources = {
        myReservations: 'Mes réservations',
        upcomingReservations: 'Réservations à venir',
        noReservationsLabel: 'Vous n’avez pas encore de réservation.',
        noReservationsLabelRedesign: 'Pas encore de réservation? Changeons cela!',
        noReservationsButtonLabel: 'Voir ce qui se passe',
        noReservationsButtonLabelRedesign: 'Découvrir les services et les événements',
        noReservationsText: 'Consulter Ça se passe chez Sephora pour voir les disponibilités près de chez vous. Membres Beauty Insider, profitez de toutes les expériences offertes par Sephora, réservez votre premier service aujourd’hui!',
        noReservationsTextRedesign: 'Découvrez nos services et événements pour découvrir un large éventail d’offres dans les magasins les plus près. Réservez votre premier service dès aujourd’hui et ne manquez pas nos événements à venir!'
    };

    return resources[label];
}
