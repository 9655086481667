import { Grid, Icon, Text } from 'components/ui';
import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';

const UpperFunnelTile = ({
    children, enabled, iconName, subTitle, title, dataAt
}) => (
    <Grid
        is='span'
        borderRadius={2}
        columns='auto 1fr'
        gap={'.1em .5em'}
        backgroundColor='nearWhite'
        paddingY='.5em'
        paddingX={['.5em', '.75em']}
    >
        <Icon
            name={iconName}
            color='gray'
            size='1.25em'
        />
        <Text
            numberOfLines={1}
            marginTop='.1em'
            children={title}
            data-at={Sephora.debug.dataAt(dataAt)}
        />
        {enabled && (
            <>
                <span />
                <Text fontSize='0.91em'>
                    <Text
                        fontSize={['11px', '12px']}
                        display='block'
                        color='green'
                        children={subTitle}
                    />
                    {children}
                </Text>
            </>
        )}
    </Grid>
);

UpperFunnelTile.defaultProps = { children: null };

UpperFunnelTile.propTypes = {
    children: PropTypes.element,
    enabled: PropTypes.bool.isRequired,
    iconName: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default wrapFunctionalComponent(UpperFunnelTile, 'UpperFunnelTile');
