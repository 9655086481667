import React from 'react';

import anaConsts from 'analytics/constants';

import { Link, Text } from 'components/ui';

import auth from 'utils/Authentication';
import dateUtils from 'utils/Date';
import isFunction from 'utils/functions/isFunction';
import locationUtils from 'utils/Location';
import languageLocaleUtils from 'utils/LanguageLocale';
import skuUtils from 'utils/Sku';
import urlUtils from 'utils/Url';
import calendarUtils from 'utils/Calendar';
import experienceDetailsUtils from 'utils/ExperienceDetails';
import javascriptUtils from 'utils/javascript';
import resourceWrapper from 'utils/framework/resourceWrapper';
import uiUtils from 'utils/UI';

import sdnApi from 'services/api/sdn';

import actions from 'actions/Actions';
import reduxStore from 'store/Store';

import Empty from 'constants/empty';
import { EDP_IMG_SIZES } from 'components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/constants';

const { getEdpPageUrl, formatAPIDateTime } = experienceDetailsUtils;
const {
    getLocaleResourceFile, getCurrentLanguageCountryCode, getCurrentCountry, getCountryLongName, isFRCanada, isUS, COUNTRIES
} =
    languageLocaleUtils;
const { navigateTo, getHappeningActivityInfo } = locationUtils;
const { getImagePath, getParams, getParamsByName, removeParam } = urlUtils;
const { showInfoModal, showInterstice } = actions;
const { dispatch } = reduxStore;

const { SMUI_CAROUSEL_WIDTH, LGUI_CAROUSEL_WIDTH, ZOOM } = EDP_IMG_SIZES;
const {
    MEDIA_TYPE: { IMAGE }
} = anaConsts;

const LOCALES = {
    EN: 'en-US',
    FR: 'fr-CA'
};

const CALENDAR_DEFAULT_DAYS_LENGTH = 90;

function capitalizeWord(word = '') {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

function buildEDPHeroImageSrc(item, isMobile = false) {
    let src = getImagePath(skuUtils.getImgSrc(ZOOM, item.media));
    const size = isMobile ? SMUI_CAROUSEL_WIDTH : LGUI_CAROUSEL_WIDTH;
    src = removeParam(src, 'pb');
    const symbol = Object.keys(getParams(src)).length ? '&' : '?';
    const imageSource = `${src + symbol}imwidth=${size}`;
    const imageSourceX2 = `${src + symbol}imwidth=${size * 2}`;

    return [imageSource, imageSourceX2];
}

function getMediaItems({ images = [], displayName = '', type = '' }) {
    const mediaListItems = images.map((item, index) => ({
        type: IMAGE,
        media: {
            ...item,
            altText: `${displayName} - ${type} image ${index + 1}`
        }
    }));

    // this helper will expnad for futere implementation of service videos

    return mediaListItems;
}

function formatArtistName(preferredName = '') {
    if (preferredName.indexOf(',') > -1) {
        let [lastName, firstName] = preferredName.split(',');

        firstName = firstName.replace(/\d/g, ''); // remove numeric characters
        firstName = capitalizeWord(firstName.trim());
        lastName = lastName.charAt(0).toLocaleUpperCase(); // return the first letter only

        return `${firstName} ${lastName}`;
    }

    return preferredName;
}

function eventsRSVP(options) {
    const { activityId, payload } = options;
    const targetUrl = '/happening/events/confirmation';

    return sdnApi.eventsRSVP(options).then(response => {
        if (response.responseStatus !== 200 || !response.confirmationNumber) {
            throw new Error('Invalid eventsRSVP response.');
        }

        const queryParams = 'id=' + response.confirmationNumber + '&activityId=' + activityId + '&bookingId=' + payload.bookingId;

        navigateTo(null, `${targetUrl}?${queryParams}`);
    });
}

async function getArtistsAvailabilityDates({ storeId }) {
    try {
        const { country, language } = Sephora.renderQueryParams;
        const { activityId: bookingId } = getHappeningActivityInfo();
        const data = await sdnApi.getServiceBookingDates({ country, language, bookingId, storeId });

        if (data.responseStatus && data.responseStatus !== 200) {
            throw Error('Invalid dates response');
        }

        return data;
    } catch {
        return null;
    }
}

async function getArtistsAvailabilitySlots({ storeId, timeZone }, selectedDate, resourceIds, showLoader = true) {
    showLoader && dispatch(showInterstice(true));

    try {
        const { activityId: bookingId } = getHappeningActivityInfo();

        const startDateTime = formatAPIDateTime(selectedDate, timeZone);
        const endDateTime = formatAPIDateTime(selectedDate, timeZone, true);

        const data = await sdnApi.getServiceBookingSlots({
            bookingId,
            storeId,
            resourceIds,
            startDateTime,
            endDateTime
        });

        if (data.responseStatus && data.responseStatus !== 200) {
            throw Error('Invalid slots response');
        }

        return data;
    } catch {
        return null;
    } finally {
        showLoader && dispatch(showInterstice(false));
    }
}

function addToCalendar(startDateTime, duration, activityName, storeName, activity) {
    const icsUrl = calendarUtils.buildCalendarUrl(startDateTime, duration, activityName, {
        description: activityName,
        location: 'Sephora ' + storeName,
        url: getEdpPageUrl(activity)
    });

    icsUrl && urlUtils.redirectTo(icsUrl);
}

function getWaiverMediaPrefValues() {
    const waiverMediaInfo = Sephora?.configurationSettings?.waiverMediaInfo || Empty.Object;

    if (javascriptUtils.isObjectEmpty(waiverMediaInfo)) {
        return {};
    }

    const currentLanguageCountryCode = getCurrentLanguageCountryCode();
    const countryCode = getCurrentCountry();

    const prefId = waiverMediaInfo[`waiverMediaId-${countryCode}`];
    const prefName = waiverMediaInfo[`name-${currentLanguageCountryCode}`];

    return { prefId, prefName };
}

function getRebookingInfo() {
    const rebookingStoreId = getParamsByName('storeId')?.[0];
    const rebookingZipCode = getParamsByName('zipCode')?.[0];
    const rebookingArtistId = getParamsByName('artistId')?.[0];
    const rescheduleConfirmationNumber = getParamsByName('rescheduleConfirmationNumber')?.[0];

    return {
        rebookingStoreId,
        rebookingZipCode,
        rebookingArtistId,
        rescheduleConfirmationNumber
    };
}

function ensureSephoraPrefix(storeName) {
    return storeName?.toLowerCase()?.startsWith('sephora') ? storeName : `Sephora ${storeName}`;
}

function buildCalendarDates(daysLength, startDate) {
    const datesList = [];

    const now = new Date();
    const currentDate = startDate ? startDate : now;

    const length = daysLength ?? CALENDAR_DEFAULT_DAYS_LENGTH;

    for (let i = 0; i < length; i++) {
        const date = dateUtils.addDays(currentDate, i);
        datesList.push(date);
    }

    return datesList;
}

function showSignInModal(cb) {
    auth.requireAuthentication()
        .then(() => isFunction(cb) && cb())
        .catch(() => {});
}

function showCountryMissMatchModal() {
    const getText = getLocaleResourceFile('utils/locales', 'happening');
    const getTextWithLink = resourceWrapper(getText);

    const handleClick = () => {
        setTimeout(() => uiUtils.scrollTo({ elementId: 'regionAndLanguage' }));

        return dispatch(showInfoModal({ isOpen: false }));
    };

    const countryName = isUS() ? getCountryLongName(COUNTRIES.CA) : getCountryLongName(COUNTRIES.US);

    const options = {
        isOpen: true,
        title: getText('changeCountry'),
        message: (
            <>
                <Text
                    is={'p'}
                    marginBottom={4}
                    children={getTextWithLink(
                        'changeCountryMessage',
                        false,
                        countryName,
                        <Link
                            color='blue'
                            underline
                            onClick={handleClick}
                            children={getText('bottomOfTheSite')}
                        />
                    )}
                />
                <Text
                    is={'p'}
                    children={getText('switchCountryBasketMessage', [countryName])}
                />
            </>
        ),
        buttonText: getText('ok'),
        buttonWidth: ['100%', null, 180],
        width: 2
    };

    return dispatch(showInfoModal(options));
}

/**
 *
 * @param {*} timeZone
 * @param {*} isoString
 * @param {*} customOptions
 * @param {*} isCustomWeekday
 * @returns { weekday, year, month, day, hour, minute, second, dayPeriod, isToday, isTomorrow }
 * @weekday as Today, Tomorrow or actual weekday
 * @dayPeriod as AM | PM
 * @isToday | @isTomorrow only set when @isCustomWeekday is true
 */
function getDateInTimeZoneFormattedParts(timeZone = null, isoString = null, customOptions = {}, isCustomWeekday = true) {
    const isCAFrench = isFRCanada();
    const getText = getLocaleResourceFile('utils/locales', 'Date');

    // Use the provided isoString or default to current date
    const inputDate = isoString ? new Date(isoString) : new Date();

    // Locale to format inputDate in
    const locale = isCAFrench ? LOCALES.FR : LOCALES.EN;

    // Options for formatting date and time
    const options = {
        ...(timeZone && { timeZone }),
        weekday: 'long', // e.g., Friday
        year: 'numeric', // e.g., 2024
        month: 'long', // e.g., August
        day: 'numeric', // e.g., 6
        hour: 'numeric', // e.g., 10
        minute: '2-digit', // e.g., 08
        second: '2-digit', // e.g., 06
        hour12: true, // 12-hour format returns dayPeriod as "AM or PM"
        ...customOptions
    };

    // Format the inputDate in the specified timezone and get its parts
    const parts = new Intl.DateTimeFormat(locale, options).formatToParts(inputDate);

    const dateParts = { isToday: false, isTomorrow: false };

    // Extract formatted date and time components
    for (const { type, value } of parts) {
        dateParts[type] = value;
    }

    // Override weekday returned value to Today or Tomorrow
    if (isCustomWeekday) {
        const formatInLocale = LOCALES.EN;

        const todayDate = new Date();
        const formattedToday = new Intl.DateTimeFormat(formatInLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }).format(todayDate);

        const tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        const formattedTomorrow = new Intl.DateTimeFormat(formatInLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }).format(tomorrowDate);

        const inputDateOptions = {
            ...(timeZone && { timeZone }),
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        const formattedInputDate = new Intl.DateTimeFormat(formatInLocale, inputDateOptions).format(inputDate);

        // Determine if the formattedInputDate is equal to formattedToday or formattedTomorrow
        if (formattedInputDate === formattedToday) {
            dateParts.weekday = getText('TODAY');
            dateParts.isToday = true;
        } else if (formattedInputDate === formattedTomorrow) {
            dateParts.weekday = getText('TOMORROW');
            dateParts.isTomorrow = true;
        }
    }

    if (isCAFrench) {
        let customWeekday = dateParts.weekday.toLowerCase();
        customWeekday = customWeekday.replace('.', '');

        dateParts.weekday = customWeekday;
        dateParts.month = dateParts.month.toLowerCase();
        dateParts.dayPeriod = dateParts.dayPeriod === 'a.m.' ? 'AM' : 'PM';
    }

    return dateParts;
}

/**
 *
 * @param {*} timeZone
 * @param {*} isoString
 * @returns Date object based on (@isoString or current time) in specified @timeZone or user locale
 */
function getDateInTimeZone(timeZone, isoString) {
    const {
        year, month, day, hour, minute, second
    } = getDateInTimeZoneFormattedParts(
        timeZone,
        isoString,
        { month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false },
        false
    );

    // Construct a string representing the date and time in ISO format without timezone information
    const isoStringInTimezone = `${year}-${month}-${day}T${hour}:${minute}:${second}`;

    // Create a Date object from the ISO string in the specified timezone
    const dateInTimezone = new Date(isoStringInTimezone);

    return dateInTimezone;
}

/**
 *
 * @param {*} timeZone
 * @param {*} isoString
 * @param {*} isDateOnly
 * @returns 'Friday, August 16, 10:45 PM' | 'Friday, August 16' when @isDateOnly = true
 */
function getFormattedTimeSlot(timeZone, isoString, isDateOnly = false) {
    const {
        weekday, month, day, hour, minute, dayPeriod
    } = getDateInTimeZoneFormattedParts(timeZone, isoString);

    const monthDayPart = isFRCanada() ? `${day} ${month}` : `${month} ${day}`;
    const formattedDate = `${weekday}, ${monthDayPart}`;
    const formattedtime = `${hour}:${minute} ${dayPeriod}`;

    return isDateOnly ? formattedDate : `${formattedDate}, ${formattedtime}`;
}

/**
 *
 * @param {*} dateString
 * @param {*} timeZone
 * @returns 10:45 PM
 */
function getTimeSlotFormattedHours(isoString, timeZone) {
    const { hour, minute, dayPeriod } = getDateInTimeZoneFormattedParts(timeZone, isoString, null, false);

    return `${hour}:${minute} ${dayPeriod}`;
}

/**
 *
 * @param {*} dateString
 * @param {*} timeZone
 * @returns Tuesday, August 20, 2024
 */
function formatAppointmentDate(isoString, timeZone) {
    const { weekday, year, month, day } = getDateInTimeZoneFormattedParts(timeZone, isoString);

    const monthDayPart = isFRCanada() ? `${day} ${month}` : `${month} ${day}`;

    return `${weekday}, ${monthDayPart}, ${year}`;
}

/**
 *
 * @param {*} isoString
 * @param {*} timeZone
 * @param {*} durationMinutes
 * @param {*} isEndTimeOnly
 * @returns '9:00 AM - 9:30 AM' | '9:30 AM' when @isEndTimeOnly = true
 */
function formatAppointmentTimeFrame(isoString, timeZone, durationMinutes, isEndTimeOnly = false) {
    // Get endTimeZoneDate in the specified isoString & timeZone
    const endTimeZoneDate = getDateInTimeZone(timeZone, isoString);
    // Add the durationMinutes
    endTimeZoneDate.setMinutes(endTimeZoneDate.getMinutes() + durationMinutes);

    // Get end time parts from the specified endTimeZoneDate, no need for timeZone as it's already converted
    const { hour: endHour, minute: endMinute, dayPeriod: endDayPeriod } = getDateInTimeZoneFormattedParts(null, endTimeZoneDate, null, false);

    if (isEndTimeOnly) {
        return `${endHour}:${endMinute} ${endDayPeriod}`;
    }

    // Get start time parts from the specified isoString & timeZone
    const { hour: startHour, minute: startMinute, dayPeriod: startDayPeriod } = getDateInTimeZoneFormattedParts(timeZone, isoString, null, false);

    return `${startHour}:${startMinute} ${startDayPeriod} - ${endHour}:${endMinute} ${endDayPeriod}`;
}

/**
 *
 * @param {*} isoString
 * @param {*} timeZone
 * @param {*} durationMinutes
 * @returns Boolean
 */
function getIsEventInProgress(isoString, timeZone, durationMinutes) {
    const currentDateInTZ = getDateInTimeZone(timeZone);
    const startDateInTZ = getDateInTimeZone(timeZone, isoString);

    const endDateInTZ = getDateInTimeZone(timeZone, isoString);
    // Add the durationMinutes
    endDateInTZ.setMinutes(endDateInTZ.getMinutes() + durationMinutes);

    const isInProgress = startDateInTZ <= currentDateInTZ && currentDateInTZ <= endDateInTZ;

    return isInProgress;
}

/**
 *
 * @param {*} isoString
 * @param {*} timeZone
 * @param {*} durationMinutes
 * @param {*} isEventInProgress
 * @returns (Today|Tomorrow), 8:00 AM - 11:00 AM | Sat, Aug 31, 8:00 AM - 11:00 AM | Join us until 11:00 AM
 */
function getEventFormattedDate(isoString, timeZone, durationMinutes, isEventInProgress = false) {
    const getText = getLocaleResourceFile('utils/locales', 'happening');

    const {
        weekday, month, day, isToday, isTomorrow
    } = getDateInTimeZoneFormattedParts(timeZone, isoString, { weekday: 'short', month: 'short' });

    const timeFrame = formatAppointmentTimeFrame(isoString, timeZone, durationMinutes, isEventInProgress);

    if (isEventInProgress) {
        return `${getText('joinUsUntil')} ${timeFrame}`;
    }

    const monthDayPart = isFRCanada() ? `${day} ${month}` : `${month} ${day}`;
    const datePart = isToday || isTomorrow ? weekday : `${weekday}, ${monthDayPart}`;

    return `${datePart}, ${timeFrame}`;
}

/**
 *
 * @param {*} isoString
 * @param {*} timeZone
 * @param {*} durationMinutes
 * @param {*} isEventInProgress
 * @returns (Today|Tomorrow), 8:00 AM - 11:00 AM | Saturday, August 31, 8:00 AM - 11:00 AM
 */
function getEventFormattedFullDate(isoString, timeZone, durationMinutes) {
    const {
        weekday, month, day, isToday, isTomorrow
    } = getDateInTimeZoneFormattedParts(timeZone, isoString, null);

    const timeFrame = formatAppointmentTimeFrame(isoString, timeZone, durationMinutes);

    const monthDayPart = isFRCanada() ? `${day} ${month}` : `${month} ${day}`;
    const datePart = isToday || isTomorrow ? weekday : `${weekday}, ${monthDayPart}`;

    return `${datePart}, ${timeFrame}`;
}

function isFutureEvent(isoString, timeZone, durationMinutes) {
    const currentDateInTZ = getDateInTimeZone(timeZone);

    const endDateInTZ = getDateInTimeZone(timeZone, isoString);
    // Add the durationMinutes
    endDateInTZ.setMinutes(endDateInTZ.getMinutes() + durationMinutes);

    return currentDateInTZ <= endDateInTZ;
}

/**
 *
 * @param {*} ymdString in format YYYY-MM-DD
 * @returns Date
 */
function getDateFromYMD(ymdString) {
    const [year, m, day] = ymdString.split('-').map(s => parseInt(s, 10));
    const month = m - 1; // Month (0-based index)

    return new Date(year, month, day);
}

/**
 *
 * @param {*} isoString
 * @param {*} timeZone
 * @returns Number
 */
function getHoursDifference(isoString, timeZone) {
    const currentDateInTZ = getDateInTimeZone(timeZone);
    const startDateInTZ = getDateInTimeZone(timeZone, isoString);

    const timeDifference = startDateInTZ - currentDateInTZ;
    const oneHourInMs = 1000 * 60 * 60;
    const hoursDifference = Math.floor(timeDifference / oneHourInMs);

    return hoursDifference;
}

/**
 *
 * @param {*} startDateTime
 * @param {*} timeZone
 * @returns Boolean
 */
function isEventStillUpcoming(isoString, timeZone) {
    const currentDateInTZ = getDateInTimeZone(timeZone);
    const currentDateInTZEpoch = currentDateInTZ.getTime();

    const startDateInTZ = getDateInTimeZone(timeZone, isoString);
    const startDateInTZEpoch = startDateInTZ.getTime();

    const isStillAvailable = currentDateInTZEpoch < startDateInTZEpoch;

    return isStillAvailable;
}

export {
    buildEDPHeroImageSrc,
    getMediaItems,
    getIsEventInProgress,
    formatAppointmentDate,
    formatArtistName,
    eventsRSVP,
    getArtistsAvailabilityDates,
    getArtistsAvailabilitySlots,
    getHoursDifference,
    addToCalendar,
    isFutureEvent,
    getWaiverMediaPrefValues,
    getRebookingInfo,
    isEventStillUpcoming,
    ensureSephoraPrefix,
    buildCalendarDates,
    getTimeSlotFormattedHours,
    showSignInModal,
    showCountryMissMatchModal,
    getFormattedTimeSlot,
    formatAppointmentTimeFrame,
    getDateFromYMD,
    getEventFormattedDate,
    getEventFormattedFullDate
};
