import { connect } from 'react-redux';
import FrameworkUtils from 'utils/framework';
import { createSelector } from 'reselect';

import { coreUserDataSelector } from 'viewModel/selectors/user/coreUserDataSelector';
import purchaseHistorySelector from 'selectors/purchaseHistory/purchaseHistorySelector';
import RecentlyViewedDataSelector from 'selectors/recentlyViewedData/recentlyViewedDataSelector';
import initializedCurrentLovesSelector from 'selectors/loves/initializedCurrentLovesSelector';
import beautyRecommendationsSelector from 'selectors/beautyRecommendations/beautyRecommendationsSelector';

import recapComponentListSelector from 'viewModel/content/recapList/selectors/recapComponentListSelector';
import recapBasketComponentSelector from 'viewModel/content/recapList/selectors/recapBasketComponentSelector';
import { getRecapJSXComponentCards } from 'viewModel/content/recapList/getRecapJSXComponentCards';

import UserActions from 'actions/UserActions';
import RvDataActions from 'actions/RvDataActions';
import { p13nSelector } from 'selectors/p13n/p13nSelector';
import PersonalizationUtils from 'utils/Personalization';

import { sendCmsComponentEvent } from 'analytics/utils/cmsComponents';
import constants from 'constants/content';
import anaConsts from 'analytics/constants';

const {
    COMPONENT_TYPES: { RECAP }
} = constants;

const {
    CMS_COMPONENT_EVENTS: { IMPRESSION, ITEM_CLICK }
} = anaConsts;

const { getPersonalizedComponent } = PersonalizationUtils;
const { wrapHOC } = FrameworkUtils;
const { recentlyViewedDataSelector } = RecentlyViewedDataSelector;
const { fetchPurchaseHistory, fetchBeautyRecommendations } = UserActions;
const { updateRvData } = RvDataActions;

export default wrapHOC(
    connect(
        createSelector(
            coreUserDataSelector,
            recapComponentListSelector,
            purchaseHistorySelector,
            recentlyViewedDataSelector,
            initializedCurrentLovesSelector,
            recapBasketComponentSelector,
            beautyRecommendationsSelector,
            p13nSelector,
            (_state, ownProps) => ownProps.title,
            (_state, ownProps) => ownProps.personalization,
            (_state, ownProps) => ownProps,
            (
                user,
                { componentList, ...requiredData },
                purchaseHistory,
                { rvData },
                currentLovesData,
                basket,
                beautyRecommendations,
                p13n,
                carouselTitle,
                personalization,
                ownProps
            ) => {
                const data = {
                    ...(requiredData.purchaseHistory && {
                        purchaseHistory: {
                            items: purchaseHistory?.items,
                            dataReady: Array.isArray(purchaseHistory?.items)
                        }
                    }),
                    ...(requiredData.rvData && {
                        rvData: {
                            items: rvData,
                            dataReady: Array.isArray(rvData)
                        }
                    }),
                    ...(requiredData.currentLovesData && {
                        currentLovesData: {
                            items: currentLovesData.currentLoves,
                            dataReady: currentLovesData.currentLovesIsInitialized
                        }
                    }),
                    ...(requiredData.basket && {
                        basket: {
                            ...basket,
                            dataReady: basket.isInitialized
                        }
                    }),
                    ...(requiredData.beautyRecommendations && {
                        beautyRecommendations: {
                            items: beautyRecommendations?.items,
                            dataReady: Array.isArray(beautyRecommendations?.items)
                        }
                    })
                };

                const isLoading = !Object.values(data).every(val => val.dataReady);
                const personalizedComponent = getPersonalizedComponent(personalization, user, p13n, componentList);
                const items = personalizedComponent?.variationData?.items || componentList;
                const userId = user.userId;

                const triggerClick = function (bannerSid, position) {
                    const { sid, title } = ownProps;
                    const eventName = ITEM_CLICK;

                    const _items = items.map((item, index) => {
                        if (index === position) {
                            return { ...item, itemIndex: position };
                        }

                        return item;
                    });

                    sendCmsComponentEvent({
                        items: _items,
                        eventName,
                        title,
                        sid,
                        componentSid: bannerSid,
                        component: RECAP
                    });
                };
                // a card in cards array will be rendered in carousel
                // a rendered card might be in a skeleton state or content state
                const cards = getRecapJSXComponentCards({
                    isLoading,
                    items,
                    data,
                    carouselTitle,
                    triggerClick
                });

                const mountItems = cards.map(card => ({
                    ...card.props,
                    uniqueSkus: card.props?.uniqueSkus || [],
                    skus: card.props?.skus || {}
                }));

                const triggerRecapImpression = targets => {
                    if (!isLoading) {
                        const { title, sid } = ownProps;

                        const currentItems = mountItems
                            .map((item, index) => ({
                                ...item,
                                itemIndex: index
                            }))
                            .filter((item, index) => targets.includes(index));

                        const eventName = IMPRESSION;
                        setTimeout(() => {
                            sendCmsComponentEvent({
                                items: currentItems,
                                eventName,
                                title,
                                sid,
                                component: RECAP
                            });
                        }, 1000);
                    }
                };

                // entire carousel is hidden initially to avoid CLS issues for anonymous users (EXP-4648)
                let hideRow = true;

                if (user.isInitialized && !user.isAnonymous && cards.length > 0) {
                    hideRow = false;
                }

                return {
                    userId,
                    hideRow,
                    isLoading,
                    cards,
                    requiredData,
                    triggerRecapImpression
                };
            }
        ),
        {
            fetchPurchaseHistory,
            updateRvData,
            fetchBeautyRecommendations
        }
    )
);
