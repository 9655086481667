import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+State+List+API

function getStateList(countryCode) {
    const url = `/api/util/countries/${countryCode}/states`;

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data.states));
}

export default getStateList;
