export default function getResource(label, vars = []) {
    const resources = {
        title: 'Apply Points',
        apply: 'Apply',
        applied: 'Applied',
        remove: 'Remove',
        amountApplied: `<b>${vars[0]}</b> applied`,
        pointsAppliedLegal: 'Points applied for Beauty Insider Cash are non-refundable.',
        multiplePromoSubTitle: `Up to <b>${vars[0]}</b> available`,
        singlePromoSubTitle: `<b>${vars[0]} off</b> (${vars[1]} points)`,
        modalTitle: 'Apply Points to Your Purchase',
        modalSubTitle: `You currently have <b>${vars[0]}</b> points`,
        done: 'Done',
        biCashAmount: `<b>${vars[0]}</b> Beauty Insider Cash`,
        pointsAmount: `${vars[0]} points`,
        subtotal: 'Order Subtotal',
        chooseText: 'Please choose one:'
    };

    return resources[label];
}
