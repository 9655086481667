export default function getResource(label, vars = []) {
    const resources = {
        smsSignInModalTitle: 'S’abonner à Sephora',
        greeting: `Bonjour ${vars[0]},`,
        ModalTextHeading: 'Inscrivez-vous aux mises à jour par textos de Sephora.',
        ModalTextInputHeading: 'Saisissez votre numéro de téléphone cellulaire ci-dessous pour vous inscrire et toujours profiter des nouveautés, offres et événements exclusifs.',
        enterMobileNumber: 'Numéro de téléphone cellulaire',
        signUpNow: 'Terminer l’inscription',
        TermsAndConditon: 'En entrant votre numéro de téléphone et en cliquant sur Terminer l’inscription, vous consentez aux ',
        textTerms: 'MODALITÉS DES TEXTOS',
        TermsAndConditon2: ' et à recevoir des textos de marketing automatiques, y compris des rappels de panier abandonné. La fréquence des messages varie. Le consentement n’est pas une condition d’achat. Des frais de messagerie texte et de données peuvent s’appliquer. Consultez notre ',
        privacyPolicy: 'POLITIQUE DE CONFIDENTIALITÉ',
        TermsAndConditon3: ' et ',
        notice: 'AVIS DE PRIME FINANCIÈRE.',
        TermsAndConditon4: ' Textez ARRET pour annuler en tout temps. Textez AIDE pour obtenir de l’aide.',
        TermsAndConditon5: ' Sephora : 600, boul. de Maisonneuve Ouest, bureau 2400, Montréal (Québec) H3A 3J2, Canada. 1-877-737-4672.'
    };

    return resources[label];
}
