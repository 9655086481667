const CHALLENGE_STATUS = {
    NOT_JOINED: 'NOT_JOINED',
    OPTED_IN: 'OPTED_IN',
    COMPLETED: 'COMPLETED'
};

const QUIZ = {
    COMPLETE_SKIN_ROUTINE_QUIZ: 'complete_skin_routine_quiz',
    COMPLETE_BEAUTY_PREFERENCES: 'complete_beauty_preferences'
};

export {
    CHALLENGE_STATUS, QUIZ
};
