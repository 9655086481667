const actionTypes = {
    PURCHASES_FILTER_OPTIONS: 'PURCHASES_FILTER_OPTIONS',
    RESTORE_PRODUCT_WITH_NO_EXTRA_DETAILS: 'RESTORE_PRODUCT_WITH_NO_EXTRA_DETAILS',
    REVIEW_FILTERS_APPLIED: 'REVIEW_FILTERS_APPLIED',
    REVIEW_FILTERS_SELECTED: 'REVIEW_FILTERS_SELECTED',
    REVIEW_SEARCH_APPLIED: 'REVIEW_SEARCH_APPLIED',
    SELECT_FILTER_OPTION: 'SELECT_FILTER_OPTION',
    SELECT_SORT_OPTION: 'SELECT_SORT_OPTION',
    SET_PRODUCT: 'SET_PRODUCT',
    TOGGLE_CUSTOM_SETS: 'TOGGLE_CUSTOM_SETS',
    UPDATE_CURRENT_PRODUCT_USER_SPECIFIC: 'UPDATE_CURRENT_PRODUCT_USER_SPECIFIC',
    UPDATE_CURRENT_PRODUCT: 'UPDATE_CURRENT_PRODUCT',
    UPDATE_CURRENT_SKU_IN_CURRENT_PRODUCT: 'UPDATE_CURRENT_SKU_IN_CURRENT_PRODUCT',
    UPDATE_RESERVE_ONLINE_PICK_UP_IN_STORE_DETAILS: 'UPDATE_RESERVE_ONLINE_PICK_UP_IN_STORE_DETAILS',
    UPDATE_SAME_DAY_DELIVERY_DETAILS: 'UPDATE_SAME_DAY_DELIVERY_DETAILS',
    LOAD_HIGHLIGHTED_REVIEWS: 'LOAD_HIGHLIGHTED_REVIEWS',
    UPDATE_HIGHLIGHTED_REVIEWS: 'UPDATE_HIGHLIGHTED_REVIEWS',
    REMOVE_HIGHLIGHTED_REVIEWS: 'REMOVE_HIGHLIGHTED_REVIEWS',
    SET_SELECTED_SENTIMENT: 'SET_SELECTED_SENTIMENT',
    SET_PRODUCT_PAGE_FULFILLMENT_OPTIONS: 'SET_PRODUCT_PAGE_FULFILLMENT_OPTIONS'
};

export default actionTypes;
