import ufeApi from 'services/api/ufeApi';
import urlUtils from 'utils/Url';
import apiUtils from 'utils/Api';
import catalogUtils from 'utils/Catalog';

const { addBrowseExperienceParams } = apiUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Type+Ahead+Search+API

function searchTypeAhead(query, includeOnlyProducts = false, suggestions = {}) {
    const constructorSessionID = !!global.ConstructorioTracker && global.ConstructorioTracker.getSessionID();
    const constructorClientID = !!global.ConstructorioTracker && global.ConstructorioTracker.getClientID();
    const isNLPSearchEnabled = Sephora.configurationSettings.isNLPSearchEnabled;
    const isNLPInstrumentationEnabled = Sephora.configurationSettings.isNLPInstrumentationEnabled;
    const lastParam = includeOnlyProducts ? '&includeOnlyProducts=true' : '';
    const { productSuggestions = 6, searchSuggestions = 3, categorySuggestions = 5 } = suggestions;
    let url = '/api/catalog/search/';

    if (catalogUtils.isSXSServiceEnabled()) {
        url = '/api/v2/catalog/search/';
    }

    const type = catalogUtils.isSXSServiceEnabled() ? 'typeAhead' : 'typeahead';

    url =
        url +
        `?type=${type}&q=${encodeURIComponent(query)}${lastParam}&searchSuggestions=${searchSuggestions}&productSuggestions=${productSuggestions}`;

    if (catalogUtils.isSXSServiceEnabled()) {
        url = url + `&categorySuggestions=${categorySuggestions}`;
    }

    if (isNLPInstrumentationEnabled) {
        if (isNLPSearchEnabled && constructorSessionID && constructorClientID) {
            url = url + `&constructorSessionID=${constructorSessionID}&constructorClientID=${constructorClientID}&targetSearchEngine=nlp`;
        }
    } else {
        url = url + '&targetSearchEngine=nlp';
    }

    const opts = {};
    const config = {
        service: 'search',
        isSXSSearchEnabled: catalogUtils.isSXSServiceEnabled()
    };

    addBrowseExperienceParams(opts, config);

    if (Object.keys(opts).length) {
        url += '&' + urlUtils.makeQueryString(opts);
    }

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default searchTypeAhead;
