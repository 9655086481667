import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/pages/viewpage.action?pageId=141421043

function getClientSummary(userProfileId, includeCampaigns = false) {
    let url;

    if (Sephora.configurationSettings.isAdvocacyContentfulEnabled) {
        url = `/api2/bi/profiles/${userProfileId}/clientSummary?includeActiveCampaigns=${includeCampaigns}`;
    } else {
        url = `/api/bi/profiles/${userProfileId}/clientSummary?includeActiveCampaigns=${includeCampaigns}`;
    }

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getClientSummary;
