import Empty from 'constants/empty';
import { createSelector } from 'reselect';
import { testTargetOffersSelector } from 'selectors/testTarget/offers/testTargetOffersSelector';

const sephoraMachineLearningModelV2Selector = createSelector(
    testTargetOffersSelector,
    offers => offers.sephoraMachineLearningModelV2 || Empty.Object
);

export { sephoraMachineLearningModelV2Selector };
