import ufeApi from 'services/api/ufeApi';

const getServiceBookingDetails = (
    token,
    {
        language, country, channel = 'rwd', bookingId, zipCode, latitude, longitude, includeServiceInfo = true, selectedStoreId
    }
) => {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const locale = `${language.toLowerCase()}-${country}`;
    const searchParams = latitude && longitude ? `latitude=${latitude}&longitude=${longitude}` : `zipCode=${zipCode}`;
    const selectedStoreIdParam = selectedStoreId ? `&selectedStoreId=${selectedStoreId}` : '';

    const url = `${sdnDomain}/v2/happening/services/${bookingId}/booking/stores?${searchParams}&channel=${channel}&locale=${locale}&country=${country}&includeServiceInfo=${includeServiceInfo}${selectedStoreIdParam}`;

    return ufeApi
        .makeRequest(url, {
            method: 'GET',
            headers: {
                'x-requested-source': 'rwd',
                authorization: `Bearer ${token}`
            }
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
};

export default getServiceBookingDetails;
