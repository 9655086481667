export default function getResource(label, vars = []) {
    const resources = {
        UPCOMING: 'Upcoming',
        PAST: 'Past',
        CANCELLED: 'Canceled',
        CANCELLED_BY_STORE: 'Canceled By Store',
        LATE_CANCELLATION: `Late Cancelation ${vars[0]} Fee`,
        NO_SHOW: `No Show ${vars[0]} Fee`,
        addToCalendar: 'Add to Calendar',
        reschedule: 'Reschedule',
        cancel: 'Cancel',
        bookAgain: 'Book Again',
        productRecs: 'View Product Recs',
        serviceDescription: 'Service Description',
        confirmation: 'Confirmation',
        location: 'Location',
        details: 'Details',
        selectedFeature: 'Selected Feature',
        reservationDetails: 'Reservation Details',
        dateAndTime: 'Date and Time',
        eventDescription: 'Event Description'
    };

    return resources[label];
}
