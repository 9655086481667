import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import FrameworkUtils from 'utils/framework';
import { userSelector } from 'selectors/user/userSelector';
import personalizedPromotionsSelector from 'selectors/personalizedPromotions/personalizedPromotionsSelector';

const { wrapHOC } = FrameworkUtils;
const fields = createStructuredSelector({
    user: userSelector,
    personalizedPromotions: personalizedPromotionsSelector
});

const functions = null;

const withMyOffersLinkProps = wrapHOC(connect(fields, functions));

export {
    withMyOffersLinkProps, fields, functions
};
