import ufeApi from 'services/api/ufeApi';
import localeUtils from 'utils/LanguageLocale';
import RCPSCookies from 'utils/RCPSCookies';

// https://confluence.sephora.com/wiki/display/ILLUMINATE/GET+ROPIS+Specific+Product+Details

function getRopisSpecificProductDetails(profileId = 'current', productId, skuId = null) {
    const apiVersion = RCPSCookies.isRCPSProductEnabled() ? '/api/v3' : '/api2';

    let url = `${apiVersion}/users/profiles/${profileId}/ropisproduct/${productId}`;
    const queryParams = [];

    if (skuId !== null) {
        const countryCode = localeUtils.getCurrentCountry();
        const languageCode = localeUtils.getCurrentLanguageCountryCode().replace('_', '-');
        queryParams.push(`?preferedSku=${skuId}&countryCode=${countryCode}&loc=${languageCode}`);
    }

    url = url + queryParams.join('&');

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default getRopisSpecificProductDetails;
