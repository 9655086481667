import ufeApi from 'services/api/ufeApi';
const URL = '/api/auth/v1/emailVerificationStoreBI';

function emailVerificationStoreBI(email, token) {
    const payload = {};

    if (email) {
        payload.email = email;
    } else if (token) {
        payload.token = token;
    }

    return ufeApi
        .makeRequest(URL, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-type': 'application/json',
                'x-requested-source': 'web'
            }
        })
        .then(data => {
            return data.errorCode || data.errors ? Promise.reject(data) : data;
        })
        .catch(err => Promise.reject(err));
}

export default { emailVerificationStoreBI };
