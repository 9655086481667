import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

import {
    Box, Flex, Image, Text
} from 'components/ui';
import LegacyGrid from 'components/LegacyGrid/LegacyGrid';
import Radio from 'components/Inputs/Radio/Radio';
import ContentHeading from 'components/RichProfile/EditMyProfile/Content/ContentHeading';
import localeUtils from 'utils/LanguageLocale';

class Eyes extends BaseClass {
    constructor(props) {
        super(props);

        const biDataToSave = this.props.biDataToSave || {};
        const biAccountToSave = biDataToSave.biAccount || {};
        const personalizedInfoToSave = biAccountToSave.personalizedInformation || {};

        if (personalizedInfoToSave.eyeColor) {
            this.state = {
                eyeColor: personalizedInfoToSave.eyeColor
            };
        } else {
            const eyeColors = this.props.biAccount.personalizedInformation.eyeColor;

            let selectedColor = eyeColors.filter(eyeColor => eyeColor.isSelected);

            if (selectedColor.length === 0) {
                selectedColor = null;
            } else {
                selectedColor = selectedColor[0].value;
            }

            this.state = {
                eyeColor: selectedColor
            };
        }
    }

    getData = () => {
        return { biAccount: { personalizedInformation: { eyeColor: this.state.eyeColor } } };
    };

    handleEyeColorChange = eyeColor => () => {
        this.setState({
            eyeColor: eyeColor
        });
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/RichProfile/EditMyProfile/Content/Eyes/locales', 'Eyes');
        const { biAccount } = this.props;

        const eyeColors = biAccount.personalizedInformation.eyeColor;

        return (
            <div
                role='group'
                aria-labelledby='profile_heading_eyeColor'
            >
                <ContentHeading
                    id='profile_heading_eyeColor'
                    parens={getText('selectOne')}
                >
                    {getText('eyeColor')}
                </ContentHeading>
                <LegacyGrid gutter={4}>
                    {eyeColors.map(eyeColor => {
                        const eyeColorFile = eyeColor.value;

                        //revisit this
                        return (
                            <LegacyGrid.Cell
                                key={eyeColor.value}
                                width={Sephora.isMobile() ? 1 / 2 : 1 / 3}
                                marginTop={5}
                            >
                                <Radio
                                    hasHover={true}
                                    hasDot={false}
                                    paddingY={null}
                                    name='eyeColor'
                                    checked={this.state.eyeColor === eyeColor.value}
                                    onChange={this.handleEyeColorChange(eyeColor.value)}
                                >
                                    <Flex alignItems='center'>
                                        <Box
                                            border='3px solid'
                                            borderColor={this.state.eyeColor === eyeColor.value ? 'black' : 'transparent'}
                                            borderRadius='7px'
                                        >
                                            <Image
                                                display='block'
                                                src={`/img/ufe/rich-profile/eyecolor-${eyeColorFile}.png`}
                                                size={36}
                                            />
                                        </Box>
                                        <Text marginLeft={4}>{eyeColor.displayName}</Text>
                                    </Flex>
                                </Radio>
                            </LegacyGrid.Cell>
                        );
                    })}
                </LegacyGrid>
            </div>
        );
    }
}

export default wrapComponent(Eyes, 'Eyes');
