// This module provides API call methods for SDN store API endpoint:
// https://stage-developer.sephora.com/specs/v1/docs/store.html

const olrToken = 'OLR_AUTH_TOKEN';
const bvToken = 'BV_AUTH_TOKEN';
const ufeToken = 'UFE_AUTH_TOKEN';

import sdnToken from 'services/api/sdn/sdnToken';
import addPaymentCreditCard from 'services/api/onlineReservation/addPaymentCreditCard';
import bookReservation from 'services/api/onlineReservation/bookReservation';
import getServicesAndEventsContent from 'services/api/happening/getServicesAndEventsContent';
import getActivityEDPContent from 'services/api/happening/getActivityEDPContent';
import getRwdStoreDetailsContent from 'services/api/happening/getRwdStoreDetailsContent';
import getReservationsContent from 'services/api/happening/getReservationsContent';
import getReservationsDetails from 'services/api/happening/getReservationsDetails';
import getServiceBookingDetails from 'services/api/happening/getServiceBookingDetails';
import getServiceBookingDates from 'services/api/happening/getServiceBookingDates';
import getServiceBookingSlots from 'services/api/happening/getServiceBookingSlots';
import getEventRSVPConfirmationContent from 'services/api/happening/getEventRSVPConfirmationContent';
import getServicesConfirmationContent from 'services/api/happening/getServicesConfirmationContent';
import eventsRSVP from 'services/api/happening/eventsRSVP';
import sendServiceReservation from 'services/api/happening/sendServiceReservation';
import cancelServiceOrEventReservation from 'services/api/happening/cancelServiceOrEventReservation';
import getStoreDetailsPageContent from 'services/api/storeDetails/getStoreDetailsPageContent';
import cancelReservation from 'services/api/onlineReservation/cancelReservation';
import createReservation from 'services/api/onlineReservation/createReservation';
import deletePaymentCreditCard from 'services/api/onlineReservation/deletePaymentCreditCard';
import getExperiences from 'services/api/onlineReservation/getExperiences';
import getPoliciesPerActivity from 'services/api/onlineReservation/getPoliciesPerActivity';
import getPaymentOptions from 'services/api/onlineReservation/getPaymentOptions';
import getReservationByConfNumber from 'services/api/onlineReservation/getReservationByConfNumber';
import getReservations from 'services/api/onlineReservation/getReservations';
import updatePaymentCreditCard from 'services/api/onlineReservation/updatePaymentCreditCard';
import updateReservation from 'services/api/onlineReservation/updateReservation';

import getBrandsList from 'services/api/reverseLookUp/getBrandsList';
import getProductIdLab from 'services/api/reverseLookUp/getProductIdLab';
import getProductList from 'services/api/reverseLookUp/getProductList';
import getProductSkuList from 'services/api/reverseLookUp/getProductSkuList';
import getReverseLookUpSkuDetails from 'services/api/reverseLookUp/getReverseLookUpSkuDetails';
import getMultiMatch from 'services/api/reverseLookUp/getMultiMatch';
import getLABCodeDescription from 'services/api/reverseLookUp/getLABCodeDescription';
import notificationsApi from 'services/api/notifications';
import getFulfillmentOptions from 'services/api/upfunnel/getFulfillmentOptions';
import gameOptIn from 'services/api/gamification/gameOptIn';
import acknowledgeGameCompletion from 'services/api/gamification/acknowledgeGameCompletion';
import challengeMessage from 'services/api/gamification/challengeMessage';
import checkin from 'services/api/reservations/checkin';
import getItemSubstitutionProductRecs from 'services/api/itemSubstitution/getItemSubstitutionProductRecs';
import validatePromo from 'services/api/targetedLandingPage/validatePromo';
import getUserReservationPaymentInfo from 'services/api/happening/getUserRservationPaymentInfo';
import getSeasonalContent from 'services/api/happening/getSeasonalContent';

const withSdnToken = sdnToken.withSdnToken;

export default {
    addPaymentCreditCard,
    getServicesAndEventsContent: withSdnToken(getServicesAndEventsContent, ufeToken),
    getActivityEDPContent: withSdnToken(getActivityEDPContent, ufeToken),
    getRwdStoreDetailsContent: withSdnToken(getRwdStoreDetailsContent, ufeToken),
    getStoreDetailsPageContent: withSdnToken(getStoreDetailsPageContent, ufeToken),
    getReservationsContent: withSdnToken(getReservationsContent, ufeToken),
    getReservationsDetails: withSdnToken(getReservationsDetails, ufeToken),
    getServiceBookingDetails: withSdnToken(getServiceBookingDetails, ufeToken),
    getServiceBookingDates: withSdnToken(getServiceBookingDates, ufeToken),
    getServiceBookingSlots: withSdnToken(getServiceBookingSlots, ufeToken),
    getEventRSVPConfirmationContent: withSdnToken(getEventRSVPConfirmationContent, ufeToken),
    getServicesConfirmationContent: withSdnToken(getServicesConfirmationContent, ufeToken),
    eventsRSVP: withSdnToken(eventsRSVP, ufeToken),
    sendServiceReservation: withSdnToken(sendServiceReservation, ufeToken),
    cancelServiceOrEventReservation: withSdnToken(cancelServiceOrEventReservation, ufeToken),
    bookReservation: withSdnToken(bookReservation, olrToken),
    cancelReservation: withSdnToken(cancelReservation, olrToken),
    createReservation: withSdnToken(createReservation, olrToken),
    deletePaymentCreditCard,
    getExperiences: withSdnToken(getExperiences, olrToken),
    getPoliciesPerActivity: withSdnToken(getPoliciesPerActivity, olrToken),
    getPaymentOptions,
    getReservationByConfNumber: withSdnToken(getReservationByConfNumber, olrToken),
    getReservations: withSdnToken(getReservations, olrToken),
    updatePaymentCreditCard,
    updateReservation: withSdnToken(updateReservation, olrToken),

    getBrandsList: withSdnToken(getBrandsList, bvToken),
    getProductIdLab: withSdnToken(getProductIdLab, bvToken),
    getProductList: withSdnToken(getProductList, bvToken),
    getProductSkuList: withSdnToken(getProductSkuList, bvToken),
    getMultiMatch: withSdnToken(getMultiMatch, bvToken),
    getLABCodeDescription: withSdnToken(getLABCodeDescription, bvToken),
    getReverseLookUpSkuDetails,
    getFulfillmentOptions,
    gameOptIn: withSdnToken(gameOptIn.gameOptIn, ufeToken),
    acknowledgeGameCompletion: withSdnToken(acknowledgeGameCompletion.acknowledgeGameCompletion, ufeToken),
    challengeMessage: withSdnToken(challengeMessage.challengeMessage, ufeToken),
    // Notification service
    notifyCurbsidePickup: withSdnToken(notificationsApi.curbsidePickupCheckin, ufeToken),
    // Reservation service
    checkin: withSdnToken(checkin, olrToken),
    getItemSubstitutionProductRecs: getItemSubstitutionProductRecs,
    validatePromo: withSdnToken(validatePromo, ufeToken),
    getUserReservationPaymentInfo: withSdnToken(getUserReservationPaymentInfo, ufeToken),
    getSeasonalContent: withSdnToken(getSeasonalContent, ufeToken)
};
