export default function getResource(label, vars = []) {
    const resources = {
        sephoraStore: `Sephora ${vars[0]}`,
        pickupInfo: 'Pickup Info',
        callStore: 'Call Store',
        happeningAt: 'Happening at Sephora',
        happeningAtRedesign: 'Services and Events at Sephora',
        seeWhatsGoingOn: 'Free services, brand launches, classes & more—see what’s going on at your store today.',
        seeWhatsGoingOnRedesign: 'Explore our beauty services and free events at your store today.',
        seeWhatsHappening: 'See what’s happening at this store',
        seeWhatsHappeningRedesign: 'Explore Services and Events',
        getDirections: 'Get Directions',
        away: 'away',
        howToFindUs: 'How to find us?',
        tempClosed: 'Temporarily Closed',
        tempUnavailable: 'Temporarily Unavailable',
        KohlsCopy: 'Sephora promotions and rewards may not apply at Kohl\'s stores.'
    };

    return resources[label];
}
