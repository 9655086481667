const resources = {
    checkYourBalance: 'Vérifiez votre solde',
    enterGiftCardNumber: 'Entrez le numéro à 16 chiffres et le NIP de votre carte-cadeau.',
    reCaptcha: 'Sephora utilise ReCaptcha et les utilisateurs sont assujettis aux normes de Google en matière de',
    privacyPolicy: 'politique de confidentialité',
    terms: 'de paiement',
    balanceCall: 'Pour entendre votre solde, appelez',
    currentBalance: 'Solde actuel',
    cardNumber: 'Numéro de carte',
    pin: 'NIP',
    checkBalance: 'Vérifier le solde'
};

export default function getResource(label) {
    return resources[label];
}
