export default function getResource(label, vars = []) {
    const resources = {
        emailAddress: 'Adresse de courriel',
        asMText: 'Si vous ne souhaitez plus recevoir d’avis, saisissez l’adresse courriel que vous désirez supprimer et cliquez sur « Supprimer ».',
        asMBText: 'Cesser les notifications par courriel',
        nsMText: 'Sephora enverra un courriel d’avis de produit en stock à :',
        nsMBText: 'Terminer',
        empty: 'Veuillez saisir votre adresse courriel.',
        invalid: 'Veuillez saisir une adresse courriel au format nomutilisateur@domaine.com.',
        enterValidEmail: 'Veuillez saisir une adresse courriel valide.',
        subscribe: 'Votre courriel d’avis de produit en stock sera envoyé à ',
        remove: 'Vous ne devriez plus recevoir d’avis de produit en stock par courriel à',
        emailSmsNotifications: 'Notifications par courriel et par textos',
        emailMe: 'Notification par courriel',
        smsMe: 'Notification par textos',
        emailTied: 'Nous pouvons seulement envoyer un avis au courriel lié à votre compte.',
        BIOnly: '(Membre Sephora Beauty Insider seulement)',
        emailLabel: 'Sephora enverra un courriel d’avis de produit en stock à :',
        emailNoLonger: 'Si vous ne souhaitez plus recevoir d’avis de retour en stock, cliquez sur « Cesser les notifications par courriel »',
        needTo: 'Vous devez ',
        signInLink: 'ouvrir une session',
        createAccountLink: 'créer un compte',
        or: ' ou ',
        toSet: ' pour configurer les notifications par SMS de Sephora.',
        mobileLabel: 'Numéro de téléphone cellulaire',
        completeButton: 'Terminer',
        gotIt: 'Compris',
        signInCapitalize: 'Ouvrir une session',
        createAccountCapitalize: 'Créer un compte',
        errorMessagesSms: 'Vous devez accepter les modalités avant de continuer.',
        checkboxAgree: 'Je choisis de m’inscrire pour recevoir des notifications de produit en stock par message texte pour ce produit.',
        subscribedByPhone: `Veuillez vérifier votre boîte de réception SMS à ${vars[0]} pour confirmer votre abonnement aux notifications de produit en stock ou votre abonnement au contenu marketing.`,
        errorMsg: 'Un problème est survenu lors du traitement de votre soumission. Veuillez réessayer plus tard.',
        noValidPhoneMsg: 'Veuillez saisir un numéro de téléphone cellulaire valable.',
        unsubscribeTitle: `Pour vous désabonner des avis de produit en stock au numéro ci-dessous, textez « *STOP* » au *${vars[0]}*.`,
        unsubscribeBody:
            `Si vous êtes abonné pour recevoir des notifications de produit en stock ou à venir pour plusieurs produits, vous cesserez de recevoir toutes les notifications pour tous les produits pour lesquels vous vous êtes abonné. \n\nSe désinscrire des notifications de produit de retour en stock ou à venir ne vous désinscrit pas des textos marketing. Pour ne plus recevoir de textos marketing, textez « *STOP* » au *${vars[0]}*.`,
        termsAndConditionsUS:
            `En entrant mon numéro de téléphone, en cochant la ou les case(s) sélectionnée(s) ci-dessus, en cliquant sur Terminer et en confirmant mon inscription, j’accepte de recevoir des notifications de retour en stock par textos et (ou) des textos marketing, je consens aux [*MODALITÉS DES TEXTOS*|${vars[0]}] de Sephora (qui comprend uune renonciation aux recours collectifs et aux procès devant jury), j’ai lu la [*POLITIQUE DE CONFIDENTIALITÉ*|${vars[1]}] de Sephora, et je consens à recevoir des textos marketing automatiques récurrents, notamment des textos marketing généraux, des rappels de panier abandonné et des avis de retour en stock, de Sephora et (ou) de ses fournisseurs de services. Les messages peuvent être utilisés ou envoyés à l’aide d’un système automatisé, d’un composeur automatique, d’un système automatisé pour la sélection ou la composition de numéros de téléphone, d’un système de composition automatique de numéros de téléphone ou de tout autre type de système, de logiciel, de matériel, ou de machine (peu importe la façon dont elle peut être classée) qui peut utiliser une procédure ou un processus automatisé pour envoyer des messages. La fréquence des messages varie. Le consentement n’est pas une condition d’achat. Des frais de messagerie texte et de données peuvent s’appliquer. ARRET pour vous désinscrire. AIDE pour obtenir de l’aide.`,
        termsAndConditionsCA: `En entrant votre numéro de téléphone, en cliquant sur Terminer et en confirmant votre inscription, vous consentez aux [*MODALITÉS DES TEXTOS*|${vars[0]}] et à recevoir (selon vos choix) des messages textes de marketing automatiques récurrents avec des offres exclusives et des mises à jour sur les produits (y compris des rappels de paniers abandonnés), et (ou) des notifications de retour en stock. La fréquence des messages varie. Des frais de messagerie texte et de données peuvent s’appliquer. Consultez notre [*POLITIQUE DE CONFIDENTIALITÉ*|${vars[1]}]. Textez ARRET pour annuler en tout temps. AIDE pour obtenir de l’aide. Sephora : 600, boul. de Maisonneuve Ouest, bureau 2400, Montréal (Québec)  H3A 3J2, Canada 1-877-737-4672.`,
        textAlerts: 'J’aimerais aussi recevoir des alertes marketing par textos de Sephora.'
    };
    return resources[label];
}
