const createEmotion = require('create-emotion').default;
const context = typeof global !== 'undefined' ? global : {};

if (context.emotionClient === undefined) {
    context.emotionClient = {};
}

module.exports = createEmotion(context.emotionClient, {
    prefix: false
});
