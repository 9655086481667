export default function getResource(label, vars = []) {
    const resources = {
        map: 'Plan',
        moreTimesLocations: 'Afficher plus d’heures et d’emplacements',
        milesAbbreviation: 'mi',
        kmAbbreviation: 'km'
    };

    return resources[label];
}
