export default function getResource(label, vars = []) {
    const resources = {
        checkoutPickupItems: 'Articles à ramasser',
        checkoutShippedItems: 'Articles à faire livrer',
        freeShippingModalTitle: 'Obtenir la livraison en 2 jours GRATUITE',
        beautyInsiderPageText: 'page Beauty Insider',
        freeShippingModalBody2: 'pour en savoir plus.',
        freeShippingModalButtonText: 'Compris',
        merchandiseSubtotalText: 'Sous-total des marchandises',
        discountsText: 'Réductions',
        shippingAndHandlingText: 'Expédition et manutention',
        pickupText: 'Ramassage',
        free: 'GRATUIT',
        tbdText: 'À déterminer',
        gstHstText: 'TPS/TVH estimées',
        taxText: 'Taxes estimées et autres frais',
        estimatedTotalText: 'Total estimé ',
        shippingAndTaxesText: 'L’expédition et les taxes seront calculées à la caisse',
        shippingHandlingInfo: 'Renseignements sur l’expédition et la manutention',
        salesTax: 'Taxe de vente',
        item: 'article',
        items: 'articles',
        pointsUsed: 'Utilisé',
        accountCredit: 'Crédit au compte',
        bagFee: 'Frais de sac',
        specialFee: 'Frais spéciaux',
        gotIt: 'Compris',
        continueToCheckout: 'Passer à la caisse',
        autoReplenishSavings: 'Économies sur le réapprovisionnement automatique',
        youSave: 'Vous économisez',
        sduSavingsUS: '6,95 $',
        sduSavingsCA: '9,95 $',
        withSDUUnlimited: 'avec la livraison le jour même illimitée',
        checkout: 'Passer à la caisse'
    };

    return resources[label];
}
