const resources = {
    new: 'New',
    value: 'value',
    colors: 'Colors',
    quickLook: 'Quicklook',
    sponsored: 'Sponsored',
    regPrice: ' Reg. price',
    selectSaleItems: 'Select sale items',
    viewSimilarProducts: 'View similar products'
};

export default function getResource(label) {
    return resources[label];
}
