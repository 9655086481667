import { createSelector } from 'reselect';
import { showSelector } from 'selectors/testTarget/offers/unrecognizedPersistentBanner/show/showSelector';
import { isTestTargetReadySelector } from 'viewModel/selectors/testTarget/isTestTargetReadySelector';
import userUtils from 'utils/User';
import localeUtils from 'utils/LanguageLocale';

const showUnrecognizedPersistentBannerSelector = createSelector(isTestTargetReadySelector, showSelector, (testReady, show) => {
    return testReady && show && userUtils.isAnonymous() && localeUtils.isUS();
});

export { showUnrecognizedPersistentBannerSelector };
