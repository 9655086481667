import { Text, Link, Divider } from 'components/ui';
import BaseClass from 'components/BaseClass';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import localeUtils from 'utils/LanguageLocale';
import React from 'react';
import { wrapComponent } from 'utils/framework';
import Radio from 'components/Inputs/Radio/Radio';
import resourceWrapper from 'utils/framework/resourceWrapper';
import javascriptUtils from 'utils/javascript';
import { modal } from 'style/config';
import activityConstants from 'components/OnlineReservation/activityConstants';

const { isObjectEmpty } = javascriptUtils;
const { WAIVER } = activityConstants;

const getWaiverMedia = () => {
    const waiverMediaInfo = Sephora?.configurationSettings?.waiverMediaInfo || {};
    const currentLanguageCountryCode = localeUtils.getCurrentLanguageCountryCode();
    const countryCode = localeUtils.getCurrentCountry();

    if (isObjectEmpty(waiverMediaInfo)) {
        return {};
    }

    const id = waiverMediaInfo['waiverMediaId-' + countryCode];
    const name = waiverMediaInfo['name-' + currentLanguageCountryCode];

    return {
        id,
        name
    };
};

const hasWaiverData = () => {
    const { id, name } = getWaiverMedia();

    return !!(id && name);
};

class WaiverSection extends BaseClass {
    onWaiverChange = (updatedProps = {}) => {
        const { waiverInfo, updateWaiverInfo } = this.props;
        updateWaiverInfo({
            ...waiverInfo,
            ...updatedProps
        });
    };

    showMediaPopup = () => {
        const { openModal, closeModal, waiverInfo } = this.props;

        openModal({
            mediaId: waiverInfo.prefId,
            title: 'Back to Reservation Details',
            isOpen: true,
            showDismiss: false,
            backHandler: closeModal,
            backDataAt: Sephora.debug.dataAt('back_btn')
        });
    };

    componentDidMount() {
        const { updateWaiverInfo, waiverInfo } = this.props;
        const { id: prefId, name: prefName } = getWaiverMedia();
        updateWaiverInfo({
            ...waiverInfo,
            prefId,
            prefName,
            shouldShow: hasWaiverData()
        });
    }

    handleSelfBookingOnChange = e => {
        this.onWaiverChange({
            hasParentAccepted: false,
            prefValue: e.target.value
        });
    };

    handleBookingOnBehalfOnChange = e => {
        this.onWaiverChange({
            prefValue: e.target.value
        });
    };

    handleBookingForMinorOnChange = e => {
        this.onWaiverChange({
            hasParentAccepted: e.target.checked
        });
    };

    handleClickLink = e => {
        e.preventDefault();
        this.showMediaPopup();
    };

    WaiverLinkComponent = ({ msg, ...props }) => (
        <Link
            {...props}
            display='inline'
            color='blue'
            padding={2}
            margin={-2}
            onClick={this.handleClickLink}
            children={msg}
        />
    );

    render() {
        const getText = resourceWrapper(
            localeUtils.getLocaleResourceFile('components/GlobalModals/PaidReservationModal/WaiverSection/locales', 'WaiverSection')
        );
        const { waiverInfo } = this.props;
        const waiverMedia = getWaiverMedia();

        if (!hasWaiverData()) {
            return null;
        }

        const WaiverLink = this.WaiverLinkComponent;

        return (
            <>
                <Divider
                    marginY={4}
                    marginX={modal.outdentX}
                    thick={true}
                />
                <div>
                    <Text
                        is='h2'
                        marginBottom='1em'
                        fontSize='md'
                        fontWeight='bold'
                        children={getText('waiverTitle')}
                    />
                    <p
                        children={getText(
                            'waiverGeneralMessage',
                            false,
                            <WaiverLink
                                data-at={Sephora.debug.dataAt('covid_waiver_btn')}
                                msg={waiverMedia.name}
                            />
                        )}
                    />
                    <Text
                        is='h3'
                        marginBottom='1em'
                        fontSize='md'
                        fontWeight='bold'
                        children={getText('chooseOne')}
                    />
                    <Radio
                        data-at={Sephora.debug.dataAt('i_acknowledge_option')}
                        paddingY={null}
                        marginTop={4}
                        checked={waiverInfo.prefValue === WAIVER.ACKFORCLIENT}
                        onChange={this.handleSelfBookingOnChange}
                        children={getText('acceptWaiver', false, <WaiverLink msg={waiverMedia.name} />)}
                        value={WAIVER.ACKFORCLIENT}
                    />
                    <Radio
                        data-at={Sephora.debug.dataAt('i_book_for_someone_option')}
                        paddingY={null}
                        marginTop={4}
                        checked={waiverInfo.prefValue === WAIVER.ACKFORCLIENTUNDER18}
                        onChange={this.handleBookingOnBehalfOnChange}
                        value={WAIVER.ACKFORCLIENTUNDER18}
                    >
                        {getText('underAgeWaiver')}
                        {waiverInfo.prefValue === WAIVER.ACKFORCLIENTUNDER18 && (
                            <Checkbox
                                paddingY={null}
                                marginTop={4}
                                checked={waiverInfo.hasParentAccepted}
                                onChange={this.handleBookingForMinorOnChange}
                                children={getText('parentalWaiver', false, <WaiverLink msg={waiverMedia.name} />)}
                            />
                        )}
                    </Radio>
                </div>
            </>
        );
    }
}

export default wrapComponent(WaiverSection, 'WaiverSection', true);
