/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';

import { Box, Text, Button } from 'components/ui';
import TextInput from 'components/Inputs/TextInput/TextInput';
import FormValidator from 'utils/FormValidator';
import ErrorMsg from 'components/ErrorMsg';
import ReCaptchaText from 'components/ReCaptchaText/ReCaptchaText';
import ReCaptcha from 'components/ReCaptcha/ReCaptcha';
import ErrorConstants from 'utils/ErrorConstants';
import LanguageLocaleUtils from 'utils/LanguageLocale';

import store from 'Store';
import utilityApi from 'services/api/utility';
import ErrorsUtils from 'utils/Errors';
import ErrorsActions from 'actions/ErrorsActions';

const { getLocaleResourceFile } = LanguageLocaleUtils;
const FIELD_LENGTHS = FormValidator.FIELD_LENGTHS;

class GiftCards extends BaseClass {
    constructor() {
        super();
        this.state = {};
        this.reCaptcha = null;
    }

    validateForm = () => {
        ErrorsUtils.clearErrors();
        let firstErrorFocused;

        store.watchAction(ErrorsActions.TYPES.ADD_ERROR, action => {
            if (!firstErrorFocused) {
                firstErrorFocused = true;
                ErrorsUtils.focusError(action.error);
            }
        });

        // Clear errors previous and gift card balance
        this.setState({
            error: null,
            gcBalance: null
        });
        const fieldsForValidation = [this.gcCardNumberInput, this.gcPinNumberInput];
        ErrorsUtils.collectClientFieldErrors(fieldsForValidation);
        ErrorsUtils.validate();

        let totalErrors = store.getState().errors;
        totalErrors = Object.assign({}, totalErrors[ErrorConstants.ERROR_LEVEL.FIELD], totalErrors[ErrorConstants.ERROR_LEVEL.FORM]);

        const hasErrors = Object.keys(totalErrors).length;

        return hasErrors;
    };

    checkBalance = token => {
        if (!this.validateForm()) {
            const giftCardInfo = {
                gcNumber: this.gcCardNumberInput.getValue(),
                gcPin: this.gcPinNumberInput.getValue()
            };

            if (token) {
                giftCardInfo.captchaToken = token;
            }

            utilityApi
                .getGiftCardBalance(giftCardInfo)
                .then(response => {
                    this.setState({ gcBalance: response.giftCardBalance });
                })
                .catch(reponseError => {
                    this.setState({ error: reponseError.errorMessages[0] });
                });
        }
    };

    validateCaptchaAndCheckBalance = () => {
        if (this.reCaptcha && !this.validateForm()) {
            this.reCaptcha.execute();
        } else {
            this.checkBalance();
        }
    };

    onCaptchaTokenReady = token => {
        if (token) {
            this.checkBalance(token);
        }

        this.reCaptcha.reset();
    };

    onChallengerShow = () => {
        return null;
    };

    onChallengerDismiss = () => {
        return null;
    };

    render() {
        const getText = getLocaleResourceFile('components/GiftCards/locales', 'GiftCards');

        const { hasTitle = true } = this.props;
        const isCaptchaEnabled = Sephora.configurationSettings.captchaGiftCardBalanceEnabled;

        return (
            <React.Fragment>
                <Text
                    is='p'
                    lineHeight='tight'
                    marginBottom={5}
                >
                    {hasTitle && <b>{getText('giftCardBalance')}</b>}
                    {hasTitle && <br />}
                    {getText('toCheckCurrentBalance')}
                </Text>
                {this.state.gcBalance && (
                    <Text
                        is='p'
                        fontWeight='bold'
                        marginBottom={4}
                        role='alert'
                        data-at={Sephora.debug.dataAt('balance_msg')}
                    >
                        {getText('balance')} = {this.state.gcBalance}
                    </Text>
                )}
                {this.state.error && (
                    <ErrorMsg
                        marginBottom={4}
                        children={this.state.error}
                    />
                )}
                <Box
                    is='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={e => {
                        e.preventDefault();
                        this.validateCaptchaAndCheckBalance();
                    }}
                    maxWidth={Sephora.isDesktop() && 343}
                >
                    <TextInput
                        name='gcCardNumber'
                        label={getText('cardNumber')}
                        autoOff={true}
                        required={true}
                        type='tel'
                        maxLength={FIELD_LENGTHS.giftCardNumber}
                        value={(this.gcCardNumberInput && this.gcCardNumberInput.getValue()) || ''}
                        ref={comp => (this.gcCardNumberInput = comp)}
                        validateError={gcCardNumber => {
                            if (FormValidator.isEmpty(gcCardNumber)) {
                                return ErrorConstants.ERROR_CODES.GIFT_CARD_NUMBER;
                            }

                            return null;
                        }}
                    />
                    <TextInput
                        name='gcPinNumber'
                        label={getText('pin')}
                        autoOff={true}
                        required={true}
                        type='tel'
                        maxLength={FIELD_LENGTHS.giftCardPin}
                        value={(this.gcPinNumberInput && this.gcPinNumberInput.getValue()) || ''}
                        ref={comp => (this.gcPinNumberInput = comp)}
                        validateError={gcPinNumber => {
                            if (FormValidator.isEmpty(gcPinNumber)) {
                                return ErrorConstants.ERROR_CODES.GIFT_CARD_PIN;
                            }

                            return null;
                        }}
                    />
                    <Button
                        data-at={Sephora.debug.dataAt('check_gc')}
                        variant='secondary'
                        children={getText('check')}
                        type='submit'
                    />

                    {isCaptchaEnabled && (
                        <ReCaptcha
                            ref={c => {
                                if (c !== null) {
                                    this.reCaptcha = c;
                                }
                            }}
                            onChange={this.onCaptchaTokenReady}
                            onChallengerShow={this.onChallengerShow}
                            onChallengerDismiss={this.onChallengerDismiss}
                        />
                    )}
                    {isCaptchaEnabled && <ReCaptchaText marginTop={6} />}
                </Box>
            </React.Fragment>
        );
    }
}

export default wrapComponent(GiftCards, 'GiftCards');
