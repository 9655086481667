export default function getResource(label, vars = []) {
    const resources = {
        near: 'À proximité',
        ok: 'OK',
        chooseLocation: 'Choisir un lieu',
        cancel: 'Annuler',
        shareLocationAvailability: 'Partager votre position pour consulter les disponibilités',
        chooseLocationTime: 'Choisir l’emplacement et l’heure',
        chooseArtist: 'Choisir un artiste disponible',
        chooseNewArtistTitle: 'Choisir un nouvel artiste disponible',
        chooseNewArtistText: '**Votre artiste n’est plus disponible à ce moment-là.** Veuillez sélectionner un(e) nouvel(le) artiste disponible',
        chooseLocationTimeArtist: 'Sélectionnez un emplacement et une heure pour voir les artistes disponibles',
        chooseTime: 'Choisir une heure',
        notAbleToFindStore: 'Nous n’avons pas été en mesure de trouver un magasin à proximité',
        tryDifferentLocation: 'Veuillez essayer un autre emplacement.',
        changeLocation: 'Changer de lieu',
        noAppointmentsAvailable1: 'Nous sommes désolés, aucun rendez-vous n’est disponible',
        noAppointmentsAvailable2: 'pour l’instant',
        tryDifferentExperience: 'Veuillez essayer une autre expérience.',
        seeWhatsHappening: 'Voir ce qui se passe',
        bookReservation: 'Prendre rendez-vous',
        signUpForThisClass: 'S’inscrire à ce cours',
        reschedule: 'Changer la date',
        continueToBooking: 'Continuer pour réserver',
        artistDisclaimer: 'L’artiste peut changer en fonction de la disponibilité ce jour-là.',
        giftCardLink: 'Vous voulez offrir un service? Acheter une carte-cadeau Sephora',
        genericErrorTimeSlots: 'Oups! Ce service est en forte demande et l’heure que vous avez sélectionnée est maintenant prise. Veuillez sélectionner une autre heure pour compléter la réservation.'
    };

    return resources[label];
}
