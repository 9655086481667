export default function getResource(label, vars = []) {
    const resources = {
        UPCOMING: 'À venir',
        PAST: 'Passé',
        CANCELLED: 'Annulée',
        CANCELLED_BY_STORE: 'Annulé par le magasin',
        LATE_CANCELLATION: 'Annulation tardive',
        NO_SHOW: 'Absence',
        fee: `${vars[0]} Frais`
    };

    return resources[label];
}
