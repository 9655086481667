import { connect } from 'react-redux';
import FrameworkUtils from 'utils/framework';
import ProductActions from 'actions/ProductActions';

const { wrapHOC } = FrameworkUtils;
const { fireSponsoredProductClickTracking } = ProductActions;

const functions = {
    fireSponsoredProductClickTracking
};

const withRMNCarouselProps = wrapHOC(connect(null, functions));

export {
    functions, withRMNCarouselProps
};
