/* eslint-disable class-methods-use-this */
import React from 'react';
import Actions from 'Actions';
import store from 'store/Store';
import reservationsBindings from 'analytics/bindingMethods/pages/reservations/ReservationsBindings';
import BaseClass from 'components/BaseClass';
import Modal from 'components/Modal/Modal';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import { Button, Grid } from 'components/ui';
import dateUtils from 'utils/Date';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import localeUtils from 'utils/LanguageLocale';
import urlUtils from 'utils/Url';
import { wrapComponent } from 'utils/framework';

const shouldBeCharge = givenDate => {
    if (givenDate && dateUtils.getDifferentInHour(givenDate) <= 24) {
        return true;
    } else {
        return false;
    }
};

class CancelReservationModal extends BaseClass {
    constructor() {
        super();
    }

    requestClose = () => {
        store.dispatch(Actions.showCancelReservationModal({ isOpen: false }));
    };

    cancelReservation = () => {
        const { activityDetails } = this.props;
        const { activityName, storeId, activityId, type: activityType } = activityDetails;
        const confirmationNumber = urlUtils.getParamsByName('id')[0];
        const cancelReservation =
            activityType === ACTIVITY.TYPE.SERVICES ? ExperienceDetailsUtils.updateReservation : ExperienceDetailsUtils.cancelReservation;

        reservationsBindings.reservationCancel({
            reservationName: activityName.toLowerCase(),
            storeId,
            activityId,
            activityType
        });

        return cancelReservation({
            confirmationNumber,
            rescheduleFlow: false
        });
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/GlobalModals/CancelReservationModal/locales', 'CancelReservationModal');
        const startDate = this.props?.activityDetails?.startDate;

        const activityDate = new Date(startDate);

        return (
            <Modal
                isOpen={this.props.isOpen}
                onDismiss={this.requestClose}
                width={0}
            >
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {shouldBeCharge(activityDate) && this.props?.activityDetails?.isNoShowChargeApplicable
                        ? getText('askToCancelCharge', [this.props?.activityDetails?.priceInfo?.lateCancellationCharge])
                        : getText('askToCancel')}
                </Modal.Body>
                <Modal.Footer>
                    <Grid columns={2}>
                        <Button
                            variant='primary'
                            block={true}
                            onClick={this.requestClose}
                        >
                            {getText('no')}
                        </Button>
                        <Button
                            variant='secondary'
                            block={true}
                            onClick={this.cancelReservation}
                        >
                            {getText('yes')}
                        </Button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default wrapComponent(CancelReservationModal, 'CancelReservationModal');
