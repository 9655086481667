import ACTION_TYPES from 'constants/actionTypes/productSamples';
import Actions from 'Actions';
import store from 'Store';
import basketUtils from 'utils/Basket';
import skuUtils from 'utils/Sku';
import localeUtils from 'utils/LanguageLocale';
const getText = localeUtils.getLocaleResourceFile('actions/locales', 'ProductSamplesActions');
import Storage from 'utils/localStorage/Storage';
import LOCAL_STORAGE from 'utils/localStorage/Constants';

const productSamplesActions = {
    setSampleProduct: function (product) {
        return {
            type: ACTION_TYPES.SET_PRODUCT_SAMPLE,
            payload: product
        };
    },
    closeProductSamplesModal: function () {
        const items = basketUtils.getBasketItems();
        const productSamplesSelected = items.filter(
            list => list.sku.type?.toLowerCase() === skuUtils.skuTypes.SAMPLE && list.sku.subType?.toLowerCase() === skuUtils.skuSubTypes.PRODUCT
        );
        store.dispatch(Actions.showProductSamplesModal({ isOpen: false }));
        Storage.local.removeItem(LOCAL_STORAGE.PDP_SAMPLES_MODAL);

        if (productSamplesSelected.length > 0) {
            return Actions.showInfoModal({
                isOpen: true,
                title: getText('title'),
                message: getText('message'),
                buttonText: getText('ok')
            });
        }

        return null;
    },
    showErrorMessage: function () {
        return Actions.showInfoModal({
            isOpen: true,
            title: getText('errorTitle'),
            message: getText('errorMessage'),
            buttonText: getText('errorButton')
        });
    }
};

export default productSamplesActions;
