export default function getResource(label, vars = []) {
    const resources = {
        modalTitle: 'Veuillez ouvrir une session Sephora',
        termsOfUseLink: 'Conditions d’utilisation',
        privacyPolicyLink: 'Politique de confidentialité',
        wantToSaveYourPoints: `Envie d’économiser vos ${vars[0]} points?`
    };

    return resources[label];
}
