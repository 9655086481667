import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import { Text, Link } from 'components/ui';
import ProductList from 'components/Content/ProductList';
import analyticsConstants from 'analytics/constants';

function LovesList(props) {
    const {
        currentLoves, title, grouping, variant, customStyles, showSignInModal, localization, isUserRecognized
    } = props;
    const { signInButton, signInText, yourLoves } = localization;
    const hasLoves = currentLoves?.length > 0;

    return (
        <div>
            {hasLoves ? (
                <ProductList
                    skuList={currentLoves}
                    title={title}
                    variant={variant}
                    grouping={grouping}
                    customStyles={customStyles}
                    analyticsContext={analyticsConstants.CONTEXT.BASKET_LOVES}
                />
            ) : (
                <>
                    <Text
                        is='h2'
                        fontSize={'lg'}
                        children={title}
                        fontWeight={'bold'}
                        lineHeight={'tight'}
                        marginBottom={1}
                    />
                    {isUserRecognized ? (
                        <Text
                            is='p'
                            lineHeight={'base'}
                            children={yourLoves}
                        />
                    ) : (
                        <Text
                            is='p'
                            lineHeight={'base'}
                        >
                            <Link
                                onClick={showSignInModal}
                                color='blue'
                                children={signInButton}
                            />
                            {signInText}
                        </Text>
                    )}
                </>
            )}
        </div>
    );
}

export default wrapFunctionalComponent(LovesList, 'LovesList');
