import ufeApi from 'services/api/ufeApi';

const getServiceBookingDates = (token, {
    language, country, channel = 'rwd', bookingId, storeId
}) => {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const locale = `${language.toLowerCase()}-${country}`;

    const url = `${sdnDomain}/v2/happening/services/${bookingId}/availability/dates?storeId=${storeId}&channel=${channel}&locale=${locale}&country=${country}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: {
            'x-requested-source': 'rwd',
            authorization: `Bearer ${token}`
        }
    });
};

export default getServiceBookingDates;
