/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import {
    Box, Grid, Text, Link
} from 'components/ui';
import Action from 'components/Content/Action';
import uiUtils from 'utils/UI';
import contentConstants from 'constants/content';
import ProductListLayouts from 'components/Content/ProductListLayout';
import RenderInViewPort from 'components/RenderInViewPort';

const {
    PRODUCT_LIST_GROUPING, CONTEXTS, COMPONENT_SPACING, PRODUCT_LIST_LAYOUT_VARIANTS, PRODUCT_LIST_CARD_SIZE_VARIANTS, PRODUCT_LIST_VARIANTS
} =
    contentConstants;
const { SKELETON_TEXT } = uiUtils;
const ActionLink = Action(Link);

class ProductList extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            skus: props.skuList,
            title: props.title,
            targetUrl: props.action?.targetUrl
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.skus !== prevProps.skus) {
            this.setState({ skus: this.props.skus });
        }

        if (this.props.skuList !== prevProps.skuList) {
            this.setState({ skus: this.props.skuList });
        }

        if (this.props.title !== prevProps.title) {
            this.setState({ title: this.props.title });
        }
    }

    showSkeleton = () => this.props.showSkeleton || this.state.showSkeleton;

    render() {
        const {
            action,
            actionLabel,
            sid,
            grouping,
            showQuickLookOnMobile,
            ignoreTargetUrlForBox,
            context,
            localization,
            marginTop,
            marginBottom,
            variant,
            subtitle,
            podId,
            isBIRBReward,
            isAnonymous,
            isShowAddFullSize,
            isInBasket,
            renderBiButton,
            isBirthDayRewardList,
            secondSubtitle,
            titleMarginBottom,
            componentType,
            customStyles,
            customCardSize,
            analyticsContext
        } = this.props;

        const showAddButton = grouping.includes(PRODUCT_LIST_GROUPING.SHOW_ADD_BUTTON);
        const showPrice = grouping.includes(PRODUCT_LIST_GROUPING.SHOW_PRICE);
        const showMarketingFlags = grouping.includes(PRODUCT_LIST_GROUPING.SHOW_MARKETING_FLAGS);
        const showRankingNumbers = grouping.includes(PRODUCT_LIST_GROUPING.SHOW_RANKING_NUMBERS);
        const showLovesButton = grouping.includes(PRODUCT_LIST_GROUPING.SHOW_LOVES_BUTTON);
        const showRatingWithCount = grouping.includes(PRODUCT_LIST_GROUPING.SHOW_RATING_WITH_TOTAL_COUNT);

        const { skus, title, targetUrl } = this.state || this.props;

        const showSkeleton = this.showSkeleton();

        if (!showSkeleton && (!skus || skus?.length === 0)) {
            return null;
        }

        const isModal = context === CONTEXTS.MODAL;
        const ProductListLayout = ProductListLayouts[PRODUCT_LIST_LAYOUT_VARIANTS[variant]];

        return (
            <Box
                id={sid}
                marginTop={Array.isArray(customStyles?.marginTop) ? customStyles?.marginTop : marginTop}
                marginBottom={Array.isArray(customStyles?.marginBottom) ? customStyles?.marginBottom : marginBottom}
            >
                {(title || showSkeleton) && (
                    <RenderInViewPort>
                        <Grid
                            alignItems='baseline'
                            columns='1fr auto'
                            lineHeight='tight'
                            gap={3}
                        >
                            <Grid
                                marginBottom={titleMarginBottom ? titleMarginBottom : isBIRBReward ? 5 : 4}
                                gap={0}
                            >
                                {isBirthDayRewardList && subtitle && (
                                    <Text
                                        is='h3'
                                        fontSize={['base', 'md']}
                                        fontWeight='bold'
                                        color='gray'
                                        css={showSkeleton && [{ minWidth: 204 }, SKELETON_TEXT]}
                                        children={subtitle}
                                    />
                                )}

                                <Text
                                    is='h2'
                                    fontSize={['md', 'lg']}
                                    fontWeight='bold'
                                    css={showSkeleton && [{ minWidth: 204 }, SKELETON_TEXT]}
                                    children={title}
                                />

                                <Text
                                    is='p'
                                    fontSize={['sm', 'md']}
                                    fontWeight='normal'
                                    css={showSkeleton && [{ minWidth: 204 }, SKELETON_TEXT]}
                                    children={!isBirthDayRewardList ? subtitle : null}
                                    dangerouslySetInnerHTML={isBirthDayRewardList && secondSubtitle ? { __html: secondSubtitle.inner } : null}
                                />
                            </Grid>

                            {(action || targetUrl) && !showSkeleton && (
                                <ActionLink
                                    sid={sid}
                                    action={{
                                        ...action,
                                        ...targetUrl
                                    }}
                                    padding={2}
                                    margin={-2}
                                    color='blue'
                                    children={actionLabel || action.title || localization.showMore}
                                    analyticsNextPageData={{ linkData: `${title?.trim().toLowerCase()}:carousel:show more` }}
                                />
                            )}
                        </Grid>
                    </RenderInViewPort>
                )}
                {showSkeleton || this.props.renderSubHeader?.()}
                <ProductListLayout
                    podId={podId}
                    sid={sid}
                    showSkeleton={showSkeleton}
                    isModal={isModal}
                    title={title}
                    rootContainerName={title}
                    showMarketingFlags={showMarketingFlags}
                    showRankingNumbers={showRankingNumbers}
                    showLovesButton={showLovesButton}
                    showRatingWithCount={showRatingWithCount}
                    showQuickLookOnMobile={showQuickLookOnMobile}
                    ignoreTargetUrlForBox={ignoreTargetUrlForBox}
                    skus={skus}
                    showPrice={showPrice}
                    showAddButton={showAddButton}
                    renderBiButton={renderBiButton}
                    size={PRODUCT_LIST_CARD_SIZE_VARIANTS[variant]}
                    customCardSize={customCardSize}
                    isBIRBReward={isBIRBReward}
                    isAnonymous={isAnonymous}
                    isShowAddFullSize={isShowAddFullSize}
                    isInBasket={isInBasket}
                    componentType={componentType}
                    showErrorModal={true}
                    analyticsContext={analyticsContext}
                    rougeBadgeText={localization.rougeBadgeText}
                />
            </Box>
        );
    }
}

ProductList.propTypes = {
    sid: PropTypes.string,
    componentName: PropTypes.string,
    componentType: PropTypes.number,
    context: PropTypes.oneOf([CONTEXTS.CONTAINER, CONTEXTS.MODAL]),
    enablePageRenderTracking: PropTypes.bool,
    enableTesting: PropTypes.bool,
    page: PropTypes.string,
    grouping: PropTypes.array,
    action: PropTypes.object,
    skuList: PropTypes.array,
    localization: PropTypes.object.isRequired,
    title: PropTypes.string,
    showQuickLookOnMobile: PropTypes.bool,
    marginTop: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    marginBottom: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    variant: PropTypes.oneOf(Object.keys(PRODUCT_LIST_LAYOUT_VARIANTS))
};

ProductList.defaultProps = {
    sid: null,
    enablePageRenderTracking: false,
    showQuickLookOnMobile: false,
    grouping: [],
    marginTop: COMPONENT_SPACING.LG,
    marginBottom: COMPONENT_SPACING.LG,
    variant: PRODUCT_LIST_VARIANTS.SMALL_CAROUSEL
};

export default wrapComponent(ProductList, 'ProductList', true);
