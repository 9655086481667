import userUtils from 'utils/User';
import skuUtils from 'utils/Sku';
const isRougeExclusiveEnabled = Sephora.configurationSettings.isRougeExclusiveEnabled || false;

const isRougeExclusive = () => {
    return isRougeExclusiveEnabled && userUtils.isRouge();
};

const updateRougeExclusiveSkus = (skus, basket) => {
    if (!isRougeExclusive() || !skus || !basket) {
        return skus;
    }

    const { netBeautyBankPointsAvailable } = basket;

    const newSkus = skus.map(s => {
        const isInBasket = skuUtils.isInBasket(s.skuId);

        if (!isInBasket) {
            s.hasNotEnoughPointsToRedeem = s.rewardPoints > netBeautyBankPointsAvailable;
        }

        return s;
    });

    return newSkus;
};

const updateRougeExclusiveBiRewardGroups = (biRewardGroups, basket) => {
    if (!isRougeExclusive() || !biRewardGroups || !basket) {
        return biRewardGroups;
    }

    Object.entries(biRewardGroups).forEach(([key, value]) => {
        biRewardGroups[key] = updateRougeExclusiveSkus(value, basket);
    });

    return biRewardGroups;
};

export default {
    isRougeExclusiveEnabled,
    isRougeExclusive,
    updateRougeExclusiveSkus,
    updateRougeExclusiveBiRewardGroups
};
