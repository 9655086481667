export default function getResource(label, vars = []) {
    const resources = {
        giftCardBalance: 'Gift Card balance',
        toCheckCurrentBalance: 'To check your current balance, enter the card’s 16 digit number and PIN',
        balance: 'Balance',
        cardNumber: 'Card Number',
        pin: 'PIN',
        check: 'Check'
    };

    return resources[label];
}
