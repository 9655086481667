export default function getResource(label, vars = []) {
    const resources = {
        termsOfUseLink: 'Conditions d’utilisation',
        privacyPolicyLink: 'Politique de confidentialité',
        privacyPolicyTitle: 'Politique de confidentialité',
        termsOfUseTitle: 'Conditions d’utilisation de Sephora'
    };

    return resources[label];
}
