import userUtils from 'utils/User';
import p13nUtils from 'utils/localStorage/P13n';
import anaConsts from 'analytics/constants';
import processEvent from 'analytics/processEvent';
import constants from 'constants/content';
import Location from 'utils/Location';
import urlUtils from 'utils/Url';
import Storage from 'utils/localStorage/Storage';
import Empty from 'constants/empty';

const {
    COMPONENT_TYPES: {
        BANNER_LIST, PRODUCT_LIST, RECAP, SOFT_LINKS, PROMOTION_LIST, REWARD_LIST, BANNER
    }
} = constants;

const { SOT_P13N_TRACKING_EVENT, CMS_REFERER_LOCAL_STORAGE_KEY } = anaConsts;

export const shouldSentEvent = eventData => {
    if (!eventData) {
        return;
    }

    const hasPersonalizationData = eventData?.personalizationData.bannersPersonalizedData?.length > 0;

    if (hasPersonalizationData) {
        processEvent.process(SOT_P13N_TRACKING_EVENT, { data: eventData });
    }
};

// Get the rest of the personalization data from the local storage, by matching the context of
// the banner item with the context of the personalization data.
export const matchContexts = itemPersonalization => {
    const { personalizationData } = getPersonalizationInfo() || Empty.Array;

    if (!itemPersonalization?.context || !personalizationData.length) {
        return null;
    }

    const { context } = itemPersonalization;

    const matchData = personalizationData.find(dataItem => dataItem.context === context);

    return matchData?.p13n || Empty.Object;
};

// Get the personalized item from the local storage, by matching the sid of the item with the sid of the personalization data.
export const personlizedItem = sid => {
    const { personalizationData } = getPersonalizationInfo();

    if (!personalizationData.length) {
        return null;
    }

    const matchData = personalizationData.find(dataItem => dataItem?.variationData?.sid === sid);

    if (!matchData) {
        return null;
    }

    return { ...matchData?.variationData, p13n: matchData?.p13n };
};

export const getPersonalizationInfo = () => {
    const personalizationData = p13nUtils.getAllPersonalizedCache();
    const profileId = userUtils.getProfileId();
    const profileStatus = userUtils.getProfileStatus();
    const biAccountId = digitalData?.user?.[0].segment.biAccountId;
    const biStatus = userUtils.getBiStatus();

    return {
        biStatus,
        profileId,
        biAccountId,
        profileStatus,
        personalizationData
    };
};

export const mountComponentEventData = ({ item, component }) => {
    const componentData = {
        sid: item.sid,
        itemIndex: typeof item?.itemIndex === 'number' ? item.itemIndex + 1 : null
    };

    const { personalization } = item;
    componentData.p13n = {};

    if (item?.sys?.publishedVersion) {
        componentData.publishedVersion = item.sys.publishedVersion;
    }

    if (item?.p13n?.context) {
        componentData.p13n = item.p13n;
    } else if (personalization) {
        const personalizationLocalData = matchContexts(personalization);

        if (personalizationLocalData) {
            componentData.p13n = personalizationLocalData;
        }

        const personalizationDataContent = p13nUtils.getPersonalizationCache(personalization.context);

        if (personalizationDataContent?.variationData?.sid) {
            componentData.sid = personalizationDataContent?.variationData?.sid;
            componentData.publishedVersion = personalizationDataContent?.variationData?.sys.publishedVersion;
        }
    }

    switch (component) {
        case BANNER_LIST: {
            break;
        }
        case BANNER: {
            break;
        }
        case RECAP:
        case PROMOTION_LIST: {
            componentData.title = item.title;

            break;
        }

        case REWARD_LIST:
        case PRODUCT_LIST: {
            delete componentData.sid;
            componentData.skuId = item.skuId;

            break;
        }

        case SOFT_LINKS: {
            componentData.label = item.label;
            componentData.type = item.type;

            break;
        }

        default: {
            break;
        }
    }

    return componentData;
};

/**
 * Mounts the event data for the component list, ex: BannerList, RecapList
 * @param {*} items
 * @param {*} eventName
 * @param {*} title
 * @param {*} sid
 * @param {*} componentSid
 * @param {*} component example: 'BannerList' | 'Recap'
 * @returns
 */
export const mountCmsComponentEventData = ({
    items, eventName, title, sid, componentSid, component, p13n, parentInfo
}) => {
    const bannersPersonalizedData = [];
    const { biStatus, profileId, biAccountId, profileStatus } = getPersonalizationInfo();

    const personalizationData = {
        title,
        biStatus,
        profileId,
        biAccountId,
        profileStatus,
        sid,
        contentType: component
    };
    const content = Sephora?.beautyOffersContent?.length > 0 ? Sephora.beautyOffersContent : Sephora?.homePageItems;

    const currentItem = parentInfo ? parentInfo : content.find(item => item.sid === sid || item.sid === componentSid);

    if (currentItem?.sys?.publishedVersion) {
        personalizationData.publishedVersion = currentItem.sys.publishedVersion;
    }

    if (!componentSid) {
        items.forEach(item => {
            const componentData = mountComponentEventData({
                item,
                component
            });

            bannersPersonalizedData.push(componentData);
        });
    } else {
        const variantData = personlizedItem(componentSid);
        const item = variantData ?? items.find(_item => _item.sid === componentSid);

        const componentData = mountComponentEventData({
            item,
            component
        });

        bannersPersonalizedData.push(componentData);
    }

    personalizationData.bannersPersonalizedData = bannersPersonalizedData;

    if (p13n) {
        personalizationData.p13n = p13n;
    }

    const eventData = {
        linkName: eventName,
        actionInfo: eventName,
        specificEventName: eventName,
        personalizationData
    };

    return eventData;
};

const FB_COMPONENTS = [PROMOTION_LIST, REWARD_LIST, BANNER];

/**
 * Check if the component is a FB component to avoid kill switch
 * @param {*} component
 * @returns
 */
export const isValidFBComponent = component => {
    const isFB = Sephora?.template === 'Basket/RwdBasketpage';
    const isBeautyPage = Location.isOffersPage();
    const isRewardPage = Location.isRewardsPage();

    if ((isFB && FB_COMPONENTS.includes(component)) || isBeautyPage || isRewardPage) {
        return true;
    }

    return false;
};
/**
 * Sends the event data for the component list, ex: BannerList, RecapList
 * @param {*} items
 * @param {*} eventName
 * @param {*} title
 * @param {*} sid
 * @param {*} componentSid
 * @param {*} component example: 'BannerList' | 'Recap'
 * @param {*} p13n
 * @param {*} parentInfo - additional info to be sent in the event data
 * @returns
 */

export const sendCmsComponentEvent = ({
    items, eventName, title, sid, componentSid, component, p13n, parentInfo, ignoreKillSwitch = false
}) => {
    if (!items) {
        return null;
    }

    if (!eventName || typeof sid === undefined || !component || !items?.length) {
        return null;
    }

    const ignoreSW = ignoreKillSwitch || isValidFBComponent(component);

    if ((!Location.isHomepage() || !Sephora?.configurationSettings?.impressionTracker?.isEnabledForHomePage) && !ignoreSW) {
        return null;
    }

    const eventData = mountCmsComponentEventData({
        items,
        eventName,
        title,
        sid,
        componentSid,
        component,
        p13n,
        parentInfo
    });

    shouldSentEvent(eventData);

    return eventData;
};

export const checkCmsRefererInfo = () => {
    const referer = urlUtils.getParamValueAsSingleString('referer');
    const cmsRefererInfo = Storage.local.getItem(CMS_REFERER_LOCAL_STORAGE_KEY);

    if (referer && cmsRefererInfo) {
        const parent = cmsRefererInfo?.parent.sid || cmsRefererInfo?.child?.sid;

        if (referer !== parent) {
            Storage.local.removeItem(CMS_REFERER_LOCAL_STORAGE_KEY);
        }
    }

    return;
};

export const mountRefererLinkLinK = target => {
    if (!target) {
        return '';
    }

    return `${target.sid}:${target?.sys.publishedVersion};`;
};

export const mountCmsRefererLink = () => {
    const dataReferer = Storage.local.getItem(CMS_REFERER_LOCAL_STORAGE_KEY);

    if (dataReferer?.products?.length) {
        const refererLinks = [];

        for (const key in dataReferer.referer) {
            if (Object.prototype.hasOwnProperty.call(dataReferer.referer, key)) {
                const refererItem = dataReferer.referer[key];
                const childSid = refererItem.sid;
                const childPublishedVersion = refererItem.sys.publishedVersion;
                const parentSid = refererItem.parent.sid;
                const parentPublishedVersion = refererItem.parent.sys.publishedVersion;
                const products = refererItem.products.join(',');
                const conteType = refererItem.parent.type || refererItem.type;

                const link = `${childSid}:${childPublishedVersion}:${parentSid}:${parentPublishedVersion}:${conteType}=${products}`;
                refererLinks.push(link);
            }
        }

        Storage.local.removeItem(CMS_REFERER_LOCAL_STORAGE_KEY);

        return refererLinks.join(';');
    }

    return null;
};

const mountProductReference = (products, sid) => {
    if (!products) {
        return [];
    }

    return !products.includes(sid) ? products.concat(sid) : products;
};

export const addProductToReferer = sku => {
    const cmsRefererInfo = Storage.local.getItem(CMS_REFERER_LOCAL_STORAGE_KEY);

    if (cmsRefererInfo) {
        const current = cmsRefererInfo.currentSid;

        if (current) {
            Storage.local.setItem(CMS_REFERER_LOCAL_STORAGE_KEY, {
                ...cmsRefererInfo,
                products: mountProductReference(cmsRefererInfo.products, sku.skuId),
                referer: {
                    ...cmsRefererInfo.referer,
                    [current]: {
                        ...cmsRefererInfo.referer[current],
                        products: mountProductReference(cmsRefererInfo.referer[current].products, sku.skuId),
                        addedToBasket: true
                    }
                }
            });
        }
    }

    return;
};

/** function to mount the component reference
 * @param {object} currentSid - current component sid
 * @param {string} targetURL - target URL
 * @param {object} parent - parent component
 * @param {object} child - child component
 */

export const mountComponentRef = ({ currentSid, targetURL, parent, child }) => {
    const currentComponentRef = Storage.local.getItem(CMS_REFERER_LOCAL_STORAGE_KEY);

    const currentComponentData = currentComponentRef?.referer?.[currentSid]
        ? currentComponentRef?.referer?.[currentSid]
        : {
            ...currentComponentRef?.referer?.[currentSid],
            parent: {
                type: parent.type,
                sid: parent.sid,
                sys: {
                    publishedVersion: parent?.sys?.publishedVersion
                }
            },
            sid: currentSid,
            type: child.type,
            products: [],
            sys: {
                publishedVersion: child?.sys?.publishedVersion
            },
            targetUrl: targetURL
        };

    const componentRef = {
        products: [],
        ...currentComponentRef,
        currentSid,
        referer: {
            ...currentComponentRef?.referer,
            [currentSid]: currentComponentData
        }
    };

    Storage.local.setItem(CMS_REFERER_LOCAL_STORAGE_KEY, componentRef);
};
