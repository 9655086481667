export default function getResource(label, vars = []) {
    const resources = {
        bannerButton: 'Sign Up for Text Alerts',
        bannerTitle: 'Be the first to know what’s new.',
        bannerParagraph: 'Never miss out on exclusives releases, events, and offers.',
        bannerRates: '*Msg & data rates may apply.'
    };

    return resources[label];
}
