export default function getResource(label, vars = []) {
    const resources = {
        shareExperience: 'Partagez cette expérience',
        description: 'Description',
        map: 'Plan',
        copyLinkShare: 'Copier le lien suivant pour partager',
        minutesAbbr: 'min',
        beauty: `Studio ${vars[0]}`,
        inCityState: ` dans ${vars[0]}, ${vars[1]}`
    };

    return resources[label];
}
