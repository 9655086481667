/* eslint-disable no-bitwise */

import { Fragment, createRef } from 'react';
import { Text, Button, Link } from 'components/ui';
import BaseClass from 'components/BaseClass';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import LocaleUtils from 'utils/LanguageLocale';
import Radio from 'components/Inputs/Radio/Radio';
import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import withCreditCardFormViewModel from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm/withCreditCardFormViewModel';
import withBillingAddressFormViewModel from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/BillingAddressForm/withBillingAddressFormViewModel';
import viewModes from 'components/GlobalModals/PaidReservationModal/PaymentSection/viewModes';

const { CREATE, DEFAULT, EDIT, SELECT } = viewModes;

import CreditCardFormComponent from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm';
import BillingAddressFormComponent from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/BillingAddressForm';

const CreditCardForm = withCreditCardFormViewModel(CreditCardFormComponent);
const BillingAddressForm = withBillingAddressFormViewModel(BillingAddressFormComponent);

const getText = LocaleUtils.getLocaleResourceFile(
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/locales',
    'PaymentInfo'
);

class PaymentInfo extends BaseClass {
    state = {
        saveAsDefault: this.props.creditCardToEdit?.isDefault || !this.props.hasCreditCards,
        saveForFuture: !this.props.hasCreditCards || Boolean(this.props.creditCardToEdit),
        useDefaultAddress: true
    };
    creditCardFormRef = createRef();
    billingAddressFormRef = createRef();

    get creditCardForm() {
        return this.creditCardFormRef.current;
    }

    get billingAddressForm() {
        return this.billingAddressFormRef.current;
    }

    handleUseDefaultAddress = () => this.setState({ useDefaultAddress: true });

    handleUseDifferentAddress = () => this.setState({ useDefaultAddress: false });

    handleCancel = () => {
        const { setErrorMessage, setViewMode } = this.props;
        setErrorMessage(null);
        setViewMode(SELECT);
    };

    render() {
        const {
            creditCardToEdit, defaultAddress: address, hasCreditCards, isAnonymous, triggerAnalyticsErrors, viewMode
        } = this.props;
        const { saveAsDefault, saveForFuture, useDefaultAddress } = this.state;
        const showBillingAddressForm = !address || !useDefaultAddress;

        return (
            <Fragment>
                <CreditCardForm
                    creditCardToEdit={creditCardToEdit}
                    ref={this.creditCardFormRef}
                    triggerAnalyticsErrors={triggerAnalyticsErrors}
                />
                {address && (
                    <Fragment>
                        <Text
                            fontSize='base'
                            fontWeight='bold'
                        >
                            {getText('billingAddress')}
                        </Text>
                        <Radio
                            checked={useDefaultAddress}
                            marginTop={2}
                            onClick={this.handleUseDefaultAddress}
                        >
                            {`${address.address1}, ${address.address2 ? address.address2 + ',' : ''} ${address.city}, ${address.state}, ${
                                address.postalCode
                            }`}
                        </Radio>
                        <Radio
                            checked={!useDefaultAddress}
                            marginBottom={4}
                            data-at={Sephora.debug.dataAt('use_different_address_btn')}
                            onClick={this.handleUseDifferentAddress}
                        >
                            {getText('useDiffAddressRadio')}
                        </Radio>
                    </Fragment>
                )}
                {showBillingAddressForm && (
                    <BillingAddressForm
                        creditCardToEdit={creditCardToEdit}
                        ref={this.billingAddressFormRef}
                    />
                )}
                {!isAnonymous && (
                    <Fragment>
                        {!creditCardToEdit && (
                            <Checkbox
                                checked={saveForFuture}
                                marginTop={3}
                                onChange={this.onSaveForFutureChanged}
                            >
                                {getText('saveForFuture')}
                            </Checkbox>
                        )}
                        <Checkbox
                            checked={saveAsDefault}
                            data-at={Sephora.debug.dataAt('set_as_my_default_card_label')}
                            disabled={!hasCreditCards}
                            marginTop={2}
                            onChange={this.onSaveAsDefaultChanged}
                        >
                            {getText('saveAsDefault')}
                        </Checkbox>
                    </Fragment>
                )}
                <Button
                    data-at={Sephora.debug.dataAt('save_and_continue_btn')}
                    marginTop={5}
                    onClick={this.onSave}
                    paddingX={7}
                    variant='primary'
                >
                    {getText('saveContinueButton')}
                </Button>
                {(viewMode === EDIT || (viewMode === CREATE && hasCreditCards)) && (
                    <Link
                        color='blue'
                        data-at={Sephora.debug.dataAt('payment_expanded_cancel_btn')}
                        marginLeft={5}
                        onClick={this.handleCancel}
                    >
                        {getText('cancel')}
                    </Link>
                )}
            </Fragment>
        );
    }

    onSaveForFutureChanged = () => {
        const { hasCreditCards } = this.props;
        const { saveAsDefault, saveForFuture } = this.state;
        const nextSetForFutureValue = !saveForFuture;
        const addingCreditCardDefaultValue = nextSetForFutureValue && saveAsDefault;

        this.setState({
            saveForFuture: nextSetForFutureValue,
            saveAsDefault: !hasCreditCards ? nextSetForFutureValue : addingCreditCardDefaultValue
        });
    };

    onSaveAsDefaultChanged = () => {
        const { saveAsDefault, saveForFuture } = this.state;
        const isAddingNewCard = !this.props.creditCardToEdit;
        const nextSetAsDefaultValue = !saveAsDefault;
        const newState = { saveAsDefault: nextSetAsDefaultValue };

        if (isAddingNewCard) {
            newState['saveForFuture'] = nextSetAsDefaultValue || saveForFuture;
        }

        this.setState(newState);
    };

    onSave = () => {
        const {
            defaultAddress, processCreditCard, setErrorMessage, setViewMode, triggerAnalyticsErrors, setPaymentError
        } = this.props;
        const { saveAsDefault, saveForFuture, useDefaultAddress } = this.state;
        const showBillingAddressForm = !defaultAddress || !useDefaultAddress;
        const creditCardErrors = this.creditCardForm.validate();
        let formDataIsValid = !creditCardErrors.fields.length;
        let billingAddressErrors = { messages: [] };

        if (showBillingAddressForm) {
            billingAddressErrors = this.billingAddressForm.validate();
            formDataIsValid &= !billingAddressErrors.fields.length;
        }

        if (setPaymentError) {
            setPaymentError(null);
        }

        if (formDataIsValid) {
            const creditCard = this.creditCardForm.getCreditCard();
            const address = showBillingAddressForm ? this.billingAddressForm.getAddress() : defaultAddress;
            processCreditCard(creditCard, address, saveAsDefault, saveForFuture)
                .then(() => setViewMode(DEFAULT))
                .catch(() => {
                    // Do nothing here!
                    // We need this empty catch block for suppressing console log error.
                });
        } else {
            triggerAnalyticsErrors([...creditCardErrors.messages, ...billingAddressErrors.messages]);
            setErrorMessage(null);
        }
    };
}

PaymentInfo.defaultProps = { defaultAddress: null };
PaymentInfo.propTypes = {
    defaultAddress: PropTypes.object,
    hasCreditCards: PropTypes.bool.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
    processCreditCard: PropTypes.func.isRequired,
    setErrorMessage: PropTypes.func.isRequired,
    setViewMode: PropTypes.func.isRequired,
    viewMode: PropTypes.oneOf([CREATE, DEFAULT, EDIT, SELECT]).isRequired
};

export default wrapComponent(PaymentInfo, 'PaymentInfo', true);
