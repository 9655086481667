import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import styles from 'components/OnlineReservation/ExperienceDetailPage/SlotList/styles';

function Slot({
    children, hasActiveSlot, isFocused, isSelected, index, isEmptySlot, ...restProps
}) {
    return isEmptySlot ? (
        <div
            css={[styles.slotUnavailable, isFocused && styles.slotOutline]}
            children={children}
        />
    ) : (
        <div
            role='option'
            {...restProps}
            css={[
                styles.slot,
                Sephora.isTouch ||
                    isSelected || {
                    ':hover': styles.slotHover
                },
                isSelected && styles.slotSelected,
                isFocused && ((index === 0 && !hasActiveSlot) || isSelected) && styles.slotOutline
            ]}
        >
            <span css={styles.slotInner}>{children}</span>
        </div>
    );
}

export default wrapFunctionalComponent(Slot, 'Slot');
