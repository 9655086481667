import InflateComponents from 'utils/framework/InflateComponents';
import withRootComponentProps from 'utils/framework/withRootComponentProps';
import withReduxProvider from 'utils/framework/wrapWithReduxProvider';
import SpaUtils from 'utils/Spa';
import Header from 'components/Header';
import GlobalModalsWrapper from 'components/GlobalModals/GlobalModalsWrapper';
import GlobalContent from 'components/GlobalContent/GlobalContent';
import { HydrationFinished, PostLoad } from 'constants/events';

Sephora.Util.InflatorSPA = {};
const HOCWrappingIsDisabled = Sephora.configurationSettings.core.disableHOCWrapping === true;

const createDisplayNameAsForSSR = component => {
    if (HOCWrappingIsDisabled) {
        const { displayName } = component;
        const ssrDisplayName = `ReduxProvider(${displayName})`;

        return ssrDisplayName;
    } else {
        const { displayName: ssrDisplayName } = withReduxProvider(component);

        return ssrDisplayName;
    }
};

const renderRootComponent = component => {
    const displayName = createDisplayNameAsForSSR(component);
    const element = document.body.querySelector(`[rootid="${displayName}"]`);

    if (element) {
        const componentWithProps = withReduxProvider(withRootComponentProps(component, displayName));
        InflateComponents.render(componentWithProps, null, element);
    }
};

const renderRootComponents = async () => {
    const isSpaPage = SpaUtils.isSpaTemplate(Sephora.renderedData.template);
    let Page;

    if (isSpaPage) {
        Page = (await import(/* webpackChunkName: "components" */ '../../SpaApplication')).default;
    } else {
        const allPages = (await import(/* webpackChunkName: "components" */ 'pages/index')).default;
        Page = allPages[Sephora.renderedData.template];
    }

    renderRootComponent(Header);
    renderRootComponent(Page);
    renderRootComponent(GlobalModalsWrapper);
    renderRootComponent(GlobalContent);

    Sephora.Util.InflatorComps.services.loadEvents.HydrationFinished = true;
    window.dispatchEvent(new CustomEvent(HydrationFinished));
    Sephora.Util.Perf.report(HydrationFinished);
};

const renderPostLoadRootComponents = async () => {
    Sephora.Util.Perf.report('PostLoad Ready');

    const Footer = (await import(/* webpackChunkName: "postload" */ 'components/Footer')).default;
    const { PostloadedGAdTagList } = (await import(/* webpackChunkName: "postload" */ 'components/GAdTag')).default;

    InflateComponents.services.loadEvents[PostLoad] = true;
    window.dispatchEvent(new CustomEvent(PostLoad));

    renderRootComponent(Footer);
    renderRootComponent(PostloadedGAdTagList);

    Sephora.Util.Perf.report('Post Load Rendered');
};

export default {
    renderPostLoadRootComponents,
    renderRootComponents
};
