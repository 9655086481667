export default function getResource(label, vars = []) {
    const resources = {
        Standard: 'Commande standard.',
        Pickup: 'Panier « Achetez en ligne, ramassez en magasin »',
        Sameday: 'Commande de livraison le jour même.',
        ShipToHome: 'Panier d’articles à expédier',
        ViewYour: 'Consulter votre '
    };

    return resources[label];
}
