export default function getResource(label, vars = []) {
    const resources = {
        UPCOMING: 'Upcoming',
        PAST: 'Past',
        CANCELLED: 'Canceled',
        CANCELLED_BY_STORE: 'Canceled By Store',
        LATE_CANCELLATION: 'Late Cancelation',
        NO_SHOW: 'No Show',
        fee: `${vars[0]} Fee`
    };

    return resources[label];
}
