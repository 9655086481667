export default function getResource(label, vars = []) {
    const resources = {
        personalInfo: 'Renseignements personnels',
        edit: 'Modifier',
        phone: 'Numéro de téléphone',
        sendMeTexts: 'Oui, envoyez-moi des rappels par message texte au sujet de mon rendez-vous.',
        specialRequests: 'Demande spéciale (facultative)',
        specialRequestsPlaceholder: 'Nos conseillers beauté feront de leur mieux pour répondre à vos demandes (par exemple, type de look, occasion, marques ou produits favoris)',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        emailAddress: 'Adresse de courriel',
        saveAndContinue: 'Enregistrer et continuer'
    };

    return resources[label];
}
