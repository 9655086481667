import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import basketSelector from 'selectors/basket/basketSelector';

const { wrapHOC } = FrameworkUtils;
const fields = createSelector(basketSelector, basket => {
    return {
        basket
    };
});

const withSkuQuantityProps = wrapHOC(connect(fields));

export {
    withSkuQuantityProps, fields
};
