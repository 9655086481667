import getBiRewardsGroup from 'services/api/beautyInsider/getBiRewardsGroup';
import BIApi from 'services/api/beautyInsider';
import UtilActions from 'utils/redux/Actions';

function fetchProfileRewards() {
    return dispatch => {
        getBiRewardsGroup.getBiRewardsGroupForCheckout().then(data => dispatch(UtilActions.merge('rewards', 'rewards', data)));
    };
}

function fetchRecentlyRedeemedRewards(userId, options) {
    return dispatch => {
        BIApi.getPurchaseHistory(userId, options).then(data =>
            dispatch(UtilActions.merge('rewards', 'rewardsPurchasedGroups', data.purchasedGroups))
        );
    };
}

export default {
    fetchProfileRewards,
    fetchRecentlyRedeemedRewards
};
