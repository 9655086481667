const STATUS = {
    UPCOMING: 'UPCOMING',
    PAST: 'PAST',
    CANCELLED: 'CANCELLED',
    NO_SHOW: 'NO_SHOW'
};

const CANCELLED_SUBSTATUS = {
    CANCELLED: 'CANCELLED',
    CANCELLED_BY_STORE: 'CANCELLED_BY_STORE',
    LATE_CANCELLATION: 'LATE_CANCELLATION',
    NO_SHOW: 'NO_SHOW'
};

const STATUS_ICONS = {
    [STATUS.UPCOMING]: 'filled-checkmark.svg',
    [STATUS.PAST]: 'filled-blue-checkmark.svg',
    [STATUS.CANCELLED]: 'remove-text.svg',
    [STATUS.NO_SHOW]: 'remove-text.svg'
};

const EXPERIENCES = {
    SERVICES: 'services',
    EVENTS: 'events'
};

export {
    STATUS, STATUS_ICONS, EXPERIENCES, CANCELLED_SUBSTATUS
};
