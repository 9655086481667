import * as RwdBasketConst from 'constants/RwdBasket';
const {
    BASKET_TYPES: { BOPIS_BASKET, SAMEDAY_BASKET }
} = RwdBasketConst;

const FULFILLMENT_TYPES = {
    SAMEDAY: 'SAMEDAY',
    PICK: 'PICK'
};

const ITEM_SUBSTITUTION_BASKET_MAP = {
    [SAMEDAY_BASKET]: FULFILLMENT_TYPES.SAMEDAY,
    [BOPIS_BASKET]: FULFILLMENT_TYPES.PICK
};

const PRODUCT_SALE_DATA = {
    PARTIAL: 'PARTIAL'
};

const ITEM_SUBSTITUTION_INFO_SID = 'itemSubstitutionInfo';

export {
    ITEM_SUBSTITUTION_BASKET_MAP, FULFILLMENT_TYPES, PRODUCT_SALE_DATA, ITEM_SUBSTITUTION_INFO_SID
};
