export default function getResource(label, vars = []) {
    const resources = {
        giftCardBalance: 'Solde de carte-cadeau',
        toCheckCurrentBalance: 'Pour consulter votre solde actuel, saisissez le numéro de la carte composé de 16 chiffres et le NIP.',
        balance: 'Solde',
        cardNumber: 'Numéro de carte',
        pin: 'NIP',
        check: 'Vérifier'
    };

    return resources[label];
}
