import { createSelector } from 'reselect';
import { lovesSelector } from 'selectors/loves/lovesSelector';

const initializedCurrentLovesSelector = createSelector(lovesSelector, ({ currentLoves, currentLovesIsInitialized }) => {
    return {
        currentLoves,
        currentLovesIsInitialized
    };
});

export default initializedCurrentLovesSelector;
