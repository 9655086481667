import ufeApi from 'services/api/ufeApi';

/*
 * API: https://jira.sephora.com/wiki/display/ILLUMINATE/Contact+Us+API
 * Story: https://jira.sephora.com/browse/ILLUPH-106582
 * */
const contactUs = function (params) {
    const url = '/api/util/customerService/contact';
    var options = {
        method: 'POST',
        url: url,
        body: JSON.stringify(params)
    };

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : data));
};

export default contactUs;
