import {
    Grid, Text, Link, Box, Button
} from 'components/ui';
import BaseClass from 'components/BaseClass';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import ErrorConstants from 'utils/ErrorConstants';
import FormValidator from 'utils/FormValidator';
import HelperUtils from 'utils/Helpers';
import localeUtils from 'utils/LanguageLocale';
import React from 'react';
import store from 'Store';
import Textarea from 'components/Inputs/Textarea/Textarea';
import TextInput from 'components/Inputs/TextInput/TextInput';
import InputEmail from 'components/Inputs/InputEmail/InputEmail';
import { wrapComponent } from 'utils/framework';
import ErrorsUtils from 'utils/Errors';
import userUtils from 'utils/User';

const getMessage = ErrorsUtils.getMessage;

class UserInfoSection extends BaseClass {
    constructor(props) {
        super(props);

        const { user } = store.getState();
        const {
            phoneNumber: userPhoneNumber, login, firstName, lastName, profileStatus, beautyInsiderAccount
        } = user;
        const phoneNumber = userPhoneNumber || beautyInsiderAccount?.homePhone;
        const formattedPhone = phoneNumber && phoneNumber.length > 0 ? FormValidator.getFormattedPhoneNumber(phoneNumber) : '';
        const isEditMode = profileStatus === userUtils.PROFILE_STATUS.ANONYMOUS;

        this.state = {
            formattedPhone,
            isEditMode,
            phone: phoneNumber,
            smsEnabled: false,
            specialRequests: '',
            firstName: firstName,
            lastName: lastName,
            email: login
        };

        if (!Sephora.isNodeRender) {
            this.updateState = this.updateState.bind(this);
            this.updatePhoneState = this.updatePhoneState.bind(this);
            this.toggleSms = this.toggleSms.bind(this);
            this.getNonEditMode = this.getNonEditMode.bind(this);
            this.getEditMode = this.getEditMode.bind(this);
            this.toggleEdit = this.toggleEdit.bind(this);
            this.validateAndUpdate = this.validateAndUpdate.bind(this);
            this.validateForm = this.validateForm.bind(this);
        }

        this.firstNameInput = React.createRef();
        this.lastNameInput = React.createRef();
        this.phoneNumberInput = React.createRef();
        this.emailInput = React.createRef();
    }

    componentDidMount() {
        this.validateAndUpdate();
        this.props.setPersonalService(this.props.isPersonalService);
    }

    updateState = e => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.validateAndUpdate();
        });
    };

    updatePhoneState = e => {
        const updatedObj = {};
        updatedObj['phone'] = e.target.value.replace(HelperUtils.specialCharacterRegex, '');
        updatedObj['formattedPhone'] = FormValidator.getFormattedPhoneNumber(updatedObj['phone']) || updatedObj['phone'];
        this.setState(updatedObj);
    };

    toggleSms = () => {
        this.setState(
            prevState => ({ smsEnabled: !prevState.smsEnabled }),
            () => {
                this.validateAndUpdate();
            }
        );
    };

    toggleEdit = () => {
        this.setState(
            prevState => ({ isEditMode: !prevState.isEditMode }),
            () => {
                if (this.state.isEditMode) {
                    this.props.updateUserInfo(null);
                }
            }
        );
    };

    validateAndUpdate = toggleEdit => {
        const { updateUserInfo, triggerAnalyticsErrors } = this.props;
        const userInfoErrors = this.validateForm();

        if (!userInfoErrors.fields.length) {
            updateUserInfo(this.state);
            toggleEdit && this.toggleEdit();
        } else {
            triggerAnalyticsErrors(userInfoErrors.messages);
            updateUserInfo(null);
        }
    };

    getNonEditMode = () => {
        const { firstName, lastName, email } = this.state;

        return (
            <React.Fragment>
                <Text
                    is='p'
                    fontSize='base'
                >
                    <Text
                        data-at={Sephora.debug.dataAt('first_name_label')}
                        is='span'
                        marginRight='.3em'
                        children={firstName}
                    />
                    <Text
                        data-at={Sephora.debug.dataAt('last_name_label')}
                        is='span'
                        children={lastName}
                    />
                </Text>
                <Text
                    data-at={Sephora.debug.dataAt('email_label')}
                    is='p'
                    fontSize='base'
                    marginBottom={4}
                    children={email}
                />
            </React.Fragment>
        );
    };

    getEditMode = getText => {
        const { firstName, lastName, email } = this.state;
        const nameAndLastnameMaxLength = FormValidator.FIELD_LENGTHS.name;

        return (
            <React.Fragment>
                <Grid
                    gap={3}
                    columns='1fr 1fr'
                >
                    <TextInput
                        label={getText('firstName')}
                        name='firstName'
                        id='guest_booking_firstName'
                        data-at={Sephora.debug.dataAt('first_name_input')}
                        maxLength={nameAndLastnameMaxLength}
                        value={firstName}
                        required={true}
                        onChange={this.updateState}
                        ref={this.firstNameInput}
                        validate={this.validateFirstName}
                    />
                    <TextInput
                        name='lastName'
                        label={getText('lastName')}
                        id='guest_booking_lastName'
                        data-at={Sephora.debug.dataAt('last_name_input')}
                        required={true}
                        value={lastName}
                        maxLength={nameAndLastnameMaxLength}
                        onChange={this.updateState}
                        ref={this.lastNameInput}
                        validate={this.validateLastName}
                    />
                </Grid>

                <InputEmail
                    label={getText('emailAddress')}
                    name='email'
                    login={email}
                    id='guest_booking_email'
                    data-at={Sephora.debug.dataAt('email_input')}
                    ref={this.emailInput}
                    required={true}
                    onChange={this.updateState}
                />
            </React.Fragment>
        );
    };

    validateForm = () => {
        const errors = FormValidator.getErrors([
            this.firstNameInput.current,
            this.lastNameInput.current,
            this.phoneNumberInput.current,
            this.emailInput.current
        ]);

        return errors;
    };

    validateAndSave = () => {
        const { isEditMode } = this.state;

        return !isEditMode ? this.validateAndUpdate(false) : null;
    };

    validatePhoneNumber = phoneNumber => {
        if (FormValidator.isEmpty(phoneNumber)) {
            return getMessage(ErrorConstants.ERROR_CODES.PHONE_NUMBER);
        }

        if (!FormValidator.isValidPhoneNumber(phoneNumber)) {
            return getMessage(ErrorConstants.ERROR_CODES.PHONE_NUMBER_INVALID);
        }

        return null;
    };

    validateFirstName = firstNameString => {
        const nameAndLastnameMaxLength = FormValidator.FIELD_LENGTHS.name;

        if (FormValidator.isEmpty(firstNameString)) {
            return getMessage(ErrorConstants.ERROR_CODES.FIRST_NAME);
        }

        if (!FormValidator.isValidLength(firstNameString, 1, nameAndLastnameMaxLength)) {
            return ErrorConstants.ERROR_CODES.FIRST_NAME_LONG;
        }

        return null;
    };

    validateLastName = lastNameString => {
        const nameAndLastnameMaxLength = FormValidator.FIELD_LENGTHS.name;

        if (FormValidator.isEmpty(lastNameString)) {
            return getMessage(ErrorConstants.ERROR_CODES.LAST_NAME);
        }

        if (!FormValidator.isValidLength(lastNameString, 1, nameAndLastnameMaxLength)) {
            return ErrorConstants.ERROR_CODES.LAST_NAME_LONG;
        }

        return null;
    };

    saveAndContinue = event => {
        event.preventDefault();
        this.validateAndUpdate(true);
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/GlobalModals/PaidReservationModal/UserInfoSection/locales', 'UserInfoSection');
        const { isEditMode } = this.state;
        const { getTermsAndConsLink } = this.props;

        return (
            <div data-at={Sephora.debug.dataAt('personal_info_section')}>
                <Grid
                    lineHeight='tight'
                    alignItems='baseline'
                    columns='1fr auto'
                    marginBottom={4}
                >
                    <Text
                        data-at={Sephora.debug.dataAt('personal_info_title')}
                        is='h2'
                        fontSize='md'
                        fontWeight='bold'
                        children={getText('personalInfo')}
                    />
                    {!isEditMode && (
                        <Link
                            padding={2}
                            margin={-2}
                            data-at={Sephora.debug.dataAt('edit_btn')}
                            color='blue'
                            onClick={this.toggleEdit}
                        >
                            {getText('edit')}
                        </Link>
                    )}
                </Grid>
                {isEditMode ? this.getEditMode(getText) : this.getNonEditMode()}
                <TextInput
                    data-at={Sephora.debug.dataAt('phone_number_input')}
                    ref={this.phoneNumberInput}
                    name='phone'
                    autoComplete='tel'
                    autoCorrect='off'
                    type='tel'
                    label={getText('phone')}
                    value={this.state.formattedPhone}
                    onKeyDown={!Sephora.isTouch && FormValidator.inputAcceptOnlyNumbers}
                    required={true}
                    validate={this.validatePhoneNumber}
                    onChange={this.updatePhoneState}
                    onBlur={this.validateAndSave}
                    maxLength={FormValidator.FIELD_LENGTHS.formattedPhone}
                />
                <Checkbox
                    data-at={Sephora.debug.dataAt('send_reminder_box')}
                    paddingY={null}
                    checked={this.state.smsEnabled}
                    children={getText('sendMeTexts')}
                    onChange={this.toggleSms}
                />
                <Text
                    is='p'
                    color='gray'
                    fontSize='sm'
                    lineHeight='tight'
                    marginY={4}
                    data-at={Sephora.debug.dataAt('terms_conditions_data')}
                >
                    {ExperienceDetailsUtils.getEdpTelephoneUseAuthorization(getTermsAndConsLink && getTermsAndConsLink())}
                </Text>
                {isEditMode && (
                    <Box marginY={6}>
                        <Button
                            variant='primary'
                            onClick={this.saveAndContinue}
                            hasMinWidth={true}
                            data-at={Sephora.debug.dataAt('save_btn')}
                            children={getText('saveAndContinue')}
                        />
                    </Box>
                )}
                {this.props.hasSpecialRequests && (
                    <React.Fragment>
                        <Text
                            is='p'
                            marginTop={5}
                            marginBottom={2}
                            fontWeight='bold'
                            children={getText('specialRequests')}
                        />
                        <Textarea
                            data-at={Sephora.debug.dataAt('special_request_input')}
                            placeholder={getText('specialRequestsPlaceholder')}
                            rows={5}
                            maxLength={1000}
                            name='specialRequests'
                            onChange={this.updateState}
                            value={this.state.specialRequests}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default wrapComponent(UserInfoSection, 'UserInfoSection', true);
