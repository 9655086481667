/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import {
    Box, Link, Flex, Text
} from 'components/ui';
import StoreTimeSlots from 'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/StoreTimeSlots';
import localeUtils from 'utils/LanguageLocale';
import store from 'store/Store';
import Actions from 'Actions';
import ExperienceDetailsActions from 'actions/ExperienceDetailsActions';
import anaConstants from 'analytics/constants';

class StoresAvailability extends BaseClass {
    state = {
        numberOfActivitiesToShow: this.props.isModal ? 5 : 1
    };

    handleShowNumberOfActivities = () => {
        if (this.props.showMoreClick) {
            this.props.showMoreClick();
        }

        if (this.props.isModal) {
            this.props.getMoreStoreAvailabilities(this.state.numberOfActivitiesToShow, this.state.numberOfActivitiesToShow + 5);
            this.setState({ numberOfActivitiesToShow: this.state.numberOfActivitiesToShow + 5 });
        } else if (this.state.numberOfActivitiesToShow === 3) {
            this.props.launchBookModal();
        } else {
            this.setState({ numberOfActivitiesToShow: 3 });
            store.dispatch(ExperienceDetailsActions.showMoreStoresOnMap(this.props.storesWithActivities.slice(0, 3)));
        }
    };

    handleShowGoogleMapModal = (activity, selectedStore) => () => {
        const { onRequestClose, isModal } = this.props;
        store.dispatch(Actions.showBookWidgetModal({ isOpen: false }));
        store.dispatch(
            Actions.showGoogleMapModal({
                isOpen: true,
                activity: activity.activity,
                selectedStore: selectedStore,
                analyticsData: { context: isModal ? anaConstants.PAGE_TYPES.OLR : null },
                onRequestClose
            })
        );
    };

    componentWillReceiveProps(newProps) {
        //if new set of of stores or new activities caused by date change,
        //reset state.numberOfActivitiesToShow
        if (newProps.storeList[0].displayName !== this.props.storeList[0].displayName || newProps.activities.length < this.props.activities.length) {
            this.setState({ numberOfActivitiesToShow: this.props.isModal ? 5 : 1 });
        }
    }

    render() {
        const getText = localeUtils.getLocaleResourceFile(
            'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/locales',
            'StoresAvailability'
        );
        const { isVirtual, handleTimeSlotClick, activities, storeList } = this.props;

        return (
            <div>
                {activities.map((activity, i) => {
                    const storeData = activity.store;
                    const storeName = `Sephora ${storeData.displayName}`;

                    if (i < this.state.numberOfActivitiesToShow) {
                        return (
                            <Box
                                key={i.toString()}
                                marginY={4}
                                paddingBottom={1}
                                lineHeight='tight'
                            >
                                {isVirtual || (
                                    <Flex
                                        key='storeNameAndMap'
                                        alignItems='baseline'
                                        marginBottom={1}
                                        marginLeft={2}
                                        data-at={Sephora.debug.dataAt('store name')}
                                    >
                                        {`${i + 1}. ${storeName}`}
                                        <Text
                                            marginX={2}
                                            children='•'
                                        />
                                        {storeData.distance} {localeUtils.isCanada() ? getText('kmAbbreviation') : getText('milesAbbreviation')}
                                        <Text
                                            marginX={2}
                                            children='•'
                                        />
                                        <Link
                                            padding={1}
                                            margin={-1}
                                            color='blue'
                                            onClick={this.handleShowGoogleMapModal(activity, storeData)}
                                            aria-label={`Map for ${storeName}`}
                                            children={getText('map')}
                                        />
                                    </Flex>
                                )}
                                <StoreTimeSlots
                                    storeIndex={i}
                                    handleTimeSlotClick={handleTimeSlotClick}
                                    timeSlotIndex={this.props.timeSlotIndex}
                                    storeClickedIndex={this.props.storeClickedIndex}
                                    activity={activity}
                                />
                            </Box>
                        );
                    }

                    return null;
                })}

                {!isVirtual && this.state.numberOfActivitiesToShow < storeList.length && (
                    <Flex
                        marginTop={4}
                        justifyContent='left'
                    >
                        <Link
                            padding={1}
                            margin={-1}
                            color='blue'
                            onClick={this.handleShowNumberOfActivities}
                        >
                            {getText('moreTimesLocations')}
                        </Link>
                    </Flex>
                )}
            </div>
        );
    }
}

export default wrapComponent(StoresAvailability, 'StoresAvailability');
