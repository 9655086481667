import ufeApi from 'services/api/ufeApi';
const reservationApiBase = '/v1/olr/activities';

/* eslint-disable max-len */
// API documentation: https://stage-developer.sephora.com/specs/v1/docs/store.html#booking-an-appointment
/* eslint-enable max-len */

function bookReservation(token, activityId, params) {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const url = sdnDomain + reservationApiBase + '/' + activityId;
    var options = {
        url,
        method: 'post',
        headers: { authorization: `Bearer ${token}` },
        body: JSON.stringify(params),
        mode: 'cors',
        credentials: 'omit'
    };

    return ufeApi.makeRequest(url, options).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default bookReservation;
