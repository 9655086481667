const resources = {
    new: 'Nouveauté',
    value: 'valeur',
    colors: 'Couleurs',
    quickLook: 'Aperçu rapide',
    sponsored: 'Commandité',
    regPrice: ' Prix courant',
    selectSaleItems: 'Sélectionner les articles en solde',
    viewSimilarProducts: 'Voir des produits similaires'
};

export default function getResource(label) {
    return resources[label];
}
