import { connect } from 'react-redux';
import FrameworkUtils from 'utils/framework';
import UserActions from 'actions/UserActions';

const { wrapHOC } = FrameworkUtils;

const fields = null;

const functions = {
    validateUserStatusAndGetProfileSettings: UserActions.validateUserStatusAndGetProfileSettings
};

const withOrderStatusLookupProps = wrapHOC(connect(fields, functions));

export {
    withOrderStatusLookupProps, fields, functions
};
