import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import LanguageLocaleUtils from 'utils/LanguageLocale';

const { wrapHOC } = FrameworkUtils;
const { getTextFromResource, getLocaleResourceFile } = LanguageLocaleUtils;
const getText = getLocaleResourceFile('components/Content/GiftCards/locales', 'GiftCards');

const fields = createStructuredSelector({
    localization: createStructuredSelector({
        currentBalance: getTextFromResource(getText, 'currentBalance'),
        cardNumber: getTextFromResource(getText, 'cardNumber'),
        pin: getTextFromResource(getText, 'pin'),
        checkBalance: getTextFromResource(getText, 'checkBalance')
    })
});

const functions = null;

const withGiftCardBalanceCheckFormProps = wrapHOC(connect(fields, functions));

export {
    withGiftCardBalanceCheckFormProps, fields, functions
};
