import ufeApi from 'services/api/ufeApi';

const getServicesConfirmationContent = (token, { confirmationNumber, language, country, channel = 'rwd' }) => {
    const languageLowerCase = language.toLowerCase();
    const { sdnApiHost } = Sephora.configurationSettings;

    const url = `${sdnApiHost}/v2/happening/services/confirmation/${confirmationNumber}?locale=${languageLowerCase}-${country}&channel=${channel}&country=${country}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: { 'x-requested-source': 'rwd', authorization: `Bearer ${token}` }
    });
};

export default getServicesConfirmationContent;
