import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';
import biApi from 'services/api/beautyInsider';
import userActions from 'actions/UserActions';

import profileReducer from 'reducers/profile';
const { ACTION_TYPES: TYPES } = profileReducer;

function setAccountHistorySlice(accountHistorySlice) {
    return {
        type: TYPES.SET_ACCOUNT_HISTORY_SLICE,
        accountHistorySlice: accountHistorySlice
    };
}

export default {
    TYPES,
    showEditMyProfileModal: function (isOpen, saveBeautyTraitCallBack) {
        return {
            type: TYPES.SHOW_EDIT_MY_PROFILE_MODAL,
            isOpen: isOpen,
            saveBeautyTraitCallBack: saveBeautyTraitCallBack
        };
    },

    showEditFlowModal: function (isOpen, title, content, biAccount, socialProfile, saveProfileCallback) {
        return {
            type: TYPES.SHOW_EDIT_FLOW_MODAL,
            isOpen: isOpen,
            title: title,
            content: content,
            biAccount: biAccount,
            socialProfile: socialProfile,
            saveProfileCallback: saveProfileCallback
        };
    },

    showSavedMessage: function (isSave) {
        return {
            type: TYPES.SHOW_SAVED_MESSAGE,
            isSave: isSave
        };
    },

    showSocialRegistrationModal: function (isOpen, isBi, socialRegistrationProvider) {
        //Analytics - Track Modal
        const pageDetail = isBi ? 'nickname' : 'nickname birthday';

        if (isOpen) {
            processEvent.process(anaConsts.ASYNC_PAGE_LOAD, {
                data: {
                    pageName: 'social registration:' + pageDetail + ':n/a:*',
                    pageType: 'social registration',
                    pageDetail
                }
            });
        }

        return {
            type: TYPES.SHOW_SOCIAL_REGISTRATION_MODAL,
            isOpen: isOpen,
            socialRegistrationProvider: socialRegistrationProvider
        };
    },

    showSocialReOptModal: function (isOpen, socialReOptCallback, cancellationCallback = false) {
        //Analytics - Track Modal
        if (isOpen) {
            processEvent.process(anaConsts.ASYNC_PAGE_LOAD, {
                data: {
                    pageName: 'social registration:re-opt in:n/a:*',
                    pageType: 'social registration',
                    pageDetail: 're-opt in'
                }
            });
        }

        return {
            type: TYPES.SHOW_SOCIAL_REOPT_MODAL,
            isOpen: isOpen,
            socialReOptCallback: socialReOptCallback,
            cancellationCallback: cancellationCallback
        };
    },

    updateBiAccount: function (change, successCallback, errorCallback) {
        return dispatch => {
            biApi
                .updateBiAccount(change)
                .then(() => {
                    dispatch(userActions.getUserFull(null, successCallback));
                })
                .catch(reason => {
                    if (reason.errorMessages && typeof errorCallback === 'function') {
                        errorCallback();
                    }
                });
        };
    },
    getAccountHistorySlice: function (profileId, offset = 0, limit = 10) {
        return dispatch => {
            biApi.getBiAccountHistory(profileId, offset, limit).then(data => dispatch(setAccountHistorySlice(data)));
        };
    }
};
