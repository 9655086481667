const resources = {
    storesAndServices: 'Stores & Services',
    home: 'Home',
    shop: 'Shop',
    offers: 'Offers',
    me: 'Me',
    signIn: 'Sign In',
    community: 'Community',
    store: 'Stores',
    tooltip: 'Sign in (or sign up!) for offers, points, and more.',
    homepage: 'Sephora Homepage'
};

export default function getResource(label) {
    return resources[label];
}
