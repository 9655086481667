const ACTION_TYPES = {};

const initialState = {
    afterLogin: [],
    msgPromosSkuList: [],
    promoError: null
};
const reducer = function (state = initialState) {
    return state;
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
