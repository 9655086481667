import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Auto+Login+API

function autoLogin(_body = {}) {
    const url = '/api/ssi/autoLogin';

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-type': 'application/json',
                'x-requested-source': 'web'
            },
            body: JSON.stringify(_body)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default { autoLogin };
