const SIGN_IN = 'USER.SIGN_IN';
const UPDATE = 'USER.UPDATE';
const NOT_FOUND = 'USER.NOT_FOUND';
const DRAFT_STORE_DETAILS = 'DRAFT_STORE_DETAILS';
const DRAFT_ZIP_CODE = 'DRAFT_ZIP_CODE';
const CLEAR_UPPER_FUNNEL_DRAFT = 'CLEAR_UPPER_FUNNEL_DRAFT';
const STORE_CHANGED_FROM_HEADER = 'STORE_CHANGED_FROM_HEADER';
const UPDATE_SMS_STATUS = 'UPDATE_SMS_STATUS';
const ADD_SUBSCRIBED_EMAIL = 'ADD_SUBSCRIBED_EMAIL';
const TOGGLE_SELECT_AS_DEFAULT_PAYMENT = 'PAYMENT_GROUP_TOGGLE_SELECT_AS_DEFAULT_PAYMENT';
const UPDATE_SEGMENTS = 'UPDATE_SEGMENTS';
const ADD_CC_REWARDS = 'ADD_CC_REWARDS';
const UPDATE_PASSKEYS = 'UPDATE_PASSKEYS';

export {
    SIGN_IN,
    UPDATE,
    NOT_FOUND,
    DRAFT_STORE_DETAILS,
    DRAFT_ZIP_CODE,
    CLEAR_UPPER_FUNNEL_DRAFT,
    STORE_CHANGED_FROM_HEADER,
    UPDATE_SMS_STATUS,
    ADD_SUBSCRIBED_EMAIL,
    TOGGLE_SELECT_AS_DEFAULT_PAYMENT,
    UPDATE_SEGMENTS,
    ADD_CC_REWARDS,
    UPDATE_PASSKEYS
};
