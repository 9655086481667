/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import ACTIVITY_CONSTS from 'components/OnlineReservation/activityConstants';
import DateTZ from 'utils/DateTZ';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import localeUtils from 'utils/LanguageLocale';
import Slot from 'components/OnlineReservation/ExperienceDetailPage/SlotList/Slot';
import SlotList from 'components/OnlineReservation/ExperienceDetailPage/SlotList/SlotList';
import slotListStyles from 'components/OnlineReservation/ExperienceDetailPage/SlotList/styles';
import keyConsts from 'utils/KeyConstants';

const { waitlist } = slotListStyles;

function convertToAMPM(dateTime, timeZone, isVirtual) {
    // For virtual services we need to use the user local time instead of store time
    const storeTime = isVirtual ? new Date(dateTime) : new DateTZ(dateTime, timeZone);

    return ExperienceDetailsUtils.getAMPMFormat(storeTime);
}

class StoreTimeSlots extends BaseClass {
    state = {
        activeIndex: -1,
        isFocused: false
    };

    handleSelect = (slot, index) => {
        this.props.handleTimeSlotClick(slot, this.props.activity, this.props.storeIndex, index);
        this.setState({ activeIndex: index });
    };

    handleSelectClick = (slot, index) => () => {
        this.handleSelect(slot, index);
    };

    handleKeyDown = e => {
        const slots = this.props.activity.timeSlots;
        const lastSlot = slots.length - 1;
        let index = this.state.activeIndex;

        const select = () => {
            e.preventDefault();
            this.handleSelect(slots[index], index);
        };

        switch (e.key) {
            case keyConsts.ENTER:
            case keyConsts.SPACE:
            case keyConsts.HOME:
                index = 0;
                select();

                break;
            case keyConsts.END:
                index = lastSlot;
                select();

                break;
            case keyConsts.LEFT:
            case keyConsts.UP:
                if (index <= 0) {
                    index = lastSlot;
                } else {
                    index--;
                }

                select();

                break;
            case keyConsts.RIGHT:
            case keyConsts.DOWN:
                if (index === -1 || index >= lastSlot) {
                    index = 0;
                } else {
                    index++;
                }

                select();

                break;
            default:
                return;
        }
    };

    toggleFocus = () => {
        this.setState({ isFocused: !this.state.isFocused });
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile(
            'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales',
            'StoreTimeSlots'
        );

        const { storeIndex, activity, timeSlotIndex, storeClickedIndex } = this.props;

        const { isFocused, activeIndex } = this.state;

        const slots = activity.timeSlots;
        const store = activity.store;
        const isVirtual = store?.isVirtual;
        const hasSlots = slots.length > 0;
        const hasActiveSlot = storeClickedIndex === storeIndex && timeSlotIndex !== undefined;

        return (
            <SlotList
                onFocus={this.toggleFocus}
                onBlur={this.toggleFocus}
                {...(hasSlots
                    ? {
                        onKeyDown: this.handleKeyDown,
                        ['aria-activedescendant']: activeIndex > -1 ? `store${storeIndex}_slot${activeIndex}` : null
                    }
                    : null)}
                aria-label={
                    hasSlots ? getText('availabletimes') : getText('noTimesAvailable') + ' ' + getText('for') + ' Sephora ' + store.displayName
                }
            >
                {hasSlots || (
                    <Slot
                        isEmptySlot={true}
                        isFocused={isFocused}
                        children={getText('noTimesAvailable')}
                    />
                )}
                {hasSlots &&
                    slots.map((slot, j) => {
                        const isSelected = hasActiveSlot && timeSlotIndex === j;
                        const timeZone = store.storeHours && store.storeHours.timeZone;

                        return (
                            <Slot
                                key={slot.startDateTime}
                                onClick={this.handleSelectClick(slot, j)}
                                tabIndex='-1'
                                index={j}
                                id={`store${storeIndex}_slot${j}`}
                                isSelected={isSelected}
                                isFocused={isFocused}
                                hasActiveSlot={hasActiveSlot}
                                aria-selected={isSelected}
                            >
                                {convertToAMPM(slot.startDateTime, timeZone, isVirtual)}
                                {slot.reservationAction.toLowerCase() === ACTIVITY_CONSTS.RESERVATION_ACTIONS.WAITLIST && (
                                    <span
                                        css={waitlist}
                                        children={getText('waitlist')}
                                    />
                                )}
                            </Slot>
                        );
                    })}
            </SlotList>
        );
    }
}

export default wrapComponent(StoreTimeSlots, 'StoreTimeSlots');
