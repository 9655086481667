/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import {
    Grid, Box, Flex, Text, Image, Button, Divider, Link, Icon
} from 'components/ui';
import Modal from 'components/Modal/Modal';
import IconCalAdd from 'components/LegacyIcon/IconCalAdd';
import IconCalCheck from 'components/LegacyIcon/IconCalCheck';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import localeUtils from 'utils/LanguageLocale';
import Actions from 'Actions';
import anaConsts from 'analytics/constants';
import anaUtils from 'analytics/utils';
import Authentication from 'utils/Authentication';
import calendarUtils from 'utils/Calendar';
import Debounce from 'utils/Debounce';
import decorators from 'utils/decorators';
const ITEMS_TO_SHOW = 5;
import store from 'store/Store';
import urlUtils from 'utils/Url';

class RsvpWidget extends BaseClass {
    state = {
        selectedActivity: null,
        selectedTimeSlot: null,
        modalAction: null, // can take one of ACTIVITY.RSVP_MODAL_ACTIONS
        modalActivity: null,
        modalSelectedSlotIndex: null,
        dateTimes: {},
        showMoreIterations: 1
    };

    componentDidMount() {
        const { activityInfo = {}, startDateParam } = this.props;
        const dateTimes = ExperienceDetailsUtils.getDefaultDateTimes(startDateParam, activityInfo.type);
        this.setState({ dateTimes });
    }

    handleShowGoogleMapModal = (activity, selectedStore) => () => {
        store.dispatch(
            Actions.showGoogleMapModal({
                isOpen: true,
                activity: activity,
                selectedStore: selectedStore
            })
        );
    };

    handleAddToCalClick = selectedActivity => () => {
        if (!selectedActivity.timeSlots || selectedActivity.timeSlots.length < 1) {
            return;
        }

        selectedActivity.timeSlots.length === 1 || selectedActivity.type !== ACTIVITY.TYPE.EVENTS
            ? this.addToCal(selectedActivity, selectedActivity.timeSlots[0])
            : this.showModal(selectedActivity, ACTIVITY.RSVP_MODAL_ACTIONS.CAL);
    };

    addToCal = (selectedActivity, selectedTimeSlot) => {
        this.dismissModal();
        const startDateTime = selectedTimeSlot.startDateTime;
        const durationMin = selectedTimeSlot.durationMin;
        // as part of https://jira.sephora.com/browse/SER-1381 we will start using the original start and end time for announcements too.
        const icsUrl = calendarUtils.buildCalendarUrl(startDateTime, durationMin, selectedActivity.name, {
            description: selectedActivity.description,
            location: 'Sephora ' + selectedActivity.store.displayName,
            url: ExperienceDetailsUtils.getEdpPageUrl(selectedActivity)
        });
        icsUrl && urlUtils.redirectTo(icsUrl);
    };

    handleRsvpClick = selectedActivity => () => {
        if (!selectedActivity.timeSlots || selectedActivity.timeSlots.length < 1) {
            return;
        }

        selectedActivity.timeSlots.length === 1
            ? this.startBooking(selectedActivity, selectedActivity.timeSlots[0])
            : this.showModal(selectedActivity, ACTIVITY.RSVP_MODAL_ACTIONS.RSVP);
    };

    showModal = (selectedActivity, modalAction) => {
        this.setState({
            modalActivity: selectedActivity,
            modalAction: modalAction
        });
    };

    getDismissModalState = () => {
        return {
            modalActivity: null,
            modalSelectedSlotIndex: null,
            modalAction: null
        };
    };

    dismissModal = () => {
        this.setState(this.getDismissModalState());
    };

    startBooking = (selectedActivity, selectedTimeSlot) => {
        const newState = {
            selectedActivity,
            selectedTimeSlot,
            ...this.getDismissModalState()
        };
        this.setState(newState, () => {
            Authentication.requireLoggedInAuthentication().then(() => {
                const { displayName: storeName, storeHours } = selectedActivity.store;
                const activityDetails = {
                    selectedActivity,
                    selectedTimeSlot,
                    storeName,
                    timeZone: storeHours?.timeZone
                };
                this.launchPhoneNumberModal(activityDetails);
            });
        });
    };

    launchPhoneNumberModal = activityDetails => {
        const user = store.getState().user;

        store.dispatch(
            Actions.showPhoneNumberModal({
                isOpen: true,
                reservation: phoneDetails => {
                    this.bookReservation(Object.assign({}, user, phoneDetails));
                },
                activityDetails
            })
        );
    };

    bookReservation = user => {
        const { selectedActivity, selectedTimeSlot } = this.state;
        const confirmationNumberToCancel = urlUtils.getParamsByName('reschedule') ? urlUtils.getParamsByName('reschedule')[0] : null;

        const reservationDetails = {
            // user details
            clientExternalId: user.login || user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            smsEnabled: user.smsEnabled,

            // experience details
            activityType: selectedActivity.type,
            storeId: selectedActivity.storeId,
            bookingId: selectedTimeSlot.bookingId,
            startDateTime: selectedTimeSlot.startDateTime
        };

        ExperienceDetailsUtils.bookReservation(selectedActivity.activityId, reservationDetails, confirmationNumberToCancel)
            .then(() => {
                const rsvpSuccessEvent = confirmationNumberToCancel ? anaConsts.Event.RESCHEDULE_SUCCESS : anaConsts.Event.RSVP_EVENT_SUCCESS;
                anaUtils.setNextPageData({ events: [rsvpSuccessEvent] });
            })
            .catch(error => {
                // @TODO error modal, TBD by business
                // eslint-disable-next-line no-console
                console.error(error);
            });
    };

    handleShowMoreTimesLocationsInternal = () => {
        const { dateTimes, showMoreIterations } = this.state;
        const { activities, storeList, updateActivities } = this.props;
        const activityInfo = activities[0];
        const currentLength = showMoreIterations * ITEMS_TO_SHOW;
        const storeListSnippet = storeList.slice(currentLength, currentLength + ITEMS_TO_SHOW);
        this.setState(prevState => ({ showMoreIterations: prevState.showMoreIterations + 1 }));
        decorators
            .withInterstice(ExperienceDetailsUtils.getExperienceByStores)(storeListSnippet, activityInfo, dateTimes.start, dateTimes.end)
            .then(experienceData => {
                if (experienceData.storeAvailabilities.length) {
                    updateActivities(activities.concat(experienceData.storeAvailabilities));
                }
            });
    };

    handleShowMoreTimesLocations = Debounce.preventDoubleClick(this.handleShowMoreTimesLocationsInternal);

    handleDateTimeClick = (index, handleTimeClick, slot) => () => {
        this.setState({ modalSelectedSlotIndex: index });
        setTimeout(() => {
            handleTimeClick(slot);
        }, 500);
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/ExperienceDetailPage/RsvpWidget/locales', 'RsvpWidget');
        const getStoreAvailabilityText = localeUtils.getLocaleResourceFile(
            'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/locales',
            'StoresAvailability'
        );
        const isDesktop = Sephora.isDesktop();

        const { activities, storeList } = this.props;

        const { showMoreIterations } = this.state;

        if (!activities || activities.length === 0) {
            return this.renderError(getText);
        }

        let widgetTitle = getText('happeningNearYou');
        let hasRsvpCta = false;
        let hasDateTime = false;
        let dataAt = null;

        if (activities[0]['type'] === ACTIVITY.TYPE.EVENTS) {
            widgetTitle = getText('rsvpThisEvent');
            hasRsvpCta = true;
            hasDateTime = true;
            dataAt = 'booking_widget';
        }

        return (
            <Box
                data-at={Sephora.debug.dataAt(dataAt)}
                borderRadius={2}
                padding={isDesktop && 4}
                border={isDesktop && 1}
                borderColor='lightGray'
            >
                <Box marginBottom={4}>
                    <Text
                        is='h2'
                        fontSize='md'
                        fontWeight='bold'
                        lineHeight='tight'
                        children={widgetTitle}
                    />
                    {isDesktop && <Divider marginTop={4} />}
                </Box>
                {activities.map((activity, i) => {
                    if (activity) {
                        const currentStore = activity.store;
                        const slots = activity.timeSlots;
                        const timeZone = currentStore.storeHours && currentStore.storeHours.timeZone;

                        return (
                            <React.Fragment key={i.toString()}>
                                {i > 0 && <Divider marginY={4} />}
                                <Grid
                                    gap={null}
                                    columns='1.5em 1fr auto auto'
                                    lineHeight='tight'
                                    alignItems='start'
                                >
                                    <span>{i + 1}.</span>
                                    <div>
                                        <h3 data-at={Sephora.debug.dataAt('store name')}>
                                            Sephora {currentStore.displayName}
                                            <Text
                                                marginX={2}
                                                children='•'
                                            />
                                            {currentStore.distance}{' '}
                                            {localeUtils.isCanada()
                                                ? getStoreAvailabilityText('kmAbbreviation')
                                                : getStoreAvailabilityText('milesAbbreviation')}
                                            <Text
                                                marginX='.375em'
                                                children='•'
                                            />
                                            <Link
                                                display='inline'
                                                padding={1}
                                                margin={-1}
                                                color='blue'
                                                onClick={this.handleShowGoogleMapModal(activity, currentStore)}
                                                children={getText('map')}
                                            />
                                        </h3>
                                        {hasDateTime &&
                                            (slots.length === 0 ? (
                                                <Text
                                                    is='p'
                                                    color='gray'
                                                    children={getText('noTimesAvailable')}
                                                />
                                            ) : (
                                                slots.map((slot, j) => {
                                                    return <p key={`${i}_${j}`}>{this.formatEventDateTime(slot, timeZone)}</p>;
                                                })
                                            ))}
                                    </div>
                                    {slots.length > 0 && (
                                        <>
                                            {hasRsvpCta && (
                                                <>
                                                    <Link
                                                        paddingX={3}
                                                        fontWeight='bold'
                                                        fontSize='sm'
                                                        textAlign='center'
                                                        data-at='rsvp'
                                                        onClick={this.handleRsvpClick(activity)}
                                                    >
                                                        <IconCalCheck fontSize={24} />
                                                        <Text
                                                            display='block'
                                                            marginTop={1}
                                                            children={getText('rsvp')}
                                                        />
                                                    </Link>
                                                </>
                                            )}
                                            <Box
                                                borderLeft={1}
                                                borderColor='divider'
                                                marginRight={-3}
                                            >
                                                <Link
                                                    paddingX={3}
                                                    maxWidth='7.25em'
                                                    fontWeight='bold'
                                                    fontSize='sm'
                                                    textAlign='center'
                                                    onClick={this.handleAddToCalClick(activity)}
                                                >
                                                    <IconCalAdd fontSize={24} />
                                                    <Text
                                                        display='block'
                                                        marginTop={1}
                                                        children={getText('addToCal')}
                                                    />
                                                </Link>
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                            </React.Fragment>
                        );
                    }

                    return null;
                })}
                {showMoreIterations * 5 < storeList.length && (
                    <Flex
                        marginTop={4}
                        justifyContent='left'
                    >
                        <Link
                            padding={1}
                            margin={-1}
                            color='blue'
                            onClick={this.handleShowMoreTimesLocations}
                        >
                            {getText('showMoreTimesLocations')}
                        </Link>
                    </Flex>
                )}
                {this.renderModal(getText)}
            </Box>
        );
    }

    renderModal = getTextFn => {
        const modalActivity = this.state.modalActivity;
        const handleTimeClick = slot => {
            this.state.modalAction === ACTIVITY.RSVP_MODAL_ACTIONS.RSVP
                ? this.startBooking(this.state.modalActivity, slot)
                : this.addToCal(this.state.modalActivity, slot);
        };

        return (
            <Modal
                isOpen={!!modalActivity}
                width={0}
                onDismiss={this.dismissModal}
            >
                <Modal.Header>
                    <Modal.Title>{getTextFn('chooseTime')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalActivity &&
                        modalActivity.timeSlots &&
                        modalActivity.timeSlots.map((slot, i) => {
                            const timeZone = modalActivity.store.storeHours && modalActivity.store.storeHours.timeZone;

                            return (
                                <Link
                                    key={i.toString()}
                                    display='block'
                                    width='100%'
                                    fontSize='base'
                                    onClick={this.handleDateTimeClick(i, handleTimeClick, slot)}
                                    paddingY={3}
                                >
                                    {this.formatEventDateTime(slot, timeZone)}
                                    {this.state.modalSelectedSlotIndex === i && (
                                        <Icon
                                            name='checkmark'
                                            size='1em'
                                            marginLeft={2}
                                        />
                                    )}
                                </Link>
                            );
                        })}
                </Modal.Body>
            </Modal>
        );
    };

    renderError = getTextFn => {
        return (
            <Box
                textAlign='center'
                lineHeight='tight'
            >
                <Text
                    is='p'
                    fontWeight='bold'
                >
                    {getTextFn('noAppointmentsAvailable1')}
                    <br />
                    {getTextFn('noAppointmentsAvailable2')}
                </Text>
                <Text
                    is='p'
                    marginTop='.125em'
                    marginBottom='.5em'
                >
                    {getTextFn('tryDifferentExperience')}
                </Text>
                <Image
                    display='block'
                    src='/img/ufe/no-result.svg'
                    size={128}
                    marginX='auto'
                    marginBottom={4}
                />
                <Button
                    variant='secondary'
                    href={ACTIVITY.OLR_URLS.LANDING_PAGE}
                    children={getTextFn('seeWhatsHappening')}
                />
            </Box>
        );
    };

    formatEventDateTime = (slot, timeZone) => {
        return slot.startDateTime ? ExperienceDetailsUtils.getFullDateStoreTimeFormat(slot.startDateTime, timeZone, slot && slot.durationMin) : '';
    };
}

export default wrapComponent(RsvpWidget, 'RsvpWidget');
