/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import { space } from 'style/config';
import {
    Grid, Link, Box, Flex, Text
} from 'components/ui';
import LegacyCarousel from 'components/LegacyCarousel/LegacyCarousel';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import ActivityCard from 'components/OnlineReservation/StoreHub/ActivityCard/ActivityCard';
import localeUtils from 'utils/LanguageLocale';
import store from 'store/Store';
import StoreHubActions from 'actions/StoreHubActions';
import UI from 'utils/UI';

class ActivityCarousel extends BaseClass {
    state = {
        activities: [],
        startDateTime: null
    };

    componentDidMount() {
        // If parent component passes down the activityList, don't need to listen the store
        if (!this.props.activityList || !this.props.activityList.length) {
            store.setAndWatch('storeHub', this, data => {
                if (data.storeHub.allActivities) {
                    this.setState({
                        activities: data.storeHub.allActivities[this.props.activityType],
                        startDateTime: data.storeHub.startDateTime
                    });
                }
            });
        }
    }

    handleGridView = () => {
        store.dispatch(StoreHubActions.viewAllClicked(this.state.activities));
        store.dispatch(StoreHubActions.updateFilteredActivityList(this.state.activities));
        UI.scrollToTop();
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/StoreHub/ActivityCarousel/locales', 'ActivityCarousel');
        const { activityType, activityList = [], title, viewAllLink } = this.props;

        if (!activityList.length && !this.state.activities.length) {
            return null;
        }

        const isDesktop = Sephora.isDesktop();

        //carousels are limited to 12 cards
        const cardActivities = activityList.length ? activityList.slice(0, 12) : this.state.activities.slice(0, 12);

        const cards = cardActivities.map((activity, index) => (
            <Flex
                key={index.toString()}
                css={
                    isDesktop
                        ? {
                            width: '100%'
                        }
                        : {
                            scrollSnapAlign: 'start'
                        }
                }
            >
                <ActivityCard
                    startDateTime={this.state.startDateTime}
                    activity={activity}
                />
            </Flex>
        ));

        return (
            <Box marginBottom={5}>
                <Box
                    textAlign={[null, 'center']}
                    lineHeight='tight'
                    marginBottom={4}
                >
                    <Grid
                        alignItems='baseline'
                        columns={['1fr auto', '1fr minmax(0, 75%) 1fr']}
                    >
                        <Box display={['none', 'block']} />
                        <Text
                            is='h2'
                            fontFamily='serif'
                            fontSize={activityType === 'store' ? (isDesktop ? 'xl' : 'lg') : isDesktop ? '2xl' : 'xl'}
                            data-at={Sephora.debug.dataAt('carousel_title')}
                            children={title || ACTIVITY.CAROUSEL_TYPE[activityType].title}
                        />
                        <Flex justifyContent='flex-end'>
                            {activityType !== 'featured' && (
                                <Link
                                    href={viewAllLink}
                                    onClick={!viewAllLink ? this.handleGridView : null}
                                    padding={2}
                                    margin={-2}
                                    arrowDirection='right'
                                    children={getText('viewAll')}
                                />
                            )}
                        </Flex>
                    </Grid>
                    <Text
                        is='p'
                        marginTop={2}
                        children={ACTIVITY.CAROUSEL_TYPE[activityType].subtitle}
                    />
                </Box>

                {isDesktop ? (
                    <LegacyCarousel
                        gutter={space[3]}
                        isFlexItem={true}
                        displayCount={3}
                        totalItems={cardActivities.length}
                        showArrows={true}
                        fillTrailedGap={true}
                    >
                        {cards}
                    </LegacyCarousel>
                ) : (
                    <Box
                        display='grid'
                        marginX='-container'
                        paddingX='container'
                        css={{
                            gridAutoFlow: 'column',
                            columnGap: space[2],
                            gridAutoColumns: 240,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            scrollBehavior: 'smooth',
                            scrollbarWidth: 'none',
                            scrollPadding: space.container,
                            overscrollBehaviorX: 'none',
                            scrollSnapType: 'x mandatory'
                        }}
                    >
                        {cards}
                        <Box width={`${space.container - space[2]}px`} />
                    </Box>
                )}
            </Box>
        );
    }
}

export default wrapComponent(ActivityCarousel, 'ActivityCarousel', true);
