import React from 'react';
import PropTypes from 'prop-types';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import localeUtils from 'utils/LanguageLocale';
import bookArtist from 'utils/bookArtist';
import SlotList from 'components/OnlineReservation/ExperienceDetailPage/SlotList/SlotList';
import Slot from 'components/OnlineReservation/ExperienceDetailPage/SlotList/Slot';
import keyConsts from 'utils/KeyConstants';

import activityConstants from 'components/OnlineReservation/activityConstants';

const { ANY_ARTIST } = activityConstants;

class BeautyAdvisorsAvailable extends BaseClass {
    state = {
        activeIndex: -1,
        isFocused: false
    };

    handleKeyDown = e => {
        const slots = this.props.selectedTimeSlot.resources;
        const lastSlot = slots.length - 1;
        let index = this.state.activeIndex;

        const select = () => {
            e.preventDefault();
            this.handleSelect(slots[index], index);
        };

        switch (e.key) {
            case keyConsts.ENTER:
            case keyConsts.SPACE:
            case keyConsts.HOME:
                index = 0;
                select();

                break;
            case keyConsts.END:
                index = lastSlot;
                select();

                break;
            case keyConsts.LEFT:
            case keyConsts.UP:
                if (index <= 0) {
                    index = lastSlot;
                } else {
                    index--;
                }

                select();

                break;
            case keyConsts.RIGHT:
            case keyConsts.DOWN:
                if (index === -1 || index >= lastSlot) {
                    index = 0;
                } else {
                    index++;
                }

                select();

                break;
            default:
                return;
        }
    };

    toggleFocus = () => {
        this.setState({ isFocused: !this.state.isFocused });
    };

    handleSelect = (slot, index) => {
        this.props.handleArtistSlotClick(slot);
        this.setState({ activeIndex: index });
    };

    static getAnyArtistItem = getTextFn => {
        const getText = !getTextFn
            ? localeUtils.getLocaleResourceFile(
                'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales',
                'StoreTimeSlots'
            )
            : getTextFn;

        const AnyArtist = {
            displayName: getText('anyArtist'),
            resourceId: ANY_ARTIST
        };

        return AnyArtist;
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile(
            'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales',
            'StoreTimeSlots'
        );
        const AnyArtist = BeautyAdvisorsAvailable.getAnyArtistItem(getText);
        const {
            selectedTimeSlot, storeIndex, activityClicked: activity, selectedArtistSlot, storeClickedIndex
        } = this.props;

        const { isFocused, activeIndex } = this.state;

        const store = activity.store;
        const eligibleResources = selectedTimeSlot?.resources?.filter(resource => resource?.employee?.isEligibleForBookByArtist === true);
        eligibleResources.unshift(AnyArtist);
        const hasSlots = eligibleResources?.length >= 1;
        const hasActiveSlot = storeClickedIndex === storeIndex && Boolean(selectedArtistSlot);

        return (
            <SlotList
                onFocus={this.toggleFocus}
                onBlur={this.toggleFocus}
                {...(hasSlots
                    ? {
                        onKeyDown: this.handleKeyDown,
                        ['aria-activedescendant']: activeIndex > -1 ? `store${storeIndex}_BA${activeIndex}` : null
                    }
                    : null)}
                aria-label={
                    hasSlots ? getText('availableArtists') : getText('noArtistsAvailable') + ' ' + getText('for') + ' Sephora ' + store.displayName
                }
            >
                {hasSlots ? (
                    eligibleResources.map((resource, index) => {
                        const isSelected = hasActiveSlot && selectedArtistSlot?.resourceId === resource.resourceId;
                        const artistName = bookArtist.getArtistName(resource);

                        return (
                            <Slot
                                key={resource.resourceId}
                                onClick={() => this.handleSelect(resource, index)}
                                tabIndex='-1'
                                index={index}
                                id={`store${storeIndex}_BA${index}`}
                                isSelected={isSelected}
                                hasActiveSlot={hasActiveSlot}
                                aria-selected={isSelected}
                            >
                                {artistName}
                            </Slot>
                        );
                    })
                ) : (
                    <Slot
                        isEmptySlot={true}
                        isFocused={isFocused}
                        children={getText('noArtistsAvailable')}
                    />
                )}
            </SlotList>
        );
    }
}

BeautyAdvisorsAvailable.propTypes = {
    selectedTimeSlot: PropTypes.object
};

export default wrapComponent(BeautyAdvisorsAvailable, 'BeautyAdvisorsAvailable');
