const resources = {
    title: 'Service à la clientèle',
    available: 'Disponible',
    unavailable: 'Clavardage non disponible',
    inHours: 'Tous les agents sont occupés',
    offline: 'Nous ne sommes pas en ligne présentement',
    unavailablePopoverText: 'Désolé de vous avoir manqué! Veuillez réessayer plus tard.',
    marketingMsgText: 'Clavardez avec nos experts pour obtenir des conseils et des recommandations de produits sur mesure.',
    marketingMsgLink: 'En savoir plus',
    marketingMsgButton: 'Clavarder maintenant'
};

export default function getResource(label) {
    return resources[label];
}
