import ufeApi from 'services/api/ufeApi';
// &env=ufe-dev

export function getContent(options) {
    const { path, language, country, config = {} } = options;
    const url = `/api/content${path}?ch=web&loc=${language}-${country}`;

    return ufeApi
        .makeRequest(
            url,
            {
                method: 'GET',
                headers: { 'x-requested-source': 'rwd' }
            },
            config
        )
        .then(data => data);
}

export default { getContent };
