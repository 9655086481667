export default function getResource(label, vars = []) {
    const resources = {
        uncheckJoinErrorMessage: `Vous devez accepter les conditions générales du programme Beauty Insider afin de pouvoir accumuler ${vars[0]} points sur vos achats du jour.`,
        popoverContent: `Inscrivez-vous à Beauty Insider pour économiser ${vars[0]} points sur votre achat. Obtenez des expériences uniques, des services personnalisés et des échantillons convoités.`,
        linkActionText: 'Voir les récompenses actuelles',
        dontForgetText: `N’oubliez pas d’économiser vos ${vars[0]} points!`,
        testDontLosePoints: `Ne perdez pas vos ${vars[0]} points`,
        testSavePointsFreeShip: 'Enregistrez-les et profitez de nombreux privilèges en adhérant à Beauty Insider, notre programme de fidélisation GRATUIT. Vous obtiendrez aussi la *livraison standard GRATUITE!*',
        free: 'GRATUIT',
        testNewBiPropBDayGift: 'Cadeau d’anniversaire',
        freeGift: 'Cadeau d’anniversaire GRATUIT',
        testNewBiPropPoints: 'Économies saisonnières',
        testNewBiPropShipping: 'Livraison GRATUITE',
        testJoinNowButton: 'S’inscrire',
        testLegalClickingText: 'En cliquant sur « S’inscrire », vous reconnaissez être résident du Canada ou des États-Unis et (1) avoir lu',
        testLegalPrivacyPolicyLink: 'Politique de confidentialité ',
        testLegalNoticeOfFinancialIncentiveLink: ' Avis de prime financière',
        testLegalAnd: 'et',
        testLegalAgreeTo: 'de Sephora et vous (2) acceptez les',
        testLegalTermsOfUseLink: 'CONDITIONS D’UTILISATION',
        testLegalBiTermsLink: 'MODALITÉS BEAUTY INSIDER',
        testLegalAutomaticallyText: ', et vous acceptez de recevoir automatiquement les offres Beauty Insider par courriel.',
        testEnterPasswordToCreateText: `Créez un mot de passe pour ${vars[0]}`,
        enterPasswordToSaveText: 'Entrez votre mot de passe Sephora',
        enterPasswordToSaveDetailsText: `pour ${vars[0]} économiser vos ${vars[1]} points sur cet achat.`,
        enterPasswordToCreateText: 'Entrez un mot de passe pour créer votre compte.',
        passwordInputLabel: `Mot de passe (${vars[0]} à ${vars[1]} caractères)`,
        forgotText: 'Mot de passe oublié?',
        savePointsButton: 'Économiser des points',
        createAccountButton: 'Créer un compte',
        thankYouText: 'Merci pour votre inscription!',
        browseBazaarText: 'Votre commande apparaîtra dans votre historique des commandes Jeter un coup d’œil à la foire aux récompenses Rewards Bazaar.',
        viewBazaarText: 'Consulter la foire aux récompenses',
        phoneNumber: 'Numéro de téléphone',
        useYourPhoneLabel: 'Utilisez votre numéro de téléphone pour une recherche de compte plus rapide en magasin.',
        marketingCheckbox: 'Inscrivez-moi aux alertes marketing par texto de Sephora.',
        disclosure: 'Divulgation des alertes par texto Sephora :',
        byEntering: ' En entrant votre numéro de téléphone, vérifiez les',
        signMeUp: ' Inscrivez-moi aux alertes marketing par texto Sephora,',
        clicking: ' en cliquant sur le bouton « \S’inscrire\ » et en confirmant votre inscription, vous consentez à recevoir des messages publicitaires automatiques récurrents, y compris des rappels de panier abandonné, et confirmez que vous avez lu et accepté les',
        textTerm: ' MODALITÉS DES TEXTOS.',
        message: ' La fréquence des messages varie. Le consentement n’est pas une condition d’achat. Des frais de messagerie texte et de données peuvent s’appliquer. Consultez notre ',
        privacy: 'POLITIQUE DE CONFIDENTIALITÉ',
        textStop: ' Textez ARRET pour annuler en tout temps. AIDE pour obtenir de l’aide.',
        textStopAddress: ' Sephora : 600, boul. de Maisonneuve Ouest, bureau 2400, Montréal (Québec)  H3A 3J2, Canada 1-877-737-4672.',
        byClicking: 'En cliquant sur « S’inscrire », vous reconnaissez être résident du Canada ou des États-Unis et (1) avoir lu',
        agreeTo: ' acceptez ',
        subscribeLabel: 'S’abonner aux courriels de Sephora',
        and: ' et ',
        noticeFinancialIncentive: 'AVIS DE PRIME FINANCIÈRE'
    };

    return resources[label];
}
