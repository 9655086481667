export default function getResource(label, vars = []) {
    const resources = {
        freeSamples: 'Échantillons gratuits',
        freeSamplesCTA: `Obtenir ${vars[0]} échantillon(s) gratuits avec chaque commande`,
        samplesAddedText: ` échantillon${vars[0]} ajouté`,
        done: 'Terminé'
    };

    return resources[label];
}
