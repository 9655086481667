import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import {
    Flex, Box, Text, Link
} from 'components/ui';

import CustomBulletList from 'components/Content/Happening/StoreDetails/CustomBulletList';
import Chunklets from 'components/Content/Happening/StoreDetails/Chunklets';

import LanguageLocale from 'utils/LanguageLocale';
import storeUtils from 'utils/Store';
import storeHoursUtils from 'utils/StoreHours';
import { ensureSephoraPrefix } from 'utils/happening';
import { colors } from 'style/config';

const getText = LanguageLocale.getLocaleResourceFile('components/Content/Happening/StoreDetails/StoreInformation/locales', 'StoreInformation');

const StoreInformation = ({ store, user }) => {
    const {
        address, isInStoreShoppingEnabled, isBopisable, isBeautyServicesEnabled, isStoreEventsEnabled
    } = store;

    const storeName = storeUtils.getStoreDisplayNameWithSephora(store);
    const isCurbsideEnabled = storeHoursUtils.isCurbsideEnabled(store);

    const storeBullets = [
        {
            bulletName: getText('inStoreShopping'),
            bulletFlag: isInStoreShoppingEnabled
        },
        {
            bulletName: getText('inStorePickup'),
            bulletFlag: isBopisable
        },
        {
            bulletName: getText('curbside'),
            bulletFlag: isCurbsideEnabled
        },
        {
            bulletName: getText('beautyServices'),
            bulletFlag: isBeautyServicesEnabled
        },
        {
            bulletName: getText('storeEvents'),
            bulletFlag: isStoreEventsEnabled
        }
    ];

    return (
        <Flex
            flexDirection='column'
            justifyContent='space-between'
            height='100%'
        >
            {/* Store Information */}
            <Box
                margin='auto 0'
                marginBottom={4}
            >
                <Box marginBottom={4}>
                    <Text
                        is='h1'
                        fontSize={'xl'}
                        fontWeight='bold'
                        children={ensureSephoraPrefix(storeName)}
                    />
                </Box>
                <Box>
                    <Text marginBottom={0}>{`${address.address1}, ${address.city}, ${address.state} ${address.postalCode}`}</Text>
                    <Box>
                        <Link
                            color={colors.blue}
                            padding={1}
                            margin={-1}
                            href={`tel:${address.phone.replace(/[^0-9]+/g, '')}`}
                            children={address.phone}
                        />
                    </Box>
                </Box>
            </Box>
            {/* Chunklets */}
            <Box marginBottom={5}>
                <Chunklets
                    store={store}
                    user={user}
                />
            </Box>
            {/* Bullets */}
            <Box>
                <CustomBulletList bullets={storeBullets} />
            </Box>
        </Flex>
    );
};

export default wrapFunctionalComponent(StoreInformation, 'StoreInformation');
