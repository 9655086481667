import { system } from 'styled-system';

const inset = system({
    inset: {
        property: 'inset',
        scale: 'space'
    }
});

export default inset;
