const resources = {
    eyeColor: 'Eye Color',
    blue: 'Blue',
    brown: 'Brown',
    green: 'Green',
    gray: 'Gray',
    hazel: 'Hazel',
    hairColor: 'Hair Color',
    blonde: 'Blonde',
    brunette: 'Brunette',
    auburn: 'Auburn',
    black: 'Black',
    red: 'Red',
    skinTone: 'Skin Tone',
    porcelain: 'Porcelain',
    fair: 'Fair',
    light: 'Light',
    medium: 'Medium',
    tan: 'Tan',
    olive: 'Olive',
    deep: 'Deep',
    dark: 'Dark',
    ebony: 'Ebony',
    skinType: 'Skin Type',
    normal: 'Normal',
    combination: 'Combination',
    dry: 'Dry',
    oily: 'Oily',
    skinConcerns: 'Skin Concerns',
    acne: 'Acne',
    aging: 'Aging',
    blackheads: 'Blackheads',
    calluses: 'Calluses',
    cellulite: 'Cellulite',
    cuticles: 'Cuticles',
    darkCircles: 'Dark Circles',
    dullness: 'Dullness',
    redness: 'Redness',
    sensitivity: 'Sensitivity',
    stretchMarks: 'Stretch Marks',
    sunDamage: 'Sun Damage',
    unevenSkinTones: 'Uneven Skin Tones',
    hairType: 'Hair Type',
    chemicallyTreated: 'Chemically Treated',
    coarse: 'Coarse',
    curly: 'Curly',
    fine: 'Fine',
    straight: 'Straight',
    wavy: 'Wavy',
    hairConcerns: 'Hair Concerns',
    antiAging: 'Anti-aging',
    colorProtection: 'Color Protection',
    curlEnhancing: 'Curl Enhancing',
    damaged: 'Damaged',
    dandruff: 'Dandruff',
    frizz: 'Frizz',
    heatProtection: 'Heat Protection',
    hold: 'Hold',
    oiliness: 'Oiliness',
    shine: 'Shine',
    straighteningSmoothing: 'Straightening/Smoothing',
    thinning: 'Thinning',
    volumizing: 'Volumizing',
    ageRange: 'Age Range',
    content: 'Content',
    photos: 'Photos',
    video: 'Video',
    verifiedPurchases: 'Verified Purchases',
    rating: 'Rating'
};

export default function getResource(label) {
    return resources[label];
}
