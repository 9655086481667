import ufeApi from 'services/api/ufeApi';
import RCPSCookies from 'utils/RCPSCookies';

function smsSubscribe(phoneNumber) {
    let url = '/api/util/OptinEmailSms';

    if (RCPSCookies.isRCPSAccountAPIEnabled()) {
        url = '/gway/v2/util/OptinEmailSms';
    }

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            body: JSON.stringify({
                phoneNumber,
                type: 'SMS'
            })
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default smsSubscribe;
