const SET_INITIAL_BEAUTY_PREFERENCES = 'SET_INITIAL_BEAUTY_PREFERENCES';
const SET_MULTIPLE_BEAUTY_PREFERENCES = 'SET_MULTIPLE_BEAUTY_PREFERENCES';
const SET_UPDATED_BEAUTY_PREFERENCES_MULTIPLE = 'SET_UPDATED_BEAUTY_PREFERENCES_MULTIPLE';
const CLEAR_BEAUTY_PREFERENCE = 'CLEAR_BEAUTY_PREFERENCE';
const SET_COLOR_IQ = 'SET_COLOR_IQ';
const SET_EXPANDED_PREFERENCE = 'SET_EXPANDED_PREFERENCE';
const SET_PROFILE_COMPLETION_PERCENTAGE = 'SET_PROFILE_COMPLETION_PERCENTAGE';
const SET_MAPPED_BRANDS_LIST = 'SET_MAPPED_BRANDS_LIST';
const SET_IS_COLORIQ_LAST_ANSWERED_TRAIT = 'SET_IS_COLORIQ_LAST_ANSWERED_TRAIT';
const SET_FILTERED_OUT_USER_FAV_BRAND_IDS = 'SET_FILTERED_OUT_USER_FAV_BRAND_IDS';

export {
    SET_INITIAL_BEAUTY_PREFERENCES,
    SET_MULTIPLE_BEAUTY_PREFERENCES,
    SET_UPDATED_BEAUTY_PREFERENCES_MULTIPLE,
    CLEAR_BEAUTY_PREFERENCE,
    SET_COLOR_IQ,
    SET_EXPANDED_PREFERENCE,
    SET_PROFILE_COMPLETION_PERCENTAGE,
    SET_MAPPED_BRANDS_LIST,
    SET_IS_COLORIQ_LAST_ANSWERED_TRAIT,
    SET_FILTERED_OUT_USER_FAV_BRAND_IDS
};
