import ufeApi from 'services/api/ufeApi';

function refreshToken(bodyJSON) {
    const url = '/api/auth/v1/refreshToken';

    const options = {
        url,
        method: 'POST',
        body: JSON.stringify(bodyJSON),
        headers: {
            'Content-type': 'application/json',
            'x-requested-source': 'web'
        }
    };

    return ufeApi.makeRequest(url, { ...options }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default {
    refreshToken
};
