export default function getResource(label, vars = []) {
    const resources = {
        savedAddresses: 'Adresses enregistrées',
        defaultShippingAddress: 'Adresse d’expédition par défaut',
        setAsDefaultAddress: 'Définir en tant qu’adresse par défaut',
        edit: 'Modifier',
        addShippingAddress: 'Ajouter une adresse de livraison',
        title: 'Supprimer l’adresse',
        message: 'Vous avez dépassé le nombre maximal d’adresses sauvegardées. Veuillez en supprimer une et en ajouter une autre à nouveau.',
        buttonText: 'Continuer'
    };
    return resources[label];
}
