import {
    STATUS, CANCELLED_SUBSTATUS, STATUS_ICONS, EXPERIENCES
} from 'components/Content/Happening/ReservationDetails/constants';
import localeUtils from 'utils/LanguageLocale';
const { getLocaleResourceFile } = localeUtils;
const getText = getLocaleResourceFile('components/Content/Happening/ReservationDetails/locales', 'ReservationDetails');

function getStatusIcon(status) {
    switch (status) {
        case STATUS.UPCOMING:
            return STATUS_ICONS.UPCOMING;
        case STATUS.PAST:
            return STATUS_ICONS.PAST;
        case STATUS.CANCELLED:
        case STATUS.NO_SHOW:
            return STATUS_ICONS.CANCELLED;
        default:
            return STATUS_ICONS.NO_SHOW;
    }
}

function isEvent(type) {
    return type === EXPERIENCES.EVENTS;
}

function isService(type) {
    return type === EXPERIENCES.SERVICES;
}

function isUpcoming(status) {
    return status === STATUS.UPCOMING;
}

function isPast(status) {
    return status === STATUS.PAST;
}

function isCancelled(status) {
    return status === STATUS.CANCELLED;
}

function isNoShow(subStatus) {
    return subStatus === CANCELLED_SUBSTATUS.NO_SHOW;
}

function isLateCancellation(subStatus) {
    return subStatus === CANCELLED_SUBSTATUS.LATE_CANCELLATION;
}

function getStatusLabel(reservation) {
    const {
        type,
        status,
        subStatus,

        serviceFees = {}
    } = reservation;

    if (isCancelled(status)) {
        if (isService(type)) {
            let fee = '$0';

            if (isNoShow(subStatus)) {
                fee = serviceFees?.noShowFee || fee;

                return `${getText(subStatus)} ${getText('fee', [fee])}`;
            }

            if (isLateCancellation(subStatus)) {
                fee = serviceFees?.cancellationFee || fee;

                return `${getText(subStatus)} ${getText('fee', [fee])}`;
            }
        }

        // Events and Services Canceled
        return getText(CANCELLED_SUBSTATUS[subStatus] || CANCELLED_SUBSTATUS.CANCELLED);
    }

    return getText(status);
}

export {
    getStatusIcon, isUpcoming, isPast, isCancelled, isEvent, isService, getStatusLabel, isNoShow, isLateCancellation
};
