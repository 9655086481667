const keys = {
    END: 'End',
    HOME: 'Home',
    LEFT: 'ArrowLeft',
    UP: 'ArrowUp',
    RIGHT: 'ArrowRight',
    DOWN: 'ArrowDown',
    ENTER: 'Enter',
    SPACE: ' ',
    ESC: 'Escape',
    TAB: 'Tab',
    BACKSPACE: 'Backspace',
    DELETE: 'Delete'
};

export default keys;
