/* eslint-disable no-shadow */
import memoize from '@emotion/memoize';
import isPropValid from '@emotion/is-prop-valid';
import {
    compose,
    space,
    typography,
    color,
    layout,
    flexbox,
    border,
    background,
    position,
    shadow,
    buttonStyle,
    textStyle,
    colorStyle
} from 'styled-system';

import grid from 'style/grid';

const all = compose(space, typography, color, layout, flexbox, border, background, position, grid, shadow, buttonStyle, textStyle, colorStyle);

const props = all.propNames;

const createShouldForwardProp = props => {
    const regex = new RegExp(`^(${props.join('|')})$`);

    return memoize(prop => prop === '__ref' || (isPropValid(prop) && !regex.test(prop)));
};

export default createShouldForwardProp(props);
