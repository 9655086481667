import CallUs from 'components/Content/CustomerService/CallUs';
import ChatWithUs from 'components/Content/CustomerService/ChatWithUs';
import EmailUs from 'components/Content/CustomerService/EmailUs';
import OrderStatus from 'components/Content/CustomerService/OrderStatus';

export default {
    CallUs,
    ChatWithUs,
    EmailUs,
    OrderStatus
};
