export default function getResource(label, vars = []) {
    const resources = {
        map: 'Map',
        moreTimesLocations: 'Show more times & locations',
        milesAbbreviation: 'mi',
        kmAbbreviation: 'km'
    };

    return resources[label];
}
