import React from 'react';
import PropTypes from 'prop-types';
import { wrapFunctionalComponent } from 'utils/framework';
import { mediaQueries } from 'style/config';
import { Grid } from 'components/ui';
import { CARD_GAP_GRID, CARD_IMG_SIZE_GRID, CARD_IMG_SIZE_GRID_LARGE } from 'constants/productCard';
import ProductCard from 'components/Product/ProductCard';

const COLUMNS = {
    small: 5,
    large: 4
};

const CardSizes = {
    small: {
        imageSize: CARD_IMG_SIZE_GRID
    },
    large: {
        imageSize: CARD_IMG_SIZE_GRID_LARGE
    }
};

const GridLayout = props => {
    const {
        sid,
        showSkeleton,
        title,
        showMarketingFlags,
        showRankingNumbers,
        showLovesButton,
        showRatingWithCount,
        showQuickLookOnMobile,
        ignoreTargetUrlForBox,
        skus,
        showPrice,
        showAddButton,
        renderBiButton,
        size,
        isBIRBReward,
        isAnonymous,
        isShowAddFullSize,
        isInBasket,
        rougeBadgeText
    } = props;
    const numberOfColumns = COLUMNS[size];
    const ref = React.createRef();
    const sizeConfig = CardSizes[size];

    return (
        <Grid
            id={sid}
            css={styles.contained(numberOfColumns)}
            ref={ref}
            gap={CARD_GAP_GRID}
        >
            {(showSkeleton ? [...Array(numberOfColumns).keys()] : skus).map((item, index) => (
                <ProductCard
                    key={item.sku || index}
                    position={index}
                    isSkeleton={showSkeleton}
                    sku={showSkeleton ? {} : item.sku || item}
                    showPrice={showPrice}
                    showAddButton={renderBiButton || showAddButton}
                    useInternalTracking={true}
                    componentName={sid}
                    parentTitle={title}
                    showMarketingFlags={showMarketingFlags}
                    rank={showRankingNumbers ? index + 1 : null}
                    showLovesButton={showLovesButton}
                    showRating={showRatingWithCount}
                    imageSize={sizeConfig.imageSize}
                    showQuickLookOnMobile={showQuickLookOnMobile}
                    ignoreTargetUrlForBox={ignoreTargetUrlForBox}
                    outline={'none'}
                    isBIRBReward={isBIRBReward}
                    isAnonymous={isAnonymous}
                    isShowAddFullSize={isShowAddFullSize}
                    isInBasket={isInBasket}
                    rougeBadgeText={rougeBadgeText}
                />
            ))}
        </Grid>
    );
};

const styles = {
    contained: columns => {
        return {
            position: 'relative',
            width: '100%',
            padding: 0,
            paddingLeft: '-15px',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            [mediaQueries.xsMax]: {
                gridTemplateColumns: '1fr 1fr'
            }
        };
    }
};

GridLayout.propTypes = {
    sid: PropTypes.string,
    showSkeleton: PropTypes.bool,
    title: PropTypes.string,
    showMarketingFlags: PropTypes.bool,
    showRankingNumbers: PropTypes.bool,
    showLovesButton: PropTypes.bool,
    showRatingWithCount: PropTypes.bool,
    showQuickLookOnMobile: PropTypes.bool,
    ignoreTargetUrlForBox: PropTypes.bool,
    skus: PropTypes.array,
    showPrice: PropTypes.bool,
    showAddButton: PropTypes.bool,
    renderBiButton: PropTypes.func,
    size: PropTypes.oneOf(Object.keys(CardSizes))
};

GridLayout.defaultProps = {
    sid: null,
    size: 'small'
};

export default wrapFunctionalComponent(GridLayout, 'GridLayout');
