export default function getResource(label, vars = []) {
    const resources = {
        modalTitle: 'Changer la méthode',
        standard: 'Standard',
        sameDayDelivery: 'Livraison le jour même',
        bopis: 'Achat en ligne et ramassage',
        autoReplenish: 'Réapprovisionnement automatique',
        autoReplenishEnroll: 'Veuillez vous inscrire à partir de la page du produit.',
        autoReplenishDisclaim:
            'Si vous passez à une autre méthode et que vous souhaitez utiliser le renouvellement automatique à nouveau, veuillez vous inscrire à partir de la page des détails du produit.',
        checkAvailability: 'Vérifiez la disponibilité',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        ok: 'OK',
        bopisUnavailable: 'L’achat en ligne et le ramassage ne sont pas disponibles pour le moment.',
        sameDayUnavailable: 'La livraison le jour même n’est pas disponible pour le moment.',
        qtyWarningMessage: 'La quantité de l’article sera réduite à un.',
        getItSooner: 'Obtenez-le plus rapidement',
        withSddOrBopis: ' avec la livraison le jour même ou achetez en ligne et ramassez'
    };

    return resources[label];
}
