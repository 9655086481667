export default function getResource(label, vars=[]) {
    const resources = {
        getItShippedTitle: 'Get It Shipped',
        freeShipping: 'FREE Shipping',
        shippingAndReturns: 'Shipping & Returns',
        reserveAndPickUp: 'Reserve & Pick Up',
        buyOnlineAndPickUp: 'Buy Online & Pick Up',
        selectForStoreAvailability: 'Select to see availability',
        limitedStock: 'Limited Stock',
        outOfStock: 'Out of Stock',
        inStock: 'In Stock',
        stores: 'stores',
        nearYou: 'near you',
        checkOtherStores: 'Check other stores',
        notOffered: 'Reservation not offered at this store',
        pickUpNotOffered: 'Pickup not offered at this store',
        atThisStore: 'at this store',
        availableInAllStores: 'Available in All Stores',
        onlineOnly: 'Online Only',
        reserveNotAvailable: 'Reserve & Pick Up Not Available',
        pickUpNotAvailable: 'Buy Online & Pick Up Not Available',
        reservationNotOfferedForItem: 'Reservation not offered for this item',
        pickUpNotOfferedForItem: 'Pickup not offered for this item',
        at: 'at',
        for: 'for',
        sameDayDelivery: 'Same-Day Delivery',
        standard: 'Standard',
        sameDayDeliveryNotAvailable: 'Not available for this item',
        sddTemporarilyUnavailableAtLocation: 'Same-Day Delivery is temporarily unavailable. Please try again later.',
        sameDayNotAvailableFor: 'Same-Day Delivery not available',
        yourLocation: 'your location',
        changeLocation: 'Change your location',
        autoReplenishTitle: 'Auto-Replenish',
        deliveryEvery: 'Delivery every',
        months: 'months',
        mostCommon: 'most common',
        subscriptionNotAvailable: 'Not available for this selection',
        save: 'Save',
        rougeMemberYouGet: 'As a Rouge member, you can also try',
        rougeMemberFreeSameDayDelivery: 'Free Same-Day Delivery',
        autoReplenishLegalOptIn: 'By enrolling, you consent to the reoccurring charge for your subscription.',
        freeShipSignIn: `${vars[0]} or ${vars[1]} to enjoy ${vars[2]}.`,
        signIn: 'Sign in',
        createAccount: 'create an account',
        freeStandardShipping: 'FREE standard shipping',
        storesNearYou: 'stores near you',
        inStorePickup: 'In-store pickup',
        curbsidePickup: 'Curbside pickup',
        curbsideConcierge: 'Curbside concierge',
        aboutSameDayDelivery: 'About Same-Day Delivery',
        notAvailable: 'Not available',
        sddRougeFreeShipMessage: 'As a Rouge member, you can also try Free Same-Day Delivery!',
        aboutBuyOnlineAndPickUp: 'About Buy Online & Pick Up',
        aboutAutoReplen: 'About Auto-Replenish',
        sddRougeTestV2Message: `As a Rouge member, you’re only ${vars[0]} away from FREE Same-Day Delivery.`,
        sddRougeTestV2FreeShippingMessage: 'As a Rouge member, you\’re getting FREE Same-Day Delivery.',
        autoReplenishLegalText1: `*Offer available to first-time subscribers only. For each Hot Deal item, subscription and quantity is limited to 1 per customer. Discounts expire ${vars[0]} months after the initial sign-up.`,
        autoReplenishLegalText2: `After ${vars[0]} orders at plus-up rate or after ${vars[1]} months of the initial sign-up date, whichever comes first, the subscription renews at a ${vars[2]}% discount.`,
        autoReplenishLegalText3: `Offer ends ${vars[0]}.`,
        autoReplenishFirstTimeLegalShown: `*First-time subscribers get ${vars[0]} off their first ${vars[1]} orders, delivered within ${vars[2]} months of the initial order placement. Promo period ends ${vars[3]}.`,
        autoReplenishOnThisItem: 'on this item',
        autoReplenishTermsApply: ', terms apply*',
        autoReplenishFirstTimeLegalDetails: 'Details',
        autoReplenishFirstTimeLegalMoreDetails: `For each Hot Deal item, subscription and quantity is limited to 1 per customer. After ${vars[0]} replenishment orders at ${vars[1]} off or after ${vars[2]} months of the initial sign-up date, whichever comes first, the subscription renews at ${vars[3]} off.`,
        autoReplenishCheckoutTermsConditions1: `*Price valid for first time subscribers only, for the first ${vars[0]} orders, delivered within ${vars[1]} months after initial order placement.`,
        autoReplenishCheckoutTermsConditions2: `After ${vars[0]} deliveries or expiry, subscription renews at ${vars[1]}% discount rate.`,
        autoReplenishCheckoutTermsConditions3: 'Item quantity limited to 1.',
        autoReplenishSubTitle: `Save ${vars[0]}% on this item`,
        getItShippedShipMessage: 'Delivery by',
        to: 'to',
        forFreeShipping: 'for FREE shipping',
        getItToday: 'get it today',
        readyToday: 'ready today',
        readyWithinTwoHours: 'Ready within 2 hours',
        readyTomorrow: 'ready tomorrow',
        getItTomorrow: 'Get it tomorrow'
    };

    return resources[label];
}
