export default function getResource(label, vars = []) {
    const resources = {
        title: 'Ajouter des articles Rewards Bazaar®',
        getItShippedSubTitle: `<b>${vars[0]}</b> ajouté pour la livraison`,
        bopisSubTitle: `<b>${vars[0]}</b> ajouté pour le ramassage`,
        omniRewardsNotice: '<b>NOUVEAUTÉ!</b> Vous pouvez maintenant échanger des articles de récompense avec des commandes de livraison le jour même, d’expédition standard et de ramassage*. Sous réserve de la disponibilité des stocks'
    };

    return resources[label];
}
