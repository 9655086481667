export default function getResource(label, vars = []) {
    const resources = {
        emailAddress: 'Email Address',
        asMText: 'If you no longer wish to receive the notification, enter the email address you wish to remove and click “Remove”.',
        asMBText: 'Stop Email Notifications',
        nsMText: 'Sephora will send in stock notification email to:',
        nsMBText: 'Complete',
        empty: 'Please enter your email address.',
        invalid: 'Please enter an e-mail address in the format username@domain.com.',
        enterValidEmail: 'Please enter a valid email address.',
        subscribe: 'Your in stock notification email will be sent to ',
        remove: 'You are no longer scheduled to receive in stock email notification at',
        emailSmsNotifications: 'Email & Text Notifications',
        emailMe: 'Email Notification',
        smsMe: 'Text Notification',
        emailTied: 'We can only send notification to the email tied to your account.',
        BIOnly: '(Sephora Beauty Insiders only)',
        emailLabel: 'Sephora will send in stock notification email to:',
        emailNoLonger: 'If you no longer wish to receive in stock notification click “Stop Email Notifications”',
        needTo: 'You need to ',
        signInLink: 'sign in',
        createAccountLink: 'create an account',
        or: ' or ',
        toSet: ' to set up SMS notification from Sephora.',
        mobileLabel: 'Mobile Phone Number',
        completeButton: 'Complete',
        gotIt: 'Got It',
        signInCapitalize: 'Sign In',
        createAccountCapitalize: 'Create an Account',
        errorMessagesSms: 'You need to agree to the terms & conditions before you can continue.',
        checkboxAgree: 'I choose to sign up to receive in stock text alert notifications on this product​.',
        subscribedByPhone: `Please check your SMS inbox at ${vars[0]} to confirm your in stock subscription and/or your marketing subscription.`,
        errorMsg: 'Something went wrong while trying to process your submission. Please try again later.',
        noValidPhoneMsg: 'Please enter a valid mobile phone number.',
        unsubscribeTitle: `To unsubscribe from in stock notifications at the number below, text “*STOP*” to *${vars[0]}*.`,
        unsubscribeBody:
            `If you are subscribed to receive in stock and or coming soon notifications for multiple products, you will stop receiving all notifications for all the products for which you have subscribed. \n\nOpting out of back in stock or coming soon notifications does not opt you out of marketing text messages. To opt out of marketing text messages, text “*STOP*” to *${vars[0]}*.`,
        termsAndConditionsUS:
            `By entering my phone number and checking the box(es) I selected above, clicking Complete, and confirming my sign-up, I agree to receive back in stock notification text messages and/or marketing text messages as indicated, and I consent to and agree to be bound by Sephora’s [*TEXT TERMS*|${vars[0]}] (including a class action waiver and jury trial waiver), have read Sephora’s [*PRIVACY POLICY*|${vars[1]}], and I expressly consent and agree to receive recurring autodialed marketing text messages, including but not limited to, general marketing texts, abandoned cart reminders and back in stock notifications, from Sephora and/or its service providers, which messages may utilize or be sent using an automated system, an autodialer, an automated system for the selection and/or dialing of telephone numbers, an automatic telephone dialing system, and/or any other type of system, computer, dialer, software, hardware, or machine (no matter how it may be classified) that may use an automated procedure or process for sending messages. Message frequency varies. Consent is not a condition of purchase. Message & data rates may apply. STOP to opt out. HELP for help.`,
        termsAndConditionsCA: `By entering your phone number, clicking Complete, and confirming sign-up, you consent to the [*TEXT TERMS*|${vars[0]}] and to receiving, as applicable (depending on your choices) recurring marketing texts with exclusive offers and product updates (including abandoned cart reminders) and/or in stock notifications. Message frequency varies. Message & data rates may apply. See our [*PRIVACY POLICY*|${vars[1]}]. Text STOP to cancel at any time. HELP for help. Sephora: 600 de Maisonneuve Boulevard West, Suite 2400, Montréal, Québec, H3A 3J2, Canada. 1-877-737-4672.`,
        textAlerts: 'I’d like to also receive Sephora marketing text alerts.'
    };
    return resources[label];
}
