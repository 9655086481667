export default function getResource(label, vars = []) {
    const resources = {
        title: 'Get FREE Same-Day Delivery',
        trialContentStart: 'Start saving with a',
        freeTrial: 'FREE 30-day trial',
        sameDayUnlimited: 'Same Day Unlimited',
        trialContentEnding: 'of Sephora Same-Day Unlimited.',
        subscriptionContentStart: 'Start saving with Sephora Same-Day Unlimited. Subscribe for only ',
        annually: 'annually.',
        tryNowForFree: 'Try Now For Free'
    };

    return resources[label];
}
