/* eslint-disable class-methods-use-this */

import React from 'react';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';
import { Text, Button } from 'components/ui';
import store from 'store/Store';
import Actions from 'Actions';
import processEvent from 'analytics/processEvent';
import anaConsts from 'analytics/constants';
import FormValidator from 'utils/FormValidator';
import ErrorsUtils from 'utils/Errors';
import HelperUtils from 'utils/Helpers';
import anaUtils from 'analytics/utils';
import Modal from 'components/Modal/Modal';
import TextInput from 'components/Inputs/TextInput/TextInput';
import Textarea from 'components/Inputs/Textarea/Textarea';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import ErrorConstants from 'utils/ErrorConstants';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import localeUtils from 'utils/LanguageLocale';
import WaiverSection from 'components/GlobalModals/PaidReservationModal/WaiverSection/WaiverSection';
import withInnerModal from 'components/withInnerModal/withInnerModal';
import MediaPopup from 'components/GlobalModals/MediaPopup/MediaPopup';

class PhoneNumberModal extends BaseClass {
    constructor(props) {
        super(props);
        const phone = store.getState('user').user.phoneNumber;
        const formattedPhone = phone && phone.length > 0 ? FormValidator.getFormattedPhoneNumber(phone) : '';
        const selectedActivity = this.props.activityDetails?.selectedActivity || {};
        const { isVirtual } = selectedActivity;
        this.state = {
            phone,
            formattedPhone,
            smsEnabled: true,
            specialRequests: '',
            waiverInfo: {},
            isReadyToSubmit: (phone && isVirtual) || selectedActivity.type !== ACTIVITY.TYPE.SERVICES
        };

        if (!Sephora.isNodeRender) {
            this.handleSubmit = this.handleSubmit.bind(this);
            this.togglePhoneNumberPrompt = this.togglePhoneNumberPrompt.bind(this);
            this.updateState = this.updateState.bind(this);
            this.updatePhoneState = this.updatePhoneState.bind(this);
            this.updateWaiverInfo = this.updateWaiverInfo.bind(this);
        }

        this.phoneNumberInput = React.createRef();
    }

    componentDidMount() {
        //Analytics
        const activityTitle = this.props.activityDetails.selectedActivity.name;
        const pageDetail = 'book a reservation-phone validation';
        const { analyticsData } = this.props;
        const { context } = analyticsData || {};
        const contextEvent = (context && anaUtils.getLastAsyncPageLoadData({ pageType: context })) || {};

        processEvent.process(anaConsts.ASYNC_PAGE_LOAD, {
            data: {
                pageName: `${anaConsts.PAGE_TYPES.OLR}:${pageDetail}:n/a:*title=${activityTitle}`,
                pageType: anaConsts.PAGE_TYPES.OLR,
                previousPageName: contextEvent.pageName,
                pageDetail
            }
        });
    }

    getMessage = code => {
        const error = ErrorsUtils.getError(code);

        return error && error.message && typeof error.message === 'function'
            ? error.message()
            : typeof error.message === 'string'
                ? error.message
                : '';
    };

    isReadyToSubmit = (phone, waiverInfo) => {
        return (
            phone &&
            (waiverInfo.shouldShow
                ? waiverInfo.prefValue === ACTIVITY.WAIVER.ACKFORCLIENT ||
                  (waiverInfo.prefValue === ACTIVITY.WAIVER.ACKFORCLIENTUNDER18 && waiverInfo.hasParentAccepted)
                : true)
        );
    };

    handleSubmit = e => {
        e.preventDefault();
        const isValidated = this.validateForm();

        if (isValidated) {
            const { waiverInfo, ...phoneDetails } = this.state;
            this.props.reservation(phoneDetails, waiverInfo);
            this.requestClose();
        }

        //Analytics
        const phoneModalLoadEvent = anaUtils.getMostRecentEvent(anaConsts.ASYNC_PAGE_LOAD);
        const pageName = phoneModalLoadEvent.eventInfo && phoneModalLoadEvent.eventInfo.attributes.pageName;
        anaUtils.setNextPageData({ pageName });
    };

    requestClose = () => {
        store.dispatch(Actions.showPhoneNumberModal({ isOpen: false }));
    };

    togglePhoneNumberPrompt = () => {
        const getText = localeUtils.getLocaleResourceFile(
            'components/GlobalModals/EDP/SignInWithAuthenticateModal/PhoneNumberModal/locales',
            'PhoneNumberModal'
        );
        const askPhoneNumberPrompt = getText('enterPhoneNumber');
        const verifyPhoneNumberPromp = getText('verifyNumberPrompt');
        const hasPhoneNumber = this.state.phone && this.state.phone.length > 0;

        return hasPhoneNumber ? verifyPhoneNumberPromp : askPhoneNumberPrompt;
    };

    updateState = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    updatePhoneState = e => {
        const updatedObj = {};
        updatedObj['phone'] = e.target.value.replace(HelperUtils.specialCharacterRegex, '');
        updatedObj['formattedPhone'] = FormValidator.getFormattedPhoneNumber(updatedObj['phone']);
        updatedObj['isReadyToSubmit'] = this.isReadyToSubmit(updatedObj['formattedPhone'], this.state.waiverInfo);
        this.setState(updatedObj);
    };

    updateWaiverInfo = dataToUpdate => {
        this.setState(prevState => {
            const waiverInfo = {
                ...prevState.waiverInfo,
                ...dataToUpdate
            };

            return {
                waiverInfo,
                isReadyToSubmit: this.isReadyToSubmit(prevState.phone, waiverInfo)
            };
        });
    };

    validateForm = () => {
        const errors = FormValidator.getErrors([this.phoneNumberInput]);

        return !errors.fields.length;
    };

    validatePhone = phoneNumber => {
        if (FormValidator.isEmpty(phoneNumber)) {
            return this.getMessage(ErrorConstants.ERROR_CODES.PHONE_NUMBER);
        }

        if (phoneNumber.length !== FormValidator.FIELD_LENGTHS.formattedPhone) {
            return this.getMessage(ErrorConstants.ERROR_CODES.PHONE_NUMBER_INVALID);
        }

        return null;
    };

    handleSmsEnabled = () => this.setState({ smsEnabled: !this.state.smsEnabled });

    render() {
        const getText = localeUtils.getLocaleResourceFile(
            'components/GlobalModals/EDP/SignInWithAuthenticateModal/PhoneNumberModal/locales',
            'PhoneNumberModal'
        );
        const isMobile = Sephora.isMobile();
        const isCanada = localeUtils.isCanada();
        const prompt = this.togglePhoneNumberPrompt();
        const { openModal, innerModal, closeModal } = this.props;
        const { selectedActivity, selectedTimeSlot = {}, storeName, hasSpecialRequests } = this.props.activityDetails;
        const { isReadyToSubmit } = this.state;
        const price = selectedActivity.priceInfo && selectedActivity.priceInfo.listPrice;
        const { isVirtual } = selectedActivity;
        const isMakeupDeluxeService = selectedActivity.activityId === ACTIVITY.MAKEUP_DELUXE_SERVICE_ACTIVITY_ID;
        const isServiceActivity = selectedActivity.type === ACTIVITY.TYPE.SERVICES;

        return (
            <Modal
                isOpen={this.props.isOpen}
                onDismiss={this.requestClose}
                width={2}
                hasBodyScroll={true}
            >
                <Modal.Header>
                    <Modal.Title>{selectedActivity.name}</Modal.Title>
                    {selectedTimeSlot.startDateTime && storeName && (
                        <Text
                            is='p'
                            marginTop={1}
                            lineHeight='tight'
                            fontWeight='bold'
                            fontSize='xs'
                            color='gray'
                            css={{ textTransform: 'uppercase' }}
                            children={ExperienceDetailsUtils.getBookingModalDate(this.props.activityDetails)}
                        />
                    )}
                    {isServiceActivity && price && (
                        <Text
                            is='p'
                            fontSize='base'
                            fontWeight='bold'
                            children={price}
                        />
                    )}
                </Modal.Header>
                <Modal.Body>
                    {innerModal}
                    <Text
                        is='h3'
                        fontSize='md'
                        lineHeight='tight'
                        marginBottom={4}
                        fontWeight='bold'
                        children={prompt}
                    />
                    <TextInput
                        marginBottom={null}
                        name='phone'
                        autoComplete='tel'
                        autoCorrect='off'
                        type='tel'
                        label={getText('phone')}
                        value={this.state.formattedPhone}
                        onKeyDown={!isMobile && FormValidator.inputAcceptOnlyNumbers}
                        required={true}
                        onChange={this.updatePhoneState}
                        maxLength={FormValidator.FIELD_LENGTHS.formattedPhone}
                        ref={this.phoneNumberInput}
                        validate={this.validatePhone}
                    />
                    <Checkbox
                        paddingY={4}
                        checked={this.state.smsEnabled}
                        children={getText('sendMeTexts')}
                        onChange={this.handleSmsEnabled}
                    />
                    {hasSpecialRequests && (
                        <Textarea
                            label={getText('specialRequests')}
                            placeholder={getText('specialRequestsPlaceholder')}
                            rows={5}
                            maxLength={1000}
                            name='specialRequests'
                            onChange={this.updateState}
                            value={this.state.specialRequests}
                        />
                    )}
                    {isServiceActivity && (price || (isMakeupDeluxeService && !isCanada)) && (
                        <React.Fragment>
                            <Text
                                {...ACTIVITY.SUBSECTION_HEADER_PROPS}
                                children={ACTIVITY.PAYMENT_HEADER}
                            />
                            <Text
                                is='p'
                                children={price && !isCanada ? ACTIVITY.PAYMENT_WITH_PRICE_TEXT(price) : ACTIVITY.PAYMENT_TEXT}
                            />
                            {isMakeupDeluxeService && !isCanada && (
                                <Text
                                    is='p'
                                    children={ACTIVITY.PAYMENT_TEXT_MAKEUP_DELUXE}
                                />
                            )}
                            <Text
                                {...ACTIVITY.SUBSECTION_HEADER_PROPS}
                                children={ACTIVITY.ONTIME_HEADER}
                            />
                            <Text
                                is='p'
                                children={ACTIVITY.ONTIME_TEXT}
                            />
                        </React.Fragment>
                    )}
                    <Text
                        is='p'
                        fontSize='sm'
                        color='gray'
                        lineHeight='tight'
                        marginTop={5}
                    >
                        {ExperienceDetailsUtils.getTelephoneUseAuthorization()}
                    </Text>
                    {!isVirtual && isServiceActivity && (
                        <WaiverSection
                            openModal={openModal}
                            closeModal={closeModal}
                            updateWaiverInfo={this.updateWaiverInfo}
                            waiverInfo={this.state.waiverInfo}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={!isReadyToSubmit}
                        width={['100%', 'auto']}
                        hasMinWidth={true}
                        variant='primary'
                        onClick={this.handleSubmit}
                        children={getText('completeBooking')}
                    />
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withInnerModal(wrapComponent(PhoneNumberModal, 'PhoneNumberModal', true), MediaPopup);
