export default function getResource(label, vars = []) {
    const resources = {
        signUp: 'Sign up for Sephora text updates',
        mobile: 'Mobile Phone Number',
        continueBtn: 'Continue',
        emptyError: 'Please enter a mobile phone number.',
        invalidError: 'Please enter a valid mobile phone number.'
    };

    return resources[label];
}
