import ufeApi from 'services/api/ufeApi';
import urlUtils from 'utils/Url';
import headerUtils from 'utils/Headers';

const { userXTimestampHeader } = headerUtils;
// https://jira.sephora.com/wiki/display/ILLUMINATE/Get+Shopping+List

const _makeMultipleRequests = function (requestParamsList) {
    return Promise.all(requestParamsList.map(requestParams => ufeApi.makeRequest(requestParams.url, requestParams.options))).then(values => {
        let promise;

        const errors = values.filter(data => data.errorCode);

        if (errors.length) {
            promise = Promise.reject(errors);
        } else {
            promise = Promise.resolve(values);
        }

        return promise;
    });
};

function addItemsToShoppingList(params) {
    return _makeMultipleRequests(
        params.map(data => {
            const options = {
                skuId: data.skuId,
                source: data.loveSource,
                productId: data.productId
            };

            if (data.isRopisSku) {
                options.fulfillmentType = 'ROPIS';
            }

            return {
                url: '/api/users/profiles/shoppingList',
                options: {
                    method: 'POST',
                    headers: userXTimestampHeader(),
                    body: JSON.stringify(options)
                }
            };
        })
    );
}

function removeItemsFromShoppingList(params, profileId, productId) {
    return _makeMultipleRequests(
        params.map(skuId => {
            let url = '/api/users/profiles/' + profileId + '/shoppingList/' + skuId;

            if (productId) {
                url = url + '?productId=' + productId;
            }

            return {
                url,
                options: { method: 'DELETE' }
            };
        })
    );
}

function getShoppingList(profileId, options = {}) {
    const id = options.isPublicLovesList ? 'current' : profileId;
    let url = '/api/users/profiles/' + id + '/shoppingList';

    const { itemsPerPage, currentPage, sortBy } = options;

    const qsParams = {
        itemsPerPage,
        currentPage,
        sortBy
    };

    if (options.token) {
        qsParams.token = options.token;
    }

    const qs = urlUtils.makeQueryString(qsParams);

    if (qs) {
        url += '?' + qs;
    }

    return ufeApi.makeRequest(url, { method: 'GET' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default {
    addItemsToShoppingList,
    removeItemsFromShoppingList,
    getShoppingList
};
