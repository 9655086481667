import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import FrameworkUtils from 'utils/framework';
import BeautyPreferencesSelector from 'selectors/beautyPreferences/beautyPreferencesSelector';
import Actions from 'Actions';
import BeautyPreferencesActions from 'actions/BeautyPreferencesActions';
import { userSelector } from 'selectors/user/userSelector';

const { showEditBeautyPreferencesModal } = Actions;
const { updateBeautyPreferences } = BeautyPreferencesActions;
const { beautyPreferencesSelector } = BeautyPreferencesSelector;

const { wrapHOC } = FrameworkUtils;

const fields = createSelector(userSelector, beautyPreferencesSelector, (user, beautyPreferences) => {
    return {
        profileId: user.profileId,
        beautyPreferences: beautyPreferences.beautyPreferences
    };
});

const functions = { showEditBeautyPreferencesModal, updateBeautyPreferences };
const withEditBeautyPreferencesModalProps = wrapHOC(connect(fields, functions));

export {
    fields, functions, withEditBeautyPreferencesModalProps
};
