import ufeApi from 'services/api/ufeApi';

const cancelServiceOrEventReservation = (token, { confirmationNumber, type = 'services', channel = 'rwd' }) => {
    const { sdnApiHost } = Sephora.configurationSettings;

    const url = `${sdnApiHost}/v2/happening/${type}/reservations/${confirmationNumber}?channel=${channel}`;

    return ufeApi.makeRequest(url, {
        method: 'DELETE',
        headers: {
            authorization: `Bearer ${token}`
        }
    });
};

export default cancelServiceOrEventReservation;
