import React from 'react';
import PATHS from './paths';
import { Box } from 'components/ui';

const EDPIcon = ({ name, ...svgProps }) => {
    const iconPath = PATHS[name];

    if (!iconPath) {
        return (
            <Box
                width={[24, 32]}
                height={[24, 32]}
            />
        );
    }

    return (
        <Box
            is='svg'
            width={[24, 32]}
            height={[24, 32]}
            viewBox='0 0 32 32'
            fill='none'
            {...svgProps}
        >
            {iconPath}
        </Box>
    );
};

export default EDPIcon;
