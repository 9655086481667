import BaseClass from 'components/BaseClass';
import Interstice from 'components/Interstice/Interstice';
import Preview from 'components/Preview/Preview';
import ReCaptcha from 'components/ReCaptcha/ReCaptcha';
import WelcomePopup from 'components/WelcomePopup';
import PageTemplateType from 'constants/PageTemplateType';
import React from 'react';
import { wrapComponent } from 'utils/framework';
import SmartChat from 'components/SmartChat';

class GlobalContent extends BaseClass {
    render() {
        const { template } = this.props;

        const shouldSeePreview = template !== PageTemplateType.PreviewSettings;

        return (
            <React.Fragment>
                <ReCaptcha />

                <Interstice />

                <WelcomePopup />

                {SmartChat !== null && <SmartChat />}

                {!!shouldSeePreview && <Preview />}
            </React.Fragment>
        );
    }
}

export default wrapComponent(GlobalContent, 'GlobalContent');
