const resources = {
    new: 'nouveautés',
    signIn: 'Ouvrir une session',
    signInToAdd: ' pour ajouter ou afficher vos préférences beauté.',
    infoModalTitle: 'Préférences beauté',
    infoModalMessage: 'Magasinez avec des filtres personnalisés selon vos ',
    infoModalMessageEndLink: 'préférences beauté.',
    gotIt: 'Compris',
    applyFilters: 'Appliquez des filtres pour définir vos Préférences Beauté.',
    selectAll: 'Select All',
    deselectAll: 'Deselect All'
};

export default function getResource(label) {
    return resources[label];
}
