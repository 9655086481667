const IMAGE_SIZES = {
    SMALL: 315,
    LARGE: 465,
    ZOOM: 1500
};

const SHADOW = ['light', null, 'none'];

const THUMB_COUNT = 8;

export {
    IMAGE_SIZES, SHADOW, THUMB_COUNT
};
