export default function getResource(label, vars = []) {
    const resources = {
        title: 'Appliquer vos points',
        apply: 'Appliquer',
        applied: 'Appliqué',
        remove: 'Retirer',
        amountApplied: `<b>${vars[0]}</b> appliqué`,
        pointsAppliedLegal: 'Les points échangés contre des dollars Beauty Insider ne sont pas remboursables.',
        multiplePromoSubTitle: `Jusqu’à <b>${vars[0]}</b> disponibles`,
        singlePromoSubTitle: `<b>${vars[0]}de réduction</b> (${vars[1]} points)`,
        modalTitle: 'Appliquer vos points à votre achat',
        modalSubTitle: `Vous avez actuellement <b>${vars[0]}</b> points`,
        done: 'Terminé',
        biCashAmount: `<b>${vars[0]}</b> Dollars Beauty Insider`,
        pointsAmount: `${vars[0]} points`,
        subtotal: 'Sous-total de la commande',
        chooseText: 'Veuillez choisir une réponse :'
    };

    return resources[label];
}
