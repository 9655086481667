import React from 'react';

const PATHS = {
    cancellationpolicy: (
        <g fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.99967 1.3335C9.63148 1.3335 9.33301 1.63197 9.33301 2.00016V3.25928H3.33301C2.22844 3.25928 1.33301 4.15471 1.33301 5.25928V23.3334C1.33301 24.438 2.22844 25.3334 3.33301 25.3334H8.29701C8.1981 24.8975 8.12292 24.4526 8.0729 24.0001H3.33301C2.96482 24.0001 2.66634 23.7016 2.66634 23.3334V9.11257H23.9953V11.3481C24.4531 11.5097 24.8982 11.6983 25.3286 11.912V5.25928C25.3286 4.15471 24.4332 3.25928 23.3286 3.25928H17.333V2.00016C17.333 1.63197 17.0345 1.3335 16.6663 1.3335C16.2982 1.3335 15.9997 1.63197 15.9997 2.00016V3.25928H10.6663V2.00016C10.6663 1.63197 10.3679 1.3335 9.99967 1.3335ZM9.33301 4.59261V6.00016C9.33301 6.36835 9.63148 6.66683 9.99967 6.66683C10.3679 6.66683 10.6663 6.36835 10.6663 6.00016V4.59261H15.9997V6.00016C15.9997 6.36835 16.2982 6.66683 16.6663 6.66683C17.0345 6.66683 17.333 6.36835 17.333 6.00016V4.59261H23.3286C23.6968 4.59261 23.9953 4.89109 23.9953 5.25928V7.81527H2.66634V5.25928C2.66634 4.89109 2.96482 4.59261 3.33301 4.59261H9.33301ZM21.7569 13.8463C21.0004 12.4497 18.9962 12.4497 18.2397 13.8463L11.145 26.9443C10.4232 28.2769 11.3881 29.8968 12.9036 29.8968H27.0931C28.6086 29.8968 29.5735 28.2769 28.8517 26.9443L21.7569 13.8463ZM19.4121 14.4813C19.6643 14.0158 20.3324 14.0158 20.5845 14.4813L27.6793 27.5793C27.9199 28.0235 27.5983 28.5635 27.0931 28.5635H12.9036C12.3984 28.5635 12.0768 28.0235 12.3174 27.5793L19.4121 14.4813ZM19.3832 18.8036C19.2147 18.9902 19.1214 19.2141 19.1404 19.4569C19.1221 19.4941 19.1221 19.5313 19.1221 19.5685L19.7193 24.8142C19.7383 24.9447 19.8688 25.0942 19.9993 25.0942C20.1488 25.0942 20.2793 24.9819 20.2983 24.8149L20.8583 19.5692V19.5131C20.8955 19.2513 20.8021 18.9902 20.6155 18.8036C20.2793 18.4674 19.7193 18.4674 19.3832 18.8036ZM20.8765 26.6604C20.8765 27.8307 19.1221 27.8307 19.1221 26.6604C19.1221 25.4908 20.8765 25.4908 20.8765 26.6604Z'
                fill='black'
            />
        </g>
    ),
    ontimepolicy: (
        <g fill='none'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.0001 28.3337C22.8116 28.3337 28.3334 22.8118 28.3334 16.0003C28.3334 9.18881 22.8116 3.66699 16.0001 3.66699C9.18857 3.66699 3.66675 9.18881 3.66675 16.0003C3.66675 22.8118 9.18857 28.3337 16.0001 28.3337ZM16.0001 29.3337C23.3639 29.3337 29.3334 23.3641 29.3334 16.0003C29.3334 8.63653 23.3639 2.66699 16.0001 2.66699C8.63628 2.66699 2.66675 8.63653 2.66675 16.0003C2.66675 23.3641 8.63628 29.3337 16.0001 29.3337Z'
                fill='black'
            />
            <path
                d='M16 8V17.3333L21.3333 22'
                stroke='black'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
    ),
    giftcardredemption: (
        <g fill='none'>
            <g clipPath='url(#clip0_9078_6169)'>
                <rect
                    width='32'
                    height='20'
                    rx='4'
                    fill='url(#paint0_linear_9078_6169)'
                />
                <mask
                    id='mask0_9078_6169'
                    // style='mask-type:luminance'
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='32'
                    height='20'
                >
                    <rect
                        width='32'
                        height='20'
                        rx='4'
                        fill='white'
                    />
                </mask>
                <g mask='url(#mask0_9078_6169)'>
                    <rect
                        y='-1.02588'
                        width='32'
                        height='4.52459'
                        fill='black'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M0 7.33545H32V12.5664H0V7.33545Z'
                        fill='black'
                    />
                    <rect
                        y='16.4849'
                        width='32'
                        height='4.19672'
                        fill='black'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.497 9.24717C10.497 9.24717 10.4917 9.26283 10.4168 9.47151C10.2027 9.29645 9.84404 9.30926 9.84404 9.57852C9.84404 9.89437 10.5932 9.85469 10.5699 10.4186C10.5512 10.8713 10.0087 10.9752 9.59009 10.7571C9.6267 10.6773 9.66472 10.6004 9.69066 10.5375C10.0071 10.7297 10.2797 10.6415 10.3244 10.4869C10.4468 10.0639 9.59346 10.1945 9.59346 9.59463C9.59346 9.34233 9.87784 8.95009 10.497 9.24717Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.3625 9.1748H12.4093C12.4093 9.1748 12.4063 9.26541 12.4083 9.39439H11.6247V9.84451H12.1805C12.1785 9.93422 12.1795 10.0047 12.1795 10.051H11.6247V10.6121H12.4083C12.4078 10.6556 12.4075 10.7869 12.4083 10.8276H11.3636L11.3625 9.1748Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M13.747 9.98427C13.6843 9.98427 13.6379 9.98351 13.5742 9.98326C13.5743 9.67473 13.5749 9.38625 13.5749 9.38625C13.5749 9.38625 13.6507 9.38595 13.7224 9.38595C13.7909 9.38595 14.1903 9.35583 14.2032 9.66437C14.2158 9.96861 13.9015 9.98427 13.747 9.98427ZM13.7625 9.17703C13.6703 9.17488 13.4775 9.17383 13.313 9.17383L13.3136 10.8275H13.5751C13.5751 10.8275 13.5743 10.5118 13.5742 10.2006C13.6391 10.1996 13.7737 10.196 13.8824 10.193C14.0332 10.1887 14.4294 10.1157 14.4267 9.66222C14.4235 9.15252 13.8541 9.17903 13.7625 9.17703Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M20.1786 9.94064C20.1193 9.94064 20.0766 9.93949 20.0165 9.93909C20.0167 9.64907 20.0174 9.38185 20.0174 9.38185C20.0174 9.38185 20.0868 9.3809 20.154 9.3809C20.2183 9.3809 20.5616 9.36159 20.5907 9.63061C20.6252 9.9484 20.2487 9.94064 20.1786 9.94064ZM20.7374 10.8274H21.053L20.5291 10.0866C20.6701 10.0349 20.8336 9.91507 20.813 9.64116C20.7761 9.15046 20.2947 9.18683 20.1917 9.18208C20.1048 9.17798 19.918 9.17562 19.7559 9.17643V10.8275H20.0174C20.0174 10.8275 20.0166 10.458 20.0164 10.1315C20.103 10.1318 20.1341 10.1302 20.2647 10.1268L20.7374 10.8274Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M16.2684 9.83339V9.18359H16.5235V10.8334H16.2684V10.0491L15.4179 10.0503V10.8334H15.1628V9.18359H15.4179V9.83339H16.2684Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M18.136 9.13135C17.6067 9.13135 17.2621 9.52198 17.2621 10.0037C17.2621 10.4857 17.6067 10.8762 18.136 10.8762C18.6654 10.8762 19.01 10.4857 19.01 10.0037C19.01 9.52198 18.6654 9.13135 18.136 9.13135ZM18.136 9.3688C17.7479 9.3688 17.5275 9.65307 17.5275 10.0037C17.5275 10.3544 17.7458 10.6388 18.136 10.6388C18.5262 10.6388 18.7445 10.3544 18.7445 10.0037C18.7445 9.65307 18.5241 9.3688 18.136 9.3688ZM22.1105 10.2602L22.6521 10.2568L22.3769 9.44178H22.3733L22.1105 10.2602ZM22.2294 9.17932H22.5307L23.1272 10.8367L22.8605 10.836C22.8605 10.836 22.8016 10.6688 22.7229 10.4473L22.0501 10.4515C22.0501 10.4515 22.0231 10.5368 21.943 10.8361H21.6858L22.2294 9.17932Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.497 9.24717C10.497 9.24717 10.4917 9.26283 10.4168 9.47151C10.2027 9.29645 9.84404 9.30926 9.84404 9.57852C9.84404 9.89437 10.5932 9.85469 10.5699 10.4186C10.5512 10.8713 10.0087 10.9752 9.59009 10.7571C9.6267 10.6773 9.66472 10.6004 9.69066 10.5375C10.0071 10.7297 10.2797 10.6415 10.3244 10.4869C10.4468 10.0639 9.59346 10.1945 9.59346 9.59463C9.59346 9.34233 9.87784 8.95009 10.497 9.24717Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.3625 9.1748H12.4093C12.4093 9.1748 12.4063 9.26541 12.4083 9.39439H11.6247V9.84451H12.1805C12.1785 9.93422 12.1795 10.0047 12.1795 10.051H11.6247V10.6121H12.4083C12.4078 10.6556 12.4075 10.7869 12.4083 10.8276H11.3636L11.3625 9.1748Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M13.747 9.98427C13.6843 9.98427 13.6379 9.98351 13.5742 9.98326C13.5743 9.67473 13.5749 9.38625 13.5749 9.38625C13.5749 9.38625 13.6507 9.38595 13.7224 9.38595C13.7909 9.38595 14.1903 9.35583 14.2032 9.66437C14.2158 9.96861 13.9015 9.98427 13.747 9.98427ZM13.7625 9.17703C13.6703 9.17488 13.4775 9.17383 13.313 9.17383L13.3136 10.8275H13.5751C13.5751 10.8275 13.5743 10.5118 13.5742 10.2006C13.6391 10.1996 13.7737 10.196 13.8824 10.193C14.0332 10.1887 14.4294 10.1157 14.4267 9.66222C14.4235 9.15252 13.8541 9.17903 13.7625 9.17703Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M20.1786 9.94064C20.1193 9.94064 20.0766 9.93949 20.0165 9.93909C20.0167 9.64907 20.0174 9.38185 20.0174 9.38185C20.0174 9.38185 20.0868 9.3809 20.154 9.3809C20.2183 9.3809 20.5616 9.36159 20.5907 9.63061C20.6252 9.9484 20.2487 9.94064 20.1786 9.94064ZM20.7374 10.8274H21.053L20.5291 10.0866C20.6701 10.0349 20.8336 9.91507 20.813 9.64116C20.7761 9.15046 20.2947 9.18683 20.1917 9.18208C20.1048 9.17798 19.918 9.17562 19.7559 9.17643V10.8275H20.0174C20.0174 10.8275 20.0166 10.458 20.0164 10.1315C20.103 10.1318 20.1341 10.1302 20.2647 10.1268L20.7374 10.8274Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M16.2684 9.83339V9.18359H16.5235V10.8334H16.2684V10.0491L15.4179 10.0503V10.8334H15.1628V9.18359H15.4179V9.83339H16.2684Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M18.136 9.13135C17.6067 9.13135 17.2621 9.52198 17.2621 10.0037C17.2621 10.4857 17.6067 10.8762 18.136 10.8762C18.6654 10.8762 19.01 10.4857 19.01 10.0037C19.01 9.52198 18.6654 9.13135 18.136 9.13135ZM18.136 9.3688C17.7479 9.3688 17.5275 9.65307 17.5275 10.0037C17.5275 10.3544 17.7458 10.6388 18.136 10.6388C18.5262 10.6388 18.7445 10.3544 18.7445 10.0037C18.7445 9.65307 18.5241 9.3688 18.136 9.3688ZM22.1105 10.2602L22.6521 10.2568L22.3769 9.44178H22.3733L22.1105 10.2602ZM22.2294 9.17932H22.5307L23.1272 10.8367L22.8605 10.836C22.8605 10.836 22.8016 10.6688 22.7229 10.4473L22.0501 10.4515C22.0501 10.4515 22.0231 10.5368 21.943 10.8361H21.6858L22.2294 9.17932Z'
                        fill='white'
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_9078_6169'
                    x1='-14.8856'
                    y1='10.2206'
                    x2='2.92371'
                    y2='37.9448'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#FAFAFA' />
                    <stop
                        offset='1'
                        stopColor='#F2F2F2'
                    />
                </linearGradient>
                <clipPath id='clip0_9078_6169'>
                    <rect
                        width='32'
                        height='20'
                        fill='white'
                    />
                </clipPath>
            </defs>
        </g>
    ),
    pactxsephora: (
        <g fill='none'>
            <g clipPath='url(#clip0_9078_6210)'>
                <path
                    d='M0 17.5967C0 17.5967 6.56607 22.4806 8.03125 26.9304C8.03125 26.9304 17.7447 10.8136 31.5281 5.87549'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M31.5301 5.87565C7.38209 0.0149993 10.638 18.0853 10.638 18.0853'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M14.4884 22.0465C14.4884 22.0465 28.4346 26.0622 31.5277 5.87549'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M16.8303 5.73438L18.6578 14.1243'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M29.0382 14.3994L21.1708 11.9395'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M23.618 20.6562L15.7646 16.9985'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_9078_6210'>
                    <rect
                        width='32'
                        height='32'
                        fill='white'
                    />
                </clipPath>
            </defs>
        </g>
    )
};

export default PATHS;
