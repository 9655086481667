export default function getResource(label, vars = []) {
    const resources = {
        today: 'Today',
        emailAddressLabel: 'Email Address',
        eventCheckIn: 'Event Check In',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        checkInButton: 'Check In',
        byClicking: 'By clicking “Check In”, you (1) agree to the current version of our ',
        termsOfUse: 'TERMS OF USE',
        andHaveRead: ', and (2) have read Sephora’s ',
        privacyPolicy: 'Privacy Policy',
        enterEmailEmptyErrorMessage: 'Please enter your e-mail address.',
        enterEmailFormatErrorMessage: 'Please enter an e-mail address in the format username@domain.com.',
        enterFirstNameErrorMessage: 'Please enter your first name.',
        enterLastNameErrorMessage: 'Please enter your last name.',
        youAreCheckedInHeader: 'You’re checked in!',
        youAreCheckedInHeaderGreenMessage: 'You are checked-in',
        getMoreFromSephoraHeader: 'Get More From Sephora',
        downloadAppText: 'Download our full App and become a Beauty Insider. Earn points, redeem awards, and claim a birthday gift!',
        downloadAppBtn: 'Download our App',
        whatToExpectHeader: 'What To Expect At Events',
        whatToExpectText: 'Here’s what to expect during Sephora events.',
        participantsHeader: 'Participants',
        participantsText: 'Our events typically accommodate 10-30 guests. Guests can RSVP or walk-in. Events can last 1-4 hours. We hope you will join us!',
        costHeader: 'Cost',
        costText: 'Our events are FREE to our clients! We encourage you to RSVP to as many events as you want so we can remind you 24 hours before to attend.',
        valueHeader: 'Value',
        valueText: 'We hope you learn something new by attending our events. We also provide incentives and gifts with purchase if you purchase a product at one of our events.',
        errorTitle: 'Unable to check in',
        errorBody: 'We apologize for the inconvenience, please connect with Brand Representative for next steps.',
        errorFooter: 'Ok',
        showMore: 'Show more',
        showLess: 'Show less'
    };

    return resources[label];
}
