export default function getResource(label, vars = []) {
    const resources = {
        availabletimes: 'Available times',
        noTimesAvailable: 'No times available',
        for: 'for',
        waitlist: 'Waitlist',
        availableArtists: 'Available artists',
        anyArtist: 'Any Available Artist',
        noArtistsAvailable: 'No Artist Available'
    };

    return resources[label];
}
