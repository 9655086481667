export default function getResource(label, vars = []) {
    const resources = {
        merchandiseSubtotalText: 'Sous-total',
        pointsUsed: 'Points utilisés',
        discountsText: 'Réductions',
        free: 'GRATUIT',
        tbdText: 'À déterminer',
        shippingAndHandlingText: 'Expédition et manutention',
        autoReplenishSavings: 'Économies sur le réapprovisionnement automatique',
        gstHstText: 'TPS/TVH estimées',
        taxText: 'Taxes estimées',
        pickup: 'Ramassage',
        bagFee: 'Frais du sac de ramassage'
    };

    return resources[label];
}
