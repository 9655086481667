import React from 'react';
import PropTypes from 'prop-types';
import { wrapComponent } from 'utils/framework';
import BaseClass from 'components/BaseClass';

let Events;

class RenderInViewPort extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            viewed: false
        };
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        this.handleScroll();
        import('utils/framework/Events').then(module => {
            Events = module.default;
            window.addEventListener(Events.DebouncedScroll, this.handleScroll);
            window.addEventListener(Events.DebouncedResize, this.handleScroll);
        });
    }

    componentWillUnmount() {
        window.removeEventListener(Events.DebouncedScroll, this.handleScroll);
        window.removeEventListener(Events.DebouncedResize, this.handleScroll);
    }

    isInViewport = () => {
        if (this.wrapperRef.current === null) {
            return false;
        }

        const rect = this.wrapperRef.current.getBoundingClientRect();
        const refHeight = this.wrapperRef.current.offsetHeight;
        const refWidth = this.wrapperRef.current.offsetWidth;
        const isInViewport =
            rect.top >= -refHeight &&
            rect.left >= -refWidth &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) + refWidth &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + refHeight;

        return isInViewport;
    };

    handleScroll = () => {
        const { viewed } = this.state;
        const isInViewport = this.isInViewport();

        if (!viewed && isInViewport) {
            this.setState({ viewed: isInViewport });
        }
    };

    resetView = () => {
        const isInViewport = this.isInViewport();
        this.setState({ viewed: isInViewport });
    };

    render() {
        const { viewed } = this.state;
        const { children } = this.props;

        return (
            <div ref={this.wrapperRef}>
                {viewed ? React.Children.map(children, child => React.cloneElement(child, { onUpdate: this.resetView })) : null}
            </div>
        );
    }
}

RenderInViewPort.propTypes = {
    children: PropTypes.node.isRequired
};

export default wrapComponent(RenderInViewPort, 'RenderInViewPort', true);
