const resources = {
    greeting: 'Bonjour',
    signIn: 'Ouvrir une session',
    signInPrompt: 'pour profiter de l’expédition GRATUITE',
    biHeading: 'Résumé Beauty Insider',
    biDesc: 'Voir l’activité, les économies et les avantages',
    joinNow: 'S’inscrire',
    rewardsHeading: 'Rewards Bazaar',
    rewardsDesc: 'Réclamez des articles, des échantillons et plus',
    pointsLabel: 'Point',
    chooseBirthdayGift: 'Choisissez votre cadeau d’anniversaire!',
    ccHeading: 'Programme de carte de crédit Sephora',
    ccDesc: 'Consulter et gérer les cartes de crédit et les récompenses',
    ccRewardsLabel: 'Récompenses',
    ccApplyNow: 'S’inscrire',
    ordersHeading: 'Commandes',
    ordersDesc: 'Consulter et faire le suivi des commandes en ligne ou à ramasser',
    autoReplenishHeading: 'Réapprovisionnement automatique',
    autoReplenishDescWithSubs: 'Afficher et gérer vos abonnements',
    autoReplenishDesc: 'Ne manquez jamais vos essentiels préférés avec l’abonnement pour la livraison',
    purchasesHeading: 'Acheter de nouveau',
    purchasesDesc: 'Commandez de nouveau les achats en magasin et en ligne',
    lovesHeading: 'Favoris',
    lovesDesc: 'Voir les produits conservés',
    recHeading: 'Recommandations des conseillers beauté',
    recDesc: 'Recommandations selon vos visites en magasin',
    reservationsHeading: 'Réservations',
    reservationsDesc: 'Gérez vos services et vos événements',
    beautyPrefHeading: 'Préférences beauté',
    beautyPrefDesc: 'Remplir pour des recommandations personnalisées',
    guidedSellingBeautyPrefDesc: 'Remplir pour des recommandations et des filtres personnalisés',
    accountHeading: 'Paramètres du compte',
    accountDesc: 'Paiement, coordonnées, adresses, mot de passe',
    signOut: 'Se déconnecter',
    sameDayUnlimitedHeading: 'Livraison le jour même illimitée',
    sameDayUnlimitedMemberDesc: 'Gérer votre abonnement',
    sameDayUnlimitedNonMemberDesc: 'Obtenez la livraison gratuite le jour même illimitée',
    subscribeToday: 'S’inscrire aujourd’hui',
    createAccount: 'Créer un compte',
    noAccount: 'Vous n’avez pas de compte?',
    signInForShipping: 'Ouvrez une session pour profiter de *l’expédition GRATUITE*!',
    helloBeautiful: 'Salut, beauté!',
    beautyChallenges: 'Défis Beauty Insider',
    new: 'NOUVEAUTÉ',
    beautyChallengesDescription: 'Accumulez des points en accomplissant des tâches'
};

export default function getResource(label) {
    return resources[label];
}
