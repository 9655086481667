export default function getResource(label, vars = []) {
    const resources = {
        bookAnAppointment: 'Book an Appointment',
        yourService: 'Your Services',
        reviewAndPay: 'Review & Pay',
        confirmAppointmentDetails: 'Confirm Appointment Details',
        free: 'FREE'
    };

    return resources[label];
}
