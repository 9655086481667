import React from 'react';
import viewModes from 'components/GlobalModals/PaidReservationModal/PaymentSection/viewModes';
import {
    Grid, Text, Link, Icon
} from 'components/ui';
import { withModalContext } from 'components/GlobalModals/PaidReservationModal/modalContext';
import BaseClass from 'components/BaseClass';
import CVCInfoModal from 'components/Checkout/Sections/Payment/Section/CVCInfoModal/CVCInfoModal';

import PropTypes from 'prop-types';
import Checkbox from 'components/Inputs/Checkbox/Checkbox';
import MessageBox from 'components/MessageBox';
import withPaymentSectionContentViewModel from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/withPaymentSectionContentViewModel';
import { wrapComponent } from 'utils/framework';
import ErrorMsg from 'components/ErrorMsg';
import PaymentSectionContentComponent from 'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent';

const PaymentSectionContent = withModalContext(withPaymentSectionContentViewModel(PaymentSectionContentComponent));

const { DEFAULT, SELECT } = viewModes;

class PaymentSection extends BaseClass {
    onPaymentChange = (checked = false) => {
        const { updateIsPaymentEnable } = this.props;
        updateIsPaymentEnable(checked);
    };

    state = {
        errorMessage: null,
        viewMode: DEFAULT
    };

    render() {
        const { getText, paymentEnable, paymentError, setPaymentStatus } = this.props;
        const { errorMessage, viewMode } = this.state;

        return (
            <div data-at={Sephora.debug.dataAt('payment_section')}>
                <Grid
                    columns='1fr auto'
                    alignItems='baseline'
                    lineHeight='tight'
                >
                    <Text
                        is='h2'
                        fontSize='md'
                        fontWeight='bold'
                        data-at={Sephora.debug.dataAt('payment_method_title')}
                    >
                        {getText('paymentMethod')}
                    </Text>
                    {viewMode === DEFAULT && (
                        <Link
                            padding={2}
                            margin={-2}
                            color='blue'
                            onClick={() => this.setState({ viewMode: SELECT })}
                            data-at={Sephora.debug.dataAt('payment_edit_btn')}
                        >
                            {getText('edit')}
                        </Link>
                    )}
                </Grid>
                <MessageBox
                    marginY={4}
                    data-at={Sephora.debug.dataAt('payment_method_subtitle')}
                    children={getText('creditCardRequiredInfo')}
                />
                {(errorMessage || paymentError) && (
                    <ErrorMsg
                        fontSize={null}
                        data-at={Sephora.debug.dataAt('payment_error_message')}
                        display='flex'
                    >
                        <Icon
                            name='alert'
                            marginRight={2}
                            size={18}
                        />
                        <span css={{ flex: 1 }}>{errorMessage || paymentError}</span>
                    </ErrorMsg>
                )}
                <PaymentSectionContent
                    getText={getText}
                    setErrorMessage={message => this.setState({ errorMessage: message })}
                    setPaymentError={setPaymentStatus}
                    setViewMode={this.setViewMode}
                    viewMode={viewMode}
                />
                <Checkbox
                    paddingY={4}
                    key={Math.random()}
                    checked={paymentEnable}
                    onChange={e => this.onPaymentChange(e.target.checked)}
                >
                    {getText('placingOder')}
                </Checkbox>
                <CVCInfoModal />
            </div>
        );
    }

    setViewMode = viewMode => this.setState({ viewMode });
}

PaymentSection.defaultProps = {};
PaymentSection.propTypes = { getText: PropTypes.func.isRequired };

export default wrapComponent(PaymentSection, 'PaymentSection');
