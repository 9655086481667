/* eslint-disable class-methods-use-this */
import React from 'react';
import { Text, Divider } from 'components/ui';
import ChatLink from 'components/SmartChat/ChatLink/ChatLink';
import PropTypes from 'prop-types';

import { wrapFunctionalComponent } from 'utils/framework';

const CloseAccountSuccessful = ({ localization }) => {
    return (
        <>
            <Text
                is='p'
                fontWeight='bold'
                marginBottom={4}
            >
                {localization.accountClosed}
            </Text>
            <Text
                is='p'
                marginBottom={4}
            >
                {localization.loggedOut}
            </Text>
            <Text is='p'>{localization.message}</Text>
            <Divider marginY={4} />
            <ChatLink
                variant={'link'}
                ctaText='Chat'
            />
            <Divider marginTop={4} />
        </>
    );
};

CloseAccountSuccessful.propTypes = {
    localization: PropTypes.object.isRequired
};

export default wrapFunctionalComponent(CloseAccountSuccessful, 'CloseAccountSuccessful');
