export default function getResource(label, vars = []) {
    const resources = {
        add: 'Add',
        buyOnlinePickUpTitle: `Buy Online & Pick Up (${vars[0]}) `,
        storeDetails: 'Store Details',
        bopisInfoTitle: 'Buy Online & Pick Up in Store'
    };

    return resources[label];
}
