export default function getResource(label, vars = []) {
    const resources = {
        reservationDetails: 'Reservation Details',
        pickupLocation: 'Pickup Location',
        contactInformation: 'Contact Information',
        storeDetails: 'Store Details'
    };

    return resources[label];
}
