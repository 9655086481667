export default function getResource(label, vars = []) {
    const resources = {
        title: 'Add Rewards Bazaar Items',
        getItShippedSubTitle: `<b>${vars[0]}</b> added for Get It Shipped`,
        bopisSubTitle: `<b>${vars[0]}</b> added for Pickup`,
        omniRewardsNotice: '<b>NEW!</b> You can now redeem reward items with Same-Day Delivery, Standard Shipping and Pickup orders*. Subject to inventory availability'
    };

    return resources[label];
}
