import ufeApi from 'services/api/ufeApi';

const getActivityEDPContent = (token, {
    language, country, activityType, activityId, channel = 'rwd', zipCode, storeId, email
}) => {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const locale = `${language.toLowerCase()}-${country}`;
    const userEmail = email ? `&userEmail=${email}` : '';
    const eventsEDPParams = activityType === 'events' ? `&zipCode=${zipCode}&storeId=${storeId}${userEmail}` : '';

    const url = `${sdnDomain}/v2/happening/${activityType}/${activityId}?channel=${channel}&locale=${locale}&country=${country}${eventsEDPParams}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: { 'x-requested-source': 'rwd', authorization: `Bearer ${token}` }
    });
};

export default getActivityEDPContent;
