import ufeApi from 'services/api/ufeApi';
import RCPSCookies from 'utils/RCPSCookies';
import UserUtils from 'utils/User';
import accessTokenApi from 'services/api/accessToken/accessToken';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Switch+Country+API
const accessToken = 'AUTH_ACCESS_TOKEN';

function switchCountry(_jwtAccesstoken, switchToCountry, languageCode) {
    let url = '/api/users/profile/switchCountry';
    const isUserAnonymous = UserUtils.isAnonymous();
    const isRCPSFullProfileGroup = RCPSCookies.isRCPSFullProfileGroup();

    if (isRCPSFullProfileGroup && !isUserAnonymous) {
        url = '/gway/v2/users/profile/switchCountry';
    }

    return ufeApi
        .makeRequest(url, {
            method: 'PUT',
            body: JSON.stringify({
                switchToCountry,
                languageCode
            })
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data))
        .catch(err => Promise.reject(err));
}

export default accessTokenApi.withAccessToken(switchCountry, accessToken);
