export default function getResource(label, vars = []) {
    const resources = {
        add: 'Ajouter',
        buyOnlinePickUpTitle: `Achat en ligne et ramassage (${vars[0]}) `,
        storeDetails: 'Détails du magasin',
        bopisInfoTitle: 'Achetez en ligne et ramassez en magasin'
    };

    return resources[label];
}
