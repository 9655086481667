/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import {
    Box, Flex, Divider, Text
} from 'components/ui';
import LegacyGrid from 'components/LegacyGrid/LegacyGrid';
import Loader from 'components/Loader/Loader';
import LegacyContainer from 'components/LegacyContainer/LegacyContainer';
import IconShare from 'components/LegacyIcon/IconShare';
import SimpleBreadCrumbs from 'components/SimpleBreadCrumbs/SimpleBreadCrumbs';
import BookWidget from 'components/OnlineReservation/ExperienceDetailPage/BookWidget/BookWidget';
import RsvpWidget from 'components/OnlineReservation/ExperienceDetailPage/RsvpWidget/RsvpWidget';
import ExperienceImage from 'components/OnlineReservation/ExperienceImage/ExperienceImage';
import GoogleMap from 'components/GoogleMap/GoogleMap';
import Tooltip from 'components/Tooltip/Tooltip';
import Html from 'components/Html/Html';
import InfoButton from 'components/InfoButton/InfoButton';
import ExperienceDetailsUtils from 'utils/ExperienceDetails';
import ACTIVITY from 'components/OnlineReservation/activityConstants';
import storeUtils from 'utils/Store';
import stringUtils from 'utils/String';
import localeUtils from 'utils/LanguageLocale';
import Empty from 'constants/empty';
import store from 'store/Store';
import Storage from 'utils/localStorage/Storage';
import ExperienceDetailsActions from 'actions/ExperienceDetailsActions';
import decorators from 'utils/decorators';
import locationUtils from 'utils/Location';
import urlUtils from 'utils/Url';
import dateUtils from 'utils/Date';
import anaConsts from 'analytics/constants';
import scriptUtils from 'utils/LoadScripts';
import userLocation from 'utils/userLocation/UserLocation';
import LOCAL_STORAGE from 'utils/localStorage/Constants';
import olrApi from 'services/api/sdn';

let readyForAnalytics = () => {};

export const renderActivityPolicies = (activityPolicies, priceInfo, isModal = false) => {
    const isCanada = localeUtils.isCanada();
    let filterActivityPolicies = (activityPolicies || Empty.Array).map(activity => {
        if (activity.key === 'paymentPolicy') {
            activity.policyDesc = activity?.policyDesc?.replaceAll('[BCC-ID]', priceInfo?.paymentPolicyFee || '');
        }

        if (activity.key === 'cancellationPolicy') {
            activity.policyDesc = activity?.policyDesc?.replace('[BCC-ID]', priceInfo?.lateCancellationCharge || '');
        }

        if (activity.key === 'onTimePolicy') {
            activity.policyDesc = isCanada
                ? activity?.policyDesc?.replace('of [BCC-ID]', '')
                : activity?.policyDesc?.replace('[BCC-ID]', priceInfo?.noShowCharge || '');
        }

        return activity;
    });

    if (isModal && filterActivityPolicies?.length > 0) {
        const payment = filterActivityPolicies?.find(item => item.key === 'paymentPolicy');
        filterActivityPolicies = filterActivityPolicies?.filter(item => item.key !== 'paymentPolicy');
        filterActivityPolicies?.unshift(payment);
    }

    return filterActivityPolicies.map(function buildPolicies({ policyDisplayName, policyDesc: description, derivedId }) {
        return description ? (
            <React.Fragment key={derivedId}>
                <Text
                    {...ACTIVITY.SUBSECTION_HEADER_PROPS}
                    data-at={Sephora.debug.dataAt(`${derivedId}_title`)}
                    children={policyDisplayName}
                />
                <Html
                    data-at={Sephora.debug.dataAt(`${derivedId}_text`)}
                    content={description}
                />
            </React.Fragment>
        ) : null;
    });
};

class ExperienceInfo extends BaseClass {
    state = {
        isLoaded: false,
        selectedDay: null,
        activities: [],
        questions: [],
        storeList: [],
        location: {},
        noStoreLocation: null,
        startDateParam: null,
        activityPolicies: null
    };

    componentDidMount() {
        //need to assure that google script is loaded before any
        //google functionality is used (geolocator, place prediction, map)

        const activity = this.getActivityInitialState();

        this.updateSeoTags(activity);
        this.loadActivityPolicies(activity);
        this.loadGoogleScript(() => {
            activity
                ? this.setState(
                    {
                        activity,
                        startDateParam: ExperienceDetailsUtils.getStartDateFromParam()
                    },
                    () => {
                        this.getExperienceInfoByLocation();
                        this.setupExperienceListener();
                    }
                )
                : ExperienceDetailsUtils.redirectToHub();
        });

        //Analytics, wait for the experience data to fire page load analytics
        Sephora.analytics.initialLoadDependencies.push(
            new Promise(resolve => {
                readyForAnalytics = resolve;
            })
        );
    }

    componentDidUpdate(prevProps) {
        const isPreferredStoreChanged = this.checkPreferredStoreChange(prevProps);

        if (isPreferredStoreChanged) {
            this.updateStateForNewPreferredStore();
        }
    }

    checkPreferredStoreChange = prevProps => {
        const { user } = this.props;
        const currentPostalCode = user?.preferredStoreInfo?.address?.postalCode;
        const previousPostalCode = prevProps.user?.preferredStoreInfo?.address?.postalCode;

        return user?.preferredStore && currentPostalCode !== previousPostalCode;
    };

    updateStateForNewPreferredStore = () => {
        const { user } = this.props;
        const preferredStorePostalCode = user?.preferredStoreInfo?.address?.postalCode;
        const newLocationDisplay = preferredStorePostalCode || this.state.location.display;

        if (newLocationDisplay !== this.state.location.display) {
            const activity = this.getActivityInitialState();
            this.getExperienceInfoByLocation();

            this.setState({
                location: this.state.location,
                activity,
                startDateParam: ExperienceDetailsUtils.getStartDateFromParam()
            });
        }
    };

    loadActivityPolicies = (activity = {}) => {
        if (!activity?.activityId) {
            // eslint-disable-next-line no-console
            console.warn('no Activity id provided');

            return;
        }

        const { activityId } = activity;
        const activityKey = `${LOCAL_STORAGE.EDP_POLICIES}_${activityId}_${localeUtils.getCurrentLanguage()}`;
        const cachedActivityPolicies = Storage.local.getItem(activityKey);

        if (!cachedActivityPolicies) {
            olrApi
                .getPoliciesPerActivity({ activityId: activityId })
                .then(({ responseStatus, ...activityPolicies }) => {
                    const activityPoliciesArray = Object.keys(activityPolicies).map(key => ({
                        key,
                        ...activityPolicies[key]
                    }));

                    Storage.local.setItem(activityKey, activityPoliciesArray, 1000 * 15);
                    this.setState({ activityPolicies: activityPoliciesArray });
                })
                // eslint-disable-next-line no-console
                .catch(console.error);
        } else {
            this.setState({ activityPolicies: cachedActivityPolicies });
        }
    };

    loadGoogleScript = callback => {
        //load google place script
        scriptUtils.loadScripts([scriptUtils.SCRIPTS.GOOGLE], callback);
    };

    getActivityInitialState = () => {
        const path = locationUtils.getLocation().pathname.split('/');

        const activity = {
            activityId: path.pop(),
            activityType: path.pop(),
            endPoint: path.pop()
        };

        if (!ACTIVITY.isActivityTypeValid(activity.activityType)) {
            activity.activityType = null;
        }

        return activity.activityId && activity.activityType ? activity : null;
    };

    getExperienceInfoByLocation = async () => {
        const activityType = await this.getActivityInitialState()?.activityType;
        const dateTimes = ExperienceDetailsUtils.getDefaultDateTimes(this.state.startDateParam, this.state.activity.activityType || activityType);
        userLocation.determineLocation(
            locationObj => {
                this.getExperienceInfo(locationObj, dateTimes);
            },
            null,
            { sequence: userLocation.getEdpStrategiesSequence() }
        );
    };

    setupExperienceListener = () => {
        const defaultDateTimes = ExperienceDetailsUtils.getDefaultDateTimes(this.state.startDateParam, this.state.activity.activityType);
        store.watchAction(ExperienceDetailsActions.TYPES.UPDATE_EXPERIENCE, actionData => {
            const currentLocation = this.state.location;
            const locationToSearch = actionData.location || currentLocation;
            const locationString = locationToSearch ? locationToSearch.display : null;
            const startDate = actionData.selectedDay || defaultDateTimes.start;
            const endDate = actionData.selectedDay || defaultDateTimes.end;

            decorators
                .withInterstice(ExperienceDetailsUtils.getExperienceByStoresAndLocation)(this.state.activity, locationToSearch, startDate, endDate)
                .then(experienceData => {
                    if (experienceData.storeAvailabilities.length) {
                        this.setState({
                            activities: experienceData.storeAvailabilities,
                            questions: experienceData.questions,
                            location: locationToSearch,
                            selectedDay: startDate,
                            storeList: experienceData.storeList
                        });

                        if (actionData.location) {
                            userLocation.setNewLocation(actionData.location);
                        }
                    } else {
                        //set state to display that there are no activities to show
                        if (experienceData.storeList.length) {
                            this.setState({ noActivities: true });
                            //set state to display that there are no stores to show
                        } else {
                            this.setState({ noStoreLocation: locationString });
                        }
                    }
                })
                .catch(() => {
                    //set state to display that there are no stores
                    //within given area.
                    this.setState({ noStoreLocation: locationString });
                });
        });
    };

    getExperienceInfo = (currentLocation, dateTimes, stores) => {
        // Fetch activities based on the given arguments.
        const fetchActivities = (method, args) => {
            return method(...args)
                .then(updateExperienceState)
                .catch(handleFetchError);
        };

        // Update the state based on the received experience data.
        const updateExperienceState = experienceData => {
            const updatedLocation = getUpdatedLocation();

            store.dispatch(ExperienceDetailsActions.updateExperience({ location: updatedLocation }));

            this.setState({
                activities: experienceData.storeAvailabilities,
                questions: experienceData.questions,
                isLoaded: true,
                location: updatedLocation,
                storeList: experienceData.storeList
            });
        };

        // Handle errors that occur during the fetch.
        const handleFetchError = error => {
            // eslint-disable-next-line no-console
            console.error(error);
            ExperienceDetailsUtils.redirectToHub();
        };

        // Determine the updated location based on user's preferred store information.
        const getUpdatedLocation = () => {
            const { latitude, longitude, address } = this.props.user.preferredStoreInfo;
            const updatedLocation = {
                lat: latitude,
                lon: longitude,
                display: address?.postalCode,
                country: address?.country
            };

            return updatedLocation.display !== undefined ? updatedLocation : currentLocation;
        };

        // Decide which API call method and arguments to use.
        const apiCallMethod = stores ? ExperienceDetailsUtils.getExperienceByStores : ExperienceDetailsUtils.getExperienceByStoresAndLocation;
        const apiCallArgs = stores
            ? [stores, this.state.activity, dateTimes.start, dateTimes.end]
            : [this.state.activity, getUpdatedLocation(), dateTimes.start, dateTimes.end];

        return fetchActivities(apiCallMethod, apiCallArgs);
    };

    resetErrors = () => {
        this.setState({
            noStoreLocation: null,
            noActivities: null
        });
    };

    updateActivities = newActivities => {
        this.setState({
            /* We need to match the store order here because these newActivities are from the result
            of concatenating activities and the final order might not be correct */
            activities: ExperienceDetailsUtils.matchStoreOrder(newActivities, this.state.storeList)
        });
    };

    setPageLoadAnalytics = activityInfo => {
        const activityType = ACTIVITY.ANALYTICS_OLR_EXPERIENCE[activityInfo.type];

        const confirmationNumberToCancel = urlUtils.getParamsByName('reschedule') ? urlUtils.getParamsByName('reschedule')[0] : null;
        let pageName;
        const now = new Date();

        if (confirmationNumberToCancel) {
            pageName = `reschedule ${activityType}-date & location`;
            digitalData.page.category.pageType = anaConsts.PAGE_TYPES.OLR;
            digitalData.page.pageInfo.pageName = pageName;
            digitalData.page.attributes.additionalPageInfo = `title=${activityInfo.name}`;
            digitalData.page.attributes.date.dayName = dateUtils.getDayOfWeek(now);
        } else {
            digitalData.page.category.pageType = anaConsts.PAGE_TYPES.OLR;
            digitalData.page.pageInfo.pageName = `book a ${activityType}-landing`;
            digitalData.page.attributes.additionalPageInfo = `title=${activityInfo.name}`;
            digitalData.page.attributes.previousPageData.linkData = `happening:start booking:${activityType}:${activityInfo.name}`;
        }

        readyForAnalytics();
    };

    updateSeoTags = async activityInfo => {
        //canonicalUrl desktop channel will be used for mobile as well per AC requirement
        //alternateUrl mobile will be used for desktop as well per AC requirement
        if (activityInfo) {
            const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/ExperienceDetailPage/locales', 'ExperienceInfo');

            const { activityId, activityType, endPoint } = activityInfo;

            const {
                name = [], startDate = [], city = [], state = [], storeId = []
            } = urlUtils.getParams();

            const locationPart = city[0] && state[0] ? getText('inCityState', [city, state]) : '';
            const edpTitle =
                activityType === ACTIVITY.TYPE.EVENTS
                    ? `${name}${locationPart} | Sephora`
                    : `${getText('beauty', [ACTIVITY.SEO_LABEL[activityType]])}${locationPart}: ${name} | Sephora`;

            document.title = edpTitle;

            let ogTitleTag = document.querySelector('meta[property="og:title"]');

            if (!ogTitleTag) {
                ogTitleTag = document.createElement('meta');
                ogTitleTag.setAttribute('property', 'og:title');
                ogTitleTag.content = edpTitle;
                document.getElementsByTagName('head')[0].appendChild(ogTitleTag);
            } else {
                ogTitleTag.content = edpTitle;
            }

            const queryParams = urlUtils.makeQueryString({
                storeId,
                startDate,
                name,
                city,
                state
            });
            await locationUtils.updateSeoCanonicalUrl(`/${endPoint}/${activityType}/${activityId}?${queryParams}`);
        }
    };

    render() {
        const getText = localeUtils.getLocaleResourceFile('components/OnlineReservation/ExperienceDetailPage/locales', 'ExperienceInfo');

        return !this.state.isLoaded || !this.state.activity ? this.getLoadingScreenPageContent() : this.getPageContent(getText);
    }

    getLoadingScreenPageContent = () => {
        return (
            <Loader
                hasBg={false}
                isFixed={true}
                isShown={true}
            />
        );
    };

    handleShareExperienceClick = (getText, activityInfo) => () => {
        ExperienceDetailsUtils.launchExperienceShareModal(
            <strong>{getText('copyLinkShare')}</strong>,
            ExperienceDetailsUtils.getEdpPageUrl(activityInfo)
        );
    };

    /* eslint-disable-next-line complexity */
    getPageContent = getText => {
        const isDesktop = Sephora.isDesktop();
        const isMobile = Sephora.isMobile();
        const isCanada = localeUtils.isCanada();
        const activityInfo = ExperienceDetailsUtils.getFirstBookableActivity(this.state.activities);
        const isVirtual = activityInfo?.isVirtual;
        const isService = activityInfo?.type === ACTIVITY.TYPE.SERVICES;
        const isPersonalService = activityInfo?.activityId === ACTIVITY.PERSONAL_SHOPPING_SERVICE_ACTIVITY_ID;
        const shouldRedirectToHub = (isVirtual || isPersonalService) && isCanada;

        // no activity entity was found to get the data from
        if (!activityInfo || shouldRedirectToHub) {
            ExperienceDetailsUtils.redirectToHub();

            return null;
        }

        /* eslint-disable prefer-const */
        let {
            activities, questions, storeList, startDateParam, noStoreLocation, noActivities
        } = this.state;
        /* eslint-enable prefer-const */

        const storesWithActivities = storeUtils.getStoresFromActivities(activities, storeList);
        let showDuration = true;
        let sideComponent;
        let storeToRemove = 0;
        let storesOnMap;
        let showFirstMarkerInfoBox = true;

        this.setPageLoadAnalytics(activityInfo);

        switch (activityInfo.type) {
            case ACTIVITY.TYPE.SERVICES:
            case ACTIVITY.TYPE.CLASSES:
                storesOnMap = storesWithActivities.slice(0, 1);
                sideComponent = (
                    <BookWidget
                        activityPolicies={this.state.activityPolicies}
                        selectedDay={this.state.selectedDay}
                        location={this.state.location}
                        activities={activities}
                        startDateParam={startDateParam}
                        questions={questions}
                        noActivities={noActivities}
                        noStoreLocation={noStoreLocation}
                        resetErrors={this.resetErrors}
                        storesWithActivities={storesWithActivities}
                        storeList={storeList}
                    />
                );

                break;
            case ACTIVITY.TYPE.EVENTS:
            case ACTIVITY.TYPE.ANNOUNCEMENTS:
                activities = this.state.activities.filter(activity => {
                    if (activity.timeSlots.length > 0) {
                        storeToRemove++;

                        return true;
                    } else {
                        storesWithActivities.splice(storeToRemove, 1);

                        return false;
                    }
                });
                storesOnMap = storesWithActivities.slice(0, activities.length);
                showDuration = false;
                showFirstMarkerInfoBox = false;
                sideComponent = (
                    <RsvpWidget
                        activityInfo={activityInfo}
                        activities={activities}
                        startDateParam={startDateParam}
                        storeList={storeList.slice()}
                        updateActivities={this.updateActivities}
                    />
                );

                break;
            default:
                sideComponent = null;
        }

        const { listPrice: price } = activityInfo?.priceInfo || {};
        const priceInfo = activityInfo?.priceInfo || {};

        return (
            <LegacyContainer
                is='main'
                marginTop={isDesktop ? 5 : null}
            >
                <LegacyGrid gutter={isDesktop ? 5 : null}>
                    <LegacyGrid.Cell width={isDesktop ? 'fill' : null}>
                        <Box
                            position='relative'
                            marginBottom={3}
                            marginX={isMobile ? '-container' : null}
                        >
                            <Box paddingBottom='50%' />
                            <ExperienceImage
                                alt={activityInfo.name}
                                activity={activityInfo}
                            />
                        </Box>
                        <Flex
                            alignItems='flex-start'
                            justifyContent='space-between'
                            marginBottom={isMobile ? 1 : 2}
                        >
                            <SimpleBreadCrumbs {...ExperienceDetailsUtils.getEdpBreadcrumbs(activityInfo)} />
                            <Flex
                                fontSize={20}
                                lineHeight='0'
                                marginTop={-1}
                                marginRight={1}
                            >
                                <Box
                                    aria-label={getText('shareExperience')}
                                    paddingY={2}
                                    paddingX={1}
                                    marginLeft={1}
                                    data-at={Sephora.debug.dataAt('share_button')}
                                    onClick={this.handleShareExperienceClick(getText, activityInfo)}
                                >
                                    <IconShare />
                                </Box>
                            </Flex>
                        </Flex>
                        <Text
                            is='h1'
                            fontSize={isMobile ? 'md' : 'lg'}
                            fontWeight='bold'
                            lineHeight='tight'
                            children={activityInfo.name}
                        />
                        {showDuration && activityInfo.timeSlots[0] && activityInfo.timeSlots[0].durationMin && (
                            <Text
                                is='p'
                                fontSize='xs'
                                color='gray'
                                fontWeight='bold'
                                css={{ textTransform: 'uppercase' }}
                                children={`${activityInfo.timeSlots[0].durationMin} ${getText('minutesAbbr')}`}
                            />
                        )}
                        {isService && price && (
                            <Flex
                                marginTop={isMobile ? 1 : 3}
                                alignItems='center'
                            >
                                <Text
                                    fontSize={isMobile ? 'base' : 'md'}
                                    fontWeight='bold'
                                    marginRight={2}
                                    data-at={Sephora.debug.dataAt('price_label')}
                                    children={price}
                                />

                                <Tooltip
                                    dataAt='popover_info_text'
                                    content={ExperienceDetailsUtils.getText('paymentShort', [price])}
                                >
                                    <InfoButton size='1em' />
                                </Tooltip>
                            </Flex>
                        )}
                        <Divider marginY={4} />
                        {isMobile && (
                            <Box marginY={5}>
                                {sideComponent}
                                <Divider marginTop={5} />
                            </Box>
                        )}
                        <Text
                            {...ACTIVITY.SUBSECTION_HEADER_PROPS}
                            data-at={Sephora.debug.dataAt('description_section')}
                            children={getText('description')}
                        />
                        <Text
                            is='p'
                            data-at={Sephora.debug.dataAt('description_text')}
                            children={activityInfo.description}
                        />

                        {this.state.activityPolicies && renderActivityPolicies(this.state.activityPolicies, priceInfo)}

                        {!isVirtual && activities.length > 0 && !noStoreLocation && !noActivities && (
                            <React.Fragment>
                                {isMobile && <Divider marginY={5} />}
                                <Text
                                    is='h3'
                                    fontWeight='bold'
                                    lineHeight='tight'
                                    marginY={4}
                                    children={getText('map')}
                                />
                                <Box marginX={isMobile ? '-container' : null}>
                                    <GoogleMap
                                        ratio={Sephora.isMobile ? 1 / 2 : 2 / 1}
                                        selectedStore={storesWithActivities[0]}
                                        stores={storesOnMap}
                                        showFirstMarkerInfoBox={showFirstMarkerInfoBox}
                                        isZoomControlShown
                                    />
                                </Box>
                            </React.Fragment>
                        )}
                    </LegacyGrid.Cell>
                    {isDesktop && (
                        <LegacyGrid.Cell
                            width={398}
                            children={sideComponent}
                        />
                    )}
                </LegacyGrid>
                <Divider marginY={5} />
                <script
                    type='application/ld+json'
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify(this.getBreadcrumbsJsonLd())
                    }}
                />
                {activityInfo.type === ACTIVITY.TYPE.CLASSES && (
                    <script
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(this.getCourseJsonLd(activityInfo))
                        }}
                    />
                )}
                {activityInfo.type === ACTIVITY.TYPE.EVENTS && (
                    <script
                        type='application/ld+json'
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(this.getEventJsonLd(activityInfo, storeList[0]))
                        }}
                    />
                )}
            </LegacyContainer>
        );
    };

    getBreadcrumbsJsonLd = () => {
        const domPrefix = 'www';

        return {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    position: 1,
                    '@type': 'ListItem',
                    item: {
                        name: 'Happening at Sephora',
                        '@id': 'https://' + domPrefix + '.sephora.com' + ACTIVITY.OLR_URLS.LANDING_PAGE
                    }
                },
                {
                    position: 2,
                    '@type': 'ListItem',
                    item: {
                        name: this.state.activity.activityType,
                        '@id':
                            'https://' +
                            domPrefix +
                            '.sephora.com/happening/' +
                            this.state.activity.activityType +
                            '/' +
                            this.state.activity.activityId
                    }
                }
            ]
        };
    };

    //Copied over from previous sprint
    //Author: Thomas Watts
    getCourseJsonLd = activityInfo => {
        const domPrefix = 'www';

        return {
            '@context': 'http://schema.org',
            '@type': 'Course',
            name: activityInfo.name,
            description: stringUtils.spliceByFullWords(activityInfo.description, 60),
            provider: {
                '@type': 'Organization',
                name: 'Sephora',
                sameAs: 'https://' + domPrefix + `.sephora.com/happening/classes/${activityInfo.activityId}${global.location.search}`
            }
        };
    };

    //Copied over from previous sprint
    //Author: Thomas Watts
    getEventJsonLd = (activityInfo, storeInfo) => {
        const domPrefix = 'www';
        const activityImageSrc =
            Object.keys(activityInfo.images).length > 0 && activityInfo.images.baseImage
                ? activityInfo.images.baseImage
                : ExperienceDetailsUtils.EXPERIENCE_DEFAULT_IMAGES[2];

        return {
            '@context': 'http://schema.org',
            '@type': 'Event',
            name: activityInfo.name,
            startDate: activityInfo.startDate,
            location: {
                '@type': 'Place',
                name: storeInfo.displayName,
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: storeInfo.address.address1,
                    addressLocality: storeInfo.address.city,
                    postalCode: storeInfo.address.postalCode,
                    addressRegion: storeInfo.address.state,
                    addressCountry: storeInfo.address.country
                }
            },
            image: [activityImageSrc],
            description: activityInfo.description,
            endDate: activityInfo.endDate,
            offers: {
                '@type': 'Offer',
                url: `https://${domPrefix}.sephora.com/happening/events/${activityInfo.activityId}`,
                price: '0',
                priceCurrency: localeUtils.isCanada() ? 'CAD' : 'USD'
            }
        };
    };
}

export default wrapComponent(ExperienceInfo, 'ExperienceInfo');
