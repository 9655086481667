import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import languageLocale from 'utils/LanguageLocale';
import numberUtils from 'utils/Number';

const { formatReviewCount } = numberUtils;
const { getLocaleResourceFile } = languageLocale;

const getText = getLocaleResourceFile('components/Product/ReviewCount/locales', 'ReviewCount');

function ReviewCount({ productReviewCount, ...props }) {
    return productReviewCount > 0 ? (
        <span
            {...props}
            data-at={Sephora.debug.dataAt('review_count')}
            aria-label={`${formatReviewCount(productReviewCount)} ${getText('reviews')}`}
            children={`${formatReviewCount(productReviewCount)}`}
        />
    ) : null;
}

export default wrapFunctionalComponent(ReviewCount, 'ReviewCount');
