import { UPDATE_SMARTCHAT_STATE } from 'constants/actionTypes/smartChat';

export default {
    updateSmartChatState: function (isSmartChatAvailable, isSmartChatVisible, chatData, isChatOpen) {
        return {
            type: UPDATE_SMARTCHAT_STATE,
            payload: {
                isSmartChatAvailable,
                isSmartChatVisible,
                chatData,
                isChatOpen
            }
        };
    }
};
