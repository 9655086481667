export default function getResource(label, vars = []) {
    const resources = {
        location: 'Location',
        getDirections: 'Get Directions',
        callStore: 'Call Store',
        closed: 'Closed',
        away: 'away',
        hours: 'Hours',
        openUntil: 'Open today until',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
        sunday: 'Sun'

    };

    return resources[label];
}
