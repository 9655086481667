import ufeApi from 'services/api/ufeApi';
import headerUtils from 'utils/Headers';

const { userXTimestampHeader } = headerUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Create+BI+Account+API

function createBiAccount(input) {
    const url = '/api/bi/account';

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            headers: userXTimestampHeader(),
            body: JSON.stringify(input)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default createBiAccount;
