export default function getResource(label, vars = []) {
    const resources = {
        Standard: 'Standard order.',
        Pickup: 'Buy Online & Pick Up basket',
        Sameday: 'Same-Day Delivery order.',
        ShipToHome: 'Get It Shipped basket',
        ViewYour: 'View your '
    };

    return resources[label];
}
