export default function getResource(label, vars = []) {
    const resources = {
        youNowHaveText: 'You now have ',
        rewardsAddedText: 'Use Your Beauty Insider Points',
        pointsRemainingText: ' points',
        applyText: 'Apply',
        upToText: 'Up to ',
        availableText: ' available',
        addRewardsBazaarItemsText: 'Add Rewards Bazaar items',
        rewardsBazaarText: 'Rewards Bazaar',
        itemAddedText: ' item added',
        youAreExceeding: 'You are exceeding by',
        points: 'points'
    };

    return resources[label];
}
