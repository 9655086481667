import ufeApi from 'services/api/ufeApi';
import headerUtils from 'utils/Headers';

const { userXTimestampHeader } = headerUtils;

// https://jira.sephora.com/wiki/display/ILLUMINATE/Add+to+Cart+API

function addToCart({ orderId, skuList, fulfillmentType = null }, includeAllBasketItems) {
    let url = '/api/shopping-cart/basket/items';
    const body = {
        orderId,
        skuList
    };

    if (includeAllBasketItems) {
        url += '?includeAllBasketItems=true';
    }

    if (fulfillmentType) {
        body.fulfillmentType = fulfillmentType;
    }

    return ufeApi
        .makeRequest(url, {
            method: 'POST',
            headers: userXTimestampHeader(),
            body: JSON.stringify(body)
        })
        .then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default addToCart;
