const ACTION_TYPES = {
    UPDATE_LOVES_LIST: 'UPDATE_LOVES_LIST',
    UPDATE_PUBLIC_LOVES_LIST: 'UPDATE_PUBLIC_LOVES_LIST',
    UPDATE_SHOPPING_LIST_IDS: 'UPDATE_SHOPPING_LIST_IDS'
};

const initialState = {
    currentLoves: [],
    shoppingListIds: [],
    publicLoves: [],
    totalLovesListItemsCount: 0,
    totalPublicLovesListItemsCount: 0,
    shareLink: null,
    currentLovesIsInitialized: false
};

/* shoppingListIds contains the skuId of every loved item in currentLoves.
 *
 * This is to ease the handling of add/remove loves locally upon POST/DELETE success.
 * The addLove and removeLove actions will manipulate the requested sku accordingly here
 * as we don't really need to fetch the updated list of loved skus if the API operations
 * were successful. This state will not persist session, however, each page load will
 * populate the list correctly.
 */

const reducer = function (state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_LOVES_LIST:
            return Object.assign({}, state, {
                currentLovesIsInitialized: true,
                currentLoves: action.currentLoves,
                totalLovesListItemsCount: action.totalLovesListItemsCount,
                shareLink: action.shareLink
            });
        case ACTION_TYPES.UPDATE_SHOPPING_LIST_IDS:
            return Object.assign({}, state, { shoppingListIds: action.shoppingListIds });
        case ACTION_TYPES.UPDATE_PUBLIC_LOVES_LIST:
            return Object.assign({}, state, {
                publicLoves: action.publicLoves,
                totalPublicLovesListItemsCount: action.totalPublicLovesListItemsCount
            });
        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
