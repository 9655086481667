/*global insideFrontInterface _inside*/

/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import MediaUtils from 'utils/Media';
import store from 'store/Store';
import { space } from 'style/config';
import { injectGlobal } from 'emotion';
import analyticsUtils from 'analytics/utils';
import BaseClass from 'components/BaseClass';
import FrameworkUtils from 'utils/framework';
import localeUtils from 'utils/LanguageLocale';
import Tooltip from 'components/Tooltip/Tooltip';
import processEvent from 'analytics/processEvent';
import analyticsConsts from 'analytics/constants';
import Dropdown from 'components/Dropdown/Dropdown';
import ChatIcon from 'components/SmartChat/ChatLink/ChatIcon';
import CountCircle from 'components/CountCircle/CountCircle';
import {
    Icon, Box, Flex, Text, Divider, Image, Grid, Link, Button
} from 'components/ui';

const CHAT_ICON_PADDING = [2, 3];

const getText = localeUtils.getLocaleResourceFile('components/SmartChat/ChatLink/locales', 'ChatLink');

const IN_HOURS_KEYS = ['in-hours', 'in-hours-french', 'in-hours-products'];

const OFFLINE_HOURS_KEYS = ['offline-hours', 'offline-hours-french', 'offline-hours-products'];

const { Media } = MediaUtils;
const { wrapComponent } = FrameworkUtils;

class ChatLink extends BaseClass {
    constructor(props) {
        super(props);
        this.state = {
            isSmartChatAvailable: false,
            chatData: null,
            isChatOpen: false,
            isDropdownOpen: false
        };
        this.dropdownRef = React.createRef();
        injectGlobal`
            #inside_holder { z-index: var(--layer-modal) !important }
        `;
    }

    handleClick = () => {
        this.setNextPageLoadAnalyticsData('customer service chat');

        if (this.state.isSmartChatAvailable) {
            // analytics click tracking when chat is available
            processEvent.process(analyticsConsts.LINK_TRACKING_EVENT, {
                data: {
                    linkName: 'D=c55',
                    actionInfo: analyticsConsts.LinkData.CUSTOMER_CHAT_NOW_CTA,
                    eventStrings: [analyticsConsts.Event.EVENT_71, analyticsConsts.Event.EVENT_220]
                }
            });

            insideFrontInterface.openChatPane();
        } else {
            // analytics click tracking when chat is unavailable
            processEvent.process(analyticsConsts.LINK_TRACKING_EVENT, {
                data: {
                    actionInfo: analyticsConsts.LinkData.CUSTOMER_CHAT_UNAVAILABLE,
                    linkName: 'D=c55',
                    eventStrings: [analyticsConsts.Event.EVENT_71]
                }
            });
        }
    };

    render() {
        const { isSmartChatAvailable, chatData, isChatOpen } = this.state;
        const { variant, ctaText, ...props } = this.props;
        const unreadMsg = chatData ? chatData?.unreadMessageCount : 0;
        const showUnreadMsgCount = unreadMsg > 0 && !isChatOpen;

        let message;

        if (isSmartChatAvailable) {
            message = getText('available');
        } else {
            const chatSettingsId =
                typeof insideFrontInterface !== 'undefined' &&
                insideFrontInterface.chat &&
                insideFrontInterface.chat.data &&
                insideFrontInterface.chat.data.chatSettingsId;

            // default unavailability message if chatSettingsId is missing or unknown
            message = getText('unavailable');

            if (chatSettingsId && _inside.chatSettings) {
                // _inside.chatSettings obj structure:
                // { in-hours: 1, in-hours-french: 2, offline-hours: 3, offline-hours-french: 4, ...}
                const mapKeysToSettingsIds = key => _inside.chatSettings[key];
                const inHoursSettingsIds = IN_HOURS_KEYS.map(mapKeysToSettingsIds);
                const offlineHoursSettingsIds = OFFLINE_HOURS_KEYS.map(mapKeysToSettingsIds);

                if (inHoursSettingsIds.indexOf(chatSettingsId) !== -1) {
                    message = getText('inHours');
                } else if (offlineHoursSettingsIds.indexOf(chatSettingsId) !== -1) {
                    message = getText('offline');
                }
            }
        }

        if (variant === 'icon') {
            return isSmartChatAvailable ? (
                <>
                    <Media at='xs'>
                        <ChatIcon
                            paddingX={CHAT_ICON_PADDING}
                            height='100%'
                            onClick={this.handleClick}
                        >
                            {showUnreadMsgCount && <CountCircle children={unreadMsg} />}
                        </ChatIcon>
                    </Media>
                    <Media greaterThan='xs'>
                        <Dropdown
                            ref={this.dropdownRef}
                            id='chat_drop'
                            height='100%'
                            position='static'
                            onTrigger={(e, isDropOpen) => {
                                this.setState({
                                    isDropdownOpen: isDropOpen
                                });
                            }}
                        >
                            <Dropdown.Trigger
                                paddingX={CHAT_ICON_PADDING}
                                height='100%'
                                onClick={this.handleClick}
                            >
                                <ChatIcon isFilled={this.state.isDropdownOpen}>{showUnreadMsgCount && <CountCircle children={unreadMsg} />}</ChatIcon>
                            </Dropdown.Trigger>
                            <Dropdown.Menu
                                width={this.props.dropWidth}
                                align='right'
                            >
                                {this.getMarketingContent(false)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Media>
                </>
            ) : (
                <Tooltip
                    sideOffset={-8}
                    content={getText('unavailablePopoverText')}
                >
                    <ChatIcon
                        aria-label={getText('unavailable')}
                        isUnavailable={true}
                        onClick={this.handleClick}
                        paddingX={CHAT_ICON_PADDING}
                    />
                </Tooltip>
            );
        } else if (variant === 'button') {
            return (
                <Flex
                    onClick={this.handleClick}
                    alignItems='center'
                    paddingY={2}
                    paddingX={4}
                    fontSize='base'
                    lineHeight='none'
                    color='black'
                    backgroundColor='white'
                    border={1}
                    borderColor='lightGray'
                    borderRadius='full'
                    disabled={!isSmartChatAvailable}
                    css={
                        isSmartChatAvailable && {
                            '.no-touch &:hover .ChatLink-target': {
                                textDecoration: 'underline'
                            }
                        }
                    }
                    {...props}
                >
                    <ChatIcon
                        showUpdatedUnavailableIcon={true}
                        marginRight={2}
                        isUnavailable={!isSmartChatAvailable}
                    />
                    <span>
                        <strong
                            className='ChatLink-target'
                            children={getText('title')}
                        />
                        <br />
                        <Text
                            fontSize='sm'
                            color='gray'
                            children={message}
                        />
                    </span>
                </Flex>
            );
        } else if (variant === 'button-transparent') {
            return (
                <Flex
                    onClick={this.handleClick}
                    alignItems='center'
                    paddingY={2}
                    paddingX={4}
                    fontSize='base'
                    lineHeight='none'
                    color='black'
                    backgroundColor={'transparent'}
                    border={2}
                    borderColor={'#000'}
                    borderRadius='full'
                    disabled={!isSmartChatAvailable}
                    css={
                        isSmartChatAvailable && {
                            '.no-touch &:hover .ChatLink-target': {
                                textDecoration: 'underline'
                            }
                        }
                    }
                    {...props}
                >
                    <ChatIcon
                        showUpdatedUnavailableIcon={true}
                        marginRight={2}
                        isUnavailable={!isSmartChatAvailable}
                    />
                    <span>
                        <strong
                            css='gray'
                            className='ChatLink-target'
                            children={getText('title')}
                        />
                        <br />
                        <Text
                            fontSize='sm'
                            color='gray'
                            children={message}
                        />
                    </span>
                </Flex>
            );
        } else {
            return (
                <Flex
                    onClick={this.handleClick}
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                    lineHeight='tight'
                    disabled={!isSmartChatAvailable}
                    css={
                        isSmartChatAvailable && {
                            '.no-touch &:hover .ChatLink-target': {
                                textDecoration: 'underline'
                            }
                        }
                    }
                    {...props}
                >
                    <Flex>
                        <ChatIcon
                            marginRight={2}
                            isUnavailable={!isSmartChatAvailable}
                        />
                        <span>
                            <span
                                className='ChatLink-target'
                                css={{ fontWeight: 'var(--font-weight-bold)' }}
                                children={getText('title')}
                            />
                            <br />
                            {message}
                        </span>
                    </Flex>
                    {isSmartChatAvailable && ctaText && (
                        <Link
                            display='block'
                            color='blue'
                            children={ctaText}
                        />
                    )}
                </Flex>
            );
        }
    }

    componentDidMount() {
        store.setAndWatch('smartChat', this, data => {
            this.setState({
                isSmartChatAvailable: data.smartChat.isSmartChatAvailable,
                chatData: data.smartChat.chatData,
                isChatOpen: data.smartChat.isChatOpen
            });
        });
    }

    getMarketingContent = isXS => {
        return (
            <Box
                paddingY={[3, 4]}
                paddingX={4}
                {...(isXS && {
                    position: 'fixed',
                    zIndex: 'var(--layer-header)',
                    left: 2,
                    right: 2,
                    bottom: `calc(var(--bottomNavHeight) + ${space[2]}px)`,
                    backgroundColor: 'white',
                    boxShadow: 'light',
                    borderRadius: 3
                })}
            >
                {isXS || (
                    <>
                        <Text
                            is='h2'
                            fontWeight='bold'
                            children={getText('title')}
                        />
                        <Divider
                            marginTop={3}
                            marginBottom={4}
                            marginX={-4}
                        />
                    </>
                )}
                <Grid
                    gap={3}
                    marginBottom={[3, 4]}
                    alignItems='center'
                    columns='auto 1fr'
                >
                    <Image
                        display='block'
                        src='/img/ufe/chat-experts.jpg'
                        width={103}
                        height={46}
                    />
                    <div>
                        {isXS && (
                            <Text
                                is='h2'
                                marginBottom='.125em'
                                fontWeight='bold'
                                children={getText('title')}
                            />
                        )}
                        <p>
                            {getText('marketingMsgText')}
                            <Link
                                href='/beauty/live-chat'
                                display='block'
                                padding={2}
                                margin={-2}
                                marginTop={-1}
                                color='blue'
                                children={getText('marketingMsgLink')}
                            />
                        </p>
                    </div>
                </Grid>
                {isXS || (
                    <Divider
                        marginBottom={4}
                        marginX={-4}
                    />
                )}
                <Button
                    onClick={this.handleClick}
                    block={true}
                    size='sm'
                    variant='primary'
                    children={getText('marketingMsgButton')}
                />
                {isXS && (
                    <Icon
                        name='x'
                        size={12}
                        css={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: space[4],
                            right: space[4]
                        }}
                    />
                )}
            </Box>
        );
    };

    setNextPageLoadAnalyticsData = linkTitle => {
        const navigationInfo = analyticsUtils.buildNavPath(['toolbar nav', linkTitle, linkTitle, linkTitle, linkTitle]);
        analyticsUtils.setNextPageData({ navigationInfo });
    };
}

ChatLink.propTypes = {
    variant: PropTypes.oneOf(['link', 'button', 'button-transparent', 'icon']).isRequired
};

export default wrapComponent(ChatLink, 'ChatLink', true);
