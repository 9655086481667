/* eslint-disable class-methods-use-this */
import React from 'react';
import BaseClass from 'components/BaseClass';
import { wrapComponent } from 'utils/framework';
import {
    Box, Flex, Text, Button
} from 'components/ui';
import InputDate from 'components/Inputs/InputDate/InputDate';
import Radio from 'components/Inputs/Radio/Radio';
import IconCross from 'components/LegacyIcon/IconCross';
import ErrorMsg from 'components/ErrorMsg';

import KillSwitchUrlToggle from 'components/Preview/KillSwitchUrlToggle';
import localeUtils from 'utils/LanguageLocale';
import utilityApi from 'services/api/utility';
import CookieUtils from 'utils/Cookies';
import Location from 'utils/Location';
import ufeApi from 'services/api/ufeApi';

const FORMAT_DATE = /(\d{4})-(\d{1,2})-(\d{1,2})T(\d{1,2}):(\d{1,2})/;

/**
 * current datetime in ISO
 * b/c ios safari is the new IE6
 * non-standard printf 2000-01-01T00:00:00
 */
function generateIOS7Date() {
    const d = new Date();

    return (
        `${d.getFullYear()}-${d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1}` +
        `-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}T` +
        `${d.getHours() < 10 ? '0' + d.getHours() : d.getHours()}:00:00`
    );
}

function formatDate(dateString) {
    const result = FORMAT_DATE.exec(dateString).map(Number);
    const d = new Date();
    d.setFullYear(result[1]);
    d.setMonth(result[2] - 1, result[3]);
    d.setHours(result[4]);
    d.setMinutes(result[5]);
    d.setSeconds(0);

    return (
        `${d.getMonth() < 9 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1}/` +
        `${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}/` +
        `${d.getFullYear()} ${d.getHours() < 10 ? '0' + d.getHours() : d.getHours()}:` +
        `${d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()}:` +
        `${d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()}`
    );
}

class Preview extends BaseClass {
    state = {
        shouldSeePreview: false,
        assets: true,
        oosItems: false,
        dropdownOpen: false,
        calendarOpen: false
    };

    componentDidMount() {
        const shouldSeePreview =
            (Location.isOrderConfirmationPage() || (!Location.isCheckout() && !Location.isPreview())) &&
            CookieUtils.read(CookieUtils.KEYS.IS_PREVIEW_ENV_COOKIE);

        this.setState({ shouldSeePreview }, () => {
            if (this.state.shouldSeePreview) {
                utilityApi.getPreviewSettings().then(data => {
                    this.setState({
                        oosItems: data.viewOOSAsInStock,
                        assets: data.activeStatusCheck
                    });
                });
                const selectedDate = CookieUtils.read(CookieUtils.KEYS.PREVIEW_COOKIE);
                this.date.setValue(selectedDate ? selectedDate : generateIOS7Date());
            }
        });
    }

    setPreview = () => {
        const date = this.date.getValue(true);
        const queryParams = new URLSearchParams(global.window?.location.search);
        const prvCookie = CookieUtils.read(CookieUtils.KEYS.P13N_PRV);
        const context = prvCookie.split('|')[3] || queryParams.get('context');
        const variation = prvCookie.split('|')[4] || queryParams.get('variation');

        if (date.toString() !== 'Invalid Date') {
            utilityApi
                .updatePreviewSettings({
                    previewDate: formatDate(date),
                    activeStatusCheck: this.state.assets,
                    viewOOSAsInStock: this.state.oosItems,
                    ...(context && variation && { context, variation })
                })
                .then(data => {
                    if (data.profileStatus > -1 && data.profileLocale) {
                        ufeApi.flushCache();
                        this.setState({ message: 'Preview options set.' });
                        CookieUtils.write(CookieUtils.KEYS.PREVIEW_COOKIE, date, 0);

                        if (Location.isPreview()) {
                            CookieUtils.write(CookieUtils.KEYS.IS_PREVIEW_ENV_COOKIE, true);
                            Location.setLocation('/');
                        } else {
                            Location.reload();
                        }
                    } else {
                        this.setState({ message: 'An error occured.' });
                    }
                })
                .catch(() => this.setState({ message: 'An error occured.' }));
        } else {
            this.setState({ message: 'Invalid Date - please change value' });
        }
    };

    render() {
        const isTouch = Sephora.isTouch;
        const shouldSeePreview = this.state.shouldSeePreview;

        const getText = localeUtils.getLocaleResourceFile('components/Preview/locale', 'Preview');

        return shouldSeePreview ? (
            <Box
                backgroundColor='#04E3FF'
                padding={3}
                position='fixed'
                bottom={['var(--bottomNavHeight)', null, 0]}
                left={0}
                zIndex='header'
                onMouseEnter={
                    !isTouch &&
                    (() =>
                        this.setState({
                            dropdownOpen: true
                        }))
                }
                onMouseLeave={
                    !isTouch &&
                    (() =>
                        this.setState({
                            dropdownOpen: !!this.state.calendarOpen
                        }))
                }
            >
                <Flex
                    fontSize='md'
                    fontWeight='bold'
                    justifyContent='space-between'
                    marginBottom={2}
                >
                    <span>{getText('previewSettings')}</span>
                    {isTouch && (
                        <Box
                            padding={2}
                            margin={-2}
                            lineHeight={0}
                            onClick={() =>
                                this.setState({
                                    dropdownOpen: !this.state.dropdownOpen
                                })
                            }
                        >
                            <IconCross x={this.state.dropdownOpen} />
                        </Box>
                    )}
                </Flex>
                <InputDate
                    marginBottom={null}
                    ref={c => (this.date = c)}
                    type='datetime-local'
                    step='300'
                    onFocus={() =>
                        this.setState({
                            calendarOpen: true
                        })
                    }
                    onBlur={() =>
                        this.setState({
                            calendarOpen: false
                        })
                    }
                />
                <Box
                    marginTop={4}
                    style={{
                        display: !this.state.dropdownOpen ? 'none' : null
                    }}
                >
                    {this.state.message && <ErrorMsg children={this.state.message} />}
                    <Text
                        is='p'
                        fontWeight='bold'
                        marginBottom={1}
                    >
                        {getText('showAssets')}
                    </Text>
                    <Radio
                        name='previewAssets'
                        checked={this.state.assets}
                        value={1}
                        onChange={e =>
                            this.setState({
                                assets: e.currentTarget.value === '1'
                            })
                        }
                    >
                        {getText('active')}
                    </Radio>
                    <Radio
                        name='previewAssets'
                        checked={!this.state.assets}
                        value={0}
                        onChange={e =>
                            this.setState({
                                assets: e.currentTarget.value === '1'
                            })
                        }
                    >
                        {getText('all')}
                    </Radio>
                    <Box marginY={3}>
                        <Radio
                            name='previewOOS'
                            checked={!this.state.oosItems}
                            value={0}
                            onChange={e =>
                                this.setState({
                                    oosItems: e.currentTarget.value === '1'
                                })
                            }
                        >
                            {getText('viewOnActualInventory')}
                        </Radio>
                        <Radio
                            name='previewOOS'
                            checked={this.state.oosItems}
                            value={1}
                            onChange={e =>
                                this.setState({
                                    oosItems: e.currentTarget.value === '1'
                                })
                            }
                        >
                            {getText('viewAsInStock')}
                        </Radio>
                    </Box>
                    <KillSwitchUrlToggle getText={getText} />
                    <Button
                        variant='primary'
                        onClick={this.setPreview}
                        hasMinWidth={true}
                    >
                        {getText('go')}
                    </Button>
                </Box>
            </Box>
        ) : null;
    }
}

export default wrapComponent(Preview, 'Preview', true);
