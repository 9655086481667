import React from 'react';
import { wrapFunctionalComponent } from 'utils/framework';
import {
    Grid, Box, Text, Divider, Link, Flex
} from 'components/ui';
// eslint-disable-next-line no-unused-vars
import LazyLoad from 'components/LazyLoad/LazyLoad';
import ProductCard from 'components/Product/ProductCard';
import { site, space } from 'style/config';
import { CARD_GAP, CARD_WIDTH } from 'constants/productCard';
import Carousel from 'components/Carousel';

function ProductCardCarousel({
    carouselContextId,
    title,
    showAddToBasket,
    skus = [],
    onScroll,
    link,
    analyticsContext,
    isHorizontal,
    subTitle,
    onClick,
    source,
    urlImage
}) {
    const items = [];
    let hasVariationValue = false;

    if (skus.length > 0) {
        skus.forEach(item => {
            if (!hasVariationValue) {
                hasVariationValue = !!item.variationValue;
            }

            items.push(item);
        });
    } else {
        return null;
    }

    const cards = items.map((item, index) => (
        <ProductCard
            isHorizontal={isHorizontal}
            sku={item}
            analyticsContext={analyticsContext}
            showAddButton={showAddToBasket}
            imageSize={isHorizontal ? [60, 100] : 160}
            hasVariationValue={hasVariationValue}
            parentTitle={title}
            onClick={onClick}
            key={index}
            position={index}
            source={source}
            urlImage={urlImage}
        />
    ));

    return (
        <Box
            marginBottom={[6, 8]}
            data-at={Sephora.debug.dataAt('product_carousel')}
        >
            <Divider />
            <Grid
                alignItems='baseline'
                columns='1fr auto'
                lineHeight='tight'
                gap={3}
            >
                <Flex alignItems='baseline'>
                    <Text
                        is='h2'
                        marginY='1em'
                        fontSize={['md', 'lg']}
                        fontWeight='bold'
                        children={title}
                        data-at={Sephora.debug.dataAt('product_carousel_title')}
                    />
                    {subTitle && (
                        <Text
                            is='p'
                            color='gray'
                            fontSize='sm'
                            children={subTitle}
                            marginLeft='1em'
                        />
                    )}
                </Flex>
                {link && (
                    <Link
                        padding={2}
                        margin={-2}
                        color='blue'
                        {...link}
                    />
                )}
            </Grid>
            <LazyLoad
                contextId={carouselContextId}
                component={Carousel}
                gap={CARD_GAP}
                paddingY={4}
                marginX='-container'
                scrollPadding={[2, 'container']}
                {...(isHorizontal
                    ? {
                        itemWidth: [287, (site.containerMax - space[CARD_GAP[1]] * 2) / 3]
                    }
                    : {
                        itemWidth: CARD_WIDTH
                    })}
                items={cards}
                onScroll={onScroll}
                title={title}
                hasShadowHack={true}
            />
        </Box>
    );
}

ProductCardCarousel.shouldUpdatePropsOn = ['carouselContextId', 'skus'];

export default wrapFunctionalComponent(ProductCardCarousel, 'ProductCardCarousel');
