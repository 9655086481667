export default function getResource(label, vars = []) {
    const resources = {
        bookAnAppointment: 'Prendre rendez-vous',
        yourService: 'Vos services',
        reviewAndPay: 'Révision et paiement',
        confirmAppointmentDetails: 'Confirmer les détails du rendez-vous',
        free: 'GRATUIT'
    };

    return resources[label];
}
